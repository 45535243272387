import styled, { keyframes } from 'styled-components';

const EaseIn = keyframes`
  from{ opacity:0; }
  to{ opacity:1; }
`;

interface CardProps {
  active: boolean;
}

export const TitleSection = styled.section`
  margin: 60px 0px 90px 0px;
  color: var(--purple);

  h1 {
    font-size: 3rem;
    line-height: 3rem;
    @media (max-width: 355px) {
      flex-wrap: wrap;
      font-size: 2.7rem;
    }
    @media (max-width: 315px) {
      font-size: 1.8rem;
    }
  }
`;

export const Main = styled.main`
  padding: 1.5rem 0;
  width: 100%;
  margin-right: -100px;
  animation: ${EaseIn} 0.3s;

  .informacoes {
    color: var(--purple);
    margin-left: 20px;
    margin-bottom: 5px;
  }

  .text {
    color: var(--purple);
    margin: 20px 0 0 0;
    margin-bottom: 15px;
  }

  .inputcash {
    border-radius: 10px;
    padding-left: 20px;
    width: 200px;
    height: 40px;
    border: 1px solid var(--purple);
    font-size: 22px;
    ::-webkit-input-placeholder {
      color: #979797;
    }

    @media (max-width: 425px) {
      width: 100%;
    }
  }

  .recharge {
    display: grid;
  }

  .payment {
    display: flex;
  }

  .inputpayment {
    margin-top: 60px;
    margin-left: 15px;
    border-radius: 10px;
    padding-left: 20px;
    width: 350px;
    height: 40px;
    border: 1px solid var(--purple);
    ::-webkit-input-placeholder {
      color: #424b5a;
    }
  }

  .inputpayment1 {
    margin-top: 22px;
    margin-left: 113px;
    margin-right: -84px;
    border-radius: 10px;
    padding-left: 20px;
    height: 40px;
    width: 160px;
    border: 1px solid var(--purple);
    ::-webkit-input-placeholder {
      color: #424b5a;
    }
  }

  .button {
    margin-top: 25px;
    margin-right: 20px;
    border-radius: 15px;
  }

  .button-grey {
    margin-top: 60px;
    width: 78px;
    background-color: #d8d8d8;
    margin-right: 20px;
    border-radius: 7px;
  }

  .button-confirmar {
    margin-top: 60px;
    margin-right: 20px;
    margin-bottom: 10px;
    border-radius: 16px;
    @media (max-width: 365px) {
      width: 100%;
    }
  }

  .button-cancelar {
    margin-top: 10px;
    margin-bottom: 100px;
    color: var(--orange);
    border: 2px solid var(--orange);
    border-radius: 16px;

    @media (max-width: 365px) {
      width: 100%;
    }
  }

  .inputcep {
    margin-top: 60px;
    border-radius: 10px;
    padding-left: 20px;
    width: 300px;
    height: 40px;
    border: 1px solid var(--purple);
    font-size: 22px;
    ::-webkit-input-placeholder {
      color: #979797;
    }
  }

  .cep {
    position: absolute;
    margin-left: 10px;
    background-color: var(--white);
    margin-top: 50px;
    padding: 0 12px;
    color: var(--purple);
  }

  .cep-remember {
    position: absolute;
    margin-left: 318px;
    color: var(--orange);
    margin-top: -17px;
  }

  .endereco {
    position: absolute;
    margin-left: 10px;
    background-color: var(--white);
    margin-top: 14px;
    padding: 0 12px;
    color: var(--purple);
  }
  .inputendereco {
    margin-top: 25px;
    border-radius: 10px;
    padding-left: 20px;
    width: 800px;
    height: 40px;
    border: 1px solid var(--purple);
    font-size: 22px;
    ::-webkit-input-placeholder {
      color: #979797;
    }
  }
  .numero {
    position: absolute;
    margin-left: 10px;
    background-color: var(--white);
    margin-top: 14px;
    padding: 0 12px;
    color: var(--purple);
  }
  .inputnumero {
    margin-top: 25px;
    border-radius: 10px;
    padding-left: 20px;
    height: 40px;
    width: 110px;
    border: 1px solid var(--purple);
    font-size: 22px;
    ::-webkit-input-placeholder {
      color: #979797;
    }
  }

  .complemento {
    position: absolute;
    margin-left: 140px;
    background-color: var(--white);
    margin-top: -50px;
    padding: 0 12px;
    color: var(--purple);
  }

  .inputcomplemento {
    margin-top: 25px;
    margin-left: 20px;
    border-radius: 10px;
    padding-left: 20px;
    width: 239px;
    height: 40px;
    border: 1px solid var(--purple);
    font-size: 22px;
    ::-webkit-input-placeholder {
      color: #979797;
    }
  }

  .bairro {
    position: absolute;
    margin-left: 400px;
    background-color: var(--white);
    margin-top: -50px;
    padding: 0 12px;
    color: var(--purple);
  }

  .inputbairro {
    margin-top: 25px;
    margin-left: 20px;
    border-radius: 10px;
    padding-left: 20px;
    width: 410px;
    height: 40px;
    border: 1px solid var(--purple);
    font-size: 22px;
    ::-webkit-input-placeholder {
      color: #979797;
    }
  }

  .orange-card {
    margin-top: 1.2rem;
    @media (max-width: 315px) {
      margin-left: 0px;
      width: 11.5rem;
    }
  }

  .register-card {
    margin-top: 4rem;
    width: 2rem;
    margin-left: 7.2rem;
    position: absolute;
    @media (max-width: 315px) {
      width: 1.5rem;
      margin-left: 5rem;
      margin-top: 3.5rem;
    }
  }

  .apelido {
    position: absolute;
    margin-left: 1rem;
    background-color: var(--white);
    padding: 0 12px;
    color: var(--purple);
  }

  .inputapelido {
    margin-top: 40px;
    margin-bottom: 20px;
    margin-left: 40px;
    border-radius: 10px;
    padding-left: 20px;
    width: 340px;
    height: 40px;
    border: 1px solid var(--purple);
    font-size: 22px;
    ::-webkit-input-placeholder {
      color: #979797;
    }
  }

  .numcartao {
    position: absolute;
    margin-left: 1rem;
    background-color: var(--white);
    padding: 0 12px;
    color: var(--purple);
  }

  .inputnumcartao {
    margin-top: 25px;
    margin-left: 40px;
    border-radius: 10px;
    padding-left: 20px;
    width: 340px;
    height: 40px;
    border: 1px solid var(--purple);
    font-size: 22px;
    ::-webkit-input-placeholder {
      color: #979797;
    }
  }

  .button-cadastrar {
    margin-left: 0px;
    margin-right: 20px;
    border-radius: 16px;
    @media (max-width: 650px) {
      width: 100%;
    }
    @media (max-width: 315px) {
      width: 70%;
      padding: 0;
    }
  }

  .button-generate-ticket-qrcode {
    margin-left: 0px;
    margin-right: 20px;
    border-radius: 16px;
    @media (max-width: 650px) {
      margin-top: 1rem;
      width: 100%;
    }
    @media (max-width: 315px) {
      width: 70%;
      padding: 0;
    }
  }

  .button-cancelar-cadastrar {
    margin-bottom: 70px;
    color: var(--orange);
    border: 2px solid var(--orange);
    border-radius: 16px;
    @media (max-width: 650px) {
      width: 100%;
      margin-top: 1rem;
    }
    @media (max-width: 315px) {
      width: 70%;
      padding: 0;
    }
  }

  .button-excluir {
    background-color: var(--red);
    margin-left: 20px;
    @media (max-width: 650px) {
      margin-top: -50px !important;
      margin-bottom: 40px;
      width: 100%;
      margin-left: 0px;
    }
  }

  .buttons {
    display: flex;
    margin-top: 60px;
    @media (max-width: 650px) {
      flex-direction: column;
    }
  }

  .text-cartao1 {
    color: var(--purple);
    font-size: 21px;
    margin: 70px 0 0 0;
  }

  .text-cartao2 {
    color: var(--purple);
    font-size: 21px;
    margin: 30px 0 0 0;
  }

  .inputroubo {
    margin-top: 25px;
    border-radius: 20px;
    padding-left: 20px;
    width: 800px;
    height: 200px;
    border: 1px solid var(--purple);
    font-size: 22px;

    ::-webkit-input-placeholder {
      color: #979797;
    }
  }

  .text-cartao3 {
    color: var(--purple);
    font-size: 21px;
    margin: 75px 0 0 0;
  }

  .button-document {
    margin-top: 30px;
    width: 350px;
    height: 60px;
    color: var(--purple);
    border: 1px solid var(--purple);
    border-radius: 16px;
    align-items: left;
    text-align: left;
  }

  .ovalgrey {
    padding-top: 3px;
    height: 40px;
  }

  .text-nota {
    color: var(--purple);
    font-size: 21px;
    margin: 15px 0 0 0;
  }

  @media (max-width: 1250px) {
    .box-amarela-direita-2 {
      display: none;
    }
  }

  @media (max-width: 1384px) {
    .box-amarela-direita-3 {
      display: none;
    }
    .box-amarela-direita {
      display: none;
    }
  }

  @media (max-width: 915px) {
    .cartao-bhbus {
      width: 400px;
    }
  }
`;

export const ModalContentBanner = styled.div`
  height: 300px;
  width: 500px;
  padding: 0px 10px 20px 10px;
  text-align: center;
  display: contents;
  h2 {
    font-weight: 14px;
  }
  p {
    font-weight: 12px;
    padding-bottom: 10px;
  }
`;

export const ContainerCartao = styled.div`
  display: flex;
  margin-top: -50px;
  .cartaoBranco {
    background: #f2f5f7;
    border: 2px solid var(--purple);
    box-shadow: 0px 3px 25px rgba(235, 94, 53, 0.5);
    border-radius: 22px;
    width: 260px;
    padding-top: 20px;
    padding-bottom: 20px;
    h3 {
      margin-left: 45px;
    }
  }
  .cartaoRoxo {
    background: #452359;
    border: 2px solid #4d1d6e;
    box-shadow: 0px 3px 25px rgba(235, 94, 53, 0.5);
    border-radius: 22px;
    width: 260px;
    padding-top: 20px;
    padding-bottom: 20px;
    h3 {
      margin-left: 45px;
    }
  }
  .cardmenu {
    color: var(--orange);
    .destaque {
      color: var(--purple);
    }
  }

  .imagem-cartao {
    margin-left: 45px;
  }

  div + div {
    margin-left: 30px;
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const CardBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 870px;
  margin-top: 65px;
  img {
    width: 265px;
  }
  .mycards {
    margin-top: -30px;
  }

  @media (max-width: 915px) {
    flex-direction: column;
  }
`;

export const CardRegister = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 870px;
  margin-top: 25px;
  margin-bottom: 80px;
  .img {
    width: 265px;
  }
  .register {
    margin-top: 40px;
    margin-left: -152px;
    position: absolute;
  }
  @media (max-width: 915px) {
    flex-direction: column;
  }
`;

export const TransfacilCard = styled.button<CardProps>`
  background: var(--purple);
  border-radius: 20px;
  min-width: 200px;
  padding: 14px 23px 14px 23px;
  margin-right: 25px;
  color: var(--text);
  align-items: center;
  @media (max-width: 940px) {
    margin-bottom: 1rem;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
  @media (max-width: 315px) {
    font-size: 0.9rem;
  }

  p {
    font-size: 15px;
  }

  display: flex;
  flex-direction: column;

  background: ${props => (props.active ? `#F2F5F7` : `var(--purple)`)};
  color: ${props => (props.active ? `var(--purple)` : `#F2F5F7`)};
  font-weight: ${props => (props.active ? `bolder` : ``)};

  svg {
    margin-bottom: 10px;

    path {
      fill: ${props => (props.active ? `var(--purple)` : `var(--white)`)};
    }
  }
`;
