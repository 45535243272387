/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GoTriangleRight } from 'react-icons/go';
import { FormHandles } from '@unform/core';
import { ReactComponent as QuestionIcon } from '../../assets/Faq/question-mark.svg';

import Accordion from '../../components/Accordion';
import Input from '../../components/Input';
import Button from '../../components/Button';
import DashboardLayout from '../../components/DashboardLayout';

import CoreService, { Faq } from '../../services/core';

import { Container, ContainerFaq, FormSearchFaq, TitleSection } from './styles';

const FaqSectionPF: React.FC = () => {
  const services = { coreService: new CoreService() };
  const formRef = useRef<FormHandles>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const [faqs, setFaqs] = React.useState<Faq[]>([]);

  function createAnswer(answer: string) {
    return { __html: answer };
  }

  useEffect(() => {
    async function getFaqs() {
      try {
        const response = await services.coreService.getFaqs();
        setFaqs(response.data.results);
      } catch (err) {
        console.error(err);
      }
    }
    getFaqs();
  }, []);

  useEffect(() => {
    const results = faqs.filter((faq: any) => {
      return Object.keys(faq).some((key: string) =>
        JSON.stringify(faq[key])
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/-(?!>)/g, '')
          .replace(/\s/g, '')
          .toLowerCase()
          .toUpperCase()
          .includes(
            searchTerm
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .replace(/-(?!>)/g, '')
              .replace(/\s/g, '')
              .toLowerCase()
              .toUpperCase(),
          ),
      );
    });
    setSearchResults(results);
  }, [searchTerm, faqs]);

  const handleChange = useCallback(e => {
    setSearchTerm(e.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    return {};
  }, []);

  return (
    <>
      <DashboardLayout>
        <TitleSection>
          <h1>Perguntas Frequentes</h1>
        </TitleSection>
        <ContainerFaq>
          <Container>
            <FormSearchFaq ref={formRef} onSubmit={handleSubmit}>
              <Input
                name="search"
                placeholder="Pesquise sua dúvida"
                onChange={e => handleChange(e)}
              >
                <Button type="submit" color="orange" padding="3">
                  BUSCAR
                </Button>
              </Input>
            </FormSearchFaq>
            {searchResults.map((faq: Faq) => (
              <Accordion
                key={faq.id}
                Icon={QuestionIcon}
                CollapseIcon={GoTriangleRight}
                title={faq.question}
                topic={faq?.topic}
                titleColor="purple3"
                subTitle=""
                faq
              >
                {/* eslint-disable-next-line react/no-danger */}
                <div
                  dangerouslySetInnerHTML={createAnswer(faq.answer)}
                  className="faq"
                />
              </Accordion>
            ))}
          </Container>
        </ContainerFaq>
      </DashboardLayout>
    </>
  );
};

export default FaqSectionPF;
