/* eslint-disable no-console */
import React, {
  useRef,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Select from '../../components/Select';
import FileInput from '../../components/FileInput';
import { Container, Content } from '../../styles/components';
import Header from '../../components/Home/Header';
import NavBar from '../../components/Home/NavBar';
import Background from '../../assets/Shared/testeira.png';
import { FlexContainer, FormWrapper } from './styles';
import Footer from '../../components/Home/Footer';

const TrabalheConosco: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [jobs, setJobs] = useState([]);
  const { addToast } = useToast();
  const { push } = useHistory();

  const schema = Yup.object().shape({
    name: Yup.string().required('nome é obrigatório'),
    phone: Yup.string().required('telefone é obrigatório'),
    interest_area: Yup.string().required('Area de interesse é obrigatório'),
    job: Yup.string().required('Vaga é obrigatório'),
    curriculum: Yup.string().required('curriculum é obrigatório'),
    email: Yup.string()
      .required('email é obrigatório')
      .email('Digite um email valido'),
  });

  useEffect(() => {
    const getJobs = async () => {
      try {
        const response = await api.get('/core/vagas/');
        setJobs(response.data.results);
      } catch (err) {
        console.error(err);
      }
    };
    getJobs();
  }, []);

  const jobsOptions = useMemo(() => {
    return jobs.map(job => ({ value: job.id, label: job.name }));
  }, [jobs]);

  const handleSubmit = useCallback(
    async data => {
      const formData = new FormData();

      for (const name in data) {
        if (Object.prototype.hasOwnProperty.call(data, name)) {
          formData.append(name, data[name]);
        }
      }

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

      try {
        formRef.current?.setErrors({});

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/core/trabalhe-conosco/', formData, config);

        addToast({
          type: 'success',
          title: `Curriculo enviado com sucesso`,
          description: `Curriculo enviado com sucesso`,
        });

        push('/');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: `error`,
          description: `error`,
        });
      }
    },
    [schema, addToast, push],
  );

  return (
    <>
      <Container>
        <Content>
          <Header />
        </Content>
      </Container>
      <Container style={{ backgroundImage: `url(${Background})` }}>
        <Content>
          <NavBar />
        </Content>
      </Container>
      <Container>
        <Content>
          <FlexContainer>
            <div className="text-wrapper">
              <h1>Trabalhe Conosco!</h1>
              <span>
                Que tal fazer parte de uma equipe de talentos que trabalha para
                transformar a mobilidade urbana de BH?
              </span>
              <h2>Preencha o formulário abaixo e nos envie seu currículo</h2>
            </div>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <FormWrapper>
                <p>1. Nome</p>
                <Input
                  name="name"
                  schema={schema}
                  placeholder="Digite o seu nome"
                />
                <p>2. Email</p>
                <Input
                  name="email"
                  schema={schema}
                  placeholder="Digite seu email"
                />
                <p>3. Telefone</p>
                <Input
                  phone
                  name="phone"
                  schema={schema}
                  placeholder="(xx)xxxxx-xxxx"
                />
                <p>4. Área de interesse</p>
                <Input
                  name="interest_area"
                  schema={schema}
                  placeholder="Digite a área de interesse"
                />
                <p>5. Selecione a vaga</p>
                <Select
                  schema={schema}
                  name="job"
                  placeholder="Selecione a vaga"
                  options={jobsOptions}
                />
                <p>6. Currículo</p>
                <FileInput name="curriculum" title="Anexar Arquivo" />
                <Button
                  className="button"
                  type="submit"
                  color="green"
                  padding="1"
                  rounded
                >
                  ENVIAR
                </Button>
              </FormWrapper>
            </Form>
          </FlexContainer>
        </Content>
      </Container>
      <Container>
        <Footer />
      </Container>
    </>
  );
};

export default TrabalheConosco;
