import styled from 'styled-components';

export const TitleSection = styled.section`
  height: 230px;
  width: 98%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--white);
  @media only screen and (max-width: 768px) {
    height: 190px;
  }

  h1 {
    font-size: 2.9rem;
    line-height: 4rem;
    @media (max-width: 768px) {
      margin-top: 4rem;
      text-align: center;
      line-height: 3rem;
    }
    @media (max-width: 620px) {
      margin-top: 3rem;
    }
    @media (max-width: 493px) {
      font-size: 2.4rem;
      line-height: 3rem;
      margin-top: 4rem;
    }
    @media (max-width: 450px) {
      font-size: 2rem;
      line-height: 2.5rem;
      margin-top: 3rem;
    }
    @media (max-width: 350px) {
      font-size: 1.8rem;
      line-height: 2.3rem;
      margin-top: 3rem;
    }
    @media (max-width: 291px) {
      font-size: 1.8rem;
      line-height: 2.5rem;
      margin-top: 3rem;
    }
    @media (max-width: 236px) {
      font-size: 1.5rem;
      line-height: 2rem;
      margin-top: 5rem;
    }
  }

  span {
    font-size: 1rem;
    line-height: 3.5rem;
    font-weight: normal;
    @media (max-width: 768px) {
      text-align: center;
    }
    @media (max-width: 346px) {
      line-height: 1.5rem;
      margin-top: 1.5rem;
    }
  }
`;
export const FileDiv = styled.div`
  flex-direction: column;
  padding: 5px;
  max-width: 100%;
  p {
    margin-bottom: 10px;
    font-weight: normal;
    font: 'Source Sans Pro', sans-serif;
    @media (max-width: 800px) {
      font-size: 10px;
    }
    @media (max-width: 600px) {
      font-size: 10px;
    }
  }
`;

export const GridSection = styled.section`
  display: grid;
  grid-template-columns: 2fr 380px;
  grid-template-rows: 1fr 50px;
  grid-template-areas: 'main aside';
  grid-gap: 3rem;
  min-height: 100vh;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Main = styled.main`
  grid-area: main;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  padding: 1.5rem 0;
  width: 90%;

  .h2 {
    color: var(--purple3);
    margin-bottom: 20px;
  }
  .h1 {
    color: var(--purple);
    margin-top: 100px;
    margin-bottom: 20px;
  }

  .accordion {
    background: white;
    padding: 3px;
    width: 100%;
    border: 2px solid #c2d1d9;
    border-radius: 30px;
    margin-bottom: 30px;
  }

  p {
    margin-top: 1rem;
  }

  .destaque {
    color: var(--purple);
    margin-top: 2.5rem;
    font-weight: 600;
  }
`;

export const Aside = styled.aside`
  grid-area: aside;
  margin-bottom: 50px;
  display: flex;
  margin-right: 5%;
  flex-direction: column;
  align-items: flex-end;
  img {
    position: absolute;
    right: 0;
    top: 1000px;
  }
  @media only screen and (max-width: 1108px) {
    img {
      display: none;
    }
  }
`;

export const AsideCard = styled.div`
  background: var(--white);
  margin: 1.5rem 0;
  z-index: 1;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  position: sticky;
  top: 0px;

  @media only screen and (max-width: 768px) {
    margin-top: 0;
    margin-bottom: 0;
    box-shadow: none;
  }

  .header {
    background: var(--purple2);

    display: flex;
    flex-direction: column;
    padding: 20px 35px;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 3px solid var(--orange);
    @media only screen and (max-width: 768px) {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      border-bottom: 3px solid var(--orange);
      margin-bottom: 50px;
    }

    h2 {
      font: 600 1.3rem 'Roboto Slab', sans-serif;
      text-align: center;
      color: var(--orange);
      margin-bottom: 10px;
    }

    span {
      font: 400 1rem 'Source Sans Pro', sans-serif;
      text-align: center;
      color: var(--white);
    }
  }

  .main {
    margin-top: 14px;

    form {
      .highlight-label {
        margin-bottom: 10px;
        font-style: normal;
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.1rem;
        color: var(--purple);
      }

      .select {
        height: 20px;
      }

      .input-container {
        padding: 0 25px;
        margin-bottom: 25px;
        @media (max-width: 768px) {
          padding: 0 5px;
        }
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 30px;
        @media (max-width: 768px) {
          margin-top: -20px;
        }
      }
    }

    .footer {
      background: #dfe5e8;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px 0 0 0;
      width: 100%;
      height: 7em;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      @media only screen and (max-width: 768px) {
        background: transparent;
        padding: 0px 0 0 0;
      }

      button {
        width: 206px;
        margin-bottom: 20px;
      }

      .orange-button {
        padding-top: 1rem;
        padding-bottom: 1rem;
        width: 14rem;
      }

      .transparent-button {
        color: #474747;
      }

      .white-button {
        color: var(--orange) !important;
        padding: 0.4rem;
        border: 3px solid var(--orange) !important;
      }

      span {
        color: var(--orange);
        .cards {
          position: absolute;
          margin-left: 80px;
          margin-top: -20px;
          color: var(--purple);
          width: 100px;
          height: 20px;
          @media (max-width: 720px) {
            margin-left: 55px;
            margin-top: -18px;
          }
        }
      }
    }
  }
`;

export const AsideGreyBox = styled.div`
  padding: 40px;
  background-color: rgb(97, 97, 97, 0.3);
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  p {
    margin-top: 20px;
  }
  .btn-contrato {
    padding: 10px;
    margin-top: 20px;
    border-radius: 20px;
    border: 2px solid black;
    background-color: white;
    border-color: #dc582a;
    color: #dc582a;
  }
`;

export const BodyForm = styled.div`
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 20px;
  }

  .numeros {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .asterisk {
    color: var(--red);
  }
`;

export const CircleContainer = styled.div`
  display: flex;
  transform: translateY(-68px);
  text-align: center;

  @media (max-width: 1295px) {
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 1048px) {
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 920px) {
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
    width: 90%;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 488px) {
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 472px) {
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 331px) {
    margin-bottom: 50px;
  }
`;
export const CircleWraper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 220px;
  padding: 0 25px;
  span {
    height: 60px;
    margin-top: 1rem;
    margin-bottom: 2rem;
    color: var(--title-higlight);
    @media (max-width: 435px) {
      margin-bottom: 4rem;
    }
  }
  @media (max-width: 815px) {
    width: 200px;
  }

  @media (max-width: 772px) {
    width: 170px;
  }

  @media (max-width: 768px) {
    width: 270px;
  }

  @media (max-width: 632px) {
    width: 200px;
  }

  @media (max-width: 520px) {
    width: 200px;
  }
  @media (max-width: 435px) {
    width: 150px;
  }
  @media (max-width: 332px) {
    width: 120px;
  }
`;
export const Circle = styled.div`
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 1px solid var(--purple);
  background-color: #f0f4f7;
  display: flex;
  align-items: center;
  justify-content: center;
  .envelope {
    margin-left: -10px;
    margin-top: -14px;
  }
  .balao {
    margin-top: 11px;
    z-index: 9999;
  }
`;

export const DialogContent = styled.div`
  position: relative;
  left: 2.8%;
  top: 30%;
  display: flex;
  background: white;
  justify-content: space-between;
  width: 95%;
  border-radius: 25px;

  .container {
    text-align: center;
    width: 100%;
    height: 45%;
    @media (max-width: 420px) {
      margin-left: 0px;
      width: 100%;
    }
    .img {
      position: relative;
      top: 0;
      @media (max-width: 1195px) {
        display: inline-block;
      }
    }
    h1 {
      color: var(--orange);
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
    .text {
      padding: 40px;
      @media (max-width: 350px) {
        padding: 40px 0 0 0;
      }
    }
    p {
      color: var(--title-higlight);
      margin-bottom: 2rem;
    }
    .view {
      margin-top: 50px;
    }
    .contrato {
      margin-top: 3rem;
      height: 45px;
      width: 100%;
    }
    .baixar-contrato {
      margin-bottom: 1rem;
      line-height: 1rem;
      height: 50px;
      width: 100%;
    }
  }
`;

export const Modal = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  display: none;
`;

export const Contracts = styled.div`
  h2 {
    color: var(--purple3);
    font-size: 1.5rem;
    line-height: 1.7rem;
    margin: 10px 0 25px 0;
  }

  .pessoaFisica {
    margin-top: 50px;
  }
`;

export const ContractSession = styled.div`
  width: 65%;
  min-height: 20px;
  border-radius: 30px;

  box-shadow: 3px 3px #c5c5c5;
  background-color: #ebf1f4;
  color: #61377f;

  padding: 15px 15px 10px 15px;
  .buttons {
    text-align: center;
    .button {
      height: 45px;
      border-radius: 20px 20px;
      margin: 0px 10px 10px 0px;
    }
  }

  @media (max-width: 1295px) {
    width: 70%;
  }
  @media (max-width: 1135px) {
    width: 80%;
  }
  @media (max-width: 1000px) {
    width: 98%;
  }
  @media (max-width: 813px) {
    padding: 10px;
  }
  @media only screen and (max-width: 768px) {
    position: relative;
    width: 100%;
  }
`;

export const ContractSessionContainer = styled.div`
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin: 1em;
    margin-bottom: 1.5em;

    font-size: 1em;
    font-style: normal;
    font-weight: 600;
  }

  button {
    font-size: 1em;
    margin: 4px;
    color: var(--orange);
    border: 3px solid var(--orange);
    border-radius: 2em;
  }
`;
