/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { useRef, useCallback, useState } from 'react';
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
} from 'react-select';
import api from '../../../../services/api';

import { Container, Label } from './styles';

interface Props extends SelectProps<OptionTypeBase> {
  label?: string;
  onChange: (e: any) => void;
}
const Select: React.FC<Props> = ({ label, onChange, ...rest }) => {
  const selectRef = useRef(null);
  const [lines, setLines] = React.useState([]);
  const [isFocused, setIsFocused] = useState(false);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(async () => {
    setIsFocused(false);
  }, []);

  const getList = React.useCallback(async () => {
    try {
      const { data } = await api.get(`/core/qr-code/lines/`);
      setLines(data);
    } catch (e) {
      console.log(e);
    }
  }, []);
  React.useEffect(() => {
    getList();
  }, []);

  return (
    <div style={{ width: '100%' }}>
      {label && <Label>{label}</Label>}
      <Container isErrored={false} isFocused={isFocused} isCorrect={false}>
        <ReactSelect
          options={lines}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          ref={selectRef}
          classNamePrefix="react-select"
          onChange={onChange}
          {...rest}
        />
      </Container>
    </div>
  );
};
export default Select;
