import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  button {
    width: 250px;
    height: 40px;
    cursor: pointer;
    border: 2px dashed var(--orange);
    border-radius: 12px;
    text-align: center;
    color: var(--gray);
    background-color: white;
    @media (max-width: 1100px) {
      max-width: 100%;
      width: 150px;
      height: 60px;
    }
    @media (max-width: 950px) {
      max-width: 100%;
      width: 300px;
      height: 80px;
    }
    @media (max-width: 700px) {
      max-width: 100%;
      width: auto;
      height: 70px;
    }
  }

  .input {
    margin-bottom: 2px;
  }

  input {
    opacity: 0;
    position: absolute;
    left: 0;
    display: flex;
    top: 0;
    width: 700px;
    max-width: 100%;
    height: 100px;
    cursor: pointer;
  }

  span {
    color: #e83f5b;
  }
`;
