import styled from 'styled-components';

export const Container = styled.div`
  h1,
  h4 {
    color: var(--purple);
  }

  .button-transparent {
    background-color: var(--transparent) !important;
    color: var(--orange) !important;
    border: 1px solid var(--orange) !important;
  }
`;

export const TitleSection = styled.section`
  margin: 60px 0 60px 0;
  color: var(--purple);

  h1 {
    font-size: 3rem;
    line-height: 2.3rem;
    @media (max-width: 492px) {
      line-height: 3.5rem;
    }
  }
`;

export const ModalContentBanner = styled.div`
  height: 300px;
  width: 500px;
  padding: 0px 10px 20px 10px;
  text-align: center;
  h2 {
    font-weight: 14px;
  }
  p {
    font-weight: 12px;
    padding-bottom: 10px;
  }
`;

export const Buttons = styled.button`
  background: var(--purple);
  border-radius: 20px;
  min-width: 200px;
  padding: 14px 23px 14px 23px;
  margin-right: 25px;
  color: var(--text);
  align-items: center;
  @media (max-width: 940px) {
    margin-bottom: 1rem;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
  @media (max-width: 315px) {
    font-size: 0.9rem;
  }

  p {
    font-size: 15px;
  }

  display: flex;
  flex-direction: column;

  background: var(--purple);
  color: #f2f5f7;
  svg {
    margin-bottom: 10px;

    path {
      fill: var(--white);
    }
  }
`;
