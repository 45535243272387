import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;

  width: 100%;
`;

export const CarrouselContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;

  width: 100%;
  height: 100%;
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  width: 100%;
  height: 100%;
`;

export const Item = styled.img`
  margin: 5px;
  width: 46%;

  cursor: pointer;

  @media only screen and (max-width: 768px) {
    width: 0%;
    margin: 0;
  }
`;

export const LeftColumn = styled(Item)`
  width: 48%;
  height: 100%;

  cursor: pointer;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
`;
