/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { Link } from 'react-router-dom';

import { useLoader } from '../../hooks/loader';

import Button from '../Button';

import { ModalUpdate } from './styles';
import DashBoardLoader from '../DashBoardLoader';

const ModalUpdateInformations: React.FC = () => {
  const { isActive } = useLoader();
  return (
    <ModalUpdate>
      {isActive && <DashBoardLoader />}

      <section className="modal-main">
        <>
          <div className="modal-header">
            <h1>Atualize seu cadastro</h1>
          </div>
          <div className="modal-body">
            <p>
              Para continuar atualize seus dados cadastrais clicando no botão
              atualizar.
            </p>
            <Link to="/meu-perfil">
              <Button type="button" color="purple" padding="6" rounded>
                <span className="buttonUpdate">Atualizar</span>
              </Button>
            </Link>
          </div>
        </>
      </section>
    </ModalUpdate>
  );
};

export default ModalUpdateInformations;
