import styled, { keyframes } from 'styled-components';

const EaseIn = keyframes`
  from{ opacity:0; }
  to{ opacity:1; }
`;

export const Container = styled.div`
  color: red;
`;

// export const RegisterCardButton = styled(Button)`
//   padding-top: 3.5rem;
//   padding-right: 1rem;
// `;

export const CardBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  margin-top: 65px;
  animation: ${EaseIn} 0.3s;

  .card {
    margin-right: 1rem;
  }

  .mycards {
    margin-top: -30px;
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }
  .register-nickname {
    margin-top: 1.5rem;
    @media (max-width: 720px) {
      width: 22rem;
    }
    @media (max-width: 615px) {
      margin-left: 0px;
      width: 21.8rem;
    }
    @media (max-width: 370px) {
      margin-left: 0px;
      width: 16.5rem;
    }
    @media (max-width: 315px) {
      margin-left: 0px;
      width: 11.5rem;
    }
  }
  @media (max-width: 670px) {
    display: flex;
    flex-direction: column;
    width: 300px;
  }
  @media (max-width: 370px) {
    width: 17.5rem;
  }
`;
