/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback } from 'react';

import { GoTriangleRight } from 'react-icons/go';
import Accordion from '../../components/Accordion';
import DashboardLayout from '../../components/DashboardLayout';
import LoaderSubLine from '../RoutesAndLines/LoaderSubLine';
import { ReactComponent as Bus } from '../../assets/Shared/Bus.svg';
import { Container, CollapseContainer, TimeContainer } from './styles';
import { ReactComponent as ClockIcon } from '../../assets/RoutesAndLines/clock.svg';
import AccountsService from '../../services/accounts';
import api from '../../services/api';
import Loader from '../../components/Loader';

interface Favoritas {
  id: number;
  user: number;
  code_line: string;
  name_line: string;
  sgl_line: string;
  follow: boolean;
}

interface Itineraries {
  code: string;
  sgl: string;
  name: string;
  itinerario: any;
  tabelahorario: any;
}

const Favoritos: React.FC = () => {
  const servicesAccounts = { AccountsService: new AccountsService() };
  const [favoritas, setFavoritas] = useState<Favoritas[]>([]);
  const [linhas, setLinhas] = useState<Itineraries[]>([]);
  const [subLinhas, setSubLinhas] = React.useState<any>([]);
  const [subLines, setSubLines] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [loadingSubLine, setLoadingSubLine] = React.useState<boolean>(false);
  console.log({ favoritas });
  useEffect(() => {
    async function getLinhas() {
      try {
        setLoading(true);
        const response = await api.get('/core/lines-itineraries/');
        setLinhas(response.data.lines_with_itinerary);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    }
    getLinhas();
  }, [setLinhas]);

  useEffect(() => {
    async function getFavoritas() {
      setLoading(true);
      try {
        const response = await servicesAccounts.AccountsService.getLineFavorite();
        setFavoritas(response.data.results);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    }
    getFavoritas();
  }, []);

  const handleSublines = useCallback(async (cod: any) => {
    setLoadingSubLine(true);
    const response = await api.get(`/core/sub-lines/${cod}/`);
    const responseSubLinhas = response.data.sub_lines.map(async (sub: any) => {
      const responsibles = await api.get(`/core/v2/line-info/${sub.cod}/`);
      return responsibles.data;
    });
    const promisesResponse = await Promise.all(responseSubLinhas);

    const responsibleses = await api.get(`/core/sub-lines/${cod}/`);
    const sublinhas = responsibleses.data.sub_lines.map((sub: any) => sub);
    const sublinhass = responsibleses.data.sub_lines.map((sub: any) => sub.nom);
    console.log({ sublinhass });
    const changedResponse = promisesResponse.map((item: any) => {
      const filteredSubline = response.data.sub_lines.filter(
        (subline: any) => subline.cod === Number(item.code),
      )[0];
      return { ...item, sublineName: filteredSubline.nom };
    });
    setSubLinhas(sublinhas);
    setSubLines(changedResponse);
    setLoadingSubLine(false);
    return {};
  }, []);

  return (
    <DashboardLayout>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Container>
            <h1 className="title">Linhas Favoritas</h1>
            {!linhas.length && <span>Nenhuma linha favorita</span>}
            {linhas &&
              linhas.map((linha: any) => (
                <Accordion
                  key={linha.code}
                  cod={linha.code}
                  CollapseIcon={GoTriangleRight}
                  title={linha.sgl}
                  subTitle={linha.name}
                  Icon={Bus}
                  handleSublines={handleSublines}
                  hasSublines
                >
                  {loadingSubLine ? (
                    <LoaderSubLine />
                  ) : (
                    <>
                      {subLinhas[1] &&
                        subLines.map((sub: any) => (
                          <Accordion
                            key={sub.code}
                            CollapseIcon={GoTriangleRight}
                            title={linha.sgl}
                            subTitle={sub.sublineName}
                          >
                            <CollapseContainer>
                              {Object.keys(sub.tabelahorario).map(
                                (rota: any) => {
                                  return (
                                    <>
                                      <h3>{rota}</h3>
                                      {Object.keys(sub.tabelahorario[rota]).map(
                                        (dia: any) => {
                                          return (
                                            <>
                                              {!dia.includes('Covid') && (
                                                <>
                                                  <TimeContainer key={rota}>
                                                    <div className="sub-ball-container">
                                                      <div>
                                                        <ClockIcon />
                                                      </div>
                                                    </div>
                                                    <div className="text-container">
                                                      <h1>{dia}</h1>
                                                      <div className="flex">
                                                        {sub.tabelahorario[
                                                          rota
                                                        ][dia].map(
                                                          (item: any) => {
                                                            return (
                                                              <div
                                                                className="horario"
                                                                key={item}
                                                              >
                                                                {item}
                                                              </div>
                                                            );
                                                          },
                                                        )}
                                                      </div>
                                                    </div>
                                                  </TimeContainer>
                                                </>
                                              )}
                                            </>
                                          );
                                        },
                                      )}
                                    </>
                                  );
                                },
                              )}
                              <h1>Itinerário</h1>
                              {Object.keys(sub.itinerario).map((ponto: any) => {
                                return (
                                  <>
                                    <div className="text-container">
                                      <h3
                                        className="localizacao-pontos"
                                        key={ponto}
                                      >
                                        {ponto}
                                      </h3>
                                    </div>
                                    {Object.keys(sub.itinerario[ponto]).map(
                                      (localizacao: any) => {
                                        return (
                                          <div key={localizacao}>
                                            {sub.itinerario[ponto][localizacao]}
                                          </div>
                                        );
                                      },
                                    )}
                                  </>
                                );
                              })}
                            </CollapseContainer>
                          </Accordion>
                        ))}
                      {!subLinhas[1] && (
                        <CollapseContainer>
                          {Object.keys(linha.tabelahorario).map((rota: any) => {
                            return (
                              <>
                                <h3>{rota}</h3>
                                {Object.keys(linha.tabelahorario[rota]).map(
                                  (dia: any) => {
                                    return (
                                      <>
                                        {!dia.includes('Covid') && (
                                          <>
                                            <TimeContainer key={rota}>
                                              <div className="sub-ball-container">
                                                <div>
                                                  <ClockIcon />
                                                </div>
                                              </div>
                                              <div className="text-container">
                                                <h1>{dia}</h1>
                                                <div className="flex">
                                                  {linha.tabelahorario[rota][
                                                    dia
                                                  ].map((item: any) => {
                                                    return (
                                                      <div
                                                        className="horario"
                                                        key={item}
                                                      >
                                                        {item}
                                                      </div>
                                                    );
                                                  })}
                                                </div>
                                              </div>
                                            </TimeContainer>
                                          </>
                                        )}
                                      </>
                                    );
                                  },
                                )}
                              </>
                            );
                          })}
                          <h1>Itinerário</h1>
                          {Object.keys(linha.itinerario).map((ponto: any) => {
                            return (
                              <>
                                <div className="text-container">
                                  <h3
                                    className="localizacao-pontos"
                                    key={ponto}
                                  >
                                    {ponto}
                                  </h3>
                                </div>
                                {Object.keys(linha.itinerario[ponto]).map(
                                  (localizacao: any) => {
                                    return (
                                      <div key={localizacao}>
                                        {linha.itinerario[ponto][localizacao]}
                                      </div>
                                    );
                                  },
                                )}
                              </>
                            );
                          })}
                        </CollapseContainer>
                      )}
                    </>
                  )}
                </Accordion>
              ))}
          </Container>
        </>
      )}
    </DashboardLayout>
  );
};

export default Favoritos;
