/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { IconBaseProps } from 'react-icons';
import { FavoritesProps } from '../../services/accounts';

import {
  Container,
  AccordionItem,
  InitialContainer,
  CollapseContainer,
} from './styles';

export interface AccordionProps {
  title: string;
  cod?: string;
  subTitle?: string;
  titleColor?: string;
  Icon?: React.ComponentType<IconBaseProps>;
  CollapseIcon?: React.ComponentType<IconBaseProps>;
  topic?: any;
  name?: string;
  faq?: boolean;
  region1?: any;
  region2?: any;
  region3?: any;
  region4?: any;
  handleFavorite?: (data: FavoritesProps) => Promise<void>;
  handleSublines?: (cod: any) => Promise<any>;
  hasSublines?: boolean;
  fav?: boolean;
  shadow?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  cod,
  subTitle,
  titleColor,
  Icon,
  CollapseIcon,
  faq,
  fav,
  handleFavorite,
  handleSublines,
  topic,
  hasSublines,
  region1,
  region2,
  region3,
  region4,
  name,
  shadow,
  children,
}) => {
  const ref = useRef<any>(null);
  const [active, setActive] = useState(false);
  const [favoriteActive, setFavoriteActive] = useState<boolean>(false);
  const [region1Active, setRegion1Active] = useState<boolean>(false);
  const [region2Active, setRegion2Active] = useState<boolean>(false);
  const [region3Active, setRegion3Active] = useState<boolean>(false);
  const [region4Active, setRegion4Active] = useState<boolean>(false);
  const [favoriteBloqued, setFavoriteBloqued] = useState<boolean>(false);

  console.log({
    region1Active,
    region2Active,
    region3Active,
    region4Active
  })

  useEffect(() => {
    if (faq) {
      setActive(false);
    }
    if (region1) {
      setRegion1Active(false);
    }
    if (region2) {
      setRegion2Active(false);
    }
    if (region3) {
      setRegion3Active(false);
    }
    if (region4) {
      setRegion4Active(false);
    }
    if (fav) {
      setFavoriteActive(true);
    }
  }, [faq, fav, region1, region2, region3, region4]);

  const handleToggle = useCallback(() => {
    setActive(!active);
    if (hasSublines && !active) handleSublines(cod);
  }, [active, handleSublines, hasSublines, cod]);

  const handleToggleFavorite = useCallback(async () => {
    setFavoriteActive(!fav);
    setFavoriteBloqued(true);
    await handleFavorite({
      code_line: title,
      name_line: subTitle,
      sgl_line: title,
      follow: fav,
    });
    setFavoriteBloqued(true);
  }, [fav, subTitle, title, handleFavorite]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setActive(false);
      }
    }

    document.body.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <Container
      isFaq={!!faq}
      isRegion1={!!region1}
      isRegion2={!!region2}
      isRegion3={!!region3}
      isRegion4={!!region4}
      ref={ref}
    >
      <AccordionItem
        activefav={!!favoriteActive}
        shadow={shadow}
        bloqued={favoriteBloqued}
      >
        {!!name && (
          <>
            <div className="buttonfav">
              <button type="button" onClick={handleToggleFavorite}>
                &#x2605;
              </button>
            </div>
          </>
        )}
        <InitialContainer titleColor={titleColor} active={active}>
          {Icon && (
            <div className="ball-container">
              <div>
                <Icon />
              </div>
            </div>
          )}
          <button
            type="button"
            className="icon-container"
            onClick={handleToggle}
          >
            <div className="text-container">
              <div>
                <h1>{title}</h1>
                <button type="button">
                  <CollapseIcon size={25} />
                </button>
              </div>
              {!!topic && <div className="topicContainer">{topic}</div>}
              {!!region1 && <div className="regions">{region1}</div>}
              {!!region2 && <div className="regions">{region2}</div>}
              {!!region3 && <div className="regions">{region3}</div>}
              {!!region4 && <div className="regions">{region4}</div>}
              <div className="subtitle-container">
                <span>{subTitle}</span>
              </div>
            </div>
          </button>
        </InitialContainer>
        <CollapseContainer active={active}>{children}</CollapseContainer>
      </AccordionItem>
    </Container>
  );
};

export default Accordion;
