import styled from 'styled-components';

export const TitleSection = styled.section`
  height: 268px;
  width: 98%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--white);

  h1 {
    font-size: 2.9rem;
    line-height: 4rem;
    margin-bottom: 4rem;
    @media (max-width: 750px) {
      margin-top: 2rem;
    }
  }
`;

export const Main = styled.main`
  padding: 1.5rem 0;
  line-height: 1.5rem;

  h2 {
    font-size: 0.8rem;
  }

  .title {
    font-size: 1.3rem;
    color: var(--purple);
    margin-top: 20px;
  }

  p {
    margin-top: 1rem;
  }

  span {
    margin-left: 1rem;
  }

  a {
    color: #4141c1;
  }
`;
