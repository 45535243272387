import React from 'react';
import { useLoadScript } from '@react-google-maps/api';
import Header from '../../components/Home/Header';
import { Container, Content } from '../../styles/components';
import CardSection from '../../components/Home/CardSection';
import BenefitsSection from '../../components/Home/BenefitsSection';
import Slide from '../../components/Slide/index';
import Footer from '../../components/Home/Footer';
import LocationAndRouteSection from '../../components/Home/LocationAndRouteSection';

import NewsSection from '../../components/Home/NewsSection';
import StayInInformedSection from '../../components/Home/StayInInformedSection';
import PartnerSection from '../../components/Home/PartnerSection';
import NavBar from '../../components/Home/NavBar';
import AcceptCookies from '../../components/AcceptCookies';

declare type Libraries = (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
)[];

const libraries: Libraries = ['places'];

const Home: React.FC = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
    language: 'pt-BR',
  });

  return (
    <>
      <Container>
        <Content>
          <Header />
        </Content>
      </Container>
      <Container backgroundBanner className="banner-container">
        <Content className="content">
          <NavBar />
        </Content>
      </Container>
      <Container>
        <Slide />
      </Container>
      <Container>
        <Content>
          <BenefitsSection />
        </Content>
      </Container>
      <CardSection />
      {isLoaded && <LocationAndRouteSection />}
      <NewsSection />
      <StayInInformedSection />
      <PartnerSection />
      <Container>
        <Footer />
      </Container>
      <AcceptCookies />
    </>
  );
};

export default Home;
