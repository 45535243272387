import styled, { css } from 'styled-components';

interface ActiveProps {
  active: boolean;
}

export const Container = styled.div`
  width: 100%;
`;

export const AccordionItem = styled.div`
  background: #f2f5f7
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #c2d1d9;
  border-bottom: 1px solid #c2d1d9;
`;

export const InitialContainer = styled.div<ActiveProps>`
  display: flex;
  height: 76px;
  cursor: pointer;

  justify-content: space-between;
  align-items: center;

  h1 {
    margin-top: 24px;

    font-family: Source Sans Pro;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    display: flex;
    align-items: center;

    color: #505d68;
  }

  button {
    transition: all 0.5s ease;
    cursor: pointer;
    background: transparent;
    height: 0;
    color: #c2d1d9;

    svg {
      ${props =>
        props.active &&
        css`
          transform: rotate(90deg);
        `}
    }
  }
`;

export const CollapseContainer = styled.div<ActiveProps>`
  transition: all 0.5s ease;
  margin: 20px 0;
  min-height: 0;
  height: 0;
  transition: all 0.2s ease-out;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;

  ${props =>
    props.active &&
    css`
      min-height: 30px;
      height: auto;
      transition: all 0.25s ease-in;
    `}
`;
