import styled from 'styled-components';

export const DivPrincipal = styled.div`
  .button-transparentbtn-cancelar {
    margin-left: 50px;
    @media (max-width: 800px) {
      width: 100%;
      margin-left: 0px;
      margin-top: 20px;
    }
    @media (max-width: 650px) {
      background-color: white;
      border: 1px solid var(--orange);
      color: var(--orange);
    }
  }
`;
