import styled from 'styled-components';

export const ModalPixPagamento = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 850px) {
    height: 170vh;
  }
  @media (max-width: 645px) {
    width: 100vh;
  }

  h2 {
    margin-bottom: 15px;
    color: var(--purple);
    width: 90%;
    text-align: center;
  }

  .wrapper {
    background-color: #fff;
    width: 850px;
    height: 95%;
    padding: 5px 40px 10px 40px;
    display: flex;
    flex-direction: column;
    flex-wrap: 1;
    border: 1px solid #cbcbcb;
    text-align: center;
    color: var(--purple);
    background-color: white;
    overflow: scroll;
    overflow: auto;
    @media (max-width: 850px) {
      height: 100%;
    }
    ::-webkit-scrollbar {
      background-color: transparent;
      width: 10px;
      height: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--purple);
      border-radius: 10px;
    }

    .close {
      background-color: transparent;
      outline: none;
      width: 100%;
      height: 32px;
      right: -15px;
      top: 5px;
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      position: relative;

      @media (max-width: 850px) {
        right: 0px;
      }

      &:before,
      &:after {
        content: ' ';
        position: absolute;
        width: 2.5px;
        height: 24px;
        background-color: #000;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }

  .row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
    margin-top: 10px;
    background-color: #f5f5f5;
    padding: 10px;
    @media (max-width: 850px) {
      margin-top: 30px;
    }
  }

  .code {
    margin-top: 20px;
    background-color: #edeaf0;
    border-radius: 10px;
    height: auto;
    padding: 5px 5px 0 5px;
    width: 95%;
    resize: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    word-break: break-all;
  }

  .buttonCopy {
    margin-top: 15px;
    width: 95%;
    background-color: var(--purple);
    color: white;
    height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .number {
    color: var(--orange);
    border: solid 2px var(--orange);
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: bold;
    margin-right: 20px;
  }
`;
