import styled from 'styled-components';

export const Container = styled.div`
  .cards-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    &:before,
    &after {
      content: '';
      display: table;
    }
    &:after {
      clear: both;
    }
    margin-top: 20px;
    @media (max-width: 1024px) {
      flex-wrap: wrap;
      width: 50%;
    }
    @media (max-width: 650px) {
      flex-wrap: wrap;
      width: 100%;
    }
  }
`;

export const AddCard = styled.div`
  background: #f2f5f7;
  height: 10rem;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  margin-right: 1em;
  margin-bottom: 1em;
  width: 29%;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: #e6e6e6;
  }

  span {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    text-align: center;
    letter-spacing: 0.18px;
    text-transform: uppercase;

    color: #a1aeb7;
  }

  img {
    margin-bottom: 15px;
  }
`;
