import styled from 'styled-components';

export const Section = styled.section`
  grid-area: main;
  width: 124%;
  height: 16px;
  background-color: #ebf1f4;
  margin-left: -110px;
  margin-bottom: 30px;
  @media (max-width: 700px) {
    width: 130%;
  }
  @media (max-width: 565px) {
    width: 140%;
  }
  @media (max-width: 440px) {
    width: 160%;
  }
  @media (max-width: 325px) {
    width: 180%;
  }
`;

export const Content = styled.div`
  display: grd;
  width: 100%;
  margin-right: -100px;
  margin-bottom: 30px;
  color: var(--purple);
`;

export const CardButton = styled.button`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 250px;
  padding: 12px 8px 12px 8px;
  margin-right: 25px;
  border-radius: 20px;
  background: var(--purple);
  color: var(--white);
  font-size: 16px;
  .icon {
    margin-right: 20px;
    margin-left: 10px;
  }
`;
