/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { FiX } from 'react-icons/fi';
import { Container } from './styles';

interface ModalInterface {
  handleClose(): void;
  show: boolean;
  title: string;
  success?: boolean;
  action?: any;
  style?: any;
}

const Modal: React.FC<ModalInterface> = ({
  handleClose,
  show,
  title,
  children,
  action,
  style,
}) => (
  <Container show={show}>
    <section className="modal-main" style={style}>
      <>
        <div className="modal-header">
          <h1>{title}</h1>
          <button
            type="button"
            className="close-container"
            onClick={handleClose}
          >
            Fechar
            <FiX size={25} onClick={handleClose} />
          </button>
        </div>
        <div className="modal-body">
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              width: '100%',
            }}
          >
            {children}
          </div>
          {action}
        </div>
      </>
    </section>
  </Container>
);

export default Modal;
