import styled from 'styled-components';

export const Container = styled.div`
  h1 {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0px auto;
    outline: none;
    z-index: 10;
    transform: translateY(-134%);
    font-family: 'BrandonText', Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    color: #3b393b;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
