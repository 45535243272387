/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useCallback } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FiLock } from 'react-icons/fi';

import { RxEyeClosed, RxEyeOpen } from 'react-icons/rx';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';

import Input from '../../components/Input';
import Button from '../../components/Button';
import logo from '../../assets/Shared/logo.svg';
import getValidationErrors from '../../utils/getValidationErrors';

import { Container, Content } from './styles';
import { useAuth } from '../../hooks/auth';

const ResetPassword: React.FC = () => {
  const { code } = useParams<{ code: string }>();
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { push } = useHistory();
  const [eyechange, setEyechange] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const schema = Yup.object().shape({
    new_password: Yup.string().required('Senha é obrigatória'),
    confirm_password: Yup.string().required('Senha é obrigatória'),
  });

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/accounts/register-new-password/', {
          ...data,
          code,
        });

        addToast({
          type: 'success',
          title: 'Senha registrada',
          description: 'Nova senha cadastrada com sucesso!!',
        });
        push('/');
      } catch (err: any) {
        const { ...error } = err;

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        console.error(error);

        addToast({
          type: 'error',
          title: `${
            error.response.data.message
              ? error.response.data.message
              : error.response.data.detail
          }`,
          description: `${
            error.response.data.message
              ? error.response.data.message
              : error.response.data.detail
          }`,
        });
      }
    },
    [schema, code, addToast, push],
  );

  const handleChange = React.useCallback(async () => {
    try {
      if (eyechange) {
        setShowPassword(false);
        setEyechange(false);
      } else if (!eyechange) {
        setShowPassword(true);
        setEyechange(true);
      }
    } catch (err) {
      console.log(err);
    }
  }, [eyechange]);

  React.useEffect(() => {
    localStorage.removeItem('@Transfacil:token');
    localStorage.removeItem('@Transfacil:user');
    delete api.defaults.headers.Authorization;
  }, []);

  return (
    <Container>
      <Content>
        <a href="https://transfacil.com.br">
          <img src={logo} alt="Transfacil" />
        </a>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h1>Digite a nova senha</h1>
          {eyechange ? (
            <>
              <p>
                <strong>Senha</strong>
              </p>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <RxEyeOpen
                  onClick={(e: any) => {
                    e.stopPropagation();
                    handleChange();
                  }}
                  style={{
                    marginLeft: '5px',
                    marginTop: '10px',
                    width: '30px',
                    height: '25px',
                  }}
                />
                <Input
                  name="new_password"
                  className="password"
                  type={showPassword ? 'text' : 'password'}
                  icon={FiLock}
                  placeholder="Senha"
                  schema={schema}
                />
              </div>

              <p>
                <strong>Confirme sua senha</strong>
              </p>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <RxEyeOpen
                  onClick={(e: any) => {
                    e.stopPropagation();
                    handleChange();
                  }}
                  style={{
                    marginLeft: '5px',
                    marginTop: '10px',
                    width: '30px',
                    height: '25px',
                  }}
                />
                <Input
                  name="confirm_password"
                  className="password"
                  type={showPassword ? 'text' : 'password'}
                  icon={FiLock}
                  placeholder="Confirmar senha"
                  schema={schema}
                />
              </div>
            </>
          ) : (
            <>
              <p>
                <strong>Senha</strong>
              </p>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <RxEyeClosed
                  onClick={(e: any) => {
                    e.stopPropagation();
                    handleChange();
                  }}
                  style={{
                    marginLeft: '5px',
                    marginTop: '10px',
                    width: '30px',
                    height: '25px',
                  }}
                />
                <Input
                  name="new_password"
                  className="password"
                  type={showPassword ? 'text' : 'password'}
                  icon={FiLock}
                  placeholder="Senha"
                  schema={schema}
                />
              </div>

              <p>
                <strong>Confirme sua senha</strong>
              </p>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <RxEyeClosed
                  onClick={(e: any) => {
                    e.stopPropagation();
                    handleChange();
                  }}
                  style={{
                    marginLeft: '5px',
                    marginTop: '10px',
                    width: '30px',
                    height: '25px',
                  }}
                />
                <Input
                  name="confirm_password"
                  className="password"
                  type={showPassword ? 'text' : 'password'}
                  icon={FiLock}
                  placeholder="Confirmar senha"
                  schema={schema}
                />
              </div>
            </>
          )}

          <Button color="orange" padding="5" type="submit" className="enviar">
            Enviar
          </Button>
          <Link to="/login">Voltar</Link>
        </Form>
      </Content>
    </Container>
  );
};

export default ResetPassword;
