import styled from 'styled-components';

export const Container = styled.span`
  display: inline-block;
  margin-top: 30px;

  @media (max-width: 680px) {
    width: 100%;
  }
`;
