import styled from 'styled-components';

interface CardProps {
  active: boolean;
}

export const TitleSection = styled.section`
  height: 220px;
  width: 98%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--white);

  h1 {
    font-size: 2.9rem;
    line-height: 4rem;
    margin-bottom: 1rem;
    @media (max-width: 768px) {
      margin-top: 3rem;
      text-align: center;
      line-height: 3rem;
    }
    @media only screen and (max-width: 450px) {
      margin-top: 8rem;
      line-height: 3rem;
      text-align: center;
    }
  }

  span {
    font-size: 1rem;
    line-height: 2rem;
    font-weight: normal;
    @media only screen and (max-width: 768px) {
      line-height: 1.5rem;
      text-align: center;
    }
  }
  @media only screen and (max-width: 450px) {
    height: 150px;
    width: 95%;
  }
`;

export const Main = styled.main`
  padding: 1.5rem 0;
  @media only screen and (max-width: 768px) {
    text-align: center;
  }

  form {
    > div {
      margin-bottom: 40px;
    }
  }

  .title {
    color: var(--purple);
    margin-top: 10px;
  }

  .subtitle1 {
    margin-bottom: 30px;
  }

  .subtitle {
    margin-top: 10px;
    display: inline-block;
  }

  .h1 {
    color: var(--purple);
    margin-top: 90px;
    @media only screen and (max-width: 450px) {
      margin-top: 20px;
    }
  }

  .h1-1 {
    color: var(--purple);
    margin-top: 90px;
    @media only screen and (max-width: 450px) {
      margin-top: 100px;
    }
  }

  h2 {
    margin-top: 1rem;
    margin-bottom: 20px;
    font-weight: normal;
  }

  h4 {
    color: var(--purple);
    margin-top: 10px;
  }

  span {
    margin-top: 10px;
    display: inline-block;
  }

  .text-dark-grey {
    color: #505d68;
    margin-top: 90px;
    @media (max-width: 450px) {
      margin-top: 40px;
    }
  }
  .localizacao {
    color: #dc582a;
    margin-left: 10px;
    margin-top: 0px;
    @media (max-width: 450px) {
      margin-top: 3px;
    }
  }
  .wrapper-localizacao {
    margin-top: 20px;
    display: flex;
  }
  .flex {
    display: flex;

    .googleContainer {
      .locationContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--orange);
        transition: color 0.3s ease;
        margin-bottom: 20px;
        background: transparent;
        height: 40px !important;
        align-self: flex-end;
        &:hover {
          cursor: pointer;
          color: black;
        }
      }

      input {
        margin-top: inherit;
      }
    }

    .img-gps {
      margin-top: -150px;
      margin-left: 130px;
      width: 400px;

      @media (max-width: 1118px) {
        margin-top: 30px;
        margin-left: 0px;
      }

      @media (max-width: 450px) {
        margin-top: 30px;
        margin-left: 0px;
        width: 300px;
      }
    }

    @media (max-width: 1118px) {
      flex-direction: column;
    }
  }
  input {
    border: 1px solid #c2d1d9;
    box-sizing: border-box;
    border-radius: 6px;
    margin-top: 20px;
    height: 40px;
    width: 400px;
    @media (max-width: 957px) {
      flex-direction: column;
      justify-content: center;
      text-align: left;
      align-items: center;
      width: 300px;
    }
  }
  input::placeholder {
    color: var(--gray-line);
    padding-left: 10px;
  }

  .content-passos {
    @media (max-width: 768px) {
      margin-left: 30px;
      text-align: center;
    }
  }
  .box-amarela-direita {
    position: absolute;
    top: 1600px;
    right: 0;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  .box-amarela-direita-2 {
    position: absolute;
    right: 0;
    top: 3000px;
  }
  .box-amarela-direita-3 {
    position: absolute;
    right: 0%;
    top: 700px;
  }

  @media (max-width: 1250px) {
    .box-amarela-direita-2 {
      display: none;
    }

    @media (max-width: 1384px) {
      .box-amarela-direita-3 {
        display: none;
      }
      .box-amarela-direita {
        display: none;
      }
    }
  }
  .btn {
    background: #dc582a;
    border-radius: 6px;
    color: white;
    width: 150px;
    padding: 10px 40px 10px 40px;
    margin-left: 0px;
    @media (max-width: 450px) {
      padding-left: 45%;

      width: 298px;
      height: 40px;
    }
  }
  .btn-buscar {
    color: white;
    margin: 0;
    @media (max-width: 450px) {
      display: none;
    }
  }
  .btn-search {
    height: 30px;
    width: auto;
    display: none;
    @media (max-width: 450px) {
      padding-bottom: 5px;
      display: block;
    }
  }

  .cartoes-bhbus {
    margin-top: 40px;
    @media (max-width: 957px) {
      flex-direction: column;
      justify-content: center;
      text-align: left;
      align-items: center;
    }
  }
  .cartao-bhbus {
    margin-right: 50px;
  }
  .span-cartao {
    width: 300px;
    @media only screen and (max-width: 450px) {
      margin-bottom: -20px;
    }
  }
  .ver-mais {
    color: var(--orange);
  }
  .text-purple {
    color: var(--purple);
  }

  @media (max-width: 915px) {
    .cartao-bhbus {
      width: 400px;
    }
  }

  .recarregar {
    margin-bottom: 50px;
  }
`;

export const ContainerCartao = styled.div`
  display: flex;
  /* margin-top: -50px; */
  .cartaoBranco {
    background: #ffffff;
    border: 2px solid #4d1d6e;
    box-shadow: 0px 3px 25px rgba(235, 94, 53, 0.5);
    border-radius: 22px;
    width: 260px;
    padding-top: 20px;
    padding-bottom: 20px;
    h3 {
      margin-left: 45px;
    }
  }
  .cartaoRoxo {
    background: #452359;
    border: 2px solid #4d1d6e;
    box-shadow: 0px 3px 25px rgba(235, 94, 53, 0.5);
    border-radius: 22px;
    width: 260px;
    padding-top: 20px;
    padding-bottom: 20px;
    h3 {
      margin-left: 45px;
    }
  }

  .imagem-cartao {
    margin-left: 45px;
  }

  div + div {
    margin-left: 30px;
  }

  @media (max-width: 824px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-left: 30px;
    margin-right: 0;
  }
`;

export const CircleContainer = styled.div`
  display: flex;
  margin-top: 40px;
  padding-bottom: 50px;
  max-width: 1140px;
  .text-grey {
    color: #d8d8d8;
  }
  @media (max-width: 950px) {
    flex-wrap: wrap;
  }
  @media (max-width: 950px) {
    display: grid;
    grid-template-columns: minmax(200px, 1fr) 1fr;
    margin-left: -10px;
  }
  .wrapper-circle {
    width: 100%;
  }

  a {
    text-decoration: underline;
  }
  .no-underline {
    text-decoration: none !important;
  }
  @media screen and (max-width: 811px) {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
  }
`;

export const CircleWrapper = styled.div`
  width: 150px;
  word-break: break-word;
  margin-right: 150px;
  @media (max-width: 811px) {
    margin-right: 0px;
  }
  @media (max-width: 915px) {
    margin-bottom: 30px;
    width: 200px;
  }
  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
    width: 90%;
  }
  @media (max-width: 451px) {
    width: 130px;
  }
  @media (max-width: 418px) {
    width: 50%;
  }
  @media (max-width: 370px) {
    width: 90px;
  }

  .bancas {
    width: 100px;
    margin-left: 80px;
    @media (max-width: 768px) {
      margin-left: 300px;
    }
    @media (max-width: 700px) {
      margin-left: 250px;
    }
    @media (max-width: 630px) {
      margin-left: 220px;
    }
    @media (max-width: 545px) {
      margin-left: 190px;
    }
    @media (max-width: 495px) {
      margin-left: 165px;
    }
    @media (max-width: 450px) {
      margin-left: 120px;
    }
    @media (max-width: 355px) {
      margin-left: 100px;
    }
  }
`;

export const Circle = styled.div`
  .img {
    margin-top: 30px;
    margin-left: -60px;
    position: absolute;
  }
  .img2 {
    margin-top: 30px;
    margin-left: -65px;
    position: absolute;
  }
  .img3 {
    margin-top: 25px;
    margin-left: -52px;
    position: absolute;
  }
  .img4 {
    margin-top: 25px;
    margin-left: -65px;
    position: absolute;
  }
  .img5 {
    margin-top: 25px;
    margin-left: -63px;
    position: absolute;
  }
  .img6 {
    margin-top: 25px;
    margin-left: -62px;
    position: absolute;
  }
  .img7 {
    margin-top: 20px;
    margin-left: -62px;
    position: absolute;
  }
  .img8 {
    margin-top: 27px;
    margin-left: -55px;
    position: absolute;
  }
  .img9 {
    margin-top: 33px;
    margin-left: -60px;
    position: absolute;
  }
  .img10 {
    margin-top: 29px;
    margin-left: -55px;
    position: absolute;
  }
  .phone {
    height: 40px;
    width: 40px;
    margin-top: 26px !important;
  }
`;

export const Instrucoes = styled.div`
  @media screen and (max-width: 811px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const ContainerFaq = styled.div`
  margin-top: 20px;
  overflow: scroll;
  border: 2px solid #c2d1d9;
  height: 600px;
  padding: 35px;
  width: 100%;
  border-radius: 10px;

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--purple);
    border-radius: 10px;
  }
  .float-right {
    float: right;
    margin-top: -30px;
  }
  .accordion-wrapper {
    border: 2px solid var(--gray-line);
    margin-bottom: 15px;
  }
`;

export const TransfacilCard = styled.button<CardProps>`
  background: #ffffff;
  border: 2px solid #4d1d6e;
  box-shadow: 0px 3px 25px rgba(235, 94, 53, 0.5);
  border-radius: 22px;
  width: 327px;
  padding: 25px;
  margin-right: 25px;
  color: var(--text);

  display: flex;
  flex-direction: column;

  background: ${props => (props.active ? `var(--white)` : `var(--purple)`)};
  color: ${props => (props.active ? `var(--purple)` : `var(--white)`)};
  svg {
    margin-bottom: 10px;

    path {
      fill: ${props => (props.active ? `var(--purple)` : `var(--white)`)};
    }
  }
  @media (max-width: 824px) {
    margin-bottom: 2rem;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .iconpurple {
    path {
      fill: revert-layer;
    }
  }
`;

export const CardBenefit = styled.button<CardProps>`
  background: #ffffff;
  width: 327px;
  margin-right: 25px;
  color: var(--text);

  display: flex;
  flex-direction: column;

  .modalidades {
    width: 327px;
    display: flex;
    margin-top: 5px;
    h3 {
      display: flex;
      color: var(--purple);
    }
    .frame {
      margin-top: 5px;
      align-items: left;
      text-align: left;
      margin-right: 15px;
      width: 15px;
    }
  }

  .ver-mais-modal {
    color: var(--orange);
    font-weight: 600;
    margin-bottom: 4rem;
    display: ${props => (props.active ? `none` : `block`)};
  }

  .ver-menos-modal {
    color: var(--orange);
    font-weight: 600;
    margin-bottom: 4rem;
    display: ${props => (props.active ? `block` : `none`)};
  }
`;

export const Flex = styled.div`
  display: flex;

  @media (max-width: 980px) {
    flex-direction: column;
  }
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  @media (max-width: 980px) {
    margin-bottom: -50px;
  }
  @media (max-width: 881px) {
    align-items: center;
    margin-right: 0px;
  }

  .ver_mais {
    color: var(--orange);
  }

  .cards {
    border: transparent;
    padding: 0;
    text-align: left;
  }
  .card-ver-mais {
    display: flex;
    justify-content: center;
    align-items: center;
    border: transparent;
    padding: 0;
    text-align: initial;
    margin-top: 15px;
    margin-bottom: 60px;
    padding-bottom: 20px;
  }
`;

export const DialogContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 50px 0px 50px;

  @media (max-width: 840px) {
    flex-direction: column;
  }
  @media (max-width: 450px) {
    width: 110%;
    padding-left: 18px;
  }

  .ver-mais {
    color: var(--orange);
    font-weight: 600;
    background: transparent;
  }

  .cards-benefit {
    display: none;
  }

  .container {
    text-align: center;
    width: 90%;
    height: 470px;
    margin-left: 20px;
    margin-bottom: 50px;
    @media (max-width: 450px) {
      margin-left: 0px;
      width: 100%;
      height: 660px;
    }

    .contratar {
      width: 90%;
      @media (max-width: 420px) {
        margin-left: 0px;
        width: 100%;
        font-size: 1.3rem;
      }
    }

    img {
      width: 400px;
      margin-bottom: 30px;
      @media (max-width: 535px) {
        width: 90%;
      }
      @media (max-width: 420px) {
        width: 100%;
      }
    }
    h3 {
      margin-bottom: 20px;
    }
    .vale {
      margin-bottom: 30px;
      text-align: left;
      margin-left: 30px;
      width: 90%;
      @media (max-width: 420px) {
        font-size: 1.3rem;
        margin-left: 0px;
      }
    }
    .title-benefit-vale {
      margin-top: 0px;
      margin-bottom: 20px;
      color: var(--purple);
      text-align: left;
      margin-left: 30px;
      @media (max-width: 420px) {
        font-size: 1.7rem;
        margin-left: 0px;
      }
    }
  }

  .container1 {
    width: 50%;
    height: 600px;

    @media (max-width: 840px) {
      width: 97%;
    }

    @media (max-width: 615px) {
      margin-bottom: 100px;
    }

    @media (max-width: 500px) {
      margin-bottom: 50px;
    }

    img {
      width: 400px;
      margin-bottom: 30px;
      @media (max-width: 1150px) {
        width: 90%;
      }
      @media (max-width: 800px) {
        width: 70%;
      }
      @media (max-width: 670px) {
        width: 40%;
      }
    }
    .img-cliente {
      width: 400px;
      margin-bottom: 30px;
      @media (max-width: 1150px) {
        width: 90%;
      }
      @media (max-width: 840px) {
        width: 50%;
      }
      @media (max-width: 615px) {
        width: 100%;
      }
    }
    h3 {
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 30px;
    }
  }
  .container2 {
    width: 90%;
    padding-left: 50px;

    @media (max-width: 840px) {
      margin-top: -100px;
      width: 100%;
      padding-left: 0px;
    }

    @media (max-width: 700px) {
      margin-top: -120px;
    }

    @media (max-width: 450px) {
      margin-top: -100px;
    }

    @media (max-width: 340px) {
      margin-top: -130px;
    }

    h3 {
      margin-top: 30px;
      margin-bottom: 20px;
      color: var(--purple);
    }
    .title-benefit {
      margin-top: 10px;
      margin-bottom: 10px;
      color: var(--purple);
    }

    .title-benefit1 {
      margin-top: 25px;
      margin-bottom: 10px;
      color: var(--purple);
    }

    .title-benefit-benefit {
      margin-top: 10px;
      margin-bottom: 10px;
      color: #38734e;
    }

    .title-benefit-estudantil {
      margin-top: 10px;
      margin-bottom: 10px;
      color: #913a62;
    }

    .title-cliente {
      margin-top: 0px;
      margin-bottom: 10px;
      color: var(--purple);
    }

    p {
      margin-bottom: 20px;
    }
  }

  .flex-container {
    display: flex;
    @media (max-width: 840px) {
      margin-top: 100px;
    }
    @media (max-width: 700px) {
      margin-top: 0px;
      flex-direction: column;
    }
  }

  .container-img {
    @media (max-width: 840px) {
      margin-right: 50px;
    }

    @media (max-width: 700px) {
      margin-bottom: 100px;
      margin-top: 20px;
      flex-direction: column;
      width: 95%;
    }
    img {
      width: 360px;
      margin-top: 15px;
      margin-bottom: 20px;
      @media (max-width: 1000px) {
        width: 270px;
      }
      @media (max-width: 850px) {
        width: 100%;
        margin-top: 0px;
      }
      @media (max-width: 614px) {
        width: 100%;
      }
    }
  }
`;
