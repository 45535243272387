import styled from 'styled-components';

export const Container = styled.div`
  background: #ebf1f4;
  border-radius: 10px;
  width: inherit;
  padding: 25px;
  flex-grow: 1;
  margin: 60px 0 0px 0;

  .editar {
    cursor: pointer;
    font-size: 0.8em;
    font-weight: bold;
  }

  span {
    color: var(--orange);
    float: right;
  }

  h3 {
    color: var(--purple);
  }

  .divInformation {
    h3 {
      padding: 10px;
    }
    p {
      color: var(--purple);
    }
  }

  p {
    margin-top: 10px;

    @media (max-width: 680px) {
      flex-wrap: wrap;
      width: 70%;
    }
  }

  div {
    display: flex;
    @media (max-width: 528px) {
      width: 100%;
      flex-wrap: wrap;
    }

    p + p {
      text-align: right;
      margin-left: 15vw;
      @media (max-width: 576px) {
        text-align: right;
        margin-left: 7vw;
      }
      @media (max-width: 528px) {
        text-align: left;
        margin-left: 0;
      }
    }
  }

  @media (max-width: 680px) {
    flex-direction: column;
    width: 100%;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const Info = styled.div`
  margin-bottom: 60px;
  color: var(--red);
  font-size: 0.9rem;
`;
