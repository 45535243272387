import styled from 'styled-components';

export const Container = styled.div`
  input {
    margin-top: 10px;
    padding: 10px;
    width: 350px;
    border-right: none;
    border-radius: 5px 0 0 5px;
    border: 1px solid #c2d1d9;

    ::placeholder {
      color: #c2d1d9;
    }

    @media (max-width: 590px) {
      flex-wrap: wrap;
      width: 290px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 390px) {
      flex-wrap: wrap;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  button {
    background-color: #dc582a;
    border-radius: 5px;
    color: white;
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 12px;
    padding-bottom: 12px;

    @media (max-width: 590px) {
      flex-wrap: wrap;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 390px) {
      flex-wrap: wrap;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media only screen and (max-width: 1100px) {
    img {
      display: none;
    }
  }

  position: relative;

  img {
    position: absolute;
    left: 0;
    top: 0px;
  }

  h1 {
    margin-top: 100px;
    color: var(--purple);

    @media (max-width: 1100px) {
      margin-top: 20px;
      width: 87%;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
  }

  span {
    margin-top: 7px;

    @media (max-width: 1100px) {
      width: 75%;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
    }
  }

  p {
    font-size: 1.3rem;

    @media (max-width: 1100px) {
      width: 80%;
      display: flex;
      justify-content: center;
      text-align: left;
      align-items: center;
    }
  }

  margin: 20px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .localizacao {
    font-size: 0.9rem;
    margin-top: 15px;
    color: #dc582a;
  }

  .texto-final {
    margin-top: 100px;

    @media (max-width: 410px) {
      width: 85%;
      margin-left: 0.9rem;
      flex-wrap: wrap;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 590px) {
    flex-wrap: wrap;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const BoxBoxesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const BoxesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 9%;
  z-index: 12;

  button {
    text-align: left;
    color: transparent;
    background-color: transparent;
    padding: 0;
  }

  a.link {
    height: 90%;
    width: 90%;
    text-decoration: none;
  }
  .box_roxa {
    background: #452359;
    box-shadow: 0px 3px 25px rgba(235, 94, 53, 0.5);
    border-radius: 22px;
    width: 234px;
    height: 172px;
    margin-left: 50px;
    + .box_roxa {
      margin-left: 50px;
    }
    .margem {
      margin-left: 50px;
      margin-bottom: 7px;
    }
    img {
      margin-top: 30px;
    }
    p {
      margin-left: 50px;
      margin-top: 5px;
      color: white;
    }
    h4 {
      margin-left: 50px;
      margin-top: 10px;
      color: white;
    }
  }

  @media only screen and (max-width: 1100px) {
    max-width: 234px;
    flex-wrap: wrap;

    .box_roxa {
      margin-left: 0 !important;
      margin-top: 20px;

      + .box_roxa {
        margin-top: 20px;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
