import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  button {
    width: 100%;
    cursor: pointer;
  }

  input {
    opacity: 0;
    position: absolute;
    left: 0;
    display: flex;
    top: 0;
    width: 100%;
    height: 40px;
    cursor: pointer;
  }

  span {
    color: #e83f5b;
  }
`;
