/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect,
} from 'react';
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useLoadScript,
  DirectionsService,
  DirectionsRenderer,
  Polyline,
} from '@react-google-maps/api';
import { useHistory } from 'react-router-dom';

import { getGeocode, getLatLng } from 'use-places-autocomplete';

import MapStyles from './mapStyles';
import { useMap } from '../../hooks/map';
import {
  Container,
  HamburguerMenuContainer,
  ItensContainer,
  NavItem,
} from './styles';

interface MarkerProps {
  id: number;
  name: string;
  address: string;
  lat: number;
  lng: number;
  opening: string;
  distance?: number;
}

interface MapProps {
  markersData: MarkerProps[];
  markerIcon: string;
  routes?: boolean;
  asideOpen: boolean;
  setActive(active: boolean): void;
}

declare type Libraries = (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
)[];

// Belo Horizonte Coodinates
const centerCordinates = {
  lat: -19.9161568,
  lng: -43.9351929,
};

const options = {
  styles: MapStyles,
  disableDefaultUI: true,
  zoomControl: true,
} as google.maps.MapOptions;

const libraries: Libraries = ['places'];

const Map: React.FC<MapProps> = ({
  markersData,
  markerIcon,
  routes,
  setActive,
  asideOpen,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
    language: 'pt-BR',
  });

  const navItens = [
    { name: 'HOME', path: '/' },
    { name: 'CARTÕES E RECARGA', path: '/cartoes-recarga' },
    { name: 'PARA EMPRESAS', path: '/para-empresas' },
    { name: 'LOJAS', path: '/lojas-locais-recarga' },
    { name: 'ROTAS E LINHAS', path: '/rotas-e-linhas' },
    { name: 'QUEM SOMOS', path: '/quem-somos' },
    { name: 'FALE CONOSCO', path: '/fale-conosco' },
    { name: 'ENTRAR', path: '/login' },
    { name: 'CADASTRAR', path: '/dashboard' },
  ];

  const count = useRef(0);
  const mapRef = useRef(null);
  const {
    changeDestination,
    changeRoutes,
    routes: directionsRoutes,
    changeRechargeAddress,
    origin,
    destination,
    mapCoordinates,
    infoWindows,
    selectedRoute,
    changeRechargeAddresCoordinates,
  } = useMap();
  const [directionsData, setDirectionsData] = useState();
  const { push } = useHistory();
  const [menuToggle, setMenuToggle] = useState<boolean>(false);
  const [selectedMarker, setSelectedMarker] = useState<MarkerProps>(null);
  const [mapContainerStyle, setMapContainerStyle] = useState({
    width: '100vw',
    height: '94.5vh',
  });
  console.log({ directionsData });

  const onMapLoad = useCallback(map => {
    mapRef.current = map;
  }, []);

  const handleSelectMarker = useCallback(marker => {
    setSelectedMarker(marker);
    mapRef.current.panTo({ lat: marker.lat, lng: marker.lng });
  }, []);

  const handleRedirect = useCallback(
    path => {
      push(path);
    },
    [push],
  );

  const handleSelect = useCallback(
    async address => {
      try {
        const geoCode = await getGeocode({ address });
        const { lat, lng } = await getLatLng(geoCode[0]);
        mapRef.current.panTo({ lat, lng });
        changeRechargeAddress(address);
        changeRechargeAddresCoordinates({ lat, lng });
        setActive(true);
      } catch (err) {
        console.error(err);
      }
    },
    [changeRechargeAddresCoordinates, changeRechargeAddress, setActive],
  );

  const handleDestination = useCallback(
    address => {
      changeDestination(address);
      setActive(true);
    },
    [changeDestination, setActive],
  );

  const handleToggleMenu = useCallback(() => {
    setMenuToggle(!menuToggle);
  }, [menuToggle]);

  const handleDirectionsCallback = useCallback(
    res => {
      if (res) {
        if (res.status === 'OK' && count.current <= 2) {
          count.current += 1;

          setDirectionsData(res);
        }
        if (res.status === 'OK' && count.current === 2) {
          changeRoutes(res);
        }
      }
    },
    [changeRoutes],
  );

  const markers = useMemo(() => {
    return markersData;
  }, [markersData]);

  useEffect(() => {
    mapRef?.current?.panTo(mapCoordinates);
  }, [mapCoordinates]);

  useEffect(() => {
    if (!asideOpen && count.current > 1) {
      count.current = 0;
    }
  }, [asideOpen]);

  useEffect(() => {
    if (window.screen.width <= 1024) {
      setMapContainerStyle({
        width: '100vw',
        height: '100vh',
      });
    }
  }, []);

  return (
    <Container>
      {loadError && <h1>Erro ao Carregar o mapa</h1>}
      {!isLoaded && <h1>Carregando o mapa</h1>}
      {isLoaded && (
        <>
          {routes && !asideOpen && (
            <div className="inputMap">
              <input
                className="SearchMap"
                name="SearchMap"
                onChange={e => handleDestination(e.target.value)}
                placeholder="Qual o seu local de destino?"
              />
            </div>
          )}
          {!routes && (
            <div className="inputMap">
              <input
                name="SearchMap"
                onChange={e => handleSelect(e.target.value)}
                placeholder="Qual o seu local de destino?"
                type="recharge"
              />
            </div>
          )}
          <GoogleMap
            ref={mapRef}
            mapContainerStyle={mapContainerStyle}
            zoom={18}
            center={centerCordinates}
            options={options}
            onLoad={onMapLoad}
          >
            {markers.map((marker: MarkerProps) => (
              <Marker
                key={marker.id}
                position={{ lat: marker.lat, lng: marker.lng }}
                icon={markerIcon}
                onClick={() => handleSelectMarker(marker)}
              />
            ))}
            {selectedMarker && (
              <InfoWindow
                position={{
                  lat: selectedMarker.lat,
                  lng: selectedMarker.lng,
                }}
              >
                <div className="info-window-content">
                  <h4>{selectedMarker.name}</h4>
                  <p>{selectedMarker?.address}</p>
                </div>
              </InfoWindow>
            )}
            {infoWindows.length > 0 &&
              infoWindows.map((item: any) => (
                <div key={item} style={{ display: 'block' }}>
                  <InfoWindow position={item.start_location}>
                    <div className="info-window-content">
                      <h4>{item.short_name}</h4>
                    </div>
                  </InfoWindow>
                </div>
              ))}
            {!!origin && !!destination && count.current <= 2 ? (
              <DirectionsService
                options={{
                  origin,
                  destination,
                  travelMode: google.maps.TravelMode.TRANSIT,
                  provideRouteAlternatives: true,
                  transitOptions: {
                    modes: ['BUS' as google.maps.TransitMode.BUS],
                  },
                  optimizeWaypoints: true,
                  unitSystem: google.maps.UnitSystem.METRIC,
                }}
                callback={handleDirectionsCallback}
              />
            ) : (
              <div />
            )}
            {!!Object.keys(directionsRoutes).length && (
              <>
                {Object.keys(selectedRoute).length === 0 && (
                  <DirectionsRenderer
                    options={{
                      directions: directionsRoutes,
                      hideRouteList: true,
                      suppressMarkers: true,
                      suppressInfoWindows: true,
                      polylineOptions: {
                        strokeColor: '#e3692a',
                        strokeWeight: 5,
                      },
                    }}
                  />
                )}
                {!!Object.keys(selectedRoute).length && (
                  <div className="polyline">
                    <Polyline
                      key={selectedRoute.overview_polyline}
                      path={selectedRoute.overview_path}
                      options={{
                        strokeColor: '#e3692a',
                        strokeWeight: 5,
                      }}
                    />
                  </div>
                )}
                {directionsRoutes.routes.map((item: any) => (
                  <Polyline
                    key={item.overview_polyline}
                    path={item.overview_path}
                    options={{
                      strokeColor: '#777777',
                      strokeWeight: 5,
                    }}
                  />
                ))}
              </>
            )}
          </GoogleMap>
        </>
      )}
      <>
        <button type="button" className="menu" onClick={handleToggleMenu}>
          <strong> &#9776;</strong>
        </button>
        <HamburguerMenuContainer active={menuToggle}>
          <button type="button" className="menu" onClick={handleToggleMenu}>
            <strong> &#9776;</strong>
          </button>
          <ItensContainer>
            {navItens.map(item => (
              <NavItem
                onClick={() => handleRedirect(item.path)}
                active={false}
                menuActive={menuToggle}
                key={item.name}
              >
                {item.name}
              </NavItem>
            ))}
          </ItensContainer>
        </HamburguerMenuContainer>
      </>
    </Container>
  );
};

export default Map;

export type { MarkerProps };
