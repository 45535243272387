import styled from 'styled-components';

interface GridProps {
  active: boolean;
}

export const GridContainer = styled.div<GridProps>`
  display: grid;
  grid-template-columns: 420px 1fr;
  grid-template-rows: 50px 1fr 50px;
  grid-template-areas:
    'aside header'
    'aside main'
    'aside footer';
  height: 100vh;

  @media only screen and (max-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 50px 1fr 50px;
    grid-template-areas:
      'header'
      'main'
      'footer';
    height: 100vh;

    aside {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 280px;
      position: fixed;
      overflow-y: auto;
      z-index: 2;

      transform: ${props =>
        props.active ? `translateX(0)` : `translateX(-285px)`};

      .aside_close-icon {
        position: absolute;
        display: flex;
        right: 10px;
        top: 5px;
        color: var(--purple);
        font-size: 28px;
        background-color: var(--white);
      }
    }
  }
`;

export const Header = styled.header`
  grid-area: header;
  background: var(--white);
  padding: 0 40px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  display: flex;

  .menu-icon {
    position: absolute;
    display: flex;
    top: 0;
    left: 26px;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    padding: 10px;
    font-size: 23px;
    color: var(--purple);
    background: white;
  }

  .header-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--purple);
    font-size: 30px;
    width: 100%;

    h1 {
      font-weight: normal;
      font-size: 2rem;
    }

    .profile {
      display: flex;

      align-items: center;

      .profile-data {
        flex-direction: column;
        padding: 16px;
        height: 50px;
        justify-content: center;
        align-items: left;
        display: flex;

        h3 a {
          display: flex;
          flex-direction: column;
          color: var(--purple);
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          /* identical to box height, or 125% */

          text-align: right;
          letter-spacing: 0.212019px;
        }

        h3 a button {
          display: flex;
          justify-content: flex-end;
          font-family: Source Sans Pro;
          letter-spacing: 0px;
          color: #6d6e71;
          opacity: 1;
          background: transparent;
        }
      }

      .logout-form {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          border: none;
          background: none;
          padding: 16px;
          font-size: 15px;
          color: #6d6e71;

          display: flex;
          align-items: center;

          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .aside_close-icon {
    position: absolute;
    visibility: visible;
    top: 20px;
    right: 20px;
    font-size: 35px;
    cursor: pointer;
  }

  @media only screen and (min-width: 1540px) {
    padding: 0 10%;
  }
  @media only screen and (min-width: 1200px) {
    .menu-icon {
      display: none;
    }
    .aside_close-icon {
      display: none;
    }
  }
`;

export const Aside = styled.aside`
  grid-area: aside;
  transition: all 0.3s;
  overflow-y: auto;
  background: var(--aside-background);

  .topo {
    display: flex;
    flex-direction: row;
    background-color: var(--white);
    width: 100%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  .logo-container {
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    cursor: pointer;
  }

  .menu-aside-links {
    margin-top: 20px;
    list-style: none;
    padding: 0 16px;
    /* border-bottom: 0.1px solid #227090; */
  }

  .icon-link {
    display: flex;

    svg {
      margin-right: 14px;
    }

    a {
      font-weight: 600;
    }

    &.last {
      margin-bottom: 15px;
    }
  }

  .menu-aside-links a {
    color: var(--white);
    text-decoration: underline !important;
    text-decoration-color: transparent !important;
  }
  .menu-aside-links li a:hover {
    text-decoration: underline;
    text-decoration-color: white !important;
  }

  @media only screen and (min-width: 769px) {
    .aside_close-icon {
      display: none;
    }
  }
`;

export const AsideMenu = styled.ul`
  margin-top: 20px;
  list-style: none;
  padding: 0 16px;

  .username-wrapper {
    display: flex;
  }

  .icon-link.home {
    display: flex;
    width: 100%;
    height: 77px;
    background: white;
    margin-bottom: 5px;
    border: 1px solid #ebf1f4;
    border-radius: 20px;
    align-items: center;
    justify-content: center;

    .svg {
      width: 38px;
      path {
        fill: var(--purple);
      }
    }

    .link {
      height: 28px;

      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 28px;

      color: var(--purple);
    }

    &.last {
      margin-bottom: 15px;
    }

    &:hover {
      background: white;
      border-radius: 20px;

      .svg {
        path {
          fill: var(--orange);
        }
      }

      .link {
        color: var(--orange);
      }
    }
  }

  .icon-link.links {
    display: flex;
    margin-bottom: 15px;

    .svg {
      width: 38px;
    }

    .link {
      width: 201px;
      a {
        font-weight: normal;
        color: var(--white);
        font-size: 1rem;
      }
    }

    &.last {
      margin-bottom: 15px;
    }
  }
`;

export const Main = styled.main`
  grid-area: main;
  background: var(--white);
`;

export const MainOverview = styled.div`
  margin-top: 20px;

  @media only screen and (min-width: 1540px) {
    padding: 0 10%;
  }

  @media only screen and (max-width: 1540px) {
    padding: 0 5%;
  }
  @media only screen and (max-width: 1400px) {
    padding: 0 2%;
  }
  @media only screen and (max-width: 800px) {
    padding: 0 8%;
  }
`;

export const Footer = styled.footer`
  grid-area: footer;
  background: var(--white);
`;
