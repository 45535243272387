import React, { useCallback } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { FiTrash2, FiCreditCard } from 'react-icons/fi';

import Button from '../../Button';
import TicketingService, { Payment } from '../../../services/ticketing';

import { Circle, Container, Content } from './styles';
import { useToast } from '../../../hooks/toast';

interface ItemFormOfPaymentProps {
  circleSelect: boolean;
  editOption: boolean;
  payment: Payment;
}

const ItemFormOfPayment: React.FC<ItemFormOfPaymentProps> = ({
  circleSelect,
  editOption,
  payment,

  ...rest
}) => {
  const { push } = useHistory();
  const { path } = useRouteMatch();
  const history = useHistory();
  const services = { ticketingService: new TicketingService() };
  const [loading, setLoading] = React.useState<boolean>(true);
  const { addToast } = useToast();

  const handleRedirect = useCallback(
    pathLink => {
      push(pathLink);
    },
    [push],
  );

  const removePayment = React.useCallback(async (paymentId: any) => {
    try {
      setLoading(true);
      const response = await services.ticketingService.deletePaymentForm(
        paymentId,
      );

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: `Forma de pagamento removida com sucesso!!`,
      });

      window.location.reload();

      setLoading(false);
    } catch (err) {
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Erro interno do servidor',
        description: `Tente novamente mais tarde`,
      });
    }
  }, []);

  return (
    <Container {...rest}>
      {circleSelect && (
        <Circle>
          <div className="circle1">
            <div className="circle2" />
          </div>
        </Circle>
      )}

      <Content>
        <FiCreditCard className="icon" size={30} />
        <div className="Infocard">
          <span className="titular-name">{payment.titular_name}</span>
          <span className="card-number">{payment.card_number_display}</span>
        </div>
        {editOption && (
          <Button
            color=""
            padding="0"
            rounded
            className="edit-button"
            type="button"
            onClick={() => removePayment(payment.id)}
          >
            <FiTrash2 size={30} />
          </Button>
        )}
      </Content>
    </Container>
  );
};

export default ItemFormOfPayment;
