import styled from 'styled-components';

interface ContainerProps {
  accepted: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  z-index: 2147483647;
  bottom: 16px;
  left: 16px;
  right: 16px;
  margin: auto;
  max-width: 1334px;
  min-height: 70px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 40%);
  border: solid 1px var(--purple);
  background-color: #fff;
  border-radius: 10px;
  display: ${props => (!props.accepted ? 'block' : 'none')};
  background: var(--purple);

  @media (max-width: 800px) {
    display: block;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: inherit;

  @media (max-width: 800px) {
    flex-direction: column;
  }

  p {
    align-items: center;
    padding: 20px;
    color: var(--white);

    a {
      color: var(--orange);
    }
  }
  .buttons {
    display: flex;
    margin-bottom: 10px;
    @media (max-width: 800px) {
      flex-direction: row;
      margin-left: 10px;
    }
    button {
      color: var(--white);
      font-family: 'Source Sans Pro' sans-serif;
      font-size: 14px;
      font-weight: 400;
      height: 40px;
      padding: 0 3rem;
      background: var(--orange);
      border-radius: 6px;
      border: 0;
      transition: background 0.2s;
      margin-right: 20px;
      &:hover {
        background: #e64f23;
      }
    }
  }
`;
