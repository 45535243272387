/* eslint-disable no-console */
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_APIURL,
});

api.interceptors.response.use(
  success => success,
  error => {
    console.log({ error });
    const { ...err } = error;
    const hasDetailError = err.response.data.hasOwnProperty('detail');

    if (hasDetailError) {
      if (err.response.data.detail === 'Token inválido.') {
        localStorage.removeItem('@Transfacil:token');
        localStorage.removeItem('@Transfacil:user');
        window.location.reload();
      }
      if (err.response.data.message === 'Token inválido.') {
        localStorage.removeItem('@Transfacil:token');
        localStorage.removeItem('@Transfacil:user');
        window.location.reload();
      }
    }
    return Promise.reject(error);
  },
);

export default api;
