/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React from 'react';

import { TitleSection, Main } from './styles';
import { Container, Content, Blur } from '../../styles/components';

import Header from '../../components/Home/Header';
import NavBar from '../../components/Home/NavBar';
import Footer from '../../components/Home/Footer';
import api from '../../services/api';

const PoliticaPrivacidade: React.FC = () => {
  const [privacyPolicy, setPrivacyPolicy] = React.useState<any>(undefined);
  const getPrivacyPolicy = React.useCallback(async () => {
    try {
      const response = await api.get(`/accounts/privacy-policy/`);
      setPrivacyPolicy(response?.data);
    } catch (e: any) {
      console.log({ e });
    }
  }, []);
  React.useEffect(() => {
    getPrivacyPolicy();
  }, []);
  return (
    <>
      <Container>
        <Content>
          <Header />
        </Content>
      </Container>
      <Container backgroundImage>
        <Blur>
          <Content className="content">
            <NavBar />
            <TitleSection>
              <h1 className="h1">
                Política de Privacidade e Proteção de Dados Pessoais
              </h1>
            </TitleSection>
          </Content>
        </Blur>
      </Container>
      <Container>
        <Content>
          <Main>
            <p>
              {privacyPolicy?.updated_at &&
                `Emissão em ${privacyPolicy?.updated_at}`}
            </p>
            {privacyPolicy?.text && (
              <div dangerouslySetInnerHTML={{ __html: privacyPolicy?.text }} />
            )}
          </Main>
        </Content>
      </Container>
      <Container>
        <Footer />
      </Container>
    </>
  );
};

export default PoliticaPrivacidade;
