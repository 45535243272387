import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-self: left;
  justify-content: left;
  width: 100%;
  margin-left: 0px;
  margin-right: -100px;
  margin-bottom: 30px;
  color: var(--purple);

  span {
    margin-bottom: 1em;
    margin-left: 2em;
    font-size: 20px;
    font-weight: bolder;
  }

  .button-confirmar {
    margin-top: 60px;
    margin-right: 1em;
  }

  .button-cancelar {
    color: var(--orange);
    border: 2px solid var(--orange);
    @media (max-width: 422px) {
      margin-top: 1em;
    }
  }

  .status-cartao {
    display: inline-block;
    color: black;
    margin-left: 5px;
  }

  .statusCard {
    display: flex;
  }

  .removeButton {
    transform: translateY(-95px);
    background: #c53030;
    transition: background 0.3s ease;
    &:hover {
      background: #af1b1b;
    }
  }

  .add {
    grid-template-columns: 2fr 2fr;
  }
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 0.5fr;
  grid-gap: 10px;
  margin-top: 1em;
  margin-bottom: 1em;
  color: var(--purple);
  @media (max-width: 570px) {
    grid-template-columns: 2.3fr 0.8fr 0.9fr;
  }
  @media (max-width: 450px) {
    grid-template-columns: 2.9fr 0.8fr 1.5fr;
  }
`;

export const CEPContainer = styled.div`
  display: grid;
  grid-template-columns: 0.3fr 0.9fr;
  grid-gap: 10px;
  margin-top: 1em;
  margin-bottom: 1em;
  color: var(--purple);
  @media (max-width: 810px) {
    grid-template-columns: 0.5fr 0.9fr;
  }
  @media (max-width: 483px) {
    grid-template-columns: 0.9fr 0.9fr;
  }
`;

export const SearchCEPLink = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  height: 100%;
  color: var(--purple);
  .cep-remember {
    @media (max-width: 704px) {
      width: 150px;
    }
  }
`;

export const AddressContainer = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr;
  grid-gap: 10px;
  margin-top: 1em;
  margin-bottom: 1em;
  color: var(--purple);
`;

export const LocationContainer = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 0.5fr;
  grid-gap: 10px;
  margin-top: 1em;
  margin-bottom: 1em;
  color: var(--purple);
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-end;

  .start {
    margin-right: auto;
  }
`;
