/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';

interface ISelectQuantity {
  onClick: any;
  qty: number;
}

const SelectQuantity: React.FC<ISelectQuantity> = ({ onClick, qty }) => {
  return (
    <>
      <h1> Selecione a quantidade </h1>
      <div
        style={{
          marginTop: 20,
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
        }}
      >
        {[1, 2, 4, 6, 10].flatMap((value: number, i: number) => (
          <div
            onClick={() => {
              onClick(value);
            }}
            key={i}
            style={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 40,
              width: 150,
              marginRight: 20,
              marginBottom: 20,
              border: '1px solid purple',
              borderRadius: 20,
              backgroundColor: qty === value ? 'purple' : 'white',
            }}
          >
            <h3 style={{ color: qty !== value ? 'purple' : 'white' }}>
              {value}
            </h3>
          </div>
        ))}
      </div>
    </>
  );
};

export { SelectQuantity };
