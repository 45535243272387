import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Container, Information } from './styles';
import Button from '../../Button';
import OvalGrey from '../../../assets/MyCards/ovalgrey.svg';
import Card from '../Card';
import CardContainerList from '../CardContainerList';

import { Card as CardInterface } from '../../../services/ticketing';

interface BlockCardProps {
  cards: CardInterface[];
}

const BlockCard: React.FC<BlockCardProps> = ({ cards }) => {
  const { push } = useHistory();

  const handleRedirect = useCallback(
    pathLink => {
      push(pathLink);
    },
    [push],
  );

  return (
    <Container>
      <p className="text-cartao1">1. Selecione o cartão que deseja bloquear:</p>
      <CardContainerList>
        {cards &&
          cards.map((card: CardInterface) => (
            <Card
              key={card.id}
              nickname={card.nickname}
              cardNumber={card.card_number}
            />
          ))}
      </CardContainerList>
      <p className="text-cartao2">2. Conte o que aconteceu:</p>
      <input type="text" className="inputroubo" />
      <p className="text-cartao3">3. Anexar documento*</p>
      <Button
        color="white"
        padding="1"
        rounded
        className="button-document"
        type="submit"
      >
        <img src={OvalGrey} alt="anexar" className="ovalgrey" />
      </Button>
      <p className="text-nota">
        * opcional para quem possui Boletim de Ocorrência.
      </p>
      <Button
        color="orange"
        padding="2"
        rounded
        className="button-cadastrar"
        type="button"
      >
        BLOQUEAR CARTÃO
      </Button>
      <Button
        color="white"
        padding="3.5"
        rounded
        className="button-cancelar"
        type="button"
        onClick={() => handleRedirect('/meus-cartoes')}
      >
        CANCELAR
      </Button>
      <Information>
        <span className="information">Informações:</span>
        <h3>Bloqueio dos Cartões Identificados:</h3>
        <p>
          Para retirar a segunda via é necessário aguardar 3 dias úteis após o
          bloqueio e comparecer no Posto Floresta (Rua Aquiles Lobo, 504 -
          Floresta) apresentando documento original com foto e o valor de R$
          15,00.
        </p>
        <p>
          Caso seja necessário um terceiro retirar o cartão, será necessário
          apresentar o documento original ou a versão digital com foto e uma
          carta de autorização assinada pelo titular.
        </p>
        <h3>Cartão usuário ao Portador</h3>
        <p>
          No Cartão BHBUS Ao Portador não é possível realizar o bloqueio ou
          ressarcimento dos créditos em caso de perda ou roubo.
        </p>
        <h3 className="vale-transporte">Vale Transporte</h3>
        <p>
          Para retirar a segunda via é necessário aguardar 3 dias úteis após a
          notificação de bloqueio e comparecer no Posto Floresta (Rua Aquiles
          Lobo, 504.) com a carta de autorização da empresa e o valor de R$15,00
          por cartão.
        </p>
        <h3>Cartão BHBUS MASTER:</h3>
        <p>
          Para retirar a segunda via é necessário comparecer no BH Resolve (Av.
          Santos Dumont, 363 - Centro) apresentando documento original com foto
          e boletim de ocorrência.
        </p>
        <p>
          Os atendimentos acontecem de segunda à sexta-feira, de 8h às 12h e de
          13h às 17h, por ordem de chegada, não sendo necessário agendamento
          prévio. A partir da segunda via em diante será cobrado valor
          correspondente a 7 tarifas predominantes, atualmente R$ 31,50.
        </p>
        <h3>Cartão BHBUS Benefício:</h3>
        <p>
          Para retirar a segunda via é necessário comparecer BH Resolve (Av.
          Santos Dumont, 363 - Centro) apresentando documento original com foto.
          Os atendimentos serão de segunda à sexta-feira, de 8h às 12h e de 13h
          às 17h, por ordem de chegada, não sendo possível o agendamento prévio.
          A partir da terceira via em diante será cobrado valor correspondente a
          7 tarifas predominantes, atualmente R$ 31,50.
        </p>
      </Information>
    </Container>
  );
};

export default BlockCard;
