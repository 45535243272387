import styled, { css } from 'styled-components';

interface ContainerProps {
  isFocused: boolean;
  isErrored: boolean;
  isCorrect: boolean;
}

export const Container = styled.div<ContainerProps>`
  .react-select__control {
    background: var(--white);
    color: var(--text);
    border: 1px solid var(--gray-line);
    padding: 0 0 0 5px;
    width: 100%;
    height: 41px;
    border-radius: 8px;
  }

  .react-select__placeholder {
    color: var(--gray-line);
  }

  .react-select__indicator-separator {
    height: 25px;
  }

  ${props =>
    props.isErrored &&
    css`
      .react-select__control {
        border-color: #c53030;
      }
    `}

  ${props =>
    props.isFocused &&
    css`
      .react-select__control {
        border-color: #d65b15 !important;
      }
    `}

  ${props =>
    props.isCorrect &&
    css`
      .react-select__control {
        border-color: green !important;
        color: green;
      }
    `}
`;

export const Error = styled.label`
  height: 20px;
  font-size: 15px;
  margin-bottom: 3px;
  color: #c53030;
  display: flex;
`;

export const Label = styled.label`
  margin-left: 10px;
  font-size: 18px;
`;
