import styled, { keyframes } from 'styled-components';

const EaseTopDown = keyframes`
  from{ opacity:0;top:0; }
  to{ opacity:1;top:20%;}
`;

const EasyIn = keyframes`
  from{ opacity:0; }
  to{ opacity:1; }
`;

export const ModalUpdate = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: block;
  z-index: 100000000000000;
  animation: ${EasyIn} 0.3s;

  section {
    position: fixed;
    background: #ffffff;
    animation: ${EaseTopDown} 0.4s;
    width: 100%;

    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    display: flex;

    width: 60%;
    height: auto;
    max-height: 80vh;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;

    animation: ${EasyIn} 0.5s;

    p {
      color: var(--title-higlight);
      margin-top: 17px;
    }

    .modal-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--purple);
      height: 65px;
      font: 400 1rem 'Source Sans Pro', sans-serif;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      color: var(--white);
    }

    .modal-body {
      overflow: auto;
      padding: 15px;
      width: 100%;

      display: flex;
      align-items: center;
      flex-direction: column;

      font-size: 25px;
      font-weight: bold;

      button {
        margin: 2rem 0;
        align-self: center;
        span {
          font: 400 1rem 'Source Sans Pro', sans-serif;
          color: white;
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }
`;
