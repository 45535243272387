import styled from 'styled-components';
import { Form } from '@unform/web';

export const ContainerFaq = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 100%;

  .h1 {
    color: #472b74;
    justify-self: start;
    align-self: start;
    margin-bottom: 20px;
  }

  @media (max-width: 1524px) {
    flex-wrap: wrap;
    display: flex;
  }
`;

export const Container = styled.div`
  padding-top: 35px;
  width: 100%;

  /* .h1 {
    justify-self: start;
    align-self: start;
    margin-left: 10%;
    margin-bottom: 20px;
  } */

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--purple);
    border-radius: 10px;
  }
  .float-right {
    /* float: right; */
    margin-top: -30px;
  }
  .faq {
    margin-left: 78px;
  }
`;

export const FormSearchFaq = styled(Form)`
  margin-bottom: 1em;
`;

export const TitleSection = styled.section`
  margin: 60px 0px 0px 0px;
  color: var(--purple);

  h1 {
    font-size: 3rem;
    line-height: 2.3rem;
  }
`;
