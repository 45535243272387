import React from 'react';
import {
  Redirect,
  Route,
  RouteProps as ReactDOMRouteProps,
} from 'react-router-dom';
import { useAuth } from '../hooks/auth';

interface RouteProps extends ReactDOMRouteProps {
  component: React.ComponentType;
}

const PrivateRoute: React.FC<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();
  return (
    <Route
      {...rest}
      render={() => {
        return user ? <Component /> : <Redirect to="/" />;
      }}
    />
  );
};

export default PrivateRoute;
