/* eslint-disable react/jsx-boolean-value */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { useCallback, useState, useEffect } from 'react';
import {
  useHistory,
  useRouteMatch,
  matchPath,
  useLocation,
} from 'react-router-dom';

import api from '../../../services/api';

import { Container, AddCard, TransfacilCard } from './styles';
import Card from '../Card';

import { Column } from '../../../styles/components';
import Add from '../../../assets/MyCards/addcard.svg';

import { Card as CardInterface } from '../../../services/ticketing';

interface CardListProps {
  cards: CardInterface[];
}

interface Cards {
  id: number;
  user: string;
  nickname: string;
  card_number: string;
  createdAt: Date;
}

const ListCard: React.FC<CardListProps> = ({ cards, ...rest }) => {
  const { push } = useHistory();
  const { path, url } = useRouteMatch();
  const [cartoescard, setCartoescards] = React.useState<Cards[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  const { pathname } = useLocation();

  const [cartoes, setCartoes] = useState<any>(null);

  async function getCards() {
    try {
      const response = await api.get('/ticketing/my-cards/');
      setCartoescards(response.data.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getCards();
  }, []);

  useEffect(() => {
    setCartoes(cards);
  }, [cards]);

  const handleRedirect = useCallback(
    pathLink => {
      push(pathLink);
    },
    [push],
  );

  const handleUrlMatch = (activeUrl: string): boolean => {
    const match = matchPath(pathname, {
      path: activeUrl,
      exact: true,
    });

    return match ? match.isExact : false;
  };

  return (
    <Container {...rest}>
      {cartoes && (
        <>
          <div className="cards-container">
            <Column small="12" medium="4" large="3">
              <AddCard
                onClick={() => handleRedirect('/meus-cartoes/registrar')}
              >
                <img src={Add} alt="cadastrar cartao" />
                <span>CADASTRAR NOVO CARTÃO</span>
              </AddCard>
            </Column>
            {cartoes.map((card: CardInterface) => (
              <Column key={card.id} small="12" medium="4" large="3">
                <Card
                  nickname={card.nickname}
                  cardNumber={card.card_number}
                  saldo={card?.balance}
                  id={card?.id}
                  active={true}
                  mainmenu={true}
                />
              </Column>
            ))}
          </div>
        </>
      )}
    </Container>
  );
};

export default ListCard;
