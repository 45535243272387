/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, {useState, useEffect, useCallback} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { parseCurrency } from '@brazilian-utils/brazilian-utils';
import DashboardLayout from '../../components/DashboardLayout';
import api from '../../services/api';
import { InformCVV } from './InformCVV';
import { InformValue } from './InformValue';
import { PaymentMethod } from './PaymentMethod';
import { SelectQuantity } from './SelectQuantity';
import { Buttons, Container, ModalContentBanner, TitleSection } from './styles';
import { SummaryValue } from './SummaryValue';
import TicketingService, { Card } from '../../services/ticketing';
import Loader from '../../components/Loader';
import ListCard from './ListCard';
import ModalPix from './ModalPix';
import { useToast } from '../../hooks/toast';
import { useLoader } from '../../hooks/loader';
import Modal from '../../components/Modal';
import { formatPrice } from '../../utils/currencyFormatter';
import FormPayment from '../../components/FormOfPayment/FormPayment';
import ModalText from './Modal';
import Button from '../../components/Button';
import AccountsService from '../../services/accounts';
import ModalSucess from './ModalSucess';

const BuyQrCode: React.FC = () => {
  const params: any = useParams()
  const [qty, setQty] = React.useState<number>(0);
  const [qtyInput, setQtyInput] = React.useState<string>('0.0');
  const [formPayment, setFormPayment] = React.useState<any>(undefined);
  const [selectCard, setSelectCard] = React.useState<any>(undefined);
  const [higherFee, setHigherFee] = React.useState(0);
  const [cvv, setCvv] = React.useState('');
  const [modalPixOpen, setModalPixOpen] = React.useState(false);
  const [pixImage, setPixImage] = React.useState(undefined)
  const [pixCode, setPixCode] = React.useState(undefined)
  const [pixExpiration, setPixExpiration] = React.useState(undefined)
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [successType, setSuccessType] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [cardtax, setCardtax] = useState<number>();
  const [boletotax, setBoletotax] = useState<number>();
  const [pixtax, setPixtax] = useState<number>();
  const [finaltax, setFinaltax] = useState<number>();
  const [modalPhone, setModalPhone] = useState<boolean>(false);
  const [modalCpf, setModalCpf] = useState<boolean>(false);
  const [phones, setPhones] = React.useState([]);
  const [cpfs, setCpfs] = React.useState([]);
  const servicesProfile = { AccountsService: new AccountsService() };
  const [successTypeCredit, setSuccessTypeCredit] = useState("");

  const { addToast } = useToast();
  const { changeLoader } = useLoader();
  const services = { ticketingService: new TicketingService() };
  const history = useHistory()
  const { push } = useHistory();

  const resetPay = React.useCallback(() => {
    setQty(0)
    setQtyInput('0.0');
    setFormPayment(undefined);
    setSelectCard(undefined);
    setCvv('');
  }, [])

  const closeModal = useCallback(() => {
    setModalPixOpen(false);
    push('/qr-code/RecargaHistorico');
  }, [push]);


  useEffect(() => {
  }, [successType, pixImage, pixCode, pixExpiration, modalPixOpen])

  const handleRedirect = useCallback(
    pathLink => {
      push(pathLink);
    },
    [push],
  );


  const handleSubmit = React.useCallback(async () => {
    try {
      if (!phones || !cpfs) {
        if (!phones) {
          setModalPhone(true);
        } else {
          setModalCpf(true);
        }
      } else {
        if (formPayment.payment_type === 'credit_card' && Number( qty > 0 ? qty * higherFee : qtyInput ) > Number(formPayment?.maximum)) {
          addToast({
            type: 'info',
            title: `Limite Máximo de Recarga Por Cartão de crédito é de ${formPayment?.maximum}`,
          });
          return;
        }
  
        if (cvv.length < 3 && formPayment.payment_type === 'credit_card') {
          addToast({
            type: 'error',
            title: 'CVV não informado',
          });
          return;
        }
  
        if (formPayment.payment_type === 'pix' && Number( qty > 0 ? qty * higherFee : qtyInput ) > Number(formPayment?.maximum) ) {
          addToast({
            type: 'info',
            title: `Limite Máximo de Recarga Pelo Meio de Pagamento PIX é de R$${formPayment?.maximum}`,
          });
          return;
        }
  
        changeLoader(true);
    
        let formsOfPayment;
        if (formPayment?.id === 8 || formPayment?.id === 3) {
          formsOfPayment = await api.get(`/ticketing/forms-of-payment/?payment_type=${formPayment.payment_type}`)
        }
        
        const form_of_payment = formsOfPayment?.data?.results[0]
  
  
        const response = await services.ticketingService.rechargeCard(
          params?.id,
          formPayment?.id === 1 ? selectCard?.id : form_of_payment?.id,
          (qty > 0) ? (qty * higherFee) : parseCurrency(qtyInput),
          cvv,
        );
  
        if (formPayment.payment_type === 'pix') {
          setModalPixOpen(true);
          setPixImage(response.data.pix_image_link);
          setPixCode(response.data.pix_copy_paste);
          setPixExpiration(response.data.pix_expiration);
        } else {
          setModalPixOpen(false);
        }

        if (formPayment.payment_type === 'credit_card') {
          setSuccessTypeCredit('credit_card');
          setShowSuccess(true)
        }
      }
      

      changeLoader(false);
    

      resetPay()
    
    } catch (err: any) {
      changeLoader(false);
      const { ...error } = err;
      console.log(error);
      if (error?.response?.data?.detail === "Cartão bloqueado.") {
        addToast({
          type: 'error',
          title: `${error?.response?.data?.detail}`,
          description:
            error?.response?.data?.detail &&
            `${error?.response?.data?.detail}`,
        });
      } else {
        addToast({
          type: 'error',
          title: `${error?.response?.data?.message}`,
          description:
            error?.response?.data?.sub_message &&
            `${error?.response?.data?.sub_message}`,
        });
      }
      
    }
  }, [ qty, qtyInput, formPayment, cvv, selectCard, higherFee,successType, pixImage, pixCode, pixExpiration, modalPixOpen]);

  const getQrCodeHigherFee = React.useCallback(async () => {
    try {
      const response = await api.get('/core/qr-code/higher-fee/');
      setHigherFee(response?.data?.higher_fee || 0);
    } catch (e) {
      console.log({ e });
    }
  }, []);
  async function asyncGetBoletoLimit() {
    try {
      setLoading(true);
      const response = await services.ticketingService.getBoletoLimit();

      const cardLimit = response.data.results.filter(
        (billet: any) => billet.payment_type === 'credit_card',
      );

      const pixLimit = response.data.results.filter(
        (billet: any) => billet.payment_type === 'pix',
      );

      setCardtax(cardLimit[0]?.rate);
      setPixtax(pixLimit[0]?.rate);

      if (formPayment.payment_type === 'credit_card') {
        setFinaltax(cardtax);
      } else {
        setFinaltax(pixtax);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  React.useEffect(() => {
    getQrCodeHigherFee();
    asyncGetBoletoLimit();
  }, [params,selectCard,formPayment, cvv ]);

  useEffect(() => {
    async function getProfile() {
      try {
        const response = await servicesProfile.AccountsService.getProfile();
        setPhones(response?.data?.phone);
        setCpfs(response?.data?.cpf);
      } catch (err) {
        const { ...error }: any = err;

        addToast({
          type: 'error',
          title: `${
            error?.response?.data?.message
              ? error?.response?.data?.message
              : 'Erro no Servidor'
          }`,
          description: `${
            error?.response?.data?.sub_message
              ? error?.response?.data?.sub_message
              : 'Tente deslogar e logar novamente, caso o erro persista entre em contato com o Administrador'
          }`,
        });
        console.error(err);
      }
    }
    getProfile();
  }, []);

  return (
    <DashboardLayout>
      <Container>
        <TitleSection>
          <h1>Comprar Qr Code</h1>
        </TitleSection>
        <div style={{ marginBottom: 30 }}>
          <SelectQuantity
            onClick={async (value: number) => {
              if (parseCurrency(qtyInput) > 0) setQtyInput('0.0');
              setQty(value);
            }}
            qty={qty}
          />
        </div>

        <div style={{ marginBottom: 50 }}>
          <InformValue
            qtyInput={parseCurrency(qtyInput)}
            onClick={(value: string): any => {
              if (qty > 0) setQty(0);
              setQtyInput(value);
            }}
          />
        </div>

        {(qty > 0 || parseCurrency(qtyInput) > 0) && (
          <div style={{ marginBottom: 30 }}>
            <PaymentMethod
              formPayment={formPayment}
              onClick={(data: any): any => {
                setFormPayment(data);
              }}
            />
            {formPayment?.id === 1 && (
              <div style={{ marginBottom: 20 }}>
                <div
                  style={{ color: 'var(--orange)' }}
                  >
                  * Atenção! As compras na modalidade cartão de crédito
                  estão limitados a 1 compra diária por usuário.
                </div>
                <div>
                  <ListCard setSelectCard={setSelectCard} selectCard={selectCard} />
                </div>
                {selectCard && (
                  <div style={{ marginTop: 20 }}>
                    <InformCVV cvv={cvv} setCvv={setCvv} />
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {formPayment && ((formPayment?.id === 1 && cvv !== '') || formPayment?.id !== 1) && (qty > 0 || parseCurrency(qtyInput) > 0) && (
          <>
            <div style={{ marginBottom: 50 }}>
              <SummaryValue
                balance={qty > 0 ? qty * higherFee : parseCurrency(qtyInput)}
                rate={finaltax}
              />
            </div>
            <div
              style={{
                border: '2px solid var(--orange)',
                borderRadius: '10px',
                fontSize: '12px',
                color: 'var(--orange)',
                width: 'auto',
                maxWidth: '800px',
                marginTop: '10px',
                marginBottom: '40px',
                padding: '5px',
              }}
            >
              <span>
                <h1
                  style={{
                    color: 'var(--orange)',
                    fontSize: '14px',
                  }}
                >
                  *Atenção!
                </h1>
                <p>
                  Compra de saldo para gerar sua passagem por QR CODE e utilizar na
                  linha desejada.
                </p>
                <p>
                  Modalidade EXCLUSIVA para PASSAGENS POR QR CODE, não sendo válida
                  para recarga do cartão físico.
                </p>
                <p>
                  Após a recarga não é possível realizar a transferência de crédito
                  para outros meios de embarque.
                </p>
                <p>
                  Os QR Codes gerados possuem validade de 30 minutos, após o período
                  sua utilização é invalidada e os créditos retornam ao seu saldo de
                  QR Codes em até 48h.
                </p>
                <p>
                  QR Codes não são válidos para as regras de integração e podem ser
                  utilizados apenas no sistema municipal de Belo Horizonte.
                </p>
                <p>
                  Não disponível para as linhas suplementares e estações de metrô.
                </p>
              </span>
            </div>
            <Buttons onClick={() => handleSubmit()}>Confirmar compra</Buttons>
          </>
        )}
        {modalPhone && (
        <ModalText
          handleClose={() => setModalPhone(false)}
          show={modalPhone}
          title="Telefone não Cadastrado"
        >
          <ModalContentBanner>
            <p>
              Verificamos que não há um telefone valido cadastrado no seu
              usuário. O telefone junto aos outros dados cadastrados são de
              grande importância que estejam sempre atualizados.
            </p>
            <p>Por favor, cadastre um número de telefone valido.</p>
            <Button
              color="orange"
              padding="3.1"
              rounded
              className="button-confirmar"
              type="button"
              onClick={() => handleRedirect('/meu-perfil')}
            >
              CADASTRAR
            </Button>
          </ModalContentBanner>
        </ModalText>
      )}
      {modalCpf && (
        <ModalText
          handleClose={() => setModalCpf(false)}
          show={modalCpf}
          title="CPF Não Cadastrado"
        >
          <ModalContentBanner>
            <p>
              Verificamos que não há um cpf cadastrado no seu usuário. O cpf
              junto aos outros dados cadastrados são de grande importância que
              estejam sempre corretos.
            </p>
            <p>Por favor, cadastre um número de cpf valido.</p>
            <Button
              color="orange"
              padding="3.1"
              rounded
              className="button-confirmar"
              type="button"
              onClick={() => handleRedirect('/meu-perfil')}
              style={{
                marginTop: '50px',
                minHeight: '20px',
              }}
            >
              CADASTRAR
            </Button>
          </ModalContentBanner>
        </ModalText>
      )}

        {/* {showSuccess && successType !== 'pix' && (
        <Modal
          title='Success Cartão de Credito'
          handleClose={() => closeModal()}
          success
          show={showSuccess}
        >
          {successType === 'credit_card' && (
            <>
              <h1>Pedido de compra de QRCode recebido com sucesso!</h1>
              <br /> Na tela de "QRCode" você consegue acompanhar o status
              da sua compra.
            </>
          )}
        </Modal>
      )}
      {showSuccess && successType === 'pix' && (
        <>
          <ModalPix
            closeModal={() => closeModal()}
            codeExpiration={pixExpiration}
            codePix={pixCode}
            codeImage={pixImage}
            show={modalPixOpen}
          />
        </>
      )} */}
      </Container>
      {showSuccess && (
        <ModalSucess
          handleClose={() => closeModal()}
          success
          show={showSuccess}
          successText=""
          typeModal="success"
        >
          {successTypeCredit === 'credit_card' && (
            <>
              <h1>Pedido de recarga recebido com sucesso.</h1>
              <br /> * Atenção! As compras na modalidade cartão de crédito estão
              limitados a 1 compra diária por usuário.
            </>
          )}
        </ModalSucess>
      )}
      {modalPixOpen && (
        <ModalPix
          show={modalPixOpen}
          closeModal={closeModal}
          codeExpiration={pixExpiration}
          codePix={pixCode}
          codeImage={pixImage}
        />
      )}
    </DashboardLayout>
  );
};

export { BuyQrCode };
