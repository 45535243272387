/* eslint-disable react/no-danger */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player/lazy';
import Slider from 'react-slick';
import { FaUsers } from 'react-icons/fa';
import { BsChevronRight } from 'react-icons/bs';
import { Icon } from 'react-icons-kit';
import { line } from 'react-icons-kit/entypo/line';
import Shop from '../../assets/Shared/shop2.svg';
import Bus from '../../assets/Shared/FrameBus.svg';
import api from '../../services/api';
import Accordion from '../../components/QuemSomos/Accordion';
import { Container, Content, Blur, Row, Column } from '../../styles/components';
import {
  TitleSection,
  VideoSection,
  MissionSection,
  TransfacilTextSection,
  MetricSection,
  BackgroundSlider,
  WorkwithUsSection,
  BackgroundSliderNews,
  SliderItemNews,
  SliderItem,
  JobsSection,
  ContentPolitica,
  PurpleContainer,
} from './styles';
import Marco2001 from '../../assets/QuemSomos/2001.png';
import Marco2005 from '../../assets/QuemSomos/2005.png';
import Marco2010 from '../../assets/QuemSomos/2010.png';
import Marco2011 from '../../assets/QuemSomos/2011.png';
import Marco2014 from '../../assets/QuemSomos/2014.png';
import Marco2019 from '../../assets/QuemSomos/2019_2020.png';

import Transfacil1 from '../../assets/QuemSomos/transfacil_galery_1.jpg';
import Transfacil2 from '../../assets/QuemSomos/transfacil_galery_2.jpg';
import Transfacil3 from '../../assets/QuemSomos/transfacil_galery_3.jpg';
import Transfacil4 from '../../assets/QuemSomos/transfacil_galery_4.jpg';
import Transfacil5 from '../../assets/QuemSomos/transfacil_galery_5.jpg';
import Transfacil6 from '../../assets/QuemSomos/transfacil_galery_6.jpg';
import Transfacil7 from '../../assets/QuemSomos/transfacil_galery_7.jpg';
import Transfacil8 from '../../assets/QuemSomos/transfacil_galery_8.jpg';
import Transfacil9 from '../../assets/QuemSomos/transfacil_galery_9.jpg';
import Transfacil10 from '../../assets/QuemSomos/transfacil_galery_10.jpg';
import Transfacil11 from '../../assets/QuemSomos/transfacil_galery_11.jpg';
import Transfacil12 from '../../assets/QuemSomos/transfacil_galery_12.jpg';
import Transfacil13 from '../../assets/QuemSomos/transfacil_galery_13.jpg';
import Transfacil14 from '../../assets/QuemSomos/transfacil_galery_14.jpg';
import Transfacil15 from '../../assets/QuemSomos/transfacil_galery_15.jpg';
import Transfacil16 from '../../assets/QuemSomos/transfacil_galery_16.jpg';
import Transfacil17 from '../../assets/QuemSomos/transfacil_galery_17.jpg';
import Metric1 from '../../assets/QuemSomos/metric1.png';
import Metric2 from '../../assets/QuemSomos/metric2.png';
import Metric3 from '../../assets/QuemSomos/metric3.png';
import Metric4 from '../../assets/QuemSomos/metric4.png';
import Pessoa from '../../assets/QuemSomos/Person.svg';
import Building from '../../assets/QuemSomos/Building.svg';
import framepolitica from '../../assets/Shared/framepolitica.svg';

import Footer from '../../components/Home/Footer';

import Header from '../../components/Home/Header';
import NavBar from '../../components/Home/NavBar';

const QuemSomos: React.FC = () => {
  const [jobs, setJobs] = useState([]);

  const settingsnews = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    className: 'slider',
    responsive: [
      {
        breakpoint: 769,
        settings: {
          arrows: false,
          dots: true,
          infinite: false,
        },
      },
    ],
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          arrows: true,
          dots: false,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };

  const handleLink = (link: string) => {
    window.open(link, '_blank');
  };

  useEffect(() => {
    const getJobs = async () => {
      try {
        const response = await api.get('/core/vagas/');
        setJobs(response.data.results);
      } catch (err) {
        console.error(err);
      }
    };
    getJobs();
  }, []);

  return (
    <>
      <Container>
        <Content>
          <Header />
        </Content>
      </Container>
      <Container backgroundImage className="companies-container">
        <Blur>
          <Content className="content">
            <NavBar />
            <TitleSection>
              <h1>O Transfácil</h1>
              <span>
                Conheça a nossa história e nossas motivações para entregar
                serviços de qualidade para você
              </span>
            </TitleSection>
          </Content>
        </Blur>
      </Container>
      <Container>
        <Content>
          <VideoSection>
            <div className="video-container">
              <ReactPlayer url="https://www.youtube.com/watch?v=Q1ROCyK6oN4" />
            </div>
            <div className="empresa-container">
              <div className="icon">
                <FaUsers color="#424B5A" size={45} />
              </div>

              <h1>Quem Somos</h1>
              <p>
                O Transfácil nasceu em 2001, já digital, e veio com o intuito de
                modernizar a mobilidade urbana em BH. Adeus vale-transporte de
                papel, bem vinda Bilhetagem Eletrônica! Mais segurança, mais
                agilidade no embarque, facilidade e maior controle dos clientes
                através do cartão BHBUS.
              </p>
              <p>
                Ao longo dos anos, outros projetos surgiram: ampliamos nossa
                operação com a implantação do MOVE, lançamos um App e estamos
                prontos pra muito mais.
              </p>
              <p>
                Nossa trajetória se conecta com seu dia a dia. Sabemos da nossa
                responsabilidade e trabalhamos para oferecer a você um
                transporte coletivo de qualidade.
              </p>
            </div>
          </VideoSection>
        </Content>
        <Content>
          <MissionSection>
            <div className="text-container">
              <h3>Nossa Missão</h3>
              <h1>Ser uma opção de mobilidade para você</h1>
            </div>
            <div className="mission-container">
              <h3>Nossos Valores:</h3>
              <Row>
                <Column small="6" medium="6" large="6">
                  <div className="mission-card">
                    <img src={Metric1} alt="" />
                    <h1>POTENCIALIZAR PESSOAS</h1>
                    <small>
                      Acreditar que pessoas fazem a diferença. Nossas relações
                      devem ser humanizadas, apostamos na diversidade e
                      valorização das pessoas. Cada profissional do Transfácil
                      está sempre em busca de garantir o seu desenvolvimento o
                      da equipe e do negócio, potencializando habilidades e
                      atitudes para que todos alcancem os melhores resultados.
                    </small>
                  </div>
                </Column>
                <Column small="6" medium="6" large="6">
                  <div className="mission-card">
                    <img src={Metric2} alt="" />
                    <h1>FOCO NO CLIENTE</h1>
                    <small>
                      Nosso cliente é o que nos move. Ter como base a excelência
                      do atendimento é função de todos no Transfácil. Somos
                      atentos e movemos energias para atender as necessidades
                      dos clientes de uma forma que os surpreenda positivamente.
                      Nosso foco é no cliente e sua experiência que deve ser
                      incrível em todo sistema do transporte coletivo.
                      <p>&nbsp;</p>
                    </small>
                  </div>
                </Column>
                <Column small="6" medium="6" large="6">
                  <div className="mission-card">
                    <img src={Metric3} alt="" className="imgvalor" />
                    <h1>ENTREGAR VALOR CONTINUAMENTE</h1>
                    <small>
                      Porque a gente se importa. No Transfácil, a busca por
                      resultados de relevância é algo que vem de dentro para
                      fora, uma vontade de tornar o cenário melhor para si mesmo
                      e para os outros, com atitude crítica, dedicação e
                      respeito em todas as nossas relações.
                    </small>
                  </div>
                </Column>
                <Column small="6" medium="6" large="6">
                  <div className="mission-card">
                    <img src={Metric4} alt="" />
                    <h1>APRENDER RÁPIDO</h1>
                    <small>
                      Experimentar o novo sempre. Aprendemos sempre para
                      entregar o melhor. Experimentamos novas formas de fazer,
                      ouvir e ser. Nos permitimos errar e tentar novamente,
                      sempre buscando as melhores soluções de mobilidade para os
                      nossos clientes.
                    </small>
                  </div>
                </Column>
              </Row>
            </div>
          </MissionSection>
          <TransfacilTextSection>
            <h1>Há 20 anos fazendo parte da sua trajetória</h1>
            {/* <span>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliquat enim
              ad minim.
            </span> */}
          </TransfacilTextSection>
          <BackgroundSliderNews>
            <Slider className="slider" {...settingsnews}>
              <div>
                <SliderItemNews>
                  <div className="image-container">
                    <img src={Marco2001} alt="2001" />
                  </div>
                  <div className="text-container">
                    {/* <h3>Marcos da Transfácil</h3> */}
                    {/* <h1>Veja os principais feitos da Transfácil</h1> */}
                    <h2>2001 foi ano da nossa fundação</h2>
                    <span>
                      <p>
                        O Transfácil, Consórcio Operacional do Transporte
                        Coletivo de Passageiros por Ônibus do Município de Belo
                        Horizonte, surgiu com o objetivo de implantar a
                        bilhetagem eletrônica em Belo Horizonte.
                      </p>
                      <p>
                        Somos um consórcio formado pelas empresas de transporte
                        e temos a responsabilidade de operar o sistema de
                        bilhetagem eletrônica e comercializar os créditos
                        eletrônicos, sempre pensando na eficiência e na melhoria
                        da sua experiência.
                      </p>
                    </span>
                  </div>
                </SliderItemNews>
              </div>
              <div>
                <SliderItemNews>
                  <div className="image-container">
                    <img src={Marco2005} alt="2005" />
                  </div>
                  <div className="text-container">
                    <h2>2005 – Fim do vale-transporte de papel</h2>
                    <span>
                      <p>
                        No ano de 2005, o vale-transporte de papel foi
                        oficialmente retirado de circulação. Atualmente , o
                        cartão é a forma de pagamento mais utilizada entre os
                        clientes, facilitando a vida de quem utiliza o
                        transporte coletivo de Belo Horizonte.
                      </p>
                    </span>
                  </div>
                </SliderItemNews>
              </div>
              <div>
                <SliderItemNews>
                  <div className="image-container">
                    <img src={Marco2010} alt="2010" />
                  </div>
                  <div className="text-container">
                    <h2>2010 – Cartão Master</h2>
                    <span>
                      <p>
                        Em 2010, foi implantado o cartão Master destinado ao
                        cliente com idade igual ou superior a 65 (sessenta e
                        cinco) anos que deseja transpor, de forma gratuita, a
                        roleta dos ônibus.
                      </p>
                    </span>
                  </div>
                </SliderItemNews>
              </div>
              <div>
                <SliderItemNews>
                  <div className="image-container">
                    <img src={Marco2011} alt="2011" />
                  </div>
                  <div className="text-container">
                    <h2>2011 – Meio Passe Estudantil</h2>
                    <span>
                      <p>
                        O Auxílio Transporte Escolar (ou meio passe estudantil)
                        foi implantado em 2011. O benefício é financiado pela
                        PBH e corresponde a um desconto de 50% do valor das
                        tarifas pagas no percurso ida e volta entre a residência
                        e a escola do aluno beneficiário.
                      </p>
                    </span>
                  </div>
                </SliderItemNews>
              </div>
              <div>
                <SliderItemNews>
                  <div className="image-container">
                    <img src={Marco2014} alt="2014" />
                  </div>
                  <div className="text-container">
                    <h2>2014 – Implantação do MOVE</h2>
                    <span>
                      <p>
                        Composto por 19 linhas troncais, 73 linhas alimentadoras
                        e 13 outras linhas que se integram nas estações de
                        transferência, o MOVE possibilita que o cliente vá de um
                        bairro na região de Venda Nova para um destino na região
                        sul pagando apenas uma tarifa.
                      </p>
                    </span>
                  </div>
                </SliderItemNews>
              </div>
              <div>
                <SliderItemNews>
                  <div className="image-container">
                    <img src={Marco2019} alt="2019" />
                  </div>
                  <div className="text-container">
                    <h2>2019/2020 – Nova cultura centrada nas pessoas</h2>
                    <span>
                      <p>
                        Iniciamos um processo de evolução baseado em três
                        pilares: Pessoas, Clientes e Inovação. Criamos a área de
                        Desenvolvimento Humano e Organizacional para avançarmos
                        em direção a nova cultura centrada nas pessoas,
                        reestruturamos nossa área de atendimento ao Cliente
                        focada na escuta e na melhoria da experiência, traçamos
                        um novo posicionamento de marca e presença digital,
                        sempre de olho no futuro e em busca de aumentar a
                        eficiência e a eficácia do transporte coletivo.
                      </p>
                    </span>
                  </div>
                </SliderItemNews>
              </div>
            </Slider>
          </BackgroundSliderNews>
          {/* <NewsSection>
            <div className="image-container">
              <img src={EstacaoOnibus} alt="" />
            </div>
            <div className="text-container">
              <h3>Marcos da Transfácil</h3>
              <h1>Veja os principais feitos da Transfácil</h1>
              <h2>2001 foi ano da nossa fundação</h2>
              <span>
                <p>
                  O Transfácil, Consórcio Operacional do Transporte Coletivo de
                  Passageiros por Ônibus do Município de Belo Horizonte, surgiu
                  com o objetivo de implantar a bilhetagem eletrônica em Belo
                  Horizonte.
                </p>
                <p>
                  Somos um consórcio formado pelas empresas de transporte e
                  temos a responsabilidade de operar o sistema de bilhetagem
                  eletrônica e comercializar os créditos eletrônicos, sempre
                  pensando na eficiência e na melhoria da sua experiência.
                </p>
              </span>
            </div>
          </NewsSection> */}
          <MetricSection>
            <div className="metrics">
              <div className="metricCard">
                <div className="image">
                  <img src={Pessoa} alt="" />
                </div>
                <div className="textContainer">
                  <span>389</span>
                  <p>Colaboradores</p>
                </div>
              </div>

              <div className="metricCard">
                <div className="image">
                  <img src={Building} alt="" />
                </div>
                <div className="textContainer">
                  <span>4</span>
                  <p>Consórcios</p>
                </div>
              </div>

              <div className="metricCard">
                <div className="image">
                  <img src={Shop} alt="" className="shop" />
                </div>
                <div className="textContainer">
                  <span>52</span>
                  <p>Postos de Atendimentos</p>
                  <p>Lojas e Estações</p>
                </div>
              </div>

              <div className="metricCardLinhas">
                <div className="image">
                  <div style={{ width: 55, height: 55, color: '#4D1D6E' }}>
                    <Icon size="100%" icon={line} />
                  </div>
                </div>
                <div className="textContainer">
                  <span>284</span>
                  <p>Linhas</p>
                </div>
              </div>

              <div className="metricCardVeiculos">
                <div className="image">
                  <img src={Bus} alt="" className="bus" />
                </div>
                <div className="textContainer">
                  <span>2.400</span>
                  <p>Veículos na frota</p>
                  <p>100% acessível</p>
                </div>
              </div>
            </div>
          </MetricSection>
        </Content>
        <BackgroundSlider>
          <Slider className="slides" {...settings}>
            <div>
              <SliderItem img={Transfacil1} />
            </div>
            <div>
              <SliderItem img={Transfacil2} />
            </div>
            <div>
              <SliderItem img={Transfacil3} />
            </div>
            <div>
              <SliderItem img={Transfacil4} />
            </div>
            <div>
              <SliderItem img={Transfacil5} />
            </div>
            <div>
              <SliderItem img={Transfacil6} />
            </div>
            <div>
              <SliderItem img={Transfacil7} />
            </div>
            <div>
              <SliderItem img={Transfacil8} />
            </div>
            <div>
              <SliderItem img={Transfacil9} />
            </div>
            <div>
              <SliderItem img={Transfacil10} />
            </div>
            <div>
              <SliderItem img={Transfacil11} />
            </div>
            <div>
              <SliderItem img={Transfacil12} />
            </div>
            <div>
              <SliderItem img={Transfacil13} />
            </div>
            <div>
              <SliderItem img={Transfacil14} />
            </div>
            <div>
              <SliderItem img={Transfacil15} />
            </div>
            <div>
              <SliderItem img={Transfacil16} />
            </div>
            <div>
              <SliderItem img={Transfacil17} />
            </div>
          </Slider>
        </BackgroundSlider>
        <Content>
          <WorkwithUsSection>
            <div className="text-container">
              <h1>Trabalhe Conosco!</h1>
              <h3>
                Que tal fazer parte de uma equipe de talentos que trabalha para
                transformar a mobilidade urbana de BH?
              </h3>
              <small>
                <p>
                  Estamos em um processo de evolução cultural, com um foco nas
                  pessoas e se você gosta de trabalhar em equipe, em um ambiente
                  que te desafia e motiva a crescer junto, venha fazer parte do
                  nosso time!!
                </p>
              </small>
            </div>
          </WorkwithUsSection>
          <JobsSection>
            <h1 className="h1">Confira nossas vagas e se candidate!</h1>
            <div className="accordionContainer">
              {jobs.map(vaga => (
                <Accordion
                  key={vaga.id}
                  title={vaga.name}
                  CollapseIcon={BsChevronRight}
                >
                  <div className="vagas">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: vaga.description,
                      }}
                    />
                    <button
                      onClick={() => handleLink(vaga.link)}
                      type="button"
                      className="button"
                    >
                      Quero me candidatar
                    </button>
                  </div>
                </Accordion>
              ))}
              {!jobs.length && <span>Nenhuma Vaga Disponivel</span>}
            </div>
            {/* {!!jobs.length && (
              <button
                onClick={() => push('/trabalhe-conosco')}
                type="button"
                className="button"
              >
                Quero me candidatar
              </button>
            )} */}
          </JobsSection>
        </Content>
        <Content>
          <ContentPolitica>
            <h1>Política da Qualidade</h1>
            <div className="politica">
              <div className="bloco1">
                <p>
                  Oferecer um Sistema de Transporte Coletivo de qualidade,
                  contemplando a Bilhetagem Eletrônica, o Sistema de Informação
                  ao Usuário e o Monitoramento de frota, garantindo a otimização
                  e melhoria da operação e mobilidade urbana do Município de
                  Belo Horizonte.
                </p>
                <p className="strong">
                  <strong>Se comprometendo com:</strong>
                </p>
                <br />
              </div>
              <div className="bloco2">
                <div className="bloco3">
                  <img src={framepolitica} alt="politica" />
                  <p>Excelência no atendimento ao Cliente</p>
                </div>
                <div className="bloco3">
                  <img src={framepolitica} alt="politica" />
                  <p>Cumprimento dos requisitos aplicáveis</p>
                </div>
                <div className="bloco3">
                  <img src={framepolitica} alt="politica" />
                  <p>Valorização dos colaboradores</p>
                </div>
                <div className="bloco3">
                  <img src={framepolitica} alt="politica" />
                  <p>Desenvolvimento Contínuo</p>
                </div>
                <div className="bloco3">
                  <img src={framepolitica} alt="politica" />
                  <p>
                    Melhoria da eficiência das operadoras de transporte coletivo
                  </p>
                </div>
                <div className="bloco3">
                  <img src={framepolitica} alt="politica" />
                  <p>Decisões orientadas pela análise de dados coletados</p>
                </div>
                <div className="bloco3">
                  <img src={framepolitica} alt="politica" />
                  <p>
                    Melhoria contínua dos processos, baseando-se sempre no
                    Sistema da Qualidade
                  </p>
                </div>
                <div className="bloco3">
                  <img src={framepolitica} alt="politica" />
                  <p>
                    Ter Foco em novas tecnologias de mobilidade que gerem valor
                    para o cliente
                  </p>
                </div>
              </div>
            </div>
            <div className="bloco5">
              <h3>Aprovado em Junho/2021 pela Diretoria vigente.</h3>
            </div>
          </ContentPolitica>
        </Content>

        <PurpleContainer>
          <div className="legislacao">
            <div className="flex">
              <h1 className="text-white">Legislação</h1>
              <div className="oval" />
              <div className="oval" />
              <div className="oval" />
            </div>
            <div className="flex-row">
              <div>
                <h3 className="text-white">SBE</h3>
                <h4>
                  • Lei nº 8.224 de 28 de setembro de 2001:
                  <a href="/files/lei8224.pdf" target="_blank" className="pdf">
                    [PDF]
                  </a>
                </h4>

                <p>
                  Autoriza implantar bilhetagem eletrônica nos coletivos, proíbe
                  a substituição das catracas e garante emprego dos operadores
                  na forma que menciona.
                </p>
                <h4>
                  • Portaria BHTRANS DDI nº 066/2002 de 17 de setembro de 2002:{' '}
                  <a href="/files/DDI066.pdf" target="_blank" className="pdf">
                    [PDF]
                  </a>
                </h4>
                <p>
                  Estabelece Políticas de Operação e Funcionamento do Sistema de
                  Bilhetagem Eletrônica no Serviço de Transporte Público por
                  Ônibus do Município de Belo Horizonte.
                </p>
                <h4>
                  • Portaria BHTRANS DDI nº 039/2006 de 19 de junho de 2006:
                  <a href="/files/DDI039.pdf" target="_blank" className="pdf">
                    [PDF]
                  </a>
                </h4>
                <p>
                  Altera a PORTARIA BHTRANS DDI N. º 066/2002, ampliando o prazo
                  de validade dos créditos eletrônicos dos cartões BHBUS
                  modalidade Usuário e Usuário Identificado.
                </p>
              </div>

              <div>
                <h3 className="text-white">VT</h3>
                <h4>
                  • Lei nº 7.418, de 16 de dezembro de 1985:{' '}
                  <a href="/files/lei7418.pdf" target="_blank" className="pdf">
                    [PDF]
                  </a>
                </h4>
                <p>Institui o Vale-Transporte e dá outras providências.</p>
                <h4>
                  • Decreto n º 95.247, de 17 de novembro de 1987:{' '}
                  <a
                    href="/files/decreto95247.pdf"
                    target="_blank"
                    className="pdf"
                  >
                    [PDF]
                  </a>
                </h4>
                <p>
                  Regulamenta a Lei n° 7.418, de 16 de dezembro de 1985, que
                  institui o Vale-Transporte, com a alteração da Lei n° 7.619,
                  de 30 de setembro de 1987.
                </p>
                <h3 className="text-white">MTE</h3>
                <h4>
                  • Relatório de Transparência e Igualdade Salarial de Mulheres
                  e Homens - 1º Semestre 2024:
                  <a
                    href="/files/relatorioigualdade.pdf"
                    target="_blank"
                    className="pdf"
                  >
                    [PDF]
                  </a>
                </h4>
              </div>
            </div>
          </div>
        </PurpleContainer>
      </Container>
      <Footer />
    </>
  );
};

export default QuemSomos;
