/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Cards from '../Card';

import { CardBox } from './styles';
import { useToast } from '../../../hooks/toast';
import { useLoader } from '../../../hooks/loader';

import Button from '../../Button';
import TicketingService, { Card } from '../../../services/ticketing';
import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../Input';
import Modal from '../Modal';
import { useAuth } from '../../../hooks/auth';

interface RegisterCardProps {
  cards: Card[];
  callback: Function;
}

const RegisterCard: React.FC<RegisterCardProps> = ({ cards, callback }) => {
  const MAX_CARD_BY_USER = 5;

  const { user } = useAuth();
  const { push } = useHistory();
  const { addToast } = useToast();
  const { changeLoader } = useLoader();

  const [showModalLimitCards, setshowModalLimitCards] = useState(false);
  const [cardsQuantity, setcardsQuantity] = useState<number>(cards.length);
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const services = { ticketingService: new TicketingService() };
  const formRef = useRef<FormHandles>(null);

  const handleRedirect = useCallback(
    pathLink => {
      push(pathLink);
    },
    [push],
  );

  async function createCard(data: any) {
    try {
      formRef.current?.setErrors({});
      changeLoader(true);

      const schema = Yup.object().shape({
        nickname: Yup.string().required('Nome é obrigatório'),
        card_number: Yup.string().required('Número é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await services.ticketingService.createCard(
        user.id,
        data.nickname,
        data.card_number,
      );

      formRef.current.reset();

      changeLoader(false);

      addToast({
        type: 'success',
        title: 'Cadastro Finalizado',
        description: 'Cartão cadastrado com sucesso!!',
      });

      callback();

      setTimeout(() => {
        handleRedirect('/meus-cartoes');
      }, 600);
    } catch (err: any) {
      const { ...error } = err;
      changeLoader(false);

      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }

      addToast({
        type: 'error',
        title: `${
          error.response.data.message
            ? error.response.data.message
            : error.response.data.detail
        }`,
        description: `${
          error.response.data.message
            ? error.response.data.message
            : error.response.data.detail
        }`,
      });
    }
  }

  const handleClose = useCallback(() => {
    setshowModalLimitCards(false);
  }, []);

  const handleSubmit = useCallback(
    async data => {
      if (cardsQuantity >= MAX_CARD_BY_USER) {
        setshowModalLimitCards(true);
      } else {
        setcardsQuantity(cardsQuantity + 1);
        await createCard(data);
      }
    },
    [cardsQuantity, createCard],
  );

  useEffect(() => {
    setName(name);
  }, [name]);

  useEffect(() => {
    setNumber(number);
  }, [number]);

  return (
    <>
      {showModalLimitCards && (
        <Modal
          handleClose={() => handleClose()}
          show={showModalLimitCards}
          successText=""
          typeModal="error"
        >
          <h1>Quantidade de cartões por usuário excedida.</h1>
        </Modal>
      )}
      <CardBox>
        {/* <img
          src={OrangeCardRegister}
          alt="Registrar cartao"
          className="orange-card"
        />
        <img
          src={AddCardRegister}
          alt="registrar cartao"
          className="register-card"
        /> */}
        <div className="card">
          <Cards
            nickname={name}
            cardNumber={number}
            register={true}
            qrcode={false}
          />
        </div>

        <div className="register-nickname">
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h4 className="apelido">Apelido</h4>
            <Input
              name="nickname"
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <h4 className="numcartao">Número do Cartão</h4>
            <Input
              cardtransfacil
              name="card_number"
              className="input-nickname"
              onChange={e => setNumber(e.target.value)}
            />
          </Form>
        </div>
      </CardBox>

      <div className="buttons">
        <Button
          color="orange"
          padding="3.5"
          rounded
          className="button-cadastrar"
          type="submit"
          onClick={() => handleSubmit(formRef.current.getData())}
        >
          CADASTRAR
        </Button>
        <Button
          color="white"
          padding="3.5"
          rounded
          className="button-cancelar-cadastrar"
          type="button"
          onClick={() => handleRedirect('/meus-cartoes')}
        >
          CANCELAR
        </Button>
      </div>
    </>
  );
};

export default RegisterCard;
