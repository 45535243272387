import { formatCurrency } from '@brazilian-utils/brazilian-utils';
import * as React from 'react';
import { Container } from './style';

interface ISummaryValue {
  balance?: number;
  rate?: number;
}

const SummaryValue: React.FC<ISummaryValue> = ({
  balance = 10.0,
  rate = 0,
}) => {
  const balanceRate = (balance / 100) * rate;
  const total = balance + balanceRate;
  return (
    <Container>
      <h3>
        Saldo: <span className="value"> R$ {formatCurrency(balance)} </span>
      </h3>
      <h3>
        Taxa: <span className="value"> {rate}% </span>
      </h3>
      <h3>
        Total: <span className="value"> R$ {formatCurrency(total)} </span>
      </h3>
    </Container>
  );
};

export { SummaryValue };
