/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';

import Slider from 'react-slick';
import Button from '../../Button';
import Message from '../../../assets/Home/message.png';

import CoreService, { Partner } from '../../../services/core';

import { Container, SliderContainer, SliderItem, Contact } from './styles';
import ContactSection from '../../ContactSection';

const PartnerSection: React.FC = () => {
  const services = { coreService: new CoreService() };

  const [partners, setPartner] = useState<Partner[]>([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
    fade: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          fade: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  useEffect(() => {
    async function getNews() {
      try {
        const response = await services.coreService.getPartners();
        setPartner(response.data.results);
      } catch (err) {
        console.error(err);
      }
    }
    getNews();
  }, []);

  const handleLink = (link: string) => {
    window.open(link, '_blank');
  };

  return (
    <Container>
      <h1 className="title">Parceiros e vantagens para você!</h1>
      <SliderContainer>
        <Slider {...settings}>
          {partners.map(partner => (
            <div key={partner.name}>
              <SliderItem onClick={() => handleLink(partner.link)}>
                <div className="imgContainer">
                  <img src={partner.logo} alt="" />
                </div>
                <div className="textContainer">
                  <h1>{partner.slogan}</h1>
                  <a href={partner.link} target="_blank" rel="noreferrer">
                    <Button
                      className="button"
                      color="orange"
                      padding=""
                      rounded
                    >
                      ACESSE
                    </Button>
                  </a>
                </div>
              </SliderItem>
            </div>
          ))}
        </Slider>
      </SliderContainer>
      <Contact>
        <div className="contact-section">
          <ContactSection />
        </div>
        <a href="/fale-conosco">
          <Button className="fale-conosco" color="" padding="2" rounded>
            <img src={Message} alt="" className="message" />
          </Button>
        </a>
      </Contact>
    </Container>
  );
};
export default PartnerSection;
