import styled from 'styled-components';

export const FlexContainer = styled.div`
  margin-bottom: 1rem;
  h1 {
    font: 24px 'Source Sans Pro', sans-serif;
    color: var(--orange);
    font-weight: bold;
    @media (max-width: 800px) {
      font-size: 20px;
    }
    @media (max-width: 600px) {
      font-size: 18px;
    }
  }
  h2 {
    margin-top: 20px;
  }
  p {
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 0px;
    font: 16px 'Source Sans Pro', sans-serif;
    padding-left: 8px;
    @media (max-width: 800px) {
      font-size: 14px;
    }
    @media (max-width: 600px) {
      font-size: 10px;
    }
  }
  .text-wrapper {
    padding-top: 20px;
  }
  .btn-wrapper {
    margin-top: 20px;
  }
  .btn-wrapper2 {
    margin-top: 10px;
  }

  @media (max-width: 650px) {
    flex-wrap: wrap;
  }
`;

export const FormWrapper = styled.div`
  width: 600px;
  display: inline;
  flex-direction: column;

  .button {
    width: 20%;
    @media (max-width: 650px) {
      width: 40%;
    }
  }
`;

export const FormDataDiv = styled.div`
  @media (min-width: 650px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const BoxInput = styled.div`
  width: 340px;
  padding: 5px 5px;
  @media (max-width: 1100px) {
    width: 250px;
  }
  @media (max-width: 800px) {
    width: 300px;
  }
  @media (max-width: 650px) {
    width: 200px;
  }
  @media (max-width: 610px) {
    width: 180px;
  }
  @media (max-width: 500px) {
    width: 350px;
  }

  margin: 0px 10px;
  p {
    font-weight: normal;
    font: 'Source Sans Pro', sans-serif;
  }
`;

export const BoxSectorpdc = styled.div`
  padding-top: 8px;
  padding-left: 8px;
`;

export const BoxDiv = styled.div`
  display: inline-block;
  width: 100%;
`;
export const BoxisStationB = styled.div`
  display: flex;
  width: 100%;
`;

export const BoxisStation = styled.div`
  width: 48%;
`;

export const BoxSectorDiv = styled.div`
  width: 28%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 5px;
  p {
    font-weight: normal;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
    padding-left: 8px;
    font: 'Source Sans Pro', sans-serif;
  }

  @media (max-width: 1100px) {
    padding: 0px 3px;
    width: 30%;
    font-size: 12px;
  }
  @media (max-width: 650px) {
    padding: 0px 3px;
    width: 80%;
    font-size: 12px;
    display: inline-block;
  }
`;

export const ImgDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  text-align: center;
  width: 800px;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    width: auto;
    gap: 10px;
  }
  .container-text {
    flex: 1;
  }
  img {
    width: 250px;
    margin-top: 15px;
    margin-bottom: 20px;
    @media (max-width: 600px) {
      width: 250px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
`;

export const BoxStationDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 5px;
  p {
    font-weight: normal;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
    padding-left: 8px;
    font: 'Source Sans Pro', sans-serif;
  }

  @media (max-width: 1100px) {
    padding: 0px 3px;
    width: 32%;
    font-size: 12px;
  }
  @media (max-width: 650px) {
    padding: 0px 3px;
    width: 80%;
    font-size: 12px;
    display: inline-block;
  }
`;

export const BoxSectorTitle = styled.div`
  height: 15%;
  margin-bottom: 5px;
  p {
    font-size: 16px;
  }
  @media (max-width: 700px) {
    p {
      font-size: 14px;
    }
    height: 12%;
  }
  @media (max-width: 400px) {
    p {
      font-size: 14px;
    }
    height: auto;
  }
`;
export const BoxStationTitle = styled.div`
  height: 10%;
  margin-bottom: 5px;
  p {
    font-size: 16px;
  }
  @media (max-width: 700px) {
    p {
      font-size: 14px;
    }
    height: 12%;
  }
  @media (max-width: 400px) {
    p {
      font-size: 14px;
    }
    height: auto;
  }
`;

export const BoxSectorCheck = styled.div`
  height: 85%;
  border: 2px solid var(--orange);
  border-radius: 24px;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  @media (max-width: 650px) {
    padding: 4px;
    width: 205px;
    height: 285px;

    flex-direction: column;
    display: inline-block;
  }
  @media (max-width: 300px) {
    width: auto;
    height: 400px;
    padding: 2px;
  }
  @media (max-width: 220px) {
    width: auto;
    height: 490px;
  }
`;

export const BoxStationCheck = styled.div`
  height: 40%;
  margin-top: 20px;
  border: 2px solid var(--orange);
  border-radius: 24px;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  @media (max-width: 650px) {
    padding: 4px;
    width: 155px;
    height: 205px;

    flex-direction: column;
    display: inline-block;
  }
  @media (max-width: 300px) {
    width: auto;
    height: 400px;
    padding: 2px;
  }
  @media (max-width: 220px) {
    width: auto;
    height: 490px;
  }
`;

export const BoxInputDiv = styled.div`
  width: 72%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  &:before,
  &after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
  Input.InputWork {
    border-color: var(--orange);
  }
  @media (max-width: 1100px) {
    padding: 0px 3px;
    width: 64%;
    font-size: 12px;
  }
  @media (max-width: 650px) {
    width: 100%;
  }
`;

export const WorkDiv = styled.div`
  max-width: 100%;
  html body {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  margin: 0;
  @media (max-width: 650px) {
    padding: 0px;
  }
`;

export const ButtonDiv = styled.div`
  display: flex;
  margin-top: 0px;
  height: 80px;
  @media (max-width: 300px) {
    .buttonsent {
      @media (max-width: 300px) {
        width: 800px;
      }
    }
  }
  @media (max-width: 1500px) {
    margin-top: 10px;
  }

  @media (max-width: 1050px) {
    margin-top: 25px;
  }

  @media (max-width: 650px) {
    margin-top: 2px;
  }
`;

export const FileDiv = styled.div`
  flex-direction: column;
  padding: 5px;
  max-width: 100%;
  p {
    margin-bottom: 10px;
    font-weight: normal;
    font: 'Source Sans Pro', sans-serif;
    @media (max-width: 800px) {
      font-size: 10px;
    }
    @media (max-width: 600px) {
      font-size: 10px;
    }
  }
`;

export const SpecialLink = styled.p`
  background-color: white;
  color: var(--orange);
  &:hover {
    color: var(--purple);
  }
  cursor: pointer;
`;

export const SpecialPhrase = styled.p`
  background-color: white;
  &:hover {
    color: var(--orange);
    font-weight: bold;
  }
  cursor: pointer;
`;
export const TextContent = styled.div``;

export const DialogContent = styled.div`
  display: flex;
  margin: 20px 60px;
  justify-content: space-between;
  width: auto;
  height: 650px;
  padding: 0;
  @media (max-width: 700px) {
    margin: 0px;
  }
  @media (max-width: 800px) {
    flex-direction: row;
    margin: 20px 5px;
  }
  @media (max-width: 450px) {
    max-width: 450px;
    width: auto;
    padding-left: 18px;
  }
  .container-img {
    @media (max-width: 840px) {
      margin-right: 50px;
    }

    @media (max-width: 700px) {
      margin-bottom: 100px;
      margin-top: 20px;
      flex-direction: column;
      width: 95%;
    }
    img {
      width: 360px;
      margin-top: 15px;
      margin-bottom: 20px;
      @media (max-width: 1000px) {
        width: 270px;
      }
      @media (max-width: 850px) {
        width: 100%;
        margin-top: 0px;
      }
      @media (max-width: 614px) {
        width: 100%;
      }
    }
  }
  .container2 {
    padding-left: 50px;
    @media (max-width: 700px) {
      padding-left: 5px;
      max-width: 450px;
      width: auto;
    }
    .flex-container {
      display: flex;

      h3 {
        margin-top: 30px;
        margin-bottom: 20px;
        color: var(--purple);
      }

      p {
        margin-bottom: 20px;
      }
    }

    .flex-container {
      display: flex;
      @media (max-width: 840px) {
        margin-top: 100px;
      }
    }
    h1 {
      font: 30px 'Source Sans Pro', sans-serif;
      color: var(--orange);
      font-weight: bold;
      text-align: center;
      padding-bottom: 30px;
      @media (max-width: 800px) {
        font-size: 24px;
        padding-bottom: 10px;
      }
    }
    h3 {
      font: 20px 'Source Sans Pro', sans-serif;
      color: var(--purple);
      font-weight: bold;
      @media (max-width: 800px) {
        font-size: 16px;
      }
    }
    p {
      font-weight: normal;
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 16px;
      padding-left: 8px;
      padding-bottom: 10px;
      font: 'Source Sans Pro', sans-serif;
      @media (max-width: 800px) {
        font-size: 12px;
        padding-bottom: 8px;
      }
    }
    .ver-mais {
      color: var(--orange);
      background: transparent;
    }
  }
  .title-benefit {
    @media (max-width: 800px) {
      margin-top: 50px;
    }
  }
`;

export const EspecialButton = styled.button`
  position: absolute;
  right: 5%;
  align-items: right;
  @media (max-width: 600px) {
    display: flex;
    align-items: left;
    left: 5%;
    max-width: 300px;
  }

  svg {
    margin-top: 0px;
    color: var(--black);
    cursor: pointer;
  }
`;
