/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosPromise } from 'axios';
import api from './api';

interface Card {
  id: number;
  user: string;
  nickname: string;
  card_number: string;
  createdAt: Date;
  balance: number;
}

interface Payment {
  id: number;
  user: number;
  titular_name: number;
  card_number_display: string;
  due_date: string;
  cvv: number;
  verbose_name: string;
  payment_type: string;
  charge_address: string;
  charge_address_complement: string;
  charge_address_number: number;
  charge_address_neighborhood: string;
  charge_address_city: string;
  charge_address_state: string;
  charge_address_cep: string;
  is_active: boolean;
}

interface PayEnable {
  id: number;
  payment_type: string;
  rate: string;
  enable: boolean;
  warning: string;
}

interface Recarga {
  id: number;
  created_at: string;
  amount: number;
  status_display: string;
  tacom_boleto: string;
  pix_copy_paste: string;
  pix_expiration: string;
  pix_image_link: string;
  card_data: { nickname: string; card_number: string };
}

interface RecargaQrCode {
  id: number;
  created_at: string;
  amount: number;
  status_display: string;
  tacom_boleto: string;
  pix_copy_paste: string;
  pix_expiration: string;
  pix_image_link: string;
  card_data: { nickname: string; card_number: string };
}

class TicketingService {
  createCard = (
    user: number,
    nickname: string,
    card_number: string,
  ): AxiosPromise => {
    return api.post('/ticketing/v2/my-cards/', {
      user,
      nickname,
      card_number,
    });
  };

  rechargeCard = (
    card: number,
    form_of_payment: number,
    amount: number,
    cvv?: string,
  ): AxiosPromise => {
    return api.post('/ticketing/recharge-request/', {
      card,
      form_of_payment,
      amount,
      cvv,
    });
  };

  getActiveCards = (): AxiosPromise => {
    return api.get('/ticketing/my-cards/');
  };

  getCard = (id: string): AxiosPromise => {
    return api.get(`/ticketing/my-cards/${id}/`);
  };

  getCardExtrato = (
    idcard: string,
    dateStart: any,
    dateEnd: any,
  ): AxiosPromise => {
    return api.get(
      `/ticketing/my-card/${idcard}/statement-usage?start_date=${dateStart}&end_date=${dateEnd}`,
    );
  };

  blockCard = (id: number, data: any): AxiosPromise => {
    const headers = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return api.post(`/ticketing/my-card/${id}/blocked`, data, headers);
  };

  updateCard = (
    user: number,
    id: string,
    card_number?: string,
    nickname?: string,
  ): AxiosPromise => {
    return api.put(`/ticketing/v2/my-cards/${id}/`, {
      user,
      nickname,
      card_number,
    });
  };

  patchUpdateCard = (
    user: number,
    id: string,
    nickname?: string,
  ): AxiosPromise => {
    return api.patch(`/ticketing/v2/my-cards/${id}/`, {
      user,
      nickname,
    });
  };

  createPaymentForm = (
    user: number,
    titular_name: number,
    card_number: string,
    due_date: string,
    charge_address: string,
    charge_address_complement: string,
    charge_address_number: number,
    charge_address_neighborhood: string,
    charge_address_city: string,
    charge_address_state: string,
    charge_address_cep: string,
  ): AxiosPromise => {
    return api.post('/ticketing/forms-of-payment/', {
      titular_name,
      card_number,
      due_date,
      charge_address,
      charge_address_complement,
      charge_address_number,
      charge_address_neighborhood,
      charge_address_city,
      charge_address_state,
      charge_address_cep,
    });
  };

  changeCardStatus = (paymentId: number, cardStatus: boolean): AxiosPromise => {
    return api.patch(`/ticketing/forms-of-payment/${paymentId}/`, {
      is_active: cardStatus,
    });
  };

  getPaymentForm = (): AxiosPromise => {
    return api.get('/ticketing/forms-of-payment/');
  };

  deletePaymentForm = (paymentId: number): AxiosPromise => {
    return api.delete(`/ticketing/forms-of-payment/${paymentId}/`);
  };

  getRecarga = (): AxiosPromise => {
    return api.get('/ticketing/recharge-request/');
  };

  getRecargaQrCode = (): AxiosPromise => {
    return api.get('/ticketing/recharge-request/?card_type=qr_code');
  };

  deleteTransFacilCard = (id: string) => {
    return api.delete(`/ticketing/v2/my-cards/${id}/`);
  };

  getBoletoLimit = () => {
    return api.get('/ticketing/recharge-limits/');
  };

  getFormsPay = (): AxiosPromise => {
    return api.get('/ticketing/forms-of-billing/');
  };
}

export type { Card, Payment, PayEnable, Recarga, RecargaQrCode };

export default TicketingService;
