import React from 'react';
import { FiX } from 'react-icons/fi';
import { Container, Content, Img } from './styles';
import Button from '../../../../components/Button';
import GreenCheck from '../../../../assets/Login/greencheck.svg';

interface ModalInterface {
  handleClose(): void;
  show: boolean;
  text?: string;
  success?: boolean;
}

const FormularioFinalizado: React.FC<ModalInterface> = ({
  handleClose,
  show,
}) => (
  <Container show={show}>
    <section>
      <>
        <button type="button" className="close-container" onClick={handleClose}>
          <FiX size={40} onClick={handleClose} />
        </button>
        <div className="modal-body">
          <Content>
            <Img
              src={GreenCheck}
              alt="Enviado"
              style={{ maxWidth: '300px', maxHeight: '300px', margin: '20px' }}
            />
            <h2>Seu currículo foi recebido com sucesso!</h2>
            <p>Agradecemos o interesse em fazer parte do nosso time.</p>
            <Button color="orange" padding="3" type="submit" className="clique">
              <a href="https://transfacil.com.br/" rel="noopener noreferrer">
                CONCLUIR
              </a>
            </Button>
          </Content>
        </div>
      </>
    </section>
  </Container>
);

export default FormularioFinalizado;
