/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FiCalendar } from 'react-icons/fi';
import api from '../../../services/api';
import Loader from '../../Loader';

import { Container, TitleSection, CardBox, Historico, ExtratodeUso } from './styles';
import Card from '../Card';
import { useToast } from '../../../hooks/toast';
import { useLoader } from '../../../hooks/loader';
import { formatPrice } from '../../../services/currency';

import Button from '../../Button';
import Modal from '../../Modal';
import TicketingService, {
  Card as TransFacilCard,
} from '../../../services/ticketing';
import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../Input';
import { useAuth } from '../../../hooks/auth';
import ExtratoUso from '../Extratouso';
import MeusPedidosCartao from '../../MeusPedidos';

interface EditCardProps {
  cards: TransFacilCard[];
  callback: Function;
}

const InformationsCard: React.FC<EditCardProps> = ({ callback }) => {
  const { user } = useAuth();
  const { push } = useHistory();
  const { addToast } = useToast();
  const { changeLoader } = useLoader();
  const id = window.location.pathname.split('/');

  const [nickname, setNickname] = useState<any>();
  const [nome, setNome] = useState<any>();
  const [numero, setNumero] = useState<any>();
  const [cardnumber, setCardNumber] = useState<any>();
  const [saldo, setSaldo] = useState<any>();
  const [card, setCard] = useState<TransFacilCard>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [modalExcluir, setModalExcluir] = useState<boolean>(false);
  const [cardId, setCardId] = useState<any>();
  const [usoState, setUsoState] = useState<boolean>(false);
  const [recargaState, setRecargaState] = useState<boolean>(false);
  const [eyechange, setEyechange] = React.useState<boolean>(false);


  const services = { ticketingService: new TicketingService() };
  const formRef = useRef<FormHandles>(null);

  const handleRedirect = useCallback(
    pathLink => {
      callback();
      push(pathLink);
    },
    [push],
  );

  const handleUso = () => {
    if (usoState) {
      setUsoState(false);
    } else if (!usoState){
      setRecargaState(false);
      setUsoState(true);
    }
  };

  const handleRecarga = () => {
    if (recargaState) {
      setRecargaState(false);
    } else if (!recargaState){
      setUsoState(false);
      setRecargaState(true);
    }
  };

  async function updateCard(data: any) {
    try {
      formRef.current?.setErrors({});
      // changeLoader(true);

      const schema = Yup.object().shape({
        nickname: Yup.string(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await services.ticketingService.patchUpdateCard(
        user.id,
        id[id.length - 1],
        data.nickname,
      );

      formRef.current.reset();

      // changeLoader(true);

      addToast({
        type: 'success',
        title: 'Edição Finalizada',
        description: 'Cartão editado com sucesso!!',
      });
      changeLoader(false);
      setTimeout(() => {
        handleRedirect('/meus-cartoes');
      }, 600);
    } catch (err: any) {
      const { ...error } = err;
      // changeLoader(false);

      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }

      addToast({
        type: 'error',
        title: `${
          error.response.data.message
            ? error.response.data.message
            : error.response.data.detail
        }`,
        description: `${
          error.response.data.message
            ? error.response.data.message
            : error.response.data.detail
        }`,
      });
    }
  }

  const asyncDeleteCard = useCallback(async () => {
    try {
      setLoading(true);
      const response = await services.ticketingService.deleteTransFacilCard(
        card.id.toString(),
      );
      handleRedirect('/meus-cartoes');
      addToast({
        type: 'success',
        title: 'Exclusão',
        description: 'Cartão excluído com sucesso!!',
      });
      setLoading(false);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Exclusão',
        description:
          'Erro ao excluir o cartão, por favor tente novamente mais tarde.',
      });
      setLoading(false);
      console.error(err);
    }
  }, [card]);

  const getCardsAsync = async () => {
    setLoading(true);
    const response = await services.ticketingService.getCard(id[id.length - 1]);
    setCard(response.data);
    setCardNumber(response.data.card_number);
    setNickname(response.data.nickname);
    setCardId(response?.data?.id)
    setNome(response.data.nickname);
    setNumero(response.data.card_number);
    setSaldo(response.data?.balance);
    // changeLoader(false);
    setLoading(false);
  };

  useEffect(() => {
    // changeLoader(true);
    getCardsAsync();
  }, []);

  const handleSubmit = useCallback(
    async data => {
      await updateCard(data);
    },
    [updateCard],
  );

  useEffect(() => {
    setNickname(nickname);
  }, []);

  useEffect(() => {
    setCardNumber(cardnumber);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const eyechangeParam = params.get('eyechange');
  
    if (eyechangeParam === 'true') {
      // Defina o estado de eyechange como verdadeiro
      setEyechange(true);
    } else {
      // Defina o estado de eyechange como falso
      setEyechange(false);
    }
  }, [location.search]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Container>
            <Container>
              <CardBox>
                <div>
                  <Card
                    nickname={nickname}
                    cardNumber={cardnumber}
                    style={{
                      cursor: 'default',
                    }}
                    saldo={saldo}
                    id={cardId}
                    active={true}
                    info={true}
                    eye={eyechange}
                  />
                  <h5 className='inforecarga'>
                    Seu saldo pode ser atualizado em até 72 horas, podendo exceder esse prazo em caso de problemas técnicos.
                  </h5>
                </div>

                <div className="register-nickname">
                  <Form ref={formRef} onSubmit={handleSubmit}>
                    <h4 className="apelido">Apelido</h4>
                    <Input
                      name="nickname"
                      placeholder={nickname}
                      value={nickname}
                      onChange={e => setNickname(e.target.value)}
                    />

                    {/* 
                    <h4 className="numcartao">Número do Cartão</h4>
                    <Input
                      cardtransfacil
                      name="card_number"
                      type="text"
                      placeholder={cardnumber}
                      defaultValue={cardnumber}
                      onChange={e => setCardNumber(e.target.value)}
                    />
                    */}
                  </Form>
                </div>
              </CardBox>
              <div className="buttons">
                <Button
                  color="orange"
                  padding=""
                  rounded
                  className="button-cadastrar"
                  type="submit"
                  onClick={() => handleSubmit(formRef.current.getData())}
                >
                  ATUALIZAR
                </Button>
                <Button
                  color="white"
                  padding=""
                  rounded
                  className="button-cancelar-cadastrar"
                  type="button"
                  onClick={() => handleRedirect('/meus-cartoes')}
                >
                  CANCELAR
                </Button>
                <Button
                  color="white"
                  padding=""
                  rounded
                  className="button-excluir"
                  type="button"
                  onClick={() => setModalExcluir(true)}
                >
                  EXCLUIR
                </Button>
              </div>
              <Historico>
                <Button
                  color="white"
                  padding=""
                  rounded
                  className="botaohistorico"
                  type="button"
                  onClick={handleUso}
                >
                  <FiCalendar size={15} className='icon'/>
                  HISTÓRICO DE USO
                </Button>
                <Button
                  color="white"
                  padding=""
                  rounded
                  className="botaohistorico"
                  type="button"
                  onClick={handleRecarga}
                >
                  <FiCalendar size={15} className='icon'/>
                  HISTÓRICO DE RECARGAS
                </Button>
              </Historico>
              {usoState  &&
                <ExtratoUso idcard={cardId}/>
              }
               {recargaState  &&
                <MeusPedidosCartao idcard={cardId}/>
              }
              
            </Container>
            <div className="div-saldo">
              {/* <div className="button-recarregar">
            <a href="https://transfacil.com.br/meus-cartoes/recarregar">
              <TransfacilCard>RECARREGAR</TransfacilCard>
            </a>
          </div> */}
            </div>
            <Modal
              show={modalExcluir}
              handleClose={() => {
                setModalExcluir(false);
              }}
              title="Tem certeza que deseja excluir?"
            >
              <div className="button-wrapper">
                <Button
                  color="white"
                  padding=""
                  rounded
                  type="button"
                  className="btn-cancelar"
                  onClick={() => setModalExcluir(false)}
                >
                  CANCELAR
                </Button>
                <Button
                  color="red"
                  padding=""
                  rounded
                  type="button"
                  onClick={() => {
                    setModalExcluir(true);
                    asyncDeleteCard();
                  }}
                >
                  EXCLUIR
                </Button>
              </div>
            </Modal>
          </Container>
        </>
      )}
    </>
  );
};

export default InformationsCard;
