/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-plusplus */
const isValidCpf = (value: string): any => {
  let cpf;

  const reg = new RegExp(/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+$/);

  if (value && reg.test(value)) {
    return false;
  }

  if (value) {
    cpf = value.replace(/[^\d]+/g, '');
  } else {
    return false;
  }

  if (cpf) {
    let numbers: any;
    let digits: any;
    let sum: any;
    let i;
    let result;
    let equalDigits;
    equalDigits = 1;
    if (cpf.length < 11 || cpf.length > 11) {
      return false;
    }

    for (i = 0; i < cpf.length - 1; i++) {
      if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
        equalDigits = 0;
        break;
      }
    }

    if (!equalDigits) {
      numbers = cpf.substring(0, 9);
      digits = cpf.substring(9);
      sum = 0;
      for (i = 10; i > 1; i--) {
        sum += numbers.charAt(10 - i) * i;
      }

      result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

      if (result !== Number(digits.charAt(0))) {
        return false;
      }
      numbers = cpf.substring(0, 10);
      sum = 0;

      for (i = 11; i > 1; i--) {
        sum += numbers.charAt(11 - i) * i;
      }
      result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

      if (result !== Number(digits.charAt(1))) {
        return false;
      }
      return true;
    }
    return false;
  }

  return true;
};

export { isValidCpf };
