import styled from 'styled-components';

interface CardProps {
  active: boolean;
}

export const TitleSection = styled.section`
  margin: 60px 0 60px 0;
  color: var(--purple);

  h1 {
    font-size: 3rem;
    line-height: 2.3rem;
    @media (max-width: 492px) {
      line-height: 3.5rem;
    }
  }
`;

export const Section = styled.section`
  grid-area: main;
  width: 124%;
  height: 16px;
  background-color: #ebf1f4;
  margin-left: -110px;
  margin-bottom: 30px;
`;

export const Container = styled.div`
  width: 100%;
  margin-bottom: 220px;
  margin-right: -100px;
`;

export const Circle = styled.div`
  display: flex;

  .circle1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid var(--purple);
  }

  .circle2 {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--purple);
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  margin-right: -100px;
  margin-bottom: 30px;
  color: var(--purple);

  .button {
    margin-top: 40px;
    font-size: 15px;
  }

  .button-text {
    display: grid;
    align-items: left;
    justify-content: left;
    padding: 0;
    width: 0px;
    color: red;
  }

  .button-cancelar {
    color: var(--orange);
    border: 2px solid var(--orange);
    margin-left: 1em;

    h4 {
      font-weight: normal;
    }
  }
`;

export const CardSection = styled.div`
  display: grid;
  width: 100%;
  margin-right: -100px;
  margin-bottom: 1em;
  color: var(--purple);

  .cartao {
    margin-left: 20px;
    font-size: 20px;
    font-weight: bolder;
  }

  .dados {
    margin-left: 20px;
    margin-top: 5px;
    font-size: 15px;
  }

  .button-confirmar {
    align-items: left;
    justify-content: left;
    width: 180px;
    margin-top: 60px;
    margin-left: 20px;
  }

  .inputnome {
    margin-top: 0px;
    margin-left: 20px;
    border-radius: 10px;
    padding-left: 30px;
    width: 480px;
    height: 40px;
    border: 1px solid var(--purple);
    font-size: 18px;

    ::-webkit-input-placeholder {
      color: #979797;
    }
  }

  .nome {
    position: absolute;
    margin-left: 40px;
    background-color: var(--white);
    margin-top: -12px;
    padding: 0 12px;
    color: var(--purple);
  }

  .numero {
    position: absolute;
    margin-left: 40px;
    background-color: var(--white);
    margin-top: 14px;
    padding: 0 12px;
    color: var(--purple);
  }
  .inputnumero {
    margin-top: 25px;
    margin-left: 20px;
    border-radius: 10px;
    padding-left: 30px;
    height: 40px;
    width: 260px;
    border: 1px solid var(--purple);
    font-size: 18px;

    ::-webkit-input-placeholder {
      color: #979797;
    }
  }

  .data {
    position: absolute;
    margin-left: 347px;
    background-color: var(--white);
    margin-top: -50px;
    padding: 0 12px;
    color: var(--purple);
  }
  .inputdata {
    margin-top: 25px;
    margin-left: 40px;
    border-radius: 10px;
    padding-left: 75px;
    width: 220px;
    height: 40px;
    border: 1px solid var(--purple);
    font-size: 18px;

    ::-webkit-input-placeholder {
      color: #979797;
    }
  }

  .cvv {
    position: absolute;
    margin-left: 597px;
    background-color: var(--white);
    margin-top: -50px;
    padding: 0 12px;
    color: var(--purple);
  }
  .inputcvv {
    margin-top: 25px;
    margin-left: 40px;
    border-radius: 10px;
    padding-left: 28px;
    width: 90px;
    height: 40px;
    border: 1px solid var(--purple);
    font-size: 18px;

    ::-webkit-input-placeholder {
      color: #979797;
    }
  }

  .inputcep {
    margin-top: 60px;
    border-radius: 10px;
    padding-left: 20px;
    width: 300px;
    height: 40px;
    border: 1px solid var(--purple);
    font-size: 22px;

    ::-webkit-input-placeholder {
      color: #979797;
    }
  }
  .cep {
    position: absolute;
    margin-left: 10px;
    background-color: var(--white);
    margin-top: 50px;
    padding: 0 12px;
    color: var(--purple);
  }

  .cep-remember {
    position: absolute;
    margin-left: 318px;
    color: var(--orange);
    margin-top: -17px;
  }

  .endereco {
    position: absolute;
    margin-left: 10px;
    background-color: var(--white);
    margin-top: 14px;
    padding: 0 12px;
    color: var(--purple);
  }
  .inputendereco {
    margin-top: 25px;
    border-radius: 10px;
    padding-left: 20px;
    width: 800px;
    height: 40px;
    border: 1px solid var(--purple);
    font-size: 22px;

    ::-webkit-input-placeholder {
      color: #979797;
    }
  }

  .numero {
    position: absolute;
    margin-left: 10px;
    background-color: var(--white);
    margin-top: 14px;
    padding: 0 12px;
    color: var(--purple);
  }
  .inputnumero {
    margin-top: 25px;
    border-radius: 10px;
    padding-left: 20px;
    height: 40px;
    width: 110px;
    border: 1px solid var(--purple);
    font-size: 22px;

    ::-webkit-input-placeholder {
      color: #979797;
    }
  }

  .complemento {
    position: absolute;
    margin-left: 140px;
    background-color: var(--white);
    margin-top: -50px;
    padding: 0 12px;
    color: var(--purple);
  }
  .inputcomplemento {
    margin-top: 25px;
    margin-left: 20px;
    border-radius: 10px;
    padding-left: 20px;
    width: 239px;
    height: 40px;
    border: 1px solid var(--purple);
    font-size: 22px;

    ::-webkit-input-placeholder {
      color: #979797;
    }
  }

  .bairro {
    position: absolute;
    margin-left: 400px;
    background-color: var(--white);
    margin-top: -50px;
    padding: 0 12px;
    color: var(--purple);
  }
  .inputbairro {
    margin-top: 25px;
    margin-left: 20px;
    border-radius: 10px;
    padding-left: 20px;
    width: 410px;
    height: 40px;
    border: 1px solid var(--purple);
    font-size: 22px;

    ::-webkit-input-placeholder {
      color: #979797;
    }
  }
`;

export const CardButton = styled.button<CardProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 180px;
  margin-left: 40px;
  margin-right: 25px;
  padding: 12px 8px 12px 8px;
  background: var(--orange);
  border-radius: 20px;
  color: var(--text);

  background: ${props => (props.active ? `var(--orange)` : `var(--orange)`)};
  color: ${props => (props.active ? `white` : `white`)};

  p {
    font-size: 15px;
  }
`;

export const Main = styled.main`
  padding: 0;
  width: 100%;
  margin-left: 0px;
  margin-right: -100px;

  .button-cadastrar {
    margin-top: 60px;
    margin-left: 6px;
    margin-right: 20px;
    border-radius: 16px;
  }
`;
