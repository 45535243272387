import styled from 'styled-components';

export const Buttons = styled.button`
  background: var(--purple);
  border-radius: 20px;
  min-width: 125px;
  padding: 4px 18px 4px 18px;
  color: var(--text);
  align-items: center;
  display: flex;
  flex-direction: column;

  background: var(--purple);
  color: #f2f5f7;
  svg {
    margin-bottom: 10px;

    path {
      fill: var(--white);
    }
  }
`;

export const Main = styled.main`
  width: 100%;
`;

export const DivPrincipal = styled.div`
  @media (max-width: 600px) {
    display: grid;
  }
  .paper {
    box-shadow: none;
    @media only screen and (max-width: 830px) {
      display: flex;
      max-width: 100%;
      justify-content: center;
      margin-left: -3%;
    }
  }
  .Tabela {
    max-width: 100%;
    overflow-x: auto;
  }

  .specifitcell {
    word-wrap: break-word;
    word-break: break-all;
    width: 100%;
    justify-content: center;
  }

  table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }
  #cell-1-undefined {
    max-width: 150px;
  }

  #cell-2-undefined {
    max-width: 100px;
    text-align: center;
  }

  #cell-3-undefined {
    max-width: 70px;
    text-align: center;
  }

  #cell-4-undefined {
    max-width: 200px;
    text-align: center;
    justify-content: center;
  }

  #cell-5-undefined {
    max-width: 200px;
    text-align: center;
    justify-content: center;
  }

  /* Adjust responsiveness for smaller screens */
  @media only screen and (max-width: 500px) {
    .Tabela {
      min-width: 300px; /* Adjust as needed */
    }

    #cell-1-undefined {
      max-width: 100px;
      min-width: 100px;
      word-wrap: break-word;
      word-break: break-all;
      text-align: center;
    }

    #cell-2-undefined {
      max-width: 50px;
      min-width: 40px;
    }

    #cell-3-undefined {
      max-width: 50px;
      min-width: 60px;
    }

    #cell-4-undefined {
      max-width: 130px; /* Adjust as needed */
    }

    #cell-5-undefined {
      max-width: 180px; /* Adjust as needed */
    }
    div[data-column-id='2'] {
      max-width: 120px;
      justify-content: center;
      @media only screen and (max-width: 500px) {
        max-width: 50px;
        min-width: 40px;
      }
    }
    div[data-column-id='3'] {
      max-width: 120px;
      justify-content: center;
      @media only screen and (max-width: 500px) {
        max-width: 100px;
        min-width: auto;
      }
    }
  }

  .nodata {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    .icon {
      color: var(--purple);
      margin-bottom: 10px;
      margin-top: 20px;
    }
  }
  .infonotdata {
    color: var(--purple);
    margin-top: 20px;
    margin-left: 10px;
  }
  .botoes {
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin-bottom: 20px;
    @media only screen and (max-width: 850px) {
      flex-direction: column;
    }
  }
  .button-cadastrar {
    margin-top: 10px;
    margin-right: 10px;
  }
`;
