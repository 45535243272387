import styled from 'styled-components';

export const GridContainer = styled.div`
  background: var(--white);
  margin: 5px 0;
  margin-top: 80px;
  font-family: 'Source Sans Pro';

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 1000px) {
    flex-wrap: wrap;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const FlexContainer = styled.div`
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  .Item {
    margin: 50px 50px 50px 50px;
  }
  h2 {
    color: var(--purple);
    margin-top: 5px;
    margin-bottom: 0.5rem;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const FirstContent = styled.div`
  width: 440px;
  align-self: center;
  display: flex;
  flex-direction: column;

  .text {
    color: var(--purple);
    margin-bottom: 10px;
  }
  .grey-text {
    color: grey;
    margin-top: 10px;
    margin-bottom: 10px;
    @media only screen and (max-width: 1000px) {
      flex-wrap: wrap;
      width: 100%;
      display: flex;
      justify-content: left;
      align-items: left;
    }
  }
  button {
    margin-top: 15px;
    width: 200px;
    @media (max-width: 1000px) {
      width: 100%;
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 1000px) {
    width: 90%;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const SecondContent = styled.div`
  .responsive {
    position: absolute;
    top: 630px;
    left: 52.7%;
    border-radius: 0px 0px 75px 0px;
    filter: drop-shadow(0 0 1rem black);
  }

  @media only screen and (max-width: 1024px) {
    .responsive {
      left: 49%;
  }

  @media only screen and (max-width: 1000px) {
    width: 90%;
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    .responsive {
      border-radius: 0px 0px 35px 0px;
      position: relative;
      top: 0;
      width: 70%;
      height: auto;
      left: 0%;
    }
  }
`;
