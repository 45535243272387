import styled from 'styled-components';

interface CardProps {
  selected?: boolean;
  active?: boolean;
  identficado?: boolean;
  qrcode?: boolean;
}

export const Container = styled.div<CardProps>`
  width: 100% !important;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  justify-content: center;
  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(180deg, #3d2463 0%, #3d2463 100%);
  border-radius: 22px;
  margin-right: 1em;
  margin-bottom: 1em;
  width: 29%;
  cursor: pointer;
  transition: all 0.3s ease;
  transform: scale(1);

  .mainmenuinfo {
    display: flex;
    background: ${props =>
      props.identficado ? `var(--orange)` : `var(--purple-dark)`};
    color: ${props => (props.identficado ? `#F2F5F7` : `#F2F5F7`)};
    font-weight: ${props => (props.active ? `bolder` : `bolder`)};
    border-radius: 20px;
    min-width: 150px;
    width: 100%;
  }
  .loading-icon {
    display: inline-block;
    animation: spin 1s infinite linear;
    margin-right: 10px;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .PrincipalDiv {
    width: 100%;
    @media (max-width: 500px) {
      display: contents;
    }
    .middlecardComplete {
      justify-content: center;
      @media (max-width: 500px) {
        width: 250px;
      }
    }
    .middlecard {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 650px) {
        width: 250px;
      }
      .logocartao-container {
        margin-left: 5%;
        margin-right: 5%;
        height: 70px;
        margin-top: 2em;

        .icon {
          path {
            background-color: black;
            fill: revert-layer;
          }
          * {
            color: red;
          }
        }
      }
      .areasaldo {
        display: flex;
        align-items: center;
        margin-right: 10px;

        .icon {
          margin-right: 5px;
        }
      }
      .middlecard2 {
        margin-left: 5%;
        margin-right: 10%;
      }

      .logocartao {
        align-items: center;
      }
    }
  }

  &:hover {
    transform: scale(1.03);
    box-shadow: 0px 10px 6px #00000029;
  }

  ${props =>
    props.selected &&
    `
    transform: scale(1.03);
    box-shadow: 0px 10px 6px #00000029;
    background: var(--purple);
  `};

  svg {
    font-size: 2.5em;

    path {
      opacity: 0.6;
      fill: #ffffff;
    }
  }
  .FormControl {
    color: var(--white);
  }
  .saldo {
    color: white;
    text-align: center; /* Alinha os elementos à esquerda */
    margin-bottom: 0.5em;
    font-size: 16px;
    margin-left: -15px;
    @media (max-width: 700px) {
      margin-left: 0px;
    }
    @media (max-width: 500px) {
      margin-left: -10px;
    }
  }
  .saldotext {
    color: white;
    text-align: center; /* Alinha os elementos à esquerda */
    font-size: 10px;
    margin-top: 2em;
    margin-left: -15px;
    @media (max-width: 700px) {
      margin-left: 0px;
    }
    @media (max-width: 500px) {
      margin-left: -10px;
    }
  }
`;

export const Nickname = styled.p`
  font-family: 'Sallim Signature', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  max-width: 100%;
  line-height: 1rem;
  margin-bottom: 0.5em;
  text-align: center;
  letter-spacing: 0.18px;
  color: #ffffff;
  @media (max-width: 315px) {
    line-height: 1.5rem;
  }
`;

export const CardNumber = styled.span`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.18px;
  text-transform: uppercase;
  color: #ffffff;
`;

export const TransfacilCard = styled.button<CardProps>`
  background: var(--purple);
  border-radius: 20px;
  width: 100%;
  padding: 5px 10px 5px 10px;
  color: var(--text);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  align-items: center;
  margin-top: -5px;
  @media (max-width: 700px) {
    width: 100%;
    min-width: 50px;
  }
  @media (max-width: 315px) {
    font-size: 0.9rem;
  }

  p {
    font-size: 15px;
  }

  display: flex;
  flex-direction: column;

  background: ${props =>
    props.identficado ? `var(--orange)` : `var(--purple-dark)`};
  color: ${props => (props.identficado ? `#F2F5F7` : `#F2F5F7`)};
  font-weight: ${props => (props.active ? `bolder` : `bolder`)};

  svg {
    margin-bottom: 10px;

    path {
      fill: ${props => (props.active ? `var(--purple)` : `var(--white)`)};
    }
  }
`;

export const InfoDiv = styled.div<CardProps>`
  background: var(--purple);
  display: flex;
  background: ${props =>
    props.identficado ? `var(--orange)` : `var(--purple-dark)`};
  color: ${props => (props.identficado ? `#F2F5F7` : `#F2F5F7`)};
  font-weight: ${props => (props.active ? `bolder` : `bolder`)};
  border-radius: 20px;
  min-width: 150px;
  width: 100%;
`;
