/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import api from './api';

interface Category {
  id: number;
  name: string;
}

interface Faq {
  id: number;
  question: string;
  answer: string;
  topic?: string;
}

interface New {
  id: number;
  url: string;
  image: string;
  title: string;
  subtitle: string;
  text: string;

  categories: Category[];
}

interface Partner {
  id: number;
  name: string;
  slogan: string;
  logo: string;
  link: string;
}

interface Banner {
  id: number;
  backgroundCor: string;
  backgroundImage: string;
  imagePromotion: string;
  cta_text: string;
  cta_color: string;
  media_link: string;
  title: string;
  subtitle: string;
}

interface Medias {
  media_id: number;
  media_url: string;
  permalink: string;
}

interface RechargePoint {
  id: number;
  name: string;
  address: string;
  opening: string;
  lat: string;
  lng: string;
  regions: string;
}

interface LineInfo {
  cod: string;
  sgl: string;
  nom: string;
  display: string;
  code: string;
  itinerario: any;
  name: string;
  tabelahorario: any;
}

class CoreServices {
  createContact = (
    name: string,
    email: string,
    comment: string,
    categoria?: string,
    phone?: string,
    card_number?: string,
  ) => {
    return api.post('/core/contato/', {
      name,
      email,
      phone,
      categoria,
      comment,
      card_number,
    });
  };

  createLead = (
    modality: string,
    companyName: string,
    companyDocument: string,
    aplicantName: string,
    email: string,
    phone?: string,
    employeeNumber?: any,
    cpffile?: any,
    contratofile?: any,
    inscricaofile?: any,
    esocial?: any,
    inscricao?: any,
  ) => {
    return api.post('/companies/empresas/contrate-aqui/', {
      aplicant_name: aplicantName,
      company_name: companyName,
      company_document: companyDocument,
      email,
      modality,
      phone,
      employee_number: employeeNumber,
      document_file: cpffile,
      municipal_registration_file: inscricaofile,
      municipal_registration: inscricao,
    });
  };

  getContract = () => {
    return api.get('/companies/empresas/contrato/');
  };

  getFaqs = () => {
    return api.get('/core/faqs/');
  };

  getMedias = () => {
    return api.get('/core/medias/');
  };

  getNews = (quantity?: number) => {
    if (quantity) {
      return api.get(`/core/noticias/?quantity=${quantity}`);
    }

    return api.get('/core/noticias/');
  };

  getPartners = () => {
    return api.get('/core/parceiros/');
  };

  getBanners = () => {
    return api.get('/core/banner-web/');
  };

  getRechargePoint = () => {
    return api.get('/core/recharge-points/');
  };

  getLineInfo = () => {
    return api.get('/core/line-info/');
  };
}

export type { New, Faq, Partner, Banner, Medias, RechargePoint, LineInfo };

export default CoreServices;
