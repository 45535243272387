import React, { useState, useCallback } from 'react';
import { IconBaseProps } from 'react-icons';

import {
  Container,
  AccordionItem,
  InitialContainer,
  CollapseContainer,
} from './styles';

export interface AccordionProps {
  title: string;
  CollapseIcon?: React.ComponentType<IconBaseProps>;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  CollapseIcon,
  children,
}) => {
  const [active, setActive] = useState(false);

  const handleToggle = useCallback(() => {
    setActive(!active);
  }, [active]);

  return (
    <Container>
      <AccordionItem>
        <InitialContainer active={active} onClick={handleToggle}>
          <h1>{title}</h1>
          <button
            type="button"
            className="icon-container"
            onClick={handleToggle}
          >
            <CollapseIcon size={25} />
          </button>
        </InitialContainer>
        <CollapseContainer active={active}>{children}</CollapseContainer>
      </AccordionItem>
    </Container>
  );
};

export default Accordion;
