import styled, { css } from 'styled-components';

interface ContainerProps {
  active: boolean;
}

interface navItemProps {
  active?: boolean;
}

export const Container = styled.div<ContainerProps>`
  aside {
    display: flex;
    flex-direction: column;
    z-index: 999;
    width: 383px;
    position: fixed;
    overflow-y: auto;

    height: 95vh;
    transform: translateX(0);
    @media (max-width: 1024px) {
      height: 100vh;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }

  ${props =>
    !props.active &&
    css`
      aside {
        transform: translateX(-383px);

        @media (max-width: 768px) {
          transform: translateX(-100vw);
        }
      }
    `};
`;

export const Header = styled.header`
  background: var(--white);
  padding: 0 40px;
  box-shadow: 0 -1px 0 #e8e8e8 inset;
  color: #626162;
  display: flex;
  width: 100vw;
  justify-content: space-between;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Aside = styled.aside`
  transition: all 0.3s;
  overflow-y: auto;
  height: 95vh;
  background: var(--white);
  border-right: 1px solid #dedede;
`;

export const Main = styled.main`
  grid-area: main;
  background: var(--white);
`;

export const InputsWrapper = styled.div`
  background: linear-gradient(45deg, #dc582a -20%, #dc582a 20%, #f98041 100%);
  width: 100%;
  height: 144px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  font-weight: bold;
  font-size: 22px;
`;

export const RoutesWrapper = styled.div`
  background: var(--white);
  width: 100%;
`;

export const RouteItem = styled.div`
  background: var(--white);
  width: 100%;
  border-bottom: 1px solid #dadada;
  padding: 20px;

  display: flex;
  flex-direction: column;

  h1 {
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    color: #3b393b;
  }

  h2 {
    margin-bottom: 0;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.3;
    color: #626162;
  }

  small {
    svg {
      margin-right: 5px;
    }
    margin-top: 10px;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    color: #626162;
    display: flex;
    align-items: center;
  }

  &:hover {
    cursor: pointer;
    background: #e8e8e8;
  }
`;

export const LogoContainer = styled.div`
  width: 135px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: auto;
    height: 33.599999999999994px;
    margin: 5.200000000000003px 0;
  }
`;

export const ItensContainer = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin-left: 30px;
  width: 76%;
`;

export const NavItem = styled.li<navItemProps>`
  font-weight: bold;
  line-height: 24px;
  text-transform: uppercase;
  color: #626162;
  cursor: pointer;
  margin-right: 15px;

  transition: color 0.3s ease;

  &:hover {
    color: var(--orange);
  }

  ${props =>
    props.active &&
    css`
      color: var(--orange);
      font-weight: 700;
      border-bottom: 2px solid var(--orange);
    `}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: center;
  align-items: baseline;

  a {
    color: var(--orange);
    font-size: 0.99rem;
    line-height: 1rem;
    margin-right: 15px;
    transition: color 0.3s ease;

    &:hover {
      color: #626162;
    }
  }

  button {
    height: 32px;
    background: var(-orange);
    border-radius: 10px;
    transition: all 0.2s;

    &:hover {
      background: #dc5129;
    }
  }

  .cadastrar {
    margin: 0px;

    &:hover {
      color: white;
    }
  }
`;

export const SliderContainer = styled.div`
  position: fixed;
  right: 0;
  bottom: 24px;
  left: 0;
  margin: 0 24px;

  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  display: none;

  @media (max-width: 768px) {
    display: inherit;
  }
`;

export const SliderItem = styled.div`
  background: var(--white);
  width: 100%;
  border-bottom: 1px solid #dadada;
  padding: 20px;

  display: flex;
  flex-direction: column;

  h1 {
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    color: #3b393b;
  }

  h2 {
    margin-bottom: 0;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.3;
    color: #626162;
  }

  small {
    svg {
      margin-right: 5px;
    }
    margin-top: 10px;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    color: #626162;
    display: flex;
    align-items: center;
  }

  &:hover {
    cursor: pointer;
    background: #e8e8e8;
  }
`;
