/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';

interface LatLng {
  lat: number;
  lng: number;
}
interface MapContextData {
  origin: string;
  destination: string;
  rechargeAddress: string;
  rechargeAddresCoordinates: LatLng;
  mapCoordinates: LatLng;
  routes: google.maps.DirectionsResult;
  selectedRoute: any;
  infoWindows: any;
  changeSelectedRoute(route: any): void;
  changeOrigin(adress: string): void;
  changeDestination(address: string): void;
  changeRechargeAddress(address: string): void;
  changeMapCoordinates(latLng: LatLng): void;
  changeRechargeAddresCoordinates(latLng: LatLng): void;
  changeRoutes(routesArray: google.maps.DirectionsResult): void;
  changeInfoWindows(steps: any): void;
}

const MapContext = createContext<MapContextData>({} as MapContextData);

const MapProvider: React.FC = ({ children }) => {
  const [origin, setOrigin] = useState<string>('');
  const [destination, setDestination] = useState<string>('');
  const [mapCoordinates, setMapCoordinates] = useState<LatLng>({} as LatLng);
  const [
    rechargeAddresCoordinates,
    setRechargeAddressCoordinates,
  ] = useState<LatLng>({} as LatLng);
  const [rechargeAddress, setRechargeAddress] = useState<string>('');
  const [infoWindows, setInfoWindows] = useState<any>([]);
  const [selectedRoute, setSelectedRoute] = useState<any>({});
  const [routes, setRoutes] = useState<google.maps.DirectionsResult>(
    {} as google.maps.DirectionsResult,
  );

  const changeOrigin = useCallback(address => {
    setOrigin(address);
  }, []);

  const changeDestination = useCallback(address => {
    setDestination(address);
  }, []);

  const changeRechargeAddress = useCallback(address => {
    setRechargeAddress(address);
  }, []);

  const changeMapCoordinates = useCallback(latLng => {
    setMapCoordinates(latLng);
  }, []);

  const changeSelectedRoute = useCallback(route => {
    setSelectedRoute(route);
  }, []);

  const changeRechargeAddresCoordinates = useCallback(latLng => {
    setRechargeAddressCoordinates(latLng);
  }, []);

  const changeRoutes = useCallback(async routesArray => {
    setRoutes({} as google.maps.DirectionsResult);

    const transfacilLines = await api.get('/core/lines/');

    if (Object.keys(routesArray).length) {
      const changedRoutes = routesArray.routes.map((route: any) => {
        return route.legs.map((leg: any) => {
          const transitSteps = leg.steps.filter(
            (item: any) => item.travel_mode === 'TRANSIT',
          );

          const lines = transitSteps.map((transitStep: any) => {
            return {
              name: transitStep.transit.line.name,
              short_name: transitStep.transit.line.short_name,
              color: transitStep.transit.line.color,
              departureTime: transitStep.transit.departure_time.text,
              arrivalTime: transitStep.transit.arrival_time.text,
            };
          });

          const metropolitanArray = transfacilLines.data.lines.map(
            (tline: any) => {
              const filteredLine = lines.filter((i: any) => {
                return i.short_name !== tline.sgl;
              });
              return filteredLine.length ? filteredLine : [];
            },
          );

          const concatArray = [].concat.apply([], [...metropolitanArray]);
          const isTransfacilLine = concatArray.length === 0;
          return { ...route, lines, isTransfacilLine };
        })[0];
      });

      const transfacilRoutes = changedRoutes.filter(
        (tRoute: any) => tRoute.isTransfacilLine,
      );

      const metropolitanRoutes = changedRoutes.filter(
        (tRoute: any) => !tRoute.isTransfacilLine,
      );

      const sortedRoutes = [...transfacilRoutes, ...metropolitanRoutes];

      const TimeSortedRoutes = sortedRoutes.map((item: any) => {
        const timeItem = {
          ...item,
          timeText: `${item.lines[0].departureTime} - ${
            item.lines[item.lines.length - 1].arrivalTime
          }`,
        };
        return timeItem;
      });

      setRoutes({ ...routesArray, routes: TimeSortedRoutes });
    } else {
      setRoutes({} as google.maps.DirectionsResult);
    }
  }, []);

  const changeInfoWindows = useCallback(leg => {
    setInfoWindows([]);
    const transitSteps = leg.steps.filter(
      (item: any) => item.travel_mode === 'TRANSIT',
    );
    const lines = transitSteps.map((transitStep: any) => {
      return {
        start_location: transitStep.transit.departure_stop.location,
        name: transitStep.transit.line.name,
        short_name: transitStep.transit.line.short_name,
      };
    });

    setInfoWindows(lines);
  }, []);

  return (
    <MapContext.Provider
      value={{
        origin,
        routes,
        destination,
        rechargeAddress,
        rechargeAddresCoordinates,
        changeRechargeAddresCoordinates,
        mapCoordinates,
        infoWindows,
        selectedRoute,
        changeOrigin,
        changeRechargeAddress,
        changeDestination,
        changeMapCoordinates,
        changeRoutes,
        changeInfoWindows,
        changeSelectedRoute,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

function useMap(): MapContextData {
  const context = useContext(MapContext);
  if (!context) {
    throw new Error('useMap só pode ser usado com um MapProvider');
  }

  return context;
}
export type { LatLng };

export { MapProvider, useMap };
