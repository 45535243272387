import styled from 'styled-components';

export const Container = styled.div``;

export const Information = styled.div`
  margin-top: 130px;
  font-family: 'Source Sans Pro', sans-serif;
  width: 640px;
  color: var(--purple);
  .information {
    font-size: 33px;
  }

  h3 {
    margin-top: 20px;
    font-size: 21px;
    margin-bottom: 40px;
  }
  .vale-transporte {
    margin-top: 80px;
  }
  p {
    margin-bottom: 40px;
    color: var(--purple);
    font-size: 21px;
    line-height: 1.5;
  }
`;
