/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import Loader from '../../../components/Loader';
import api from '../../../services/api';

interface IPaymentMethod {
  formPayment: any;
  onClick: any;
}

const PaymentMethod: React.FC<IPaymentMethod> = ({ formPayment, onClick }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [paymentMethods, setPaymentMethods] = React.useState<any>([]);
  const getPaymentMethod = React.useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/ticketing/recharge-limits/?card_type=qr_code`,
      );
      setPaymentMethods(response?.data?.results);
    } catch (e: any) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  }, []);
  React.useEffect(() => {
    getPaymentMethod();
  }, []);
  if (loading) return <Loader />;
  return (
    <>
      <h1> Selecione a forma de pagamento </h1>
      <div
        style={{
          marginTop: 20,
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
        }}
      >
        {paymentMethods?.flatMap((form: any, i: any) => {
          return (
            <div
              onClick={() => onClick(form)}
              key={i}
              style={{
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 40,
                width: 170,
                marginRight: 20,
                marginBottom: 20,
                border: '1px solid purple',
                borderRadius: 20,
                backgroundColor:
                  formPayment?.id === form?.id ? 'purple' : 'white',
              }}
            >
              <h3
                style={{
                  color: formPayment?.id !== form?.id ? 'purple' : 'white',
                }}
              >
                {form?.payment_type_display}
              </h3>
            </div>
          );
        })}
      </div>
    </>
  );
};

export { PaymentMethod };
