/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TitleSection, GridSection } from './styles';
import { Container, Content, Blur } from '../../styles/components';
import Header from '../../components/Home/Header';
import NavBar from '../../components/Home/NavBar';
import Footer from '../../components/Home/Footer';
import CoreService, { New } from '../../services/core';

const NoticiaCompleta: React.FC = () => {
  const services = { coreService: new CoreService() };

  const [news, setNews] = React.useState<New>({} as New);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    async function getNews() {
      try {
        const response = await services.coreService.getNews();

        const newwss = response.data.results.find((result: any) => {
          return result.id === parseInt(id, 10);
        });

        setNews(newwss);
      } catch (err) {
        console.error(err);
      }
    }

    getNews();
  }, [id]);

  return (
    <>
      <Container>
        <Content>
          <Header />
        </Content>
      </Container>
      <Container backgroundImage>
        <Blur>
          <Content className="content">
            <NavBar />
            <TitleSection>
              {news && (
                <>
                  <img src={news.image} alt="" />
                  <h1>{news.title}</h1>
                </>
              )}
            </TitleSection>
          </Content>
        </Blur>
      </Container>
      <Container backgroundColor="background-gray">
        <Content>
          <GridSection>
            {news && (
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: news.text,
                  }}
                />
              </>
            )}
          </GridSection>
        </Content>
      </Container>
      <Container>
        <Footer />
      </Container>
    </>
  );
};

export default NoticiaCompleta;
