/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable prettier/prettier */
import styled, { css, keyframes } from 'styled-components';

interface ContainerProps {
  success?: boolean;
  typeModal?: 'success' | 'error';
  show: boolean;
}

const EaseTopDown = keyframes`
  from{ opacity:0;top:0; }
  to{ opacity:1;top:20%;}
`;

const EasyIn = keyframes`
  from{ opacity:0; }
  to{ opacity:1; }
`;

export const Container = styled.div<ContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: none;
  z-index: 2;
  animation: ${EasyIn} 0.3s;

  img {
    margin-top: 30px;
    height: 60px;
  }

  section {
    display: flex;
    position: fixed;
    background: #ffffff;
    animation: ${EaseTopDown} 0.4s;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    width: 38vw;

    @media screen and (max-width: 760px) {
      width: 80%;
    }
    @media screen and (min-width: 761px) and (max-width: 1300px) {
      width: 60%;
    }
    height: auto;
    max-height: 80vh;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);

    p {
      a {
        color: #2a41c1;
      }
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;

    animation: ${EasyIn} 0.5s;

    .title {
      padding-top: 20px;
      padding-bottom: 5px;
      h1 {
        color: var(--orange);
      }
    }

    .close-container {
      position: fixed;
      top: 13px;
      right: 24px;
      color: var(--title-higlight);
      background: transparent;

      display: flex;
      align-items: center;

      svg {
        margin-top: 4px;
        color: var(--black);

        cursor: pointer;
      }
    }

    p {
      color: var(--title-higlight);
      margin-top: 17px;
    }

    .modal-body {
      padding: 20px;
      padding-top: 30px;
      width: 100%;

      display: flex;
      align-items: center;
      flex-direction: column;

      font-size: 20px;
      font-weight: bold;
      p {
        max-width: 900px;
      }
      Button {
        border-radius: 20px !important;
      }
      .resetPassword {
        border-radius: 10px !important;
      }
    }

    .react-select__placeholder {
      font-size: 18px;
      font-weight: 400;
    }

    .react-select__menu {
      font-size: 18px;
      font-weight: 400;
    }

    .react-select__control {
      height: 45px;
    }
  }

  ${(props) =>
    props.show &&
    css`
      display: block;
    `}
`;
