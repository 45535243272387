/* eslint-disable react/no-danger */
/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import Button from '../Button';
import Modal from '../Modal';

const ModalPrivacyPolicy: React.FC<any> = () => {
  const { user, data, setData } = useAuth();
  const [privacyPolicy, setPrivacyPolicy] = React.useState<any>(undefined);
  const [show, setShow] = React.useState<boolean>(false);
  const getPrivacyPolicy = React.useCallback(async () => {
    try {
      const response = await api.get(`/accounts/privacy-policy/`);
      setPrivacyPolicy(response?.data);
      setShow(true);
    } catch (e: any) {
      console.log({ e });
    }
  }, []);
  React.useEffect(() => {
    if (user && !user?.accepted_privacy_policy) getPrivacyPolicy();
  }, [user]);
  if (!show) return null;
  return (
    <Modal
      title="Política de Privacidade"
      handleClose={() => setShow(!show)}
      show={show}
      action={(
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            onClick={async () => {
              await api.post('/accounts/user-accepted-lgpd/', {
                privacy_policy: privacyPolicy?.id,
              });
              data.user = {
                ...data.user,
                accepted_privacy_policy: true,
              }
              setData(data)
              localStorage.setItem('@Transfacil:user', JSON.stringify(data.user));
              setShow(!show);
            }}
            color="orange"
            padding="6"
            className="button"
            rounded
          >
            ACEITAR
          </Button>
        </div>
      )}
    >
      <div>
        {privacyPolicy?.text && (
          <div style={{ fontSize: 14, fontWeight: 'normal' }} dangerouslySetInnerHTML={{ __html: privacyPolicy?.text }} />
        )}
      </div>
    </Modal>
  );
};

export { ModalPrivacyPolicy };
