import styled from 'styled-components';

interface CardProps {
  selected?: boolean;
}

export const Container = styled.div<CardProps>`
  height: 10rem;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #ffc2b0 0%, #dc582a 100%);
  border-radius: 22px;
  margin-right: 1em;
  margin-bottom: 1em;
  width: 29%;
  cursor: pointer;
  transition: all 0.3s ease;
  transform: scale(1);

  &:hover {
    transform: scale(1.03);
    box-shadow: 0px 10px 6px #00000029;
  }

  ${props =>
    props.selected &&
    `
    transform: scale(1.03);
    box-shadow: 0px 10px 6px #00000029;
    background: var(--purple);
  `};

  svg {
    font-size: 2.5em;

    path {
      opacity: 0.6;
      fill: #ffffff;
    }
  }
  .FormControl {
    color: var(--white);
  }
`;

export const Nickname = styled.p`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1rem;
  margin-bottom: 0.5em;
  text-align: center;
  letter-spacing: 0.18px;
  text-transform: uppercase;
  color: #ffffff;
  @media (max-width: 315px) {
    line-height: 1.5rem;
  }
`;

export const CardNumber = styled.span`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
  margin-bottom: 0.5em;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.18px;
  text-transform: uppercase;
  color: #ffffff;
`;
