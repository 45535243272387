import React from 'react';
import {
  GridContainer,
  FlexContainer,
  FirstContent,
  SecondContent,
} from './styles';
import LeitorCartao from '../../../assets/Home/leitor_cartaobhbus.png';
import Arrows from '../../../assets/Home/home_arrow.png';
import Lock from '../../../assets/Home/home_lock.png';
import Manage from '../../../assets/Home/home_manage.png';
import Button from '../../Button';

const BenefitsSection: React.FC = () => {
  return (
    <>
      <GridContainer>
        <FirstContent>
          <h1 className="text">
            Modernidade para o transporte e facilidade para a sua empresa.
          </h1>
          <span className="grey-text">
            Conheça as modalidades oferecidas e as vantagens para sua empresa e
            seus colaboradores ao adquirir o Vale Transporte BHBUS.
          </span>
          <a href="/para-empresas">
            <Button color="orange" padding="2" rounded>
              CONTRATAR AGORA
            </Button>
          </a>
        </FirstContent>
        <SecondContent>
          <img
            src={LeitorCartao}
            alt="Imagem de um cartão sendo passado no leitor do ônibus"
            className="responsive"
          />
        </SecondContent>
      </GridContainer>
      <FlexContainer>
        <div className="Item">
          <img src={Arrows} alt="Arrows" />
          <h2>AGILIDADE</h2>
          <p>Embarque sem filas</p>
        </div>
        <div className="Item">
          <img src={Lock} alt="Lock" />
          <h2>SEGURANÇA</h2>
          <p>Bloqueio do cartão e transferência dos créditos remanescentes</p>
        </div>
        <div className="Item">
          <img src={Manage} alt="Manage icon" />
          <h2>GESTÃO</h2>
          <p>Acompanhamento do saldo</p>
        </div>
      </FlexContainer>
    </>
  );
};

export default BenefitsSection;
