/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-danger */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  MdDirectionsWalk,
  MdDirectionsBus,
  MdClose,
  MdArrowBack,
} from 'react-icons/md';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { format } from 'date-fns';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import SearchMap from '../../components/SearchMap';
import Map, { MarkerProps } from '../../components/Map';
import EmptyRoute from '../../components/EmptyRoute';
import { useMap } from '../../hooks/map';
import { formatPrice } from '../../services/currency';
import Icon from '../../assets/Shared/ic-recharge-stop.svg';
import Logo from '../../assets/Shared/logo-v2.png';
import { useAuth } from '../../hooks/auth';
import Button from '../../components/Button';
import Accordion from '../../components/RoutesAndLinesMap/Accordion';
import {
  Container,
  Header,
  Aside,
  AsideDetail,
  Main,
  InputsWrapper,
  RoutesWrapper,
  RouteItem,
  LogoContainer,
  ItensContainer,
  NavItem,
  ButtonsContainer,
  DetailTitleWrapper,
  RouteContainer,
  RoutePathItem,
  TurnByTurnContainer,
  TurnByTurnItem,
  CloseContainer,
} from './styles';
import api from '../../services/api';
import Input from '../../components/Input';
import { useToast } from '../../hooks/toast';

const RoutesAndLinesMap: React.FC = () => {
  const [markersData] = useState<MarkerProps[]>([]);
  const [active, setActive] = useState<boolean>(false);
  const [horaAtual, setHoraAtual] = useState<string>('');
  const [asideOpacity, setAsideOpacity] = useState<boolean>(true);
  const [detailActive, setDetailActive] = useState<boolean>(false);
  const [lineFare, setLineFare] = React.useState<any>(undefined);
  const [origin, setOrigin] = React.useState<string>('');
  const [destination, setDestination] = React.useState<string>('');
  const [selectedLeg, setSelectedLeg] = useState(null);
  const formRef = useRef<FormHandles>(null);
  const {
    routes,
    changeDestination,
    changeOrigin,
    changeRoutes,
    changeInfoWindows,
    changeSelectedRoute,
  } = useMap();

  const { pathname } = useLocation();
  const { push } = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();

  const navItens = [
    { name: 'INICIO', path: '/' },
    { name: 'CARTÕES E RECARGA', path: '/cartoes-recarga' },
    { name: 'PARA EMPRESAS', path: '/para-empresas' },
    { name: 'LOJAS', path: '/lojas-locais-recarga' },
    { name: 'ROTAS E LINHAS', path: '/rotas-e-linhas' },
    { name: 'QUEM SOMOS', path: '/quem-somos' },
    { name: 'FALE CONOSCO', path: '/fale-conosco' },
  ];

  const handleRedirect = useCallback(
    path => {
      push(path);
    },
    [push],
  );

  // const handleDestination = useCallback(
  //   address => {
  //     changeDestination(address);
  //   },
  //   [changeDestination],
  // );

  // const handleOrigin = useCallback(
  //   address => {
  //     changeOrigin(address);
  //   },
  //   [changeOrigin],
  // );

  const handleOrigin = useCallback(
    address => {
      setOrigin(address);
      changeOrigin(address);
    },
    [changeOrigin],
  );

  const handleDestination = useCallback(
    address => {
      setDestination(address);
      changeDestination(address);
    },
    [changeDestination],
  );

  const handleCloseAside = useCallback(() => {
    setActive(false);
    changeOrigin('');
    changeDestination('');
    changeRoutes({} as google.maps.DirectionsResult);
  }, [changeDestination, changeOrigin, changeRoutes]);

  const getLineFare = React.useCallback(async () => {
    try {
      const { data } = await api.get(`/core/qr-code/lines/`);
      setLineFare(data);
    } catch (e) {
      console.log(e);
    }
  }, []);
  React.useEffect(() => {
    getLineFare();
  }, []);

  const handleDetail = useCallback(
    (leg, route) => {
      setSelectedLeg(leg);
      changeInfoWindows(leg);
      changeSelectedRoute(route);
      setDetailActive(true);
    },
    [changeInfoWindows, changeSelectedRoute],
  );

  const handleSearchMap = useCallback(() => {
    if (destination && origin) {
      const encodedQueryParams = encodeURI(
        `?origin=${origin}&destination=${destination}`,
      );
      window.location.href = `/trajetos/${encodedQueryParams}`;
    } else {
      addToast({
        type: 'info',
        title: 'Selecione primeiro a origem e destino',
        description: 'Não foi possível redirecionar para o mapa',
      });
    }
  }, [destination, origin, addToast]);

  useEffect(() => {
    const formatedDate = format(new Date(), 'HH:mm');
    setHoraAtual(formatedDate);
    const stringParams = decodeURIComponent(window.location.href.split('/')[4]);
    if (stringParams) {
      const params = new URLSearchParams(stringParams);
      setOrigin(params.get('origin'));
      setDestination(params.get('destination'));
      changeOrigin(params.get('origin'));
      changeDestination(params.get('destination'));
      setActive(true);
    }
  }, [changeDestination, changeOrigin]);

  return (
    <Container active={active} asideOpacity={asideOpacity}>
      <Header>
        <LogoContainer>
          <a href="https://transfacil.com.br">
            <img src={Logo} alt="Logo transfacil" />
          </a>
        </LogoContainer>

        <ItensContainer>
          {navItens.map(item => (
            <NavItem
              onClick={() => handleRedirect(item.path)}
              active={!!(pathname === item.path)}
              key={item.name}
            >
              {item.name}
            </NavItem>
          ))}
        </ItensContainer>
        <ButtonsContainer>
          <Link to={user ? '/dashboard' : '/login'}>Entrar</Link>
          <Button
            color="orange"
            padding="2"
            onClick={() => handleRedirect('/cadastrar')}
          >
            CADASTRAR
          </Button>
        </ButtonsContainer>
      </Header>
      {!detailActive && (
        <Aside>
          <InputsWrapper>
            {active && (
              <>
                <div className="backContainer">
                  <MdArrowBack
                    onClick={() => handleCloseAside()}
                    color="#fff"
                    size={23}
                  />
                </div>
                <div className="inputsContainer">
                  {/* <SearchMap
                    onSelect={address => handleOrigin(address)}
                    placeholder="Qual o seu local de origem?"
                    type="origin"
                    routes={false}
                    aside
                  />
                  <SearchMap
                    onSelect={address => handleDestination(address)}
                    type="destination"
                    placeholder="Qual o seu local de destino?"
                    routes={false}
                    aside
                  /> */}
                  <Form ref={formRef} onSubmit={handleSearchMap}>
                    <Input
                      onChange={e => handleOrigin(e.target.value)}
                      defaultValue={origin}
                      name="OrigemInput"
                      placeholder="Qual o seu local de origem?"
                    />
                    <Input
                      onChange={e => handleDestination(e.target.value)}
                      defaultValue={destination}
                      name="DestinoInput"
                      placeholder="Qual o seu local de destino?"
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        style={{
                          marginBottom: '4px',
                          marginTop: '2px',
                          marginLeft: '-4px',
                        }}
                        type="submit"
                        color="purple"
                        padding="4"
                        rounded
                      >
                        Buscar
                      </Button>
                    </div>
                  </Form>
                </div>
              </>
            )}
          </InputsWrapper>
          <RoutesWrapper>
            {!!Object.keys(routes).length && (
              <>
                {!!routes.routes &&
                  routes.routes.map((route: any) => (
                    <div key={route.overview_polyline}>
                      {route.legs.map((leg: any) => (
                        <RouteItem
                          key={leg.start_address}
                          onClick={() => handleDetail(leg, route)}
                        >
                          <div className="price">
                            <p className="timeText">{route.timeText}</p>

                            <span className="value">
                              {route?.fare?.value
                                ? formatPrice(route?.fare?.value)
                                : lineFare
                                    .map((datas: any) => {
                                      const matchingLine = route?.lines.find(
                                        (line: any) =>
                                          line?.short_name ===
                                          datas?.id?.linha?.linCodLinhaExterno,
                                      );
                                      if (matchingLine) {
                                        const formattedPrice = formatPrice(
                                          datas?.tarifa?.valorTarifa,
                                        );
                                        return formattedPrice;
                                      }
                                      return null;
                                    })
                                    .filter((data: any) => data !== null)
                                    .join('\n') || 'Preço não informado'}
                            </span>
                          </div>
                          <div className="location">
                            <span className="min">{leg.duration.text}</span>
                            {route.lines.map((line: any) => (
                              <div key={line.name}>
                                <p className="start">
                                  <div className="ballon">
                                    {line.short_name}
                                  </div>
                                  {` - ${line.name}`}
                                </p>
                              </div>
                            ))}
                          </div>
                        </RouteItem>
                      ))}
                    </div>
                  ))}
              </>
            )}
            {!Object.keys(routes).length && <EmptyRoute />}
          </RoutesWrapper>
        </Aside>
      )}
      {detailActive && (
        <AsideDetail>
          <DetailTitleWrapper>
            <CloseContainer onClick={() => setDetailActive(false)}>
              <MdClose />
            </CloseContainer>
            Sua Viagem
          </DetailTitleWrapper>
          {!!selectedLeg && (
            <>
              <RouteContainer>
                <RoutePathItem>
                  <p className="title">Partida</p>
                  <p>{selectedLeg.start_address}</p>
                </RoutePathItem>
                <RoutePathItem>
                  <p className="title">Destino</p>
                  <p>
                    <p>{selectedLeg.end_address}</p>
                  </p>
                </RoutePathItem>
              </RouteContainer>

              {selectedLeg.steps.map((step: any, index: any, arr: any) => {
                return (
                  <>
                    <TurnByTurnContainer key={step.encoded_lat_lngs}>
                      <TurnByTurnItem travelMode={step.travel_mode}>
                        {step.travel_mode === 'WALKING' && (
                          <>
                            <div className="imageContainer">
                              <MdDirectionsWalk />
                            </div>
                            <div className="path">
                              <div className="circle">
                                {index === 0 && (
                                  <div className="circleText">{horaAtual}</div>
                                )}
                                {index !== arr.length - arr.length &&
                                  index !== arr.length - 1 &&
                                  arr[index - 1].travel_mode === 'TRANSIT' && (
                                    <div className="circleText">
                                      {arr[index - 1].transit.arrival_time.text}
                                    </div>
                                  )}
                                {index === arr.length - 1 &&
                                  arr[index - 1].travel_mode === 'TRANSIT' && (
                                    <div className="circleText">
                                      {arr[index - 1].transit.arrival_time.text}
                                    </div>
                                  )}
                              </div>
                              <div className="line" />
                            </div>
                          </>
                        )}
                        {step.travel_mode === 'TRANSIT' && (
                          <>
                            <div className="imageContainer">
                              <MdDirectionsBus />
                            </div>
                            <div className="path">
                              <div className="circle">
                                <div className="circleText">
                                  {step.transit.departure_time.text}
                                </div>
                              </div>
                              <div className="line" />
                            </div>
                          </>
                        )}
                        {step.travel_mode === 'WALKING' && (
                          <div className="content">
                            <h3 className="title">{step.instructions}</h3>
                            <div className="stepType">A pé</div>
                            <Accordion title={`aprox ${step.duration.text}`}>
                              <ul>
                                {step.steps.map((item: any) => (
                                  <li
                                    className="instructionsItem"
                                    key={item.encoded_lat_lngs}
                                    dangerouslySetInnerHTML={{
                                      __html: item.instructions,
                                    }}
                                  />
                                ))}
                              </ul>
                            </Accordion>
                          </div>
                        )}
                        {step.travel_mode === 'TRANSIT' && (
                          <div className="content">
                            <h3 className="title">
                              {step.transit.departure_stop.name}
                            </h3>
                            <div className="stepType">
                              <div className="ballon">
                                {step.transit.line.short_name}
                              </div>
                              {step.transit.line.name}
                            </div>
                            <div className="stops">{`${step.transit.num_stops} paradas (aprox. ${step.duration.text})`}</div>
                          </div>
                        )}
                      </TurnByTurnItem>
                    </TurnByTurnContainer>
                    {index === arr.length - 1 && (
                      <TurnByTurnContainer key="END" className="mb20">
                        <TurnByTurnItem travelMode="ENDING">
                          <div className="imageContainer" />
                          <div className="path">
                            <div className="circle">
                              {arr[index - 1].travel_mode === 'TRANSIT' && (
                                <div className="circleTextEnd">
                                  {arr[index - 1].transit.arrival_time.text}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="content">
                            <h3 className="title last">
                              {selectedLeg.end_address}
                            </h3>
                          </div>
                        </TurnByTurnItem>
                      </TurnByTurnContainer>
                    )}
                  </>
                );
              })}
            </>
          )}
        </AsideDetail>
      )}
      <Main>
        <Map
          markersData={markersData}
          markerIcon={Icon}
          setActive={setActive}
          asideOpen={active}
          routes
        />
      </Main>
      {!!routes.routes && (
        <button
          type="button"
          className="seeMap"
          onClick={() => setAsideOpacity(!asideOpacity)}
        >
          Ver mapa
        </button>
      )}
      {!asideOpacity && !!routes.routes && (
        <button
          type="button"
          className="seeMap"
          onClick={() => setAsideOpacity(!asideOpacity)}
        >
          Ver Rotas
        </button>
      )}
    </Container>
  );
};

export default RoutesAndLinesMap;
