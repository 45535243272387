/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-empty-interface */
import * as React from 'react';
import Button from '../Button';
import { ModalGerarTicketQrCode } from './ModalGerarTicketQrCode';

interface IButtonGerarTicketQrCode {}

const ButtonGerarTicketQrCode: React.FC<IButtonGerarTicketQrCode> = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Button
        color="orange"
        padding="0"
        rounded
        className="button-generate-ticket-qrcode"
        type="button"
        onClick={() => setOpen(true)}
        style={{
          height: '48px',
          padding: '14px 23px 14px 23px',
          marginRight: '10px',
          marginBottom: '15px',
        }}
      >
        GERAR TICKET QR CODE
      </Button>
      {open && (
        <ModalGerarTicketQrCode
          title="GERAR TICKET QR CODE"
          handleClose={() => setOpen(false)}
          show={open}
        />
      )}
    </>
  );
};

export { ButtonGerarTicketQrCode };
