import React, { HTMLAttributes } from 'react';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { Container, Nickname, CardNumber } from './styles';

interface CardProps extends HTMLAttributes<HTMLElement> {
  nickname: string;
  cardNumber: string;
  selected?: boolean;
}

const Card: React.FC<CardProps> = ({
  nickname,
  cardNumber,
  selected,
  ...rest
}) => {
  // const [checked, setChecked] = useState<boolean>(true);
  // const toggleChecked = () => {
  //   if (checked) {
  //     setChecked(false);
  //   } else {
  //     setChecked(true);
  //   }
  // };

  // const theme = createMuiTheme({
  //   palette: {
  //     primary: orange,
  //   },
  // });

  return (
    <>
      <Container {...rest} selected={selected}>
        <Nickname>{nickname}</Nickname>
        <CardNumber>{cardNumber}</CardNumber>
        <CreditCardIcon />
        {/* <ThemeProvider theme={theme}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={checked}
                  onChange={toggleChecked}
                  color="primary"
                />
              }
              label="Cartão Ativo"
              color="secondary"
              className="FormControl"
            />
          </FormGroup>
        </ThemeProvider> */}
      </Container>
    </>
  );
};

export default Card;
