import styled from 'styled-components';

export const Circle = styled.div`
  display: flex;

  .circle1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid var(--purple);
  }

  .circle2 {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--purple);
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  color: var(--purple);
`;

export const Content = styled.div`
  border-radius: 10px;
  border: 1px solid var(--purple);
  display: flex;
  padding: 1.1em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  .Infocard {
    display: flex;
    flex-direction: column;
  }
  .titular-name {
    margin-left: 20px;
    font-size: 20px;
    font-weight: bold;
  }

  .card-number {
    margin-left: 25px;
    margin-top: 0.5em;
    font-size: 18px;
  }

  .icon {
    margin-top: 10px;
  }

  .edit-button {
    margin-top: 8px;
    margin-left: 20px;
    size: 50px;
    justify-self: left;
    color: var(--purple);
    background: transparent;
  }
`;
