import styled, { keyframes } from 'styled-components';

const EaseIn = keyframes`
  from{ opacity:0; }
  to{ opacity:1; }
`;

export const TransfacilCard = styled.button`
  background: var(--purple);
  color: var(--white);
  border-radius: 20px;
  width: 200px;
  padding: 14px 23px 14px 23px;
  margin-top: 1rem;
  margin-right: 25px;
  align-items: center;
  @media (max-width: 940px) {
    margin-bottom: 1rem;
  }
  @media (max-width: 315px) {
    width: 155px;
    font-size: 0.9rem;
  }

  p {
    font-size: 15px;
  }

  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  max-width: 1140px;
  .buttons {
    margin-top: 30px;
    padding: 0px;
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 10px;

    .btn-cancelar {
      color: var(--orange);
      border: 2px solid var(--orange);
      border-radius: 16px;
      margin-right: 40px;
    }
  }
  .div-saldo {
    display: flex;
    .info-saldo {
      .text-saldo {
        position: absolute;
        margin-left: 1rem;
        background-color: var(--white);
        padding: 0 12px;
        margin-top: -10px;
        color: var(--purple);
      }
      .saldo {
        background: var(--white);
        color: var(--purple);
        border: 2px solid var(--purple);
        border-radius: 10px;
        height: 80px;
        padding: 0 0 0 16px;
        width: 275px;

        display: flex;
        align-items: center;
        @media (max-width: 370px) {
          width: 205px;
        }
      }
      h5 {
        color: var(--orange);
        margin-top: 0.5rem;
        margin-left: 1rem;
      }
    }
    .button-recarregar {
      margin-left: 50px;
      @media (max-width: 670px) {
        margin-left: 0px;
      }
    }
    @media (max-width: 670px) {
      flex-direction: column;
    }
  }
`;

export const TitleSection = styled.section`
  margin: 0px 0px 50px 0px;
  color: var(--purple);

  h1 {
    line-height: 3rem;
    text-transform: uppercase;
    @media (max-width: 355px) {
      flex-wrap: wrap;
      font-size: 2.7rem;
    }
    @media (max-width: 315px) {
      font-size: 1.8rem;
    }
  }
`;

// export const RegisterCardButton = styled(Button)`
//   padding-top: 3.5rem;
//   padding-right: 1rem;
// `;

export const CardBox = styled.div`
  max-width: 1140px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 600px;
  margin-top: 0px;
  animation: ${EaseIn} 0.3s;

  .inforecarga {
    color: var(--orange);
    margin-left: 20px;
  }

  h2 {
    margin-left: -230px;
    margin-top: 50px;
    color: var(--gray-line);
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }
  .register-nickname {
    margin-top: 1.5rem;
    margin-left: 20px;
    width: 300px;
    @media (max-width: 720px) {
      width: 22rem;
    }
    @media (max-width: 670px) {
      margin-left: 0px;
    }
    @media (max-width: 615px) {
      width: 21.8rem;
    }
    @media (max-width: 370px) {
      width: 16.5rem;
    }
    @media (max-width: 315px) {
      width: 11.5rem;
    }
  }
  @media (max-width: 670px) {
    display: flex;
    flex-direction: column;
    width: 281.5px;
  }
  @media (max-width: 370px) {
    width: 16.5rem;
  }
`;
export const Historico = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  .botaohistorico {
    margin-top: 10px;
    width: 500px;
    height: 50px;
    max-width: 100%;
    background-color: var(--purple);
    border: 1px solid var(--purple);
    font-weight: bold;
    color: white;
    @media (max-width: 600px) {
      width: 300px;
    }
    @media (max-width: 500px) {
      width: 100%;
    }

    .icon {
      margin-right: 10px;
      margin-top: 2px;
    }
  }
`;

export const ExtratodeUso = styled.button``;

export const Extratoderecarga = styled.div``;
