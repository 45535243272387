/* eslint-disable import/order */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lonely-if */
/* eslint-disable react/display-name */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { formatCurrency } from '@brazilian-utils/brazilian-utils';
import Paper from '@material-ui/core/Paper';
import Loader from '../../Loader';
import 'react-datepicker/dist/react-datepicker.css';
import { FiXCircle } from 'react-icons/fi';
// import ModalUpdateInformations from '../../components/ModalUpdateInformations';

import { TitleSection, Main } from './styles';

import TicketingService, { Recarga } from '../../../services/ticketing';
import AccountsService from '../../../services/accounts';
import { TableExtrato } from './tableExtratouso';
import Button from '../../Button';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';

interface ExtratoUsoProps {
  idcard?: any;
}

const ExtratoUso: React.FC<ExtratoUsoProps> = ({ idcard }) => {
  const servicesProfile = { AccountsService: new AccountsService() };
  const services = { ticketingService: new TicketingService() };
  const [extrato, setExtrato] = React.useState<any>();
  const [profile, setProfile] = React.useState([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  const formatDate = (date: any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const currentDate = new Date();
  const initialDate = new Date(currentDate);
  initialDate.setDate(currentDate.getDate() - 7);
  const [dateStart, setDateStart] = useState(initialDate);
  const [dateEnd, setDateEnd] = useState(currentDate);
  const [hasData, setHasData] = useState<boolean>(true);
  const { addToast } = useToast();

  const linhasSindpautas = [
    { linha: 'S10', nomelinha: 'BH SHOPPING/SÃO FRANCISCO' },
    { linha: 'S11', nomelinha: 'CRUZEIRO/GUTIERREZ' },
    { linha: 'S19', nomelinha: 'HOSPITAL EVANGELICO/SHOPPING BOULEVARD' },
    { linha: 'S20', nomelinha: 'PALMEIRAS/SERRA' },
    { linha: 'S21', nomelinha: 'DOM CABRAL/BH SHOPPING' },
    { linha: 'S22', nomelinha: 'BURITIS/METRÔ CALAFATE' },
    { linha: 'S31', nomelinha: 'PETRÓPOLIS / BARREIRO' },
    { linha: 'S32', nomelinha: 'ITAIPÚ/CARDOSO' },
    { linha: 'S33', nomelinha: 'LINDÉIA/MILIONÁRIOS' },
    { linha: 'S41', nomelinha: 'CONJUNTO CALIFÓRNIA/PRADO' },
    { linha: 'S50', nomelinha: 'CAIÇARA / NOVA VISTA - VIA UFMG' },
    { linha: 'S53', nomelinha: 'CONFISCO/SÃO GABRIEL' },
    { linha: 'S54', nomelinha: 'DOM BOSCO/SHOPPING DEL REY' },
    { linha: 'S55', nomelinha: 'SANTA MÔNICA/MINAS SHOPPING' },
    { linha: 'S56', nomelinha: 'SÃO JOSÉ/VILARINHO' },
    {
      linha: 'S60',
      nomelinha: 'ETELVINA CARNEIRO/JARDIM LEBLON VIA VENDA NOVA',
    },
    { linha: 'S61', nomelinha: 'MANTIQUEIRA/ESTAÇÃO VILARINHO' },
    { linha: 'S63', nomelinha: 'CAMPO ALEGRE/CEU AZUL VIA RIO BRANCO' },
    { linha: 'S64', nomelinha: 'CAMPO ALEGRE/CEU AZUL VIA VENDA NOVA' },
    { linha: 'S65', nomelinha: 'SÃO BERNARDO/MINASCAIXA' },
    { linha: 'S66', nomelinha: 'TUPI/EUROPA' },
    { linha: 'S70', nomelinha: 'CONJ. FELICIDADE/SHOPPING DEL REY' },
    { linha: 'S80', nomelinha: 'JARDIM VITÓRIA/JARDINÓPOLIS' },
    { linha: 'S81', nomelinha: 'CIRCULAR NORDESTE' },
    { linha: 'S82', nomelinha: 'CIRCULAR NORDESTE' },
    { linha: 'S85', nomelinha: 'MINAS SHOPPING/SANTA INÊS' },
    { linha: 'S90', nomelinha: 'BALEIA/SAGRADA FAMILIA' },
    { linha: 'S92', nomelinha: 'ESPLANADA/BURITIS' },
  ];
  const metro = [
    { linha: '5001', nome: 'ESTAÇÃO ELDORADO' },
    { linha: '5002', nome: 'ESTAÇÃO CIDADE INDUSTRIAL' },
    { linha: '5003', nome: 'ESTAÇÃO VILA OESTE' },
    { linha: '5004', nome: 'ESTAÇÃO GAMELEIRA' },
    { linha: '5005', nome: 'ESTAÇÃO CALAFATE' },
    { linha: '5006', nome: 'ESTAÇÃO CARLOS PRATES' },
    { linha: '5007', nome: 'ESTAÇÃO LAGOINHA' },
    { linha: '5008', nome: 'ESTAÇÃO CENTRAL' },
    { linha: '5009', nome: 'ESTAÇÃO SANTA EFIGÊNIA' },
    { linha: '5010', nome: 'ESTAÇÃO SANTA TERESA' },
    { linha: '5011', nome: 'ESTAÇÃO HORTO FLORESTAL' },
    { linha: '5012', nome: 'ESTAÇÃO SANTA INÊS' },
    { linha: '5013', nome: 'ESTAÇÃO JOSÉ CÂNDIDO' },
    { linha: '5014', nome: 'ESTAÇÃO MINAS SHOPPING' },
    { linha: '5015', nome: 'ESTAÇÃO SÃO GABRIEL' },
    { linha: '5016', nome: 'ESTAÇÃO PRIMEIRO DE MAIO' },
    { linha: '5017', nome: 'ESTAÇÃO WALDOMIRO LOBO' },
    { linha: '5018', nome: 'ESTAÇÃO FLORAMAR' },
    { linha: '5019', nome: 'ESTAÇÃO VILARINHO' },
  ];
  const estacoes = [
    {
      linha: '1000',
      nome: 'ESTAÇÃO DIAMANTE',
    },
    {
      linha: '1001',
      nome: 'ESTAÇÃO DIAMANTE',
    },
    {
      linha: '1002',
      nome: 'ESTAÇÃO DIAMANTE ACESSO LOJAS',
    },
    {
      linha: '2000',
      nome: 'ESTACAO VENDA NOVA',
    },
    {
      linha: '2001',
      nome: 'ESTAÇÃO VENDA NOVA',
    },
    {
      linha: '3000',
      nome: 'ACESSO ESTAÇÃO SAO GABRIEL',
    },
    {
      linha: '3001',
      nome: 'ESTAÇÃO SÃO GABRIEL',
    },
    {
      linha: '3002',
      nome: 'ESTAÇÃO MOVE SÃO GABRIEL (TARIFA A)',
    },
    {
      linha: '4001',
      nome: 'ESTAÇÃO BARREIRO',
    },
    {
      linha: '4002',
      nome: 'ESTAÇAO BARREIRO',
    },
    {
      linha: '4003',
      nome: 'ESTAÇÃO BARREIRO SAÍDA / ACESSO SHOPPING',
    },
    {
      linha: '4004',
      nome: 'ESTAÇÃO BARREIRO ACESSO LOJAS',
    },
    {
      linha: '6000',
      nome: 'ACESSO ESTAÇÃO PAMPULHA',
    },
    {
      linha: '6001',
      nome: 'ESTAÇÃO PAMPULHA',
    },
    {
      linha: '7000',
      nome: 'LINHAS ACESSO ESTAÇÃO VILARINHO',
    },
    {
      linha: '7001',
      nome: 'ESTAÇÃO VILARINHO',
    },
    {
      linha: 'AC01',
      nome: 'ESTAÇÃO TRANSF. CANDELÁRIA',
    },
    {
      linha: 'AC02',
      nome: 'ESTAÇÃO TRANSF. MINAS CAIXA',
    },
    {
      linha: 'AC03',
      nome: 'ESTAÇÃO TRANSF. QUADRAS DO VILARINHO',
    },
    {
      linha: 'AC04',
      nome: 'ESTAÇÃO TRANSF. UPA VENDA NOVA',
    },
    {
      linha: 'AC05',
      nome: 'ESTAÇÃO TRANSF. CRISTIANO GUIMARÃES',
    },
    {
      linha: 'AC06',
      nome: 'ESTAÇÃO TRANSF. PLANALTO',
    },
    {
      linha: 'AC07',
      nome: 'ESTAÇÃO TRANSF. SÃO JOÃO BATISTA',
    },
    {
      linha: 'AC08',
      nome: 'ESTAÇÃO TRANSF. LAGOA DO NADO',
    },
    {
      linha: 'AC09',
      nome: 'ESTAÇÃO TRANSF. MONTESE',
    },
    {
      linha: 'AC10',
      nome: 'ESTAÇÃO TRANSF. MONTE CASTELO',
    },
    {
      linha: 'AC12',
      nome: 'ESTAÇÃO TRANSF. SANTA ROSA',
    },
    {
      linha: 'AC13',
      nome: 'ESTAÇÃO TRANSF. MINEIRÃO',
    },
    {
      linha: 'AC14',
      nome: 'ESTAÇÃO TRANSF. UFMG',
    },
    {
      linha: 'AC15',
      nome: 'ESTAÇÃO TRANSF. LIBERDADE',
    },
    {
      linha: 'AC16',
      nome: 'ESTAÇÃO TRANSF. COLÉGIO MILITAR',
    },
    {
      linha: 'AC17',
      nome: 'ESTAÇÃO TRANSF. SÃO FRANCISCO',
    },
    {
      linha: 'AC18',
      nome: 'ESTAÇÃO TRANSF. CACHOEIRINHA',
    },
    {
      linha: 'AC19',
      nome: 'ESTAÇÃO TRANSF. AMÉRICO VESPÚCIO',
    },
    {
      linha: 'AC20',
      nome: 'ESTAÇÃO TRANSF. APARECIDA',
    },
    {
      linha: 'AC21',
      nome: 'ESTAÇÃO TRANSF. OPERÁRIOS',
    },
    {
      linha: 'AC22',
      nome: 'ESTAÇÃO TRANSF. HOSPITAL BELO HORIZONTE',
    },
    {
      linha: 'AC23',
      nome: 'ESTAÇÃO TRANSF. IAPI',
    },
    {
      linha: 'AC24',
      nome: 'ESTAÇÃO TRANSF. HOSPITAL ODILON BEHRENS',
    },
    {
      linha: 'AC25',
      nome: 'ESTAÇÃO TRANSF. SENAI',
    },
    {
      linha: 'AC26',
      nome: 'ESTAÇÃO TRANSF. LAGOINHA',
    },
    {
      linha: 'CM02',
      nome: 'ESTAÇÃO DE TRANSF. MINAS SHOPPING',
    },
    {
      linha: 'CM03',
      nome: 'ESTAÇÃO DE TRANSF. OURO MINAS',
    },
    {
      linha: 'CM04',
      nome: 'ESTAÇÃO DE TRANSF. UNIÃO',
    },
    {
      linha: 'CM05',
      nome: 'ESTAÇÃO DE TRANSF. IPIRANGA',
    },
    {
      linha: 'CM06',
      nome: 'ESTAÇÃO DE TRANSF. CIDADE NOVA',
    },
    {
      linha: 'CM07',
      nome: 'ESTAÇÃO DE TRANSF. FEIRA DOS PRODUTORES',
    },
    {
      linha: 'CM08',
      nome: 'ESTAÇÃO DE TRANSF. SÃO JUDAS TADEU',
    },
    {
      linha: 'CM09',
      nome: 'ESTAÇÃO DE TRANSF. SAGRADA FAMÍLIA',
    },
    {
      linha: 'CM10',
      nome: 'ESTAÇÃO DE TRANSF. SILVIANO BRANDÃO',
    },
    {
      linha: 'PR01',
      nome: 'ESTAÇÃO DE TRANSF. TAMOIOS',
    },
    {
      linha: 'PR02',
      nome: 'ESTAÇÃO DE TRANSF. CARIJÓS',
    },
    {
      linha: 'SD01',
      nome: 'ESTAÇÃO DE TRANSF. SÃO PAULO',
    },
    {
      linha: 'SD02',
      nome: 'ESTAÇÃO DE TRANSF. RIO DE JANEIRO',
    },
  ];
  // const estacoes = [
  //   { linha: '1000', nome: 'ESTAÇÃO DIAMANTE' },
  //   { linha: '1001', nome: 'LINHA DE BLOQUEIO ESTAÇÃO DIAMANTE' },
  //   { linha: '1002', nome: 'LINHA BLOQUEIO ESTAÇÃO DIAMANTE ACESSO LOJAS' },
  //   { linha: '2000', nome: 'ESTACAO VENDA NOVA' },
  //   { linha: '2001', nome: 'LINHA DE BLOQUEIO ESTAÇÃO VENDA NOVA' },
  //   { linha: '3000', nome: 'ACESSO ESTAÇÃO SAO GABRIEL' },
  //   { linha: '3001', nome: 'LINHAS BLOQUEIO SÃO GABRIEL' },
  //   { linha: '3002', nome: 'LINHA BLOQUEIO MOVE SÃO GABRIEL (TARIFA A)' },
  //   { linha: '4001', nome: 'LINHA DE BLOQUEIO ESTAÇÃO BARREIRO' },
  //   { linha: '4002', nome: 'ESTAÇAO BARREIRO' },
  //   { linha: '4003', nome: 'ESTAÇÃO BARREIRO SAÍDA / ACESSO SHOPPING' },
  //   { linha: '4004', nome: 'LINHA DE BLOQUEIO ESTAÇÃO BARREIRO ACESSO LOJAS' },
  //   { linha: '6000', nome: 'ACESSO ESTAÇÃO PAMPULHA' },
  //   { linha: '6001', nome: 'LINHAS BLOQUEIO ESTAÇÃO PAMPULHA' },
  //   { linha: '7000', nome: 'LINHAS ACESSO ESTAÇÃO VILARINHO' },
  //   { linha: '7001', nome: 'LINHAS BLOQUEIO ESTAÇÃO VILARINHO' },
  //   { linha: 'AC01', nome: 'ESTAÇÃO TRANSF. CANDELÁRIA' },
  //   { linha: 'AC02', nome: 'ESTAÇÃO TRANSF. MINAS CAIXA' },
  //   { linha: 'AC03', nome: 'ESTAÇÃO TRANSF. QUADRAS DO VILARINHO' },
  //   { linha: 'AC04', nome: 'ESTAÇÃO TRANSF. UPA VENDA NOVA' },
  //   { linha: 'AC05', nome: 'ESTAÇÃO TRANSF. CRISTIANO GUIMARÃES' },
  //   { linha: 'AC06', nome: 'ESTAÇÃO TRANSF. PLANALTO' },
  //   { linha: 'AC07', nome: 'ESTAÇÃO TRANSF. SÃO JOÃO BATISTA' },
  //   { linha: 'AC08', nome: 'ESTAÇÃO TRANSF. LAGOA DO NADO' },
  //   { linha: 'AC09', nome: 'ESTAÇÃO TRANSF. MONTESE' },
  //   { linha: 'AC10', nome: 'ESTAÇÃO TRANSF. MONTE CASTELO' },
  //   { linha: 'AC12', nome: 'ESTAÇÃO TRANSF. SANTA ROSA' },
  //   { linha: 'AC13', nome: 'ESTAÇÃO TRANSF. MINEIRÃO' },
  //   { linha: 'AC14', nome: 'ESTAÇÃO TRANSF. UFMG' },
  //   { linha: 'AC15', nome: 'ESTAÇÃO TRANSF. LIBERDADE' },
  //   { linha: 'AC16', nome: 'ESTAÇÃO TRANSF. COLÉGIO MILITAR' },
  //   { linha: 'AC17', nome: 'ESTAÇÃO TRANSF. SÃO FRANCISCO' },
  //   { linha: 'AC18', nome: 'ESTAÇÃO TRANSF. CACHOEIRINHA' },
  //   { linha: 'AC19', nome: 'ESTAÇÃO TRANSF. AMÉRICO VESPÚCIO' },
  //   { linha: 'AC20', nome: 'ESTAÇÃO TRANSF. APARECIDA' },
  //   { linha: 'AC21', nome: 'ESTAÇÃO TRANSF. OPERÁRIOS' },
  //   { linha: 'AC22', nome: 'ESTAÇÃO TRANSF. HOSPITAL BELO HORIZONTE' },
  //   { linha: 'AC23', nome: 'ESTAÇÃO TRANSF. IAPI' },
  //   { linha: 'AC24', nome: 'ESTAÇÃO TRANSF. HOSPITAL ODILON BEHRENS' },
  //   { linha: 'CM02', nome: 'ESTAÇÃO DE TRANSF. MINAS SHOPPING' },
  //   { linha: 'CM03', nome: 'ESTAÇÃO DE TRANSF. OURO MINAS' },
  //   { linha: 'CM04', nome: 'ESTAÇÃO DE TRANSF. UNIÃO' },
  //   { linha: 'CM05', nome: 'ESTAÇÃO DE TRANSF. IPIRANGA' },
  //   { linha: 'CM06', nome: 'ESTAÇÃO DE TRANSF. CIDADE NOVA' },
  //   { linha: 'CM07', nome: 'ESTAÇÃO DE TRANSF. FEIRA DOS PRODUTORES' },
  //   { linha: 'CM08', nome: 'ESTAÇÃO DE TRANSF. SÃO JUDAS TADEU' },
  //   { linha: 'CM09', nome: 'ESTAÇÃO DE TRANSF. SAGRADA FAMÍLIA' },
  //   { linha: 'PR01', nome: 'ESTAÇÃO DE TRANSF. TAMOIOS' },
  //   { linha: 'PR02', nome: 'ESTAÇÃO DE TRANSF. CARIJÓS' },
  //   { linha: 'SD01', nome: 'ESTAÇÃO DE TRANSF. SÃO PAULO' },
  //   { linha: 'SD02', nome: 'ESTAÇÃO DE TRANSF. RIO DE JANEIRO' },
  // ];

  // const [
  //   modalUpdateInformationsIsOpen,
  //   setModalUpdateInformationsIsOpen,
  // ] = useState(false);

  //   const convertStringToDate = (dateTimeString: any) => {
  //     if (!dateTimeString) {
  //       return '2'; // Ou defina um valor padrão, se necessário
  //     }

  //     const [date, time] = dateTimeString.split(' ');
  //     const [day, month, year] = date.split('/').map(Number);
  //     const [hours, minutes, seconds] = time.split(':').map(Number);
  //     // Mês no JavaScript começa do zero, então subtraia 1 do mês
  //     return new Date(year, month - 1, day, hours, minutes, seconds);
  //   };

  const HandleDate7 = () => {
    const Date7 = new Date(currentDate);
    Date7.setDate(currentDate.getDate() - 7);
    setDateStart(Date7);
  };

  const HandleDate15 = () => {
    const Date15 = new Date(currentDate);
    Date15.setDate(currentDate.getDate() - 15);
    setDateStart(Date15);
  };

  const HandleDate30 = () => {
    const Date30 = new Date(currentDate);
    Date30.setDate(currentDate.getDate() - 30);
    setDateStart(Date30);
  };

  const HandleDate60 = () => {
    const Date60 = new Date(currentDate);
    Date60.setDate(currentDate.getDate() - 60);
    setDateStart(Date60);
  };

  const HandleDate90 = () => {
    const Date90 = new Date(currentDate);
    Date90.setDate(currentDate.getDate() - 90);
    setDateStart(Date90);
  };

  useEffect(() => {
    async function getRecargas() {
      try {
        const datainicio = formatDate(dateStart);
        const datafim = formatDate(dateEnd);
        const response = await services.ticketingService.getCardExtrato(
          idcard,
          datainicio,
          datafim,
        );
        if (response.data?.detail) {
          setExtrato([]);
          setLoading(false);
          setHasData(false);
        }
        const dataLinhas = await api.get(`/core/qr-code/lines/`);
        const novaInfo = response.data.map((dadosResponse: any) => {
          const novaInfoNome = dataLinhas?.data.find((dados: any) => {
            return (
              dadosResponse?.codLinha === dados?.id?.linha?.linCodLinhaExterno
            );
          });
          const dadosSuplementar = linhasSindpautas?.find((dadosSup: any) => {
            return dadosResponse?.codLinha === dadosSup?.linha;
          });
          const dadosMetro = metro?.find((dadosMetros: any) => {
            return dadosResponse?.codLinha === dadosMetros?.linha;
          });
          const dadosestacao = estacoes?.find((dadosestacoes: any) => {
            return dadosResponse?.codLinha === dadosestacoes?.linha;
          });
          const dadosIntermediarios = novaInfoNome
            ? dadosMetro || dadosestacao
              ? dadosMetro
                ? dadosMetro?.nome
                : dadosestacao?.nome
              : novaInfoNome?.id?.linha?.linNomeLinha
            : dadosSuplementar
            ? dadosSuplementar?.nomelinha
            : '';
          const codLinhaVazia =
            dadosMetro || dadosestacao ? ' ' : dadosResponse?.codLinha;
          return {
            ...dadosResponse,
            nomelinha: dadosIntermediarios,
            codLinha: codLinhaVazia,
          };
          // const linhaNome = dataLinhas?.data.map((dados: any) => {
          //   const cartaoEncontrado = response.data.find((dadoscard: any) => {
          //     return dados?.id?.linha?.linCodLinhaExterno === dadoscard?.codLinha;
          //   });

          //   return {
          //     ...dados,

          //   };
        });

        if (response.data.length > 0) {
          setExtrato(novaInfo);
          setLoading(false);
          setHasData(true);
        } else {
          setExtrato([]);
          setLoading(false);
          setHasData(false);
          // if (response.data.detail) {
          //   addToast({
          //     type: 'error',
          //     title: `Erro para gerar os dados`,
          //     description: response.data.detail,
          //   });
          //   setHasData(false);
          // } else {

          // }
        }
      } catch (err: any) {
        const { ...error } = err;
        console.log(error);
        addToast({
          type: 'error',
          title: `Erro ao carregar o histórico`,
          description: `Ocorreu um erro de servidor`,
        });

        console.error(err);
        setLoading(false);
      }
    }
    getRecargas();
  }, [dateStart, dateEnd]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <TitleSection>
            <h1>Histórico de Uso</h1>
          </TitleSection>
          <Main>
            <div className="datadiv">
              <div className="datadivindividual">
                <h4 className="datatext">
                  Selecione quantos dias de Histórico você quer buscar.
                </h4>
                <Button
                  color="orange"
                  padding="3.5"
                  rounded
                  className="button-cadastrar"
                  type="submit"
                  onClick={HandleDate7}
                >
                  7 dias
                </Button>
                <Button
                  color="orange"
                  padding="3.5"
                  rounded
                  className="button-cadastrar"
                  type="submit"
                  onClick={HandleDate15}
                >
                  15 dias
                </Button>
                <Button
                  color="orange"
                  padding="3.5"
                  rounded
                  className="button-cadastrar"
                  type="submit"
                  onClick={HandleDate30}
                >
                  30 dias
                </Button>
                <Button
                  color="orange"
                  padding="3.5"
                  rounded
                  className="button-cadastrar"
                  type="submit"
                  onClick={HandleDate60}
                >
                  60 dias
                </Button>
                <Button
                  color="orange"
                  padding="3.5"
                  rounded
                  className="button-cadastrar"
                  type="submit"
                  onClick={HandleDate90}
                >
                  90 dias
                </Button>
              </div>
            </div>
            {hasData ? (
              <Paper className="paper">
                {/* <Table>
                <TableHead>
                  <TableRow className="header">
                    <TableCell>
                      <strong onClick={handleSort}>Data da Utilização</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Linha</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Valor Utilizado</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Saldo após a Utilização</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {extrato &&
                    extrato.map((extratos: any) => (
                      <TableRow key={Math.random()}>
                        <TableCell>{extratos.dataUso}</TableCell>
                        <TableCell>{extratos.codLinha}</TableCell>
                        <TableCell>
                          {formatPrice(extratos.valorUtilizado)}
                        </TableCell>
                        <TableCell>
                          {formatPrice(extratos.saldoCartao)}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table> */}
                <div className="Tabela">
                  <TableExtrato
                    data={extrato}
                    columns={[
                      {
                        name: <div>Data da Utilização</div>,
                        selector: 'datautilizacao',
                        cell: ({ dataUso }: any) => (
                          <div className="specifitcell">{dataUso}</div>
                        ),
                      },
                      {
                        name: <div>Linha</div>,
                        selector: 'codlinha',
                        cell: ({ codLinha, nomelinha }: any) => (
                          <div className="specifitcell">
                            {codLinha === ' '
                              ? `${nomelinha}`
                              : `${codLinha} - ${nomelinha}`}
                          </div>
                        ),
                      },
                      {
                        name: <div>Valor Utilizado</div>,
                        selector: 'valorutilizado',
                        cell: ({ valorUtilizado }: any) => (
                          <div className="specifitcell">{`R$ ${formatCurrency(
                            Number(valorUtilizado),
                          )}`}</div>
                        ),
                      },
                      // {
                      //   name: <div>Saldo após a Utilização</div>,
                      //   selector: 'saldocartao',
                      //   cell: ({ saldoCartao }: any) => (
                      //     <div className="specifitcell">{`R$ ${formatCurrency(
                      //       Number(saldoCartao),
                      //     )}`}</div>
                      //   ),
                      // },
                    ]}
                  />
                </div>
              </Paper>
            ) : (
              <div className="nodata">
                <FiXCircle size={80} className="icon" />
                <h3 className="infonotdata">
                  Nenhuma utilização neste período.
                </h3>
              </div>
            )}

            {/* {modalUpdateInformationsIsOpen && <ModalUpdateInformations />} */}
          </Main>
        </>
      )}
    </>
  );
};

export default ExtratoUso;
