import React from 'react';

import { TitleSection, Main } from './styles';
import { Container, Content, Blur } from '../../styles/components';

import Header from '../../components/Home/Header';
import NavBar from '../../components/Home/NavBar';
import Footer from '../../components/Home/Footer';

const TermosDeUso: React.FC = () => {
  return (
    <>
      <Container>
        <Content>
          <Header />
        </Content>
      </Container>
      <Container backgroundImage>
        <Blur>
          <Content className="content">
            <NavBar />
            <TitleSection>
              <h1 className="h1">Termos e Condições de Uso</h1>
            </TitleSection>
          </Content>
        </Blur>
      </Container>
      <Container>
        <Content>
          <Main>
            <h1 className="title">BEM VINDO AO TRANSFÁCIL</h1>
            <p>
              Agradecemos por usar nossos produtos e serviços
              (&#34;Serviços&#34;).
            </p>
            <p>
              Este documento é um Termo de Ciência e firmado entre você, de
              agora em diante denominado como &#34;USUÁRIO&#34;, e o Consórcio
              Op. do Transporte Coletivo de Passageiros por Ônibus do Município
              de BH, inscrito no CNPJ sob o nº 04.398.505/0001-07.
            </p>
            <p>
              Este Termo e Adesão às Condições de Uso (&#34;Termos&#34;) rege a
              utilização do Site Transfácil e Aplicativo denominado
              &#34;BHBUS&#34;, disponibilizado gratuitamente pelo TRANSFÁCIL
              para computadores e dispositivos móveis (Android e iOS).
            </p>
            <p>
              O aceite pelo USUÁRIO dos Termos é indispensável para a utilização
              dos serviços prestados pelo Site e Aplicativo BHBUS.
            </p>
            <h1 className="title">1. DECLARAÇÃO DE VONTADE</h1>
            <p>
              Pelo presente termo o USUÁRIO declara ter lido, compreendido e de
              ter ciência dos direitos e obrigações constante neste instrumento,
              bem como, que este constitui uma pactuação das condições entre as
              partes,{' '}
              <strong>
                e para tanto com o cadastro no Site Transfácil ou Aplicativo
                BHBUS, o USUÁRIO declara concordar com todos os termos e
                condições.
              </strong>
            </p>
            <p>
              1.1. A partir do momento em que o USUÁRIO aceita este Termos e
              Condições de Uso, as disposições aqui constantes passam a reger a
              relação entre o BHBUS e o USUÁRIO, razão pela qual é recomendável
              que o USUÁRIO imprima uma cópia deste documento para futura
              referência, que se encontra disponível no site
              www.transfacil.com.br.
            </p>
            <p>
              1.2. O USUÁRIO se declara ciente de que as operações que
              corresponderem à aceitação de determinadas opções serão
              registradas nos bancos de dados do Transfácil, juntamente com a
              data e hora em que o aceite foi manifestado pelo USUÁRIO, podendo
              tal informação ser utilizada como prova da aceitação da opção pelo
              USUÁRIO, independentemente de outra formalidade.
            </p>
            <h1 className="title">2. DO OBJETO</h1>
            <p>
              2.1. O BHBUS tem por objeto a exibição dos horários e itinerários
              das linhas dos ônibus do transporte coletivo do município de Belo
              Horizonte, a disponibilização de um canal para
              sugestões/reclamações, a contratação de serviços de recarga de
              créditos eletrônicos utilizados para transporte coletivo,
              contratação esta realizada remotamente, ou seja, fora dos
              terminais físicos, através do dispositivo móvel.
            </p>
            <p>
              2.2. O BHBUS é licenciado ao USUÁRIO exclusivamente para uso
              particular por Pessoas Físicas, sendo expressamente vedada
              qualquer utilização comercial para prestação de serviços de
              qualquer natureza. Caso seja identificada a utilização comercial
              do BHBUS o mesmo poderá suspender o uso do USUÁRIO infrator, que
              será responsável por todos os danos diretos e indiretos que
              causar.
            </p>
            <h1 className="title">3. DO ACESSO, CADASTRO E ACEITE</h1>
            <p>
              O aplicativo BHBUS é oferecido para USUÁRIOS que possuam telefone
              do tipo smartphone, com sistema operacional Android ou iOS, dentro
              das condições operacionais disponibilizadas por estes aparelhos e
              softwares respectivos.
            </p>
            <p>
              3.1. A utilização do site e aplicativo BHBUS está condicionada a
              existência de rede de comunicação de dados no computador ou
              smartphone e ao cadastro prévio do USUÁRIO.
            </p>
            <p>
              3.2. Este Termo concede ao USUÁRIO uma licença revogável, não
              exclusiva e intransferível de uso do BHBUS, sendo certo que o
              USUÁRIO não poderá utilizar e nem permitir o uso do BHBUS para
              qualquer outra finalidade não expressamente prevista neste
              instrumento.
            </p>
            <p>
              3.3. O cadastro no BHBUS deverá ser feito com informações válidas,
              completas e atualizadas, responsabilizando o USUÁRIO civil e
              criminalmente pela veracidade e precisão das informações, devendo
              isentar o BHBUS por qualquer dano ou prejuízo que a falta de
              informações corretas ou mesmo que estas forem incorretas possam
              causar ao BHBUS ou a terceiros.
            </p>
            <p>
              3.4. O BHBUS poderá, a qualquer momento, solicitar ao USUÁRIO a
              confirmação das informações fornecidas através de documentos
              originais ou cópias simples/autenticadas, o que deve ser
              prontamente atendido pelo USUÁRIO, sob pena de bloqueio do uso do
              BHBUS até cumprida a exigência.
            </p>
            <p>
              3.5. O BHBUS não terá qualquer obrigação de policiar ou fiscalizar
              as informações fornecidas pelo USUÁRIO, mas poderá, ao seu
              exclusivo critério, excluir as informações que lhe pareçam
              inverídicas ou ofensivas.
            </p>
            <p>
              3.6. Caso ao BHBUS seja solicitada informação do USUÁRIO por
              autoridade policial, judiciária e/ou fiscal, o mesmo poderá fazer
              sem qualquer descumprimento das condições postas neste contrato.
            </p>
            <p>
              3.7. O cadastro do USUÁRIO mediante nome de USUÁRIO e senha é
              pessoal e intransferível. Sendo a utilização do BHBUS pela senha
              do USUÁRIO de exclusiva responsabilidade deste, mesmo no caso de
              utilização por terceiros, devendo adotar as medidas de segurança
              necessárias em seu dispositivo para impedir tal uso (bloqueio por
              senha numérica, biométrica ou similar).
            </p>
            <p>
              3.8. Nos casos em que o USUÁRIO suspeite que está ocorrendo
              utilização indevida de sua conta no BHBUS, entrar em contato
              imediatamente com o BHBUS para efetuar a troca de sua senha
              pessoal.
            </p>
            <p>
              3.9. Fica expressamente ciente o USUÁRIO que o mesmo em nenhuma
              hipótese terá acesso ao código fonte do BHBUS ora licenciado, por
              este se tratar de propriedade intelectual do BHBUS.
            </p>
            <h1 className="title">4. FUNCIONALIDADES E USO</h1>
            <p>
              Por meio do BHBUS, o USUÁRIO poderá consultar horários e
              itinerários das linhas dos ônibus do transporte coletivo do
              município de Belo Horizonte, fazer sugestões e/ou reclamações, e
              ainda utilizar os serviços disponíveis, tais como compra de
              créditos do seu cartão BHBUS Identificado ou Ao Portador, pagando
              através das formas de pagamento disponibilizada pelo BHBUS.
            </p>
            <p>
              4.1. O USUÁRIO tem plena ciência de que os créditos adquiridos
              estarão disponíveis nos validadores, após confirmação do pagamento
              (via <strong>cartão de crédito</strong>,{' '}
              <strong>boleto bancário</strong> e <strong>PIX</strong>). O pedido
              estará disponível para para carregamento por 35 dias. Para
              carregamento após este período, o USUÁRIO deverá encaminhar email
              para sac@transfacil.com.br, de segunda a sexta-feira, no horário
              de 08h00min às 18h00min, exceto feriados.
            </p>
            <p>
              4.2. Conforme artigo 36, do DECRETO Nº 18.370 de 07 de Julho de
              2023:
            </p>
            <p>
              § 3º Os créditos eletrônicos adquiridos a partir da publicação
              deste decreto serão gerados com os seguintes critérios:
              <br /> I - a periodicidade de cada ciclo será de 365 (trezentos e
              sessenta e cinco) dias após a geração;
              <br /> III - ultrapassado o prazo estabelecido no inciso I e, se
              ainda restarem créditos eletrônicos sem utilização, os usuários
              terão prazo de até 35 (trinta e cinco) dias para reativar os
              créditos remanescentes, de forma automática, em qualquer
              equipamento validador instalado nos ônibus do sistema convencional
              e nas linhas de bloqueio das estações de transferência e
              integração;
              <br /> IV - após o prazo de 35 (trinta e cinco) dias do inciso III
              o portador do cartão que recebeu os créditos eletrônicos poderá
              solicitar o ressarcimento por meio de uma nova recarga dos
              créditos remanescentes, a qualquer tempo, diretamente nos postos
              de vendas de cartões e créditos eletrônicos operados pelo
              consórcio operacional Transfácil.
            </p>
            <p>
              4.3. Será necessária a realização de cadastro básico informando
              nome completo, e-mail e CPF, destinado a fins de controle de
              acesso, aquisição de créditos eletrônicos, envio de boleto e
              informações sobre o pedido.
            </p>
            <p>
              4.4. O carregamento e uso dos créditos estão sujeitos aos prazos e
              condições de uso, sendo responsabilidade do USUÁRIO consultá-los.
              O BHBUS não tem nenhuma responsabilidade sobre o estabelecimento,
              modificação e divulgação das regras de uso dos créditos, nem
              poderá ser responsabilizado por qualquer consequência advinda da
              falta de seu cumprimento por parte das empresas operadoras do
              transporte. Valendo a liberação na forma descrita no item 4.1.
            </p>
            <p>
              4.5. Em caso de encerramento dos serviços, o BHBUS garante que
              todas as compras realizadas até a data da rescisão serão
              cumpridas, podendo o BHBUS, alternativamente, reembolsar o USUÁRIO
              por valores pagos e não efetivados em créditos ou serviços dentro
              deste mesmo prazo.
            </p>
            <p>
              4.6. O uso do BHBUS de forma indevida, irregular, ilegal ou em
              desacordo às condições previstas nestes Termos implicará a
              exclusão do cadastro e a rescisão imediata do contrato e dos
              serviços prestados pelo BHBUS ao USUÁRIO. Os dados constantes dos
              cadastros poderão, a critério do BHBUS, ser retidos para uso das
              autoridades competentes.
            </p>
            <p>
              4.7. O USUÁRIO deverá indenizar o TRANSFÁCIL, seus representantes,
              sócios, colaboradores e/ou empregados por qualquer dano que estes
              vierem a sofrer por má utilização do cartão, bem como por todos e
              quaisquer prejuízos resultantes da utilização indevida do BHBUS.
            </p>
            <p>
              4.8. O USUÁRIO deverá comunicar ao TRANSFÁCIL a ocorrência de
              extravio, perda, destruição, furto ou roubo do cartão BHBUS
              IDENTIFICADO. O bloqueio será realizado no prazo máximo de 72
              (setenta e duas) horas após a notificação.
            </p>
            <p>
              4.9. Os créditos remanescentes do cartão bloqueado serão
              transferidos automaticamente para a segunda via e será cobrada a
              taxa de R$ 15,00 (quinze reais) pelo novo cartão. O cartão BHBUS
              IDENTIFICADO bloqueado não poderá ser desbloqueado.
            </p>
            <p>
              4.10. Para o cartão BHBUS AO PORTADOR, não é possível bloqueio
              e/ou ressarcimento dos créditos.
            </p>
            <h1 className="title">5. CARGA, RECARGA E PAGAMENTO</h1>
            <p>
              5.1. O aplicativo BHBUS+ permite uma carga máxima de R$100,00 para
              pagamentos realizados por cartão de crédito e R$650,00 para
              pagamentos por PIX ou Boleto bancário, com limite de um pedido por
              dia por cartão cadastrado. O site BHBUS permite uma carga máxima
              de R$650,00 por pedido e um pedido por dia por cartão cadastrado.
            </p>
            <p>
              5.2. O pagamento das recargas de créditos poderá ser feito através
              dos meios de pagamento disponíveis no BHBUS. A forma de pagamento
              “Boleto” só será aceita em pedidos com valores maiores que 15
              passagens de tarifa “A”.
            </p>
            <p>
              5.3. Com a Carga a Bordo os créditos eletrônicos estarão
              disponíveis nos validadores dos ônibus, após confirmação do
              pagamento, no prazo máximo de até <strong>duas horas</strong> para
              pedido pagos via <strong>Cartão de Crédito e PIX</strong>. Para
              pagamento com Boleto Bancário os créditos eletrônicos são
              disponibilizados em até <strong>72h úteis</strong> após a
              confirmação do pagamento.
            </p>
            <p>
              5.4. Poderá ocorrer implementação, alteração, adaptação ou
              cancelamento das taxas atuais a qualquer momento. Tais
              modificações serão informadas ao USUÁRIO através do site
              www.transfacil.com.br, ou antes da confirmação da contratação de
              qualquer dos serviços tão logo haja qualquer alteração.
            </p>
            <p>
              5.5. Uma vez realizadas as recargas de créditos nos cartões
              solicitados, estas são intransferíveis conforme a legislação em
              vigor e deverão ser utilizadas apenas pelo USUÁRIO titular do
              cartão, não se admitindo a devolução em espécie do valor
              correspondente à recarga ou do saldo remanescente e nem a
              transferência desses saldos eventualmente existentes para outro
              cartão de outro USUÁRIO.
            </p>
            <h1 className="title">6. VIGÊNCIA E RESCISÃO</h1>
            <p>
              6.1. Este Termo tem vigência por prazo indeterminado, iniciando a
              partir do aceite do USUÁRIO, podendo ser modificado ou rescindido
              unilateralmente pelo BHBUS a qualquer tempo, sem qualquer ônus e
              sem aviso prévio.
            </p>
            <p>
              6.2. O Consórcio Op. do Transporte Coletivo de Passageiros por
              Ônibus do Município de BH - TRANSFACIL reserva-se o direito de, a
              seu exclusivo critério e a qualquer tempo, modificar o presente
              Termos, incluindo novas condições e/ou restrições ou suprimindo
              outras. Estas alterações terão efeito imediato e serão informadas
              ao USUÁRIO por meio do seu site oficial www.transfacil.com.br.
            </p>
            <p>
              6.3. Permanecendo o USUÁRIO na continuidade na utilização do
              serviço e do Site e Aplicativo BHBUS, considerar-se-á sua
              aceitação e concordância em cumprir os termos modificados.
            </p>
            <p>
              6.4. O USUÁRIO deve periodicamente revisar os Termos do BHBUS, e
              fica o USUÁRIO ciente que caso não concorde com as alterações
              realizadas, deverá imediatamente cancelar sua conta e suspender a
              utilização do BHBUS. Na ausência de manifestação do USUÁRIO
              entender-se-á que este aceitou tacitamente os Termos atualizados e
              o Contrato continuará válido e vigente entre as partes.
            </p>
            <p>
              6.5. O USUÁRIO poderá, a qualquer momento, solicitar a suspensão
              e/ou a exclusão da sua conta de acesso ao BHBUS, encerrando seu
              relacionamento com o BHBUS, mediante solicitação realizada
              diretamente por meio do BHBUS.
            </p>
            <p>
              6.6. O BHBUS pode modificar ou descontinuar (temporária ou
              permanentemente) a distribuição ou a atualização do Site e
              Aplicativo BHBUS. O USUÁRIO não poderá responsabilizar o BHBUS nem
              seus diretores, executivos, funcionários, afiliados, agentes,
              contratados ou licenciados por quaisquer modificações, suspensões
              ou descontinuidade do BHBUS.
            </p>
            <h1 className="title">
              7. INFORMAÇÕES CONFIDENCIAIS E POLÍTICA DE SEGURANÇA DO USUÁRIO
            </h1>
            <p>
              7.1. Com a finalidade de garantir a privacidade do USUÁRIO, bem
              como a segurança de suas informações, o BHBUS se compromete a
              regularmente reavaliar a sua política de segurança e adaptá-la,
              conforme necessário.
            </p>
            <p>
              7.2. O USUÁRIO autoriza ao BHBUS o direito deste em gravar o IP do
              Usuário, tipo de navegador, dispositivo e operadora de celular,
              posição geográfica e outros dados relativos às compras de créditos
              de transporte solicitadas.
            </p>
            <p>
              7.3. O USUÁRIO tem plena ciência que o BHBUS+ para regular
              funcionamento do Site e Aplicativo necessita das seguintes
              permissões do dispositivo para o seu correto funcionamento:
              <br />
              <span>a. Localização/GPS;</span> <br />
              <span>
                b. Câmera para captura da imagem caso o USUÁRO queira anexar ao
                chamados e solicitações ao SAC;
              </span>
            </p>
            <p>
              7.4. Em nenhuma hipótese, o BHBUS irá vender ou disponibilizar as
              informações do USUÁRIO ou geradas através do uso do BHBUS, sendo
              certo que somente as utilizará para as seguintes finalidades, com
              as quais o USUÁRIO expressamente concorda e anui:
              <br />
              <span>
                a. Para enviar ao USUÁRIO qualquer notificação administrativa,
                alertas e comunicados relevantes para o mesmo;
              </span>{' '}
              <br />
              <span>b. Para cumprir com a finalidade do BHBUS;</span>
              <br />
              <span>
                {' '}
                c. Identificar o perfil, desejos ou necessidades do USUÁRIO a
                fim de aprimorar os produtos e/ou serviços oferecidos pelo
                BHBUS;
              </span>
              <br />
              <span>
                {' '}
                d. Realizar estatísticas genéricas para monitoramento de
                utilização do BHBUS;
              </span>
              <br />
              <span>
                {' '}
                e. Para pesquisas de marketing, planejamento de projetos do
                BHBUS, e;
              </span>
              <br />
              <span>
                {' '}
                f. Resolução de problemas no BHBUS, verificação e proteção das
                informações do BHBUS contra erros, fraudes ou qualquer outro
                crime eletrônico;
              </span>
              <br />
              <span>
                {' '}
                g. Envio de anúncios, promoções, notícias, informações e
                publicidades relacionadas ao transporte coletivo e Sistema de
                Bilhetagem Eletrônica - SBE.
              </span>
            </p>
            <p>
              7.5. Caso haja qualquer alteração nas informações de cadastro
              fornecidas pelo USUÁRIO, este se compromete a informar ao BHBUS de
              tais alterações de modo a garantir o correto uso e funcionamento
              do BHBUS.
            </p>
            <p>
              7.6. As informações do USUÁRIO poderão ser transferidas a
              terceiros em decorrência da venda, aquisição, fusão, reorganização
              societária ou qualquer outra mudança no controle do BHBUS. Caso
              ocorra qualquer destas hipóteses, no entanto, resultando na
              transferência das Informações a terceiros, o USUÁRIO será
              informado previamente e caso não deseje prosseguir com a
              utilização do BHBUS, poderá excluir sua conta de acesso na forma
              prevista no Item 6 deste Termos.
            </p>
            <p>
              7.7. Não obstante as Informações fornecidas pelo USUÁRIO estejam
              seguras nos termos deste Termos e Condições de Uso, o login e a
              senha de acesso ao BHBUS são confidenciais e de responsabilidades
              exclusiva do USUÁRIO.
            </p>
            <p>
              7.8. Caso o USUÁRIO acredite que seu login e senha de acesso ao
              BHBUS tenham sido roubados ou sejam de conhecimento de outras
              pessoas, por qualquer razão, o USUÁRIO deverá imediatamente
              comunicar ao BHBUS, sem prejuízo da alteração de senha imediata.
            </p>
            <h1 className="title">
              8. ISENÇÃO DE GARANTIAS E LIMITAÇÕES DE RESPONSABILIDADE
            </h1>
            <p>
              8.1. O BHBUS estará em continuo desenvolvimento, por meio de
              atualizações e pode conter erros, dos quais se constatados pelo
              USUÁRIO, poderão ser informados ao BHBUS.
            </p>
            <p>
              8.2. O BHBUS não garante:
              <br />
              <span>
                a. que a operação do BHBUS seja continua e sem defeitos, sendo
                certo que exceto pelo estabelecido neste documento, não existem
                outras garantias, condições ou promessas relacionadas ao BHBUS.
              </span>{' '}
              <br />
              <span>
                b. nem declara ou assegura que o seu uso será ininterrupto ou
                livre de erros podendo o USUÁRIO, a qualquer tempo, remover ou
                deixar de usar o BHBUS, sem qualquer penalidade para ambas as
                partes.
              </span>
              <br />
              <span>
                {' '}
                c. nem declara nem assegura que este BHBUS esteja livre de
                perda, interrupção, ataque, vírus, interferência, pirataria ou
                invasão de segurança e isenta-se de qualquer responsabilidade em
                relação a essas questões.
              </span>
            </p>
            <h1 className="title">9. POLÍTICA DE ARREPENDIMENTO</h1>
            <p>
              Nosso compromisso é sua total satisfação nas compras realizadas
              através do Site e Aplicativo BHBUS. Visando a uma parceria de
              respeito e confiança criamos a Política de Arrependimento.
            </p>
            <p>
              9.1. Para evitar qualquer problema com a sua compra, ao realizar o
              pedido, fique atento:
              <br />
              <span>a. Digitação correta do número do cartão BHBUS;</span>{' '}
              <br />
              <span>
                b. Valor correto da recarga e dados corretos na informação de
                cartão de crédito como forma de pagamento.
              </span>
              <br />
            </p>
            <p>
              9.2. <strong>Arrependimento</strong>
            </p>
            <p>
              De acordo com o artigo 49 do Código de Defesa do Consumidor e na
              Lei 23.642/2020, nas compras realizadas fora do estabelecimento
              comercial, o consumidor tem até 07 (sete) dias corridos para se
              arrepender da compra e comunicar a desistência ao fornecedor.
            </p>
            <p>
              As solicitações de desistência da compra serão acatadas desde que:
              <br />
              <span>
                a. sejam efetuadas por meio de contato com o Serviço de
                Atendimento ao Cliente, através do telefone Whatsapp 3248-7300,
                com horario de atendimento de{' '}
                <strong>
                  Segunda-feira a Sexta-feira de 07h00min as 20h00min
                </strong>{' '}
                e de{' '}
                <strong>
                  08h00min as 14h00min aos Sábados, Domingos e Feriados
                </strong>{' '}
                ou <strong>através do email sac@transfacil.com.br</strong>, em
                até 07 (sete) dias corridos após a compra do produto;
              </span>{' '}
              <br />
              <span>
                b. os créditos eletrônicos debitados no cartão BHBUS do USUÁRIO
                provenientes do pedido realizado no Site ou Aplicativo BHBUS{' '}
                <strong>
                  {' '}
                  não tenham sido utilizados total ou parcialmente.
                </strong>
              </span>
              <br />
            </p>
            <p>
              <strong>Importante:</strong> Na hipótese dos requisitos acima não
              serem preenchidos, o TRANSFÁCIL não estará obrigado a aceitar o
              cancelamento da compra.
            </p>
            <p>
              O direito de arrependimento leva à anulação da compra e, assim
              como o consumidor tem direito à restituição dos valores pagos, o
              fornecedor tem o direito de receber o produto/serviço intacto, em
              condições de ser novamente vendido, não sendo admitido qualquer
              indício de uso ou avaria do produto/serviço de seus componentes ou
              de suas embalagens.
            </p>
            <p>
              9.3 <strong>Ressarcimento de valores</strong>
            </p>
            <p>
              O valor pago pelo cliente pelo produto/serviço será devolvido de
              acordo com a forma de pagamento utilizada na compra, como
              explicado abaixo e desde que observadas as demais condições
              aplicáveis ao caso descritas nesta política.
            </p>
            <p>
              <span>
                a. No caso de pagamento com <strong>Boleto Bancário:</strong>
                <br />A devolução dos valores será realizada por meio de
                disponibilização do valor correspondente, através de depósito
                bancário na conta fornecida pelo USUÁRIO, no menor prazo
                possível, devendo ser efetuada em até 10 (dez) dias úteis.
              </span>{' '}
              <br />
              <span>
                b. No caso de pagamento com <strong>Cartão de Crédito:</strong>{' '}
                <br />A devolução dos valores será realizada por meio de estorno
                junto a Operadora de Cartão de Crédito. A administradora do
                cartão será notificada e o estorno ocorrerá na fatura seguinte
                ou na posterior de uma só vez. O prazo de ressarcimento depende
                da administradora do cartão.
              </span>{' '}
              <br />
              <span>
                c. No caso de pagamento com <strong>PIX:</strong>
                <br />A devolução dos valores será realizada por meio de
                disponibilização do valor correspondente, através de depósito
                bancário na conta fornecida pelo USUÁRIO, no menor prazo
                possível, devendo ser efetuada em até 10 (dez) dias úteis.
              </span>
              <br />
            </p>
            <p>
              9.4. Casos de arrependimento deverão ser tratados no TRANSFÁCIL
              através do telefone Whatsapp 3248-7300 de Segunda-feira a
              Sexta-feira de 07h às 20h e de 08h às 14h aos Sábados, Domingos e
              Feriados, ou através do e-mail: sac@transfacil.com.br, a quem
              compete no caso a caso avaliar a pertinência de eventual
              devolução.
            </p>
            <h1 className="title">10. DISPOSIÇÕES FINAIS</h1>
            <p>
              10.1. O USUÁRIO poderá esclarecer dúvidas, pedir informações ou
              registrar reclamações através do telefone 3248-7300 de
              Segunda-feira a Sexta-feira de 07h as 20h e de 08h às 14h aos
              Sábados, Domingos e Feriados, ou através do e-mail:
              sac@transfacil.com.br.
            </p>
            <p>
              10.2. Até o limite permitido por lei, o BHBUS é disponibilizado
              tal como se encontra, cabendo ao USUÁRIO utilizá-lo ou não, sem
              garantias não previstas nestes Termos de qualquer tipo, sejam
              explícitas ou implícitas, inclusive de atendimento às expectativas
              do USUÁRIO com relação aos serviços prestados.
            </p>
            <p>
              10.3. O BHBUS não fornecerá atendimento ao USUÁRIO acerca do uso
              de dispositivos, bem como em casos de danos causados por vírus,
              worms, cavalos de troia, hackers, crackers ou qualquer
              contaminação semelhante ou programa destrutivo que interfira no
              uso regular do BHBUS pelo USUÁRIO.
            </p>
            <p>
              10.4. O BHBUS se reserva o direito de fazer quaisquer alterações
              no BHBUS que julgue necessárias, para remover, modificar, editar
              ou limitar o alcance e funcionalidade deste, de modo a aprimorar a
              qualidade e desempenho. O trabalho de manutenção pode limitar e/ou
              interromper parcialmente a utilização do BHBUS, e em determinadas
              circunstâncias, inclusive perda de dados. O BHBUS, portanto, não
              presume garantia pela disponibilidade do serviço ou falta de
              inconveniência técnica ou perda de dados.
            </p>
            <p>
              10.5. Todas as comunicações que consistem em avisos e mensagens
              push no BHBUS ou enviados durante o curso do serviço, que têm como
              objetivo informar os USUÁRIOS sobre determinadas questões ou
              realizar orientações, serão consideradas como tendo sido
              validamente recebidas e atendidas pelo USUÁRIO.
            </p>
            <p>
              10.6. Todas as questões envolvendo o BHBUS e o USUÁRIO serão
              regidas pelas leis da República Federativa do Brasil, sendo eleito
              o Foro da Comarca de Belo Horizonte, Estado de Minas Gerais, como
              o único competente para dirimir quaisquer litígios e/ou demandas
              que venham a envolver as Partes em relação aos serviços prestados.
            </p>
            <p>
              <strong>Belo Horizonte/MG, 23 de janeiro de 2020.</strong>
            </p>
            <p>
              CONSÓRCIO OP. DO TRANSPORTE COLETIVO DE PASSAGEIROS POR ÔNIBUS DO
              MUNICÍPIO DE BELO HORIZONTE
              <br /> CNPJ: 04.398.505/0001-07
              <br /> Rua Aquiles Lobo 504 - Floresta - BH - CEP: 30150-160
              <br /> SAC: 31 3248.7300
            </p>
          </Main>
        </Content>
      </Container>
      <Container>
        <Footer />
      </Container>
    </>
  );
};

export default TermosDeUso;
