/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import * as React from 'react';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import Button from '../Button';
import Modal from '../Modal';
import { DivPrincipal } from './style';

const ButtonDeleteUser: React.FC<any> = () => {
	const [show, setShow] = React.useState<boolean>(false)
	const { signOut } = useAuth();
	return (
		<DivPrincipal>
			<div className='botao'>
				<Button
					color="gray-line"
					padding="3.6"
					className="button-transparentbtn-cancelar"
					rounded
					onClick={() => setShow(!show)}
				>
					CANCELAR CONTA
				</Button>
			</div>
			{show && (
        <Modal
					style={{
						height: 200,
						width: 700,
					}}
          title="Deseja realmente realizar essa ação ?"
          handleClose={() => setShow(!show)}
          show={show}
					action={(
						<div 
							style={{ 
								display: 'flex',
								flexDirection: 'row',
								width: '100%',
								justifyContent: 'flex-end',
							}}
						>
							<Button
								onClick={async () => {
									await api.delete('/accounts/profile/')
									await signOut()
									setShow(!show)
								}}
								color="orange"
								padding="6"
								className="button"
								rounded
							>
								CONFIRMAR
							</Button>
						</div>
					)}
        >
          <div>
            <p style={{ fontSize: 14, fontWeight: 'normal' }}>
							Após clicar em sim esta ação não poderá ser desfeita.
            </p>
          </div>
        </Modal>
      )}
		</DivPrincipal>
	)
}

export { ButtonDeleteUser };
