import styled from 'styled-components';

export const FlexContainer = styled.div`
  min-height: 100vh;
  margin-bottom: 6rem;

  h1 {
    color: var(--purple);
  }
  h2 {
    margin-top: 20px;
  }
  span {
    display: block;
  }
  p {
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .text-wrapper {
    padding-top: 30px;
  }
  .btn-wrapper {
    margin-top: 20px;
  }
  .btn-wrapper2 {
    margin-top: 10px;
  }

  @media (max-width: 650px) {
    flex-wrap: wrap;
    width: 90%;
  }
`;

export const FormWrapper = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;

  .button {
    width: 100%;
    margin-top: 25px;
  }

  @media (max-width: 650px) {
    flex-wrap: wrap;
    width: 100%;
  }
`;
