/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useAuth } from '../../hooks/auth';

import { useToast } from '../../hooks/toast';
import api from '../../services/api';

import Input from '../../components/Input';
import Button from '../../components/Button';
import logo from '../../assets/Shared/logo-v2.png';
import { isValidCpf } from '../../utils/isValidCpf';
import getValidationErrors from '../../utils/getValidationErrors';

import { Container, Content } from './styles';
import { CheckBoxPrivacyPolicy } from '../../components/CheckBoxPrivacyPolicy';

const Cadastrar: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { user } = useAuth();
  const { push } = useHistory();

  useEffect(() => {
    if (user) {
      push('/dashboard');
    } else {
      push('/cadastrar');
    }
  }, [push, user]);

  const schema = Yup.object().shape({
    full_name: Yup.string().required('Nome é obrigatório'),
    phone: Yup.string()
      .required('Por favor preencha o número de telefone')
      .min(14, 'Por favor preencha o número de telefone'),
    cpf: Yup.string()
      .test('cpf-validation', 'CPF Inválido', value => {
        const isValid = isValidCpf(value);
        return isValid;
      })
      .required('Por favor preencha o cpf'),
    // birth_date: Yup.string().matches(
    //   /^\d{2}\/\d{2}\/\d{4}$/,
    //   'Por favor preencha a data de nascimento',
    // ),
    email: Yup.string()
      .required('E-mail é obrigatório')
      .email('Digite um email válido'),
    password: Yup.string().required('Senha é obrigatória'),
    confirm_password: Yup.string().required('Senha é obrigatória'),
    privacy_policy: Yup.string().required(
      'Política de Privacidade é obrigatória',
    ),
  });

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        await schema.validate(data, {
          abortEarly: false,
        });
        await api.post('/accounts/register/', data);
        // await api.post('/accounts/user-accepted-lgpd/', {
        //   privacy_policy: data?.privacy_policy,
        // });

        addToast({
          type: 'success',
          title: 'Cadastro Finalizado',
          description: 'Usuário cadastrado com sucesso!!',
        });

        push('/');
      } catch (err: any) {
        const { ...error } = err;

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        if (error.response.data.message) {
          addToast({
            type: 'error',
            title: `${error.response.data.message}`,
            description: `${error.response.data.sub_message}`,
          });
        }

        // if (error.response.data.birth_date) {
        //   addToast({
        //     type: 'error',
        //     title: `${error.response.data.birth_date}`,
        //     description: 'Verifique as informações digitadas.',
        //   });
        // }

        if (error.response.data.email) {
          addToast({
            type: 'error',
            title: `${error.response.data.email}`,
            description:
              'Verifique as informações adicionadas ou entre em contato com o Administrador.',
          });
        }
      }
    },
    [schema, addToast, push],
  );

  return (
    <Container>
      <Content>
        <a href="https://transfacil.com.br">
          <img src={logo} alt="Transfacil" />
        </a>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h1>Cadastrar</h1>
          <p>
            <strong>
              Como você quer ser chamado(a)? <span className="alert"> *</span>
            </strong>
          </p>
          <Input
            name="full_name"
            className="name"
            placeholder="Como você quer ser chamado(a)?"
            schema={schema}
          />
          <p>
            <strong>
              Telefone <span className="alert"> *</span>
            </strong>
          </p>
          <Input
            phone
            name="phone"
            className="phone"
            placeholder="(xx) xxxxx-xxxx"
            schema={schema}
          />
          <p>
            <strong>
              CPF <span className="alert"> *</span>
            </strong>
          </p>
          <Input
            cpf
            name="cpf"
            className="cpf"
            placeholder="xxx.xxx.xxx-xx"
            schema={schema}
          />
          {/* <p>
            <strong>
              Data de nascimento <span className="alert"> *</span>
            </strong>
          </p> */}
          {/* <Input
            birth_date
            name="birth_date"
            className="birth_date"
            placeholder="xx/xx/xxxx"
            schema={schema}
          /> */}
          <p>
            <strong>
              E-mail <span className="alert"> *</span>
            </strong>
          </p>
          <Input
            name="email"
            className="email"
            placeholder="Digite seu e-mail"
            schema={schema}
          />
          <p>
            <strong>
              Senha <span className="alert"> *</span>
            </strong>
          </p>
          <Input
            name="password"
            className="password"
            type="password"
            placeholder="Senha"
            schema={schema}
          />
          <p>
            <strong>
              Confirme sua senha <span className="alert"> *</span>
            </strong>
          </p>
          <Input
            name="confirm_password"
            className="password"
            type="password"
            placeholder="Confirmar senha"
            schema={schema}
          />
          <CheckBoxPrivacyPolicy />
          <Button color="orange" padding="5" type="submit">
            Cadastrar
          </Button>
          <Link to="/">Voltar</Link>
        </Form>
      </Content>
    </Container>
  );
};

export default Cadastrar;
