/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/require-default-props */
import {
  useEffect,
  useState,
  useRef,
  InputHTMLAttributes,
  RefObject,
} from 'react';

import { useField } from '@unform/core';

import {
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  StyledCheckbox2,
  Text,
} from './styles';

/**
 * This is a Radio component that supports rendering multiple options.
 *
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/radio
 */
interface Props {
  name: string;
  label?: string;
  options: {
    id: string;
    value: string;
    label: string;
  }[];
}

type RefInputEl = RefObject<HTMLInputElement[]>;

type InputProps = InputHTMLAttributes<HTMLInputElement> & Props;

export function RadioButton({ name, options, ...rest }: InputProps) {
  const inputRefs = useRef([]);
  const { fieldName, registerField, defaultValue = '', error } = useField(name);
  const [checked, setChecked] = useState(undefined);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs,
      getValue: (refs: RefInputEl) => {
        return refs.current.find(input => input?.checked)?.value;
      },
      setValue: (refs: RefInputEl, id: string) => {
        const inputRef = refs.current.find(ref => ref.id === id);
        if (inputRef) inputRef.checked = true;
        if (inputRef) setChecked(inputRef.value);
      },
      clearValue: (refs: RefInputEl) => {
        const inputRef = refs.current.find(ref => ref.checked === true);
        if (inputRef) inputRef.checked = false;
      },
    });
  }, [fieldName, registerField]);
  function handleCheckboxChange(e: any) {
    setChecked(e.target.value);
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '70px',
      }}
    >
      {options.map((option, index) => (
        <CheckboxContainer key={option.id} checked={option.value === checked}>
          <StyledCheckbox2 checked={option.value === checked}>
            <StyledCheckbox checked={option.value === checked} />
            <HiddenCheckbox
              type="radio"
              ref={ref => {
                inputRefs.current[index] = ref;
              }}
              id={option.id}
              name={name}
              defaultChecked={defaultValue.includes(option.id)}
              value={option.value}
              {...rest}
              onChange={handleCheckboxChange}
            />
          </StyledCheckbox2>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Text style={{ fontWeight: 'bold' }}>{option.label} </Text>
            <Text>{option.value}</Text>
          </div>
        </CheckboxContainer>
      ))}

      {error && <span>{error}</span>}
    </div>
  );
}
