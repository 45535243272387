import styled from 'styled-components';

export const TitleSection = styled.section`
  height: auto;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  color: var(--white);
  min-height: 450px;
  padding-top: 70px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    align-items: center;
  }

  img {
    float: left;
    margin-right: 30px;
    width: 350px;
    height: 350px;
    margin-top: -2rem;
    @media (max-width: 768px) {
      margin-top: 1.5rem;
      margin-right: 0;
    }
    @media (max-width: 450px) {
      width: 250px;
      height: 250px;
    }
  }
  h1 {
    color: var(--white);
    margin-bottom: 2rem;
    margin-top: 3rem;
    @media (max-width: 768px) {
      margin-top: 2rem;
      padding-bottom: 1.5rem;
      text-align: center;
    }
  }
`;

export const GridSection = styled.section`
  min-height: 100vh;
  padding-top: 50px;
  margin-bottom: 5rem;
  padding-bottom: 5rem;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    align-items: center;
  }

  div {
    text-align: justify;
    font-size: 16px;
  }

  h2 {
    color: var(--purple);
    margin-bottom: 15px;
  }
`;
