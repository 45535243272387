import React from 'react';

import { TitleSection, Main } from './styles';
import { Container, Content, Blur } from '../../styles/components';

import Header from '../../components/Home/Header';
import NavBar from '../../components/Home/NavBar';
import Footer from '../../components/Home/Footer';

const PoliticaCookies: React.FC = () => {
  return (
    <>
      <Container>
        <Content>
          <Header />
        </Content>
      </Container>
      <Container backgroundImage>
        <Blur>
          <Content className="content">
            <NavBar />
            <TitleSection>
              <h1 className="h1">Política de Cookies</h1>
            </TitleSection>
          </Content>
        </Blur>
      </Container>
      <Container>
        <Content>
          <Main>
            <h2>Emissão em 27 de julho de 2021</h2>
            <p>
              Quando você usa nossos sites, aplicativos para telefone móvel e
              serviços online (o &quot;Site&quot;), podemos armazenar cookies em
              seu navegador.Outros sites de terceiros que você decida acessar
              poderão fazer o mesmo.Esta Política deCookiesexplica o que é um
              cookie, como você pode gerenciar seus cookies e que tipos de
              cookies poderão ser capturados em nossos Sites.
            </p>
            <h1 className="title">O que é um cookie e por que o usamos?</h1>
            <p>
              Cookies são pequenos pedaços de texto. Eles são fornecidos pela
              maioriados sites e armazenados por seu navegador dawebno
              computador, telefoneou outro dispositivo que você estiver
              utilizando. Oscookiesservem a muitospropósitos. Eles podem ajudar
              umsitea lembrar suas preferências, a entenderque áreas são úteis e
              que áreas precisam ser aprimoradas, e podem lheoferecer anúncios
              direcionados ou conteúdo personalizado. Às vezes,oscookiessão
              ativados quandopixelssão colocados em um site. Os pixelstambém são
              chamados dewebbeacons,clear gifsetags. Eles permitem aos sites ler
              e colocar cookies.
            </p>
            <h1 className="title">Cookies primários e cookies de terceiros</h1>
            <p>
              Os cookies podem ser primários ou de terceiros. Um cookie primário
              é aquele que você recebe diretamente do Transfácil ao visitar um
              de nossos Sites.
            </p>
            <p>
              Um cookie de terceiros é aquele que você recebeu de uma outra
              parte, como por exemplo o Google ou o Facebook. Não controlamos o
              que terceiros fazem em outros sites. Entretanto, poderemos
              trabalhar com determinados provedores terceirizados, como o Google
              ou o Facebook, para permitir queseus cookies funcionem através de
              nosso Site, assim poderemos aprender mais sobre sua experiência em
              nosso Site e personalizar melhor nossos serviços para você.
            </p>
            <h1 className="title">Cookies persistentes e cookies de sessão</h1>
            <p>
              Um cookie persistente é um cookie armazenado pelo navegador da web
              emseu dispositivo até que expire ou que você o elimine. A
              expiração de um cookie persistente é determinada pelo criador do
              cookiee pode ocorrer em uma determinada data ou após a passagem de
              um tempo da sessão. Isso significa que, durante toda a vida útil
              do cookie, suas informações serão transmitidas ao servidor do
              criador toda vez que o usuário visitar o site ao qual ele pertence
              ou outros site configurado para verificar esse cookie(como um
              anúncio colocado nesse site). Por esse motivo, os
              cookiespersistentes também são chamados de &quot;cookies de
              rastreamento&quot;.
            </p>
            <p>
              Um cookie de sessão é criado temporariamente em seu dispositivo
              para serusado por um site durante sua visita. Esse tipo de cookie
              poderá armazenar informações inseridas e rastrear sua atividade no
              site. Um cookie de sessão é eliminado depois que você sai do site
              ou quando o navegador é fechado. Um bom exemplo de cookie de
              sessão é o carrinho de compras em um site de comércio eletrônico.
              O cookie de sessão armazena os itens que você adicionaao carrinho
              para que não sejam esquecidos enquanto você visualiza produtos em
              outras páginas do site. Usando um cookie de sessão, todos os itens
              estarão no carrinho quando você for para a página de checkout.
            </p>
            <h1 className="title">Como gerencio cookies?</h1>
            <p>
              A maioria dos navegadores permite que você escolha se aceita
              oscookies. Amaioria também permite eliminar oscookiesjá definidos.
              As opções disponíveise o mecanismo usado variam de navegador para
              navegador. Essasconfigurações do navegador são normalmente
              encontradas no menu &quot;opções&quot;,&quot;ferramentas&quot; ou
              &quot;preferências&quot;. Você também poderá consultar o menu
              &quot;ajuda&quot; do navegador. Por exemplo:
            </p>
            <p>
              <a href="https://support.microsoft.com/pt-br/windows/excluir-e-gerenciar-cookies-168dab11-0753-043d-7c16-ede5947fc64d">
                Configurações de cookies no Internet Explorer
              </a>
            </p>
            <p>
              <a href="https://support.mozilla.org/pt-BR/kb/cookies-informacoes-sites-armazenam-no-computador">
                Configurações de cookies no Firefox
              </a>
            </p>
            <p>
              <a href="https://support.google.com/accounts/answer/61416?hl=pt-BR">
                Configurações de cookies no Chrome
              </a>
            </p>
            <p>
              <a href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac">
                Configurações de cookies no Safari
              </a>
            </p>
            <p>
              Existem ferramentas disponíveis para limpeza de todos os cookies
              deixados pelos sites que você visitou. Geralmente, a eliminação de
              cookies torna anônimas as informações associadas ao pixel e o site
              não receberá mais nenhuma informação associada.
            </p>
            <h1 className="title">Que tipos de cookies usamos?</h1>
            <p>
              O Site poderá usar Cookies Estritamente Necessários, Cookies de
              Desempenho, Cookies Funcionais, Cookies de Segmentação e Cookies
              de Mídia Social.
            </p>
            <p>
              Quaisquer desses cookies poderão ser cookies primários ou cookies
              de terceiros, persistentes ou de sessão. Consulte o item &quot;
              Como Gerencio Cookies&quot; desta Política de Cookies para obter
              informações sobre como gerenciar essescookies através do seu
              navegador.
            </p>
            <p>
              <ul>
                <li>
                  Cookies Estritamente Necessários: São cookies sem os quais
                  você não seria capaz de usar o Site. Por exemplo, os Cookies
                  Estritamente Necessários ajustam os dados do Site transmitidos
                  para que correspondam à sua conexão com a internet, levam você
                  às versões seguras do Site e ajudam a oferecer serviços que
                  você solicita especificamente. Se você configurar seu
                  navegador para bloquear esses cookies,algumas partes do Site
                  não funcionarão.Cookies Estritamente Necessários não armazenam
                  DadosPessoais.
                </li>
              </ul>
            </p>
            <p>
              <ul>
                <li>
                  Cookies de Desempenho: Usamos esses cookies para contar
                  visitas e fontes de tráfego, para medir e melhorar o
                  desempenho do Site. Eles nos ajudam a saber quais são as
                  páginas mais e menos populares, e a ver como os visitantes se
                  movem pelo Site. Os Cookies de Desempenho não armazenam Dados
                  Pessoais.
                </li>
              </ul>
            </p>
            <p>
              <ul>
                <li>
                  Cookies Funcionais: Esses cookies permitem que o Site se
                  lembre das escolhas que você faz e oferecem funcionalidades
                  aprimoradas e recursos mais personalizados. Dependendo do
                  contexto,os Cookies Funcionais poderão armazenar certos tipos
                  de DadosPessoais, conforme necessário para oferecer
                  funcionalidade.
                </li>
              </ul>
            </p>
            <p>
              <ul>
                <li>
                  Cookies de Segmentação: Os Cookies de Segmentação nos ajudam a
                  gerenciar e exibir nossos anúncios, com base em sua atividade
                  no Site e em outros sites. Esta atividade é conhecida como
                  publicidade baseada em interesses. Os Cookies de Segmentação
                  se baseiam principalmente na identificação exclusiva do seu
                  navegador e dispositivo de internet.Optar por não receber
                  publicidade baseada em interesses não significa que você não
                  verá mais publicidade online, mas que as empresas que você
                  desabilitou não exibirão mais anúncios que foram adaptados aos
                  seus interesses.
                </li>
              </ul>
            </p>
            <p>
              <ul>
                <li>
                  Cookies de Mídia Social:Os Cookies de Mídia Social facilitam o
                  compartilhamento em redes sociais, fornecem ferramentas para
                  que você se conecte ao Sitee nos ajudam a entender melhor
                  tanto o público-alvo do Site como a eficácia de nossa
                  divulgação nas mídias sociais.Estes são cookies de terceiros.
                  Suas escolhas em relação aesses cookies são determinadas pelas
                  plataformas de mídia social nas quais você tem conta.
                </li>
              </ul>
            </p>
            <h1 className="title">Atualizações a essa Política de Cookies</h1>
            <p>
              Poderemos alterar esta Política de Cookies a qualquer momento.
              Quaisquer alterações nesta Política de Cookies entrarão em vigor
              quando a Políticade Cookies revisada estiver disponível no ou
              através do Site. Tais atualizações serão devidamente divulgadas
              pelo Transfácil.
            </p>
            <h1 className="title">Política de Privacidade de Dados Pessoais</h1>
            <p>
              Caso você tenha interesse em entender como o Transfácil realiza o
              tratamentodos Dados Pessoais capturados seja através de Cookies ou
              não, acesse anossa Política de Privacidade.
            </p>
          </Main>
        </Content>
      </Container>
      <Container>
        <Footer />
      </Container>
    </>
  );
};

export default PoliticaCookies;
