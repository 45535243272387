/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-empty-interface */
import * as React from 'react';
import Modal from '../../Modal';
import { Content } from './Content';

interface IModalGerarTicketQrCode {
  title: string;
  handleClose: () => void;
  show: boolean;
}

const ModalGerarTicketQrCode: React.FC<IModalGerarTicketQrCode> = ({
  title,
  handleClose,
  show,
}) => {
  return (
    <Modal title={title} handleClose={handleClose} show={show}>
      <Content handleClose={handleClose} />
    </Modal>
  );
};

export { ModalGerarTicketQrCode };
