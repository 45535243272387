import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  *{
    margin:0;
    padding:0;
    outline:0;
    box-sizing:border-box;
    -webkit-font-smoothing: antialiased;

  }

  :root{
    --white:#ffffff;
    --background-gray:#F2F5F7;
    --gray-line: #C2D1D9;
    --orange: #dc582a;
    --aside-background:#EBF1F4;
    --soft-orange:#d9856e;
    --orange-lightdark:#b34a1f;
    --orange-dark:#873812;
    --orange-gradient:linear-gradient(180deg, #F79765 0%, #dc582a 100%); 
    --purple: #472b74;
    --purple-light: #4d3873;
    --purple-lightdark:#3d2463;
    --purple-dark:#2e1a4a;
    --purple2:#4C1D6B;
    --purple3:#4D1D6E;
    --text:#7E8B92;
    --title-higlight:#505D68;
    --red:#E83f5b;
    --green:#AAC22B;
    --transparent:transparent;
    --box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  @media(max-width: 1080px){
    html {
      font-size:93.75%;
    }
  }

  @media(max-width: 720px){
    html {
      font-size:81.45%;
    }
  }

  body{
    background:var(--white);
    color:var(--text);
    -webkit-font-smoothing: antialiased;
  }

  body,input,text-area,button{
    font: 400 1rem "Source Sans Pro", sans-serif;
  }

  button {
    cursor:pointer;
    border:0;
  }

  a{
    text-decoration:none;
    color:inherit;
  }

  h1{
    font: 700 2rem "Roboto Slab", sans-serif;
  }
`;
