import React from 'react';
import Lottie from 'react-lottie';
import LoaderAnimation from '../../../assets/loader.json';

const Loader: React.FC = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoaderAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '93%',
        background: 'white',
        zIndex: 9999,
        margin: '-14px',
      }}
    >
      <Lottie
        height={150}
        width={150}
        style={{
          position: 'absolute',
          zIndex: 9999,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        options={defaultOptions}
        isStopped={false}
        isPaused={false}
      />
    </div>
  );
};

export default Loader;
