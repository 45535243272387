import React from 'react';
import { FiX } from 'react-icons/fi';
import { FaCheckCircle } from 'react-icons/fa';

import { Container } from './styles';

interface ModalInterface {
  handleClose(): void;
  show: boolean;
  typeModal?: 'success' | 'error';
  success?: boolean;
  successText: string;
}

const Modal: React.FC<ModalInterface> = ({
  handleClose,
  show,
  success,
  successText,
  children,
  typeModal,
}) => (
  <Container show={show} success={success} typeModal={typeModal}>
    <section className="modal-main">
      <>
        {typeModal === 'success' ? (
          <FaCheckCircle color="#ffffff" size={86} />
        ) : (
          <FiX color="red" size={86} />
        )}
        <p>{successText}</p>
        <p>{children}</p>
        <button type="button" className="close-container" onClick={handleClose}>
          <FiX size={25} onClick={handleClose} />
        </button>
      </>
    </section>
  </Container>
);

export default Modal;
