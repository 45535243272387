/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  InputHTMLAttributes,
  useRef,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';
import * as Yup from 'yup';
import { Container, Content, Error, Label } from './styles';

interface InputOutlinedProps
  extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  label?: string;
  name: string;
  schema?: Yup.ObjectSchema;
  rounded?: boolean;
  cpf?: boolean;
  phone?: boolean;
  card_number?: boolean;
  creditCardName?: boolean;
  cvv?: boolean;
  cep?: boolean;
  dateMMYY?: boolean;
  multiline?: boolean;
  placeholder?: string;
  icon?: React.ComponentType<IconBaseProps>;
}

const InputOutlined: React.FC<InputOutlinedProps> = ({
  name,
  rounded,
  schema,
  cpf,
  phone,
  card_number,
  creditCardName,
  cvv,
  cep,
  dateMMYY,
  icon: Icon,
  children,
  multiline,
  placeholder,
  ...rest
}) => {
  const inputRef = useRef<any>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [placeholderText, setPlaceholderText] = useState(placeholder);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setPlaceholderText('');
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setPlaceholderText(placeholder);
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  }, [placeholder]);

  const handleChange = useCallback(async () => {
    try {
      const isValid = await Yup.reach(schema, fieldName).validate(
        inputRef.current?.value,
        { abortEarly: true },
      );
      setIsCorrect(!!isValid);
    } catch (err) {
      setIsCorrect(false);
    }
  }, [schema, fieldName]);

  const handleKeyUpCpf = useCallback(
    (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.currentTarget.maxLength = 14;
      let { value } = e.currentTarget;
      value = value.replace(/\D/g, '');
      value = value.replace(/(\d{3})(\d)/, '$1.$2');
      value = value.replace(/(\d{3})(\d)/, '$1.$2');
      value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
      e.currentTarget.value = value;
    },
    [],
  );

  const handleKeyUpDate = useCallback(
    (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.currentTarget.maxLength = 5;
      let { value } = e.currentTarget;
      value = value.replace(/\D/g, '');
      value = value.replace(/(\d{2})(\d)/, '$1/$2');
      e.currentTarget.value = value;
    },
    [],
  );

  const handleKeyUpPhone = useCallback(
    (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.currentTarget.maxLength = 15;
      let { value } = e.currentTarget;
      value = value.replace(/\D/g, '');
      value = value.replace(/^(\d{2})(\d)/g, '($1) $2');
      value = value.replace(/(\d)(\d{4})$/, '$1-$2');
      e.currentTarget.value = value;
    },
    [],
  );

  const handleKeyUpCardNumber = useCallback(
    (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.currentTarget.maxLength = 19;
      let { value } = e.currentTarget;
      value = value.replace(/\D/g, '');
      value = value.replace(/(\d{4})/g, '$1 ');
      value = value.replace(/\.$/, '');
      e.currentTarget.value = value;
    },
    [],
  );

  const handleKeyUpCreditCardName = useCallback(
    (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.currentTarget.maxLength = 90;
      let { value } = e.currentTarget;
      value = value.toUpperCase();
      value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      value = value.replace(
        /[0-9-=_@#$%¨&*+()<>{}^[\\~´`'"|./°ºª§;,:?!>]/g,
        '',
      );
      e.currentTarget.value = value;
    },
    [],
  );

  const handleKeyUpCVV = useCallback(
    (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.currentTarget.maxLength = 3;
      let { value } = e.currentTarget;
      value = value.replace(/\D/g, '');
      e.currentTarget.value = value;
    },
    [],
  );

  const handleKeyUpCep = useCallback(
    (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.currentTarget.maxLength = 9;
      let { value } = e.currentTarget;
      value = value.replace(/\D/g, '');
      value = value.replace(/^(\d{5})(\d)/, '$1-$2');
      e.currentTarget.value = value;
    },
    [],
  );

  const getHandleKeyUp = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    let handleFunction;
    if (cpf) handleFunction = handleKeyUpCpf(e);
    if (phone) handleFunction = handleKeyUpPhone(e);
    if (card_number) handleFunction = handleKeyUpCardNumber(e);
    if (creditCardName) handleFunction = handleKeyUpCreditCardName(e);
    if (cvv) handleFunction = handleKeyUpCVV(e);
    if (cep) handleFunction = handleKeyUpCep(e);
    if (dateMMYY) handleFunction = handleKeyUpDate(e);
    return handleFunction;
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container isFocused={isFocused} isFilled={isFilled}>
      <Label htmlFor={name}>{placeholder}</Label>
      <Content
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        isCorrect={isCorrect}
        rounded={rounded}
        multiline={multiline}
      >
        {multiline && (
          <textarea
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onChange={handleChange}
            onKeyUp={e => getHandleKeyUp(e)}
            defaultValue={defaultValue}
            ref={inputRef}
            {...rest}
            placeholder={placeholderText}
          />
        )}

        {!multiline && (
          <input
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onChange={handleChange}
            onKeyUp={e => getHandleKeyUp(e)}
            defaultValue={defaultValue}
            ref={inputRef}
            placeholder={placeholderText}
            {...rest}
          />
        )}

        {children}
        {Icon && <Icon size={20} />}
      </Content>
      {!!error && <Error>{error}</Error>}
    </Container>
  );
};

export default InputOutlined;
