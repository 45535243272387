/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import Button from '../Button';

import { Containers, Sliders } from './styles';
import { Container, Content } from '../../styles/components';

import CoreService, { Banner } from '../../services/core';

const Slide: React.FC = () => {
  const services = { coreService: new CoreService() };
  const [banners, setBanner] = useState<Banner[]>([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 5000,
    fade: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          fade: false,
        },
      },
    ],
  };

  useEffect(() => {
    async function getNews() {
      try {
        const response = await services.coreService.getBanners();
        setBanner(response.data.results);
        console.log(response.data.results);
      } catch (err) {
        console.error(err);
      }
    }

    getNews();
  }, []);

  const handleLink = (link: string) => {
    window.open(link, '_blank');
  };

  return (
    <Container>
      <Sliders>
        <Slider className="slides" {...settings}>
          {banners.map(banner => (
            <>
              <Container
                key={banner.id}
                style={{
                  marginLeft: '-1px',
                  backgroundImage: `url(${banner.backgroundImage})`,
                  backgroundColor: `${banner.backgroundCor}`,
                }}
              >
                <Content>
                  <Containers>
                    <div className="flex">
                      {banner.imagePromotion && (
                        <a
                          href={banner.media_link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={banner.imagePromotion}
                            alt="Transfacil"
                            className="img-slider"
                          />
                        </a>
                      )}
                      <a href={banner.media_link} target="blank">
                        {banner.backgroundCor === '#ffffff' && (
                          <div className="text">
                            <h1
                              className="title"
                              style={{
                                color: 'var(--purple)',
                              }}
                            >
                              {`${banner.title}`}
                            </h1>
                            <p
                              className="description"
                              style={{
                                color: 'var(--purple)',
                              }}
                            >
                              {`${banner.subtitle}`}
                            </p>
                            <Button
                              className="button"
                              style={{ background: `${banner.cta_color}` }}
                              color=""
                              padding="1"
                              rounded
                              onClick={() => handleLink(banner.media_link)}
                            >
                              {banner.cta_text}
                            </Button>
                          </div>
                        )}
                        {banner.backgroundCor !== '#ffffff' && (
                          <div className="text">
                            <h1 className="title">{`${banner.title}`}</h1>
                            <p className="description">
                              {`${banner.subtitle}`}
                            </p>
                            <Button
                              className="button"
                              style={{ background: `${banner.cta_color}` }}
                              color=""
                              padding="1"
                              rounded
                              onClick={() => handleLink(banner.media_link)}
                            >
                              {banner.cta_text}
                            </Button>
                          </div>
                        )}
                      </a>
                    </div>
                  </Containers>
                </Content>
              </Container>
            </>
          ))}
        </Slider>
      </Sliders>
    </Container>
  );
};
export default Slide;
