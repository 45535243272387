import styled, { css } from 'styled-components';

interface HamburguerProps {
  active: boolean;
  menuActive?: boolean;
}

export const Container = styled.div`
  position: relative;
  width: 100vw;

  .menu {
    display: none;
    background: #fff;
  }

  .polyline {
    z-index: 9999999999;
  }

  @media (max-width: 1024px) {
    .menu {
      font-size: 30px;
      position: absolute;
      top: 20px;
      right: -9px;
      z-index: 998;
      display: block;
    }
  }

  .info-window-content {
    h4 {
      margin-top: 8px;
      margin-bottom: 8px;
      font-family: 'Roboto', Helvetica, Arial, sans-serif;
      font-size: 18px;
      font-weight: normal;
      line-height: 16px;
      color: #3b393b;
    }

    p {
      font-family: 'Roboto', Helvetica, Arial, sans-serif;
      font-size: 14px;
      font-weight: normal;
      line-height: 16px;
      color: #626162;
    }
  }

  .Routes {
    .destination {
      position: absolute;
      top: 70px;
      z-index: 100;
    }
  }

  .gm-style {
    div > div > div:nth-child(4n) > div:nth-child(4n) {
      display: none;
    }

    div > div > div:nth-child(4n) > div:nth-child(5n) {
      display: none;
    }

    div > div > div:nth-child(4n) > div:nth-child(6n) {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    width: 95%;
    .inputMap {
      width: 100%;
      div {
        width: 100%;
        .combobox {
          width: 90%;
          input {
            width: 100%;
          }
        }
      }
    }
  }
  .SearchMap {
    ${() =>
      css`
        position: absolute;
        z-index: 100;
        top: 15px;
        left: 15px;
      `}
    z-index: 101;
    width: 260px;
    height: 48px;
    padding-right: 16px;
    padding-left: 16px;

    background-color: #fff;
    border: 1px solid #bdbdbd;
    border-radius: 2px;
  }
`;

export const ItensContainer = styled.ul`
  display: none;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    list-style: none;
  }
`;

export const NavItem = styled.li<HamburguerProps>`
  @media (max-width: 1024px) {
    font: 400 1.4rem 'Source Sans Pro', sans-serif;
    line-height: 65px;
    cursor: pointer;
    color: var(--white);
    transition: color 0.3s ease;
    display: ${props => (props.menuActive ? 'block' : 'none')};

    &:hover {
      color: var(--orange);
    }

    ${props =>
      props.active &&
      css`
        font-size: 1.4rem;
        color: var(--orange);
        font-weight: 700;
      `}
  }
`;

export const HamburguerMenuContainer = styled.div<HamburguerProps>`
  display: none;

  .menu {
    display: none;
    background: transparent;
  }

  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    width: 0px;
    height: 100vh;
    background: var(--purple);
    z-index: 999;

    ${props =>
      props.active &&
      css`{
        width: 250px;
        background: var(--orange);
        box-shadow: -1px 5px 8px 3px rgba(0, 0, 0, 0.6);

        .menu {
          display: block;
          position: absolute;
          right: 0;
          top: 15px;

          color: #fff;
          margin: 0 17px;
          
          .one,
          .two,
          .three {
            background: var(--white);
          }
          .one {
            transform: rotate(45deg) translate(7px, 7px);
          }
          .two {
            opacity: 0;
          }
          .three {
            transform: rotate(-45deg) translate(8px, -9px);
          }
        }
      `}
  }
`;
