/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable prettier/prettier */
import styled, { css, keyframes } from 'styled-components';

interface ContainerProps {
  success?: boolean;
  typeModal?: 'success' | 'error';
  show: boolean;
}

const EaseTopDown = keyframes`
  from{ opacity:0;top:0; }
  to{ opacity:1;top:20%;}
`;

const EasyIn = keyframes`
  from{ opacity:0; }
  to{ opacity:1; }
`;

export const Container = styled.div<ContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: none;
  z-index: 100000000000000;
  animation: ${EasyIn} 0.3s;

  .modal-main {
    position: fixed;
    background: #ffffff;
    animation: ${EaseTopDown} 0.4s;
    
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    display: flex;

    width: 60%;
    height: 100%;
    max-height: 80vh;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);

    p {
      a {
        color: #2a41c1;
      }
    }

    display: flex;
    flex-direction: column;
    background: #ffffff;

    animation: ${EasyIn} 0.5s;

    .close-container {
      position: fixed;
      top: -25px;
      right: 0;
      color: var(--title-higlight);
      background: transparent;

      display: flex;
      align-items: center;

      svg {
        margin-top: 4px;
        color: var(--white);

        cursor: pointer;
      }
    }

    p {
      color: var(--title-higlight);
      margin-top: 17px;
    }

    .modal-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--purple);
      height: 65px;
      font: 400 1rem 'Source Sans Pro', sans-serif;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      color: var(--white);

      h1 {
        color: var(--white);
      }

      span {
        margin-right: 13px;
      }

      .close-container {
        position: fixed;
        top: -25px;
        right: 0;

        color: var(--white);
        background: transparent;

        display: flex;
        align-items: center;
        font-size: 25px !important;

        svg {
          margin-top: 4px;
          color: var(--white);

          cursor: pointer;
        }
      }
    }

    .modal-body {
      overflow: auto;
      padding: 15px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      font-size: 25px;
      font-weight: bold;
    }

    .react-select__placeholder {
      font-size: 18px;
      font-weight: 400;
    }

    .react-select__menu {
      font-size: 18px;
      font-weight: 400;
    }

    .react-select__control {
      height: 45px;
    }
  }

  ${props =>
    props.show &&
    css`
      display: block;
    `}
`;
