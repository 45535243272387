import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: left;
  justify-content: flex-start;
  margin-top: 65px;

  img {
    width: 265px;
  }

  .mycards {
    margin-top: -30px;
  }

  @media (max-width: 990px) {
    flex-direction: column;
  }
  @media (max-width: 315px) {
    width: 70%;
  }
`;
