/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';

interface ContactProps {
  isPage?: boolean;
}

export const Main = styled.div<ContactProps>`
  .title {
    color: var(--purple-light);
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 0em;
    margin-bottom: 1rem;

    ${props =>
    // eslint-disable-next-line prettier/prettier
    props.isPage &&
    css`
        margin-top: 3rem;
        margin-bottom: 1.5rem;
        align-items: flex-start;
      `}

    @media (max-width: 768px) {
      flex-wrap: wrap;
      margin-bottom: 9rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  svg {
    color: #424b5a;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: var(--purple-light);
    font-size: 2.5rem;

    @media (max-width: 768px) {
      flex-wrap: wrap;
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
`;

export const GridContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    margin-top: 1rem;
    margin-bottom: 5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const FlexContainer = styled.div<ContactProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 2rem;
  margin-bottom: 8rem;
  text-align: left;
  align-self: flex-start;

    @media (max-width: 768px) {
        margin-right: 0rem;
    }

  .section-contact{
    display: grid;
      @media (max-width: 768px) {
      position: absolute;
      margin-top: 1270px;
      align-items: center;
    }
  }

  .select {
    color: var(--text);
    height: 20px;
    .react-select__indicators {
      margin-top: -6px;
    }
  }
  
  .text {
    margin-left: 15px;
    font-size: 1rem;
  }

  .privacidade {
    margin-top: 30px;
    max-width: 400px;
    @media (max-width: 320px) {
      margin-top: 0px;
      max-width: 250px;
    }
  }
  .privacidadedisabled {
    margin-top: 5px;
    max-width: 400px;
    @media (max-width: 320px) {
      margin-top: 0px;
      max-width: 250px;
    }
    color: red;
    .texto {
      color: black;
    }
  }

  .portalprivacidade {
    @media (max-width: 720px) {
      margin-bottom: 80px;
      margin-top: 0px;
    }
  }

  .lgpd {
    margin-top: 0px;
    @media (max-width: 768px) {
      margin-bottom: 150px;
    }
  }

  p {
    margin-top: 10px;
    margin-bottom: 1rem;
    font-size: 1rem;
  }

  .bairro {
    margin-left: 35px;
    font-size: 1rem;
  }

  h2 {
    align-self: flex-start;
    color: var(--purple-light);
    font-size: 1.5rem;
    margin-bottom: auto;

    @media (max-width: 768px) {
      width: 100%;
      text-align: center;
    }

    ${props =>
    // eslint-disable-next-line prettier/prettier
    props.isPage &&
    css`
        color: var(--title-higlight);
      `}
  }

  span {
    margin-top: 1rem;
    font-size: 1.1rem;
  }

  .button {
    margin-top: 2rem;
    font-size: 15px;
    width: 190px;
    border-radius: 18px;

    @media (max-width: 480px) {
      margin-top: 1rem;
      width: 100%;
    }

    ${props =>
    // eslint-disable-next-line prettier/prettier
    props.isPage &&
    css`
        width: 100%;
        border-radius: 6px;
      `}
  }

  .buttonDisable {
    margin-top: 0.5rem;
    font-size: 15px;
    width: 190px;
    border-radius: 18px;

    @media (max-width: 480px) {
      margin-top: 1rem;
      width: 100%;
    }

    ${props =>
    // eslint-disable-next-line prettier/prettier
    props.isPage &&
    css`
        width: 100%;
        border-radius: 6px;
      `}
  }

  .mensagem {
    margin-top: 1rem;
  }

  textarea {
    font: 400 1rem 'Source Sans Pro', sans-serif;
    border: 1px solid #fafafa;
    background: var(--white);
    color: var(--text);
    border: 1px solid var(--gray-line);
    padding: 0 0 0 16px;
    width: 100%;
    height: 41px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    height: 129px;
  }

  textarea::placeholder {
    color: var(--gray-line);
  }

  .medias {
    margin-top: 1px;
    width: 38%;
    margin-left: 0px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
        width: 150px;
        margin: 24px auto;
        justify-content: center;
        

    }

    img {
      height: 21px;
      padding-right: 3rem;
    }
    @media (max-width: 317px) {
      margin-top: 1rem;
      width: 80%;
      justify-content: center;
      margin-left: 2rem;
    }
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 2rem;
  text-align: left;
  flex-direction: column;
  width: 350px;
  .input-container {
   text-align: center;
  }

  .categoria {
    padding: 1;
  }

  @media (max-width: 768px) {
    margin-top: -120px;
    margin-bottom: 320px;
    margin-right: 0;  
  }
  
  @media (max-width: 427px) {
    margin-top: -120px;
  }

  @media (max-width: 480px) {
    width: 25rem;
  }

  @media (max-width: 437px) {
    width: 22rem;
  }

  @media (max-width: 392px) {
    width: 20rem;
    margin-top: -120px;
  }

  @media (max-width: 364px) {
    width: 18rem;
    margin-top: -110px;
  }

  @media (max-width: 336px) {
    width: 16rem;
  }
`;
