import React from 'react';
import { Container, Content, Blur } from '../../styles/components';
import Header from '../../components/Home/Header';
import Navbar from '../../components/Home/NavBar';
import { TitleSection, GridContainer } from './styles';
import Footer from '../../components/Home/Footer';
import ContactSection from '../../components/ContactSection';

const FaleConosco: React.FC = () => {
  return (
    <>
      <Container>
        <Content>
          <Header />
        </Content>
      </Container>
      <Container backgroundImage className="companies-container">
        <Blur>
          <Content>
            <Navbar />
            <TitleSection>
              <h1>Entre em Contato</h1>
            </TitleSection>
          </Content>
        </Blur>
      </Container>
      <Container>
        <Content>
          <GridContainer>
            <ContactSection />
          </GridContainer>
        </Content>
      </Container>
      <Container>
        <Footer />
      </Container>
    </>
  );
};

export default FaleConosco;
