/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable camelcase */
import api from './api';

interface FavoritesProps {
  code_line: string;
  name_line: string;
  sgl_line: string;
  follow: boolean;
}

class AccountsService {
  getProfile = () => {
    return api.get(`/accounts/profile/`);
  };

  patchProfile = (data: any) => {
    return api.patch(`/accounts/profile/`, data);
  };

  changePassword = (
    old_password: string,
    new_password: string,
    confirm_new_password: string,
  ) => {
    return api.post('/accounts/change-password/', {
      old_password,
      new_password,
      confirm_new_password,
    });
  };

  registerNewPassword = (
    new_password: string,
    confirm_new_password: string,
  ) => {
    return api.post('/accounts/register-new-password/', {
      new_password,
      confirm_new_password,
    });
  };

  GetresetPassword = (email_or_cpf: string) => {
    return api.get(
      `/accounts/info-reset-password/?email_or_cpf=${email_or_cpf}`,
    );
  };

  resetPassword = (id: string, reset_type: string) => {
    return api.post('/v2/accounts/reset-password/', {
      id,
      reset_type,
    });
  };

  changeProfile = (
    full_name: string,
    cpf: string,
    email: string,
    phone: string,
    address: string,
    birth_date: string,
  ) => {
    return api.put('/accounts/profile/', {
      full_name,
      cpf,
      email,
      phone,
      address,
      birth_date,
    });
  };

  createLineFavorite = (data: FavoritesProps) => {
    return api.post('/accounts/follow-lines/', data);
  };

  getLineFavorite = () => {
    return api.get('/accounts/follow-lines/');
  };

  deleteLineFavorite = (id: number) => {
    return api.delete(`/accounts/follow-lines/${id}/`);
  };
}
export type { FavoritesProps };
export default AccountsService;
