import styled, { css } from 'styled-components';

interface HamburguerProps {
  active: boolean;
  menuActive?: boolean;
}

export const Container = styled.div`
  height: 40px;
  background: var(--white);
  margin: 5px 0;
  font-family: 'Source Sans Pro';

  display: flex;
  justify-content: space-between;

  span {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    line-height: 15px;

    text-align: center;

    color: var(--purple);

    a {
      font-weight: bold;
    }
  }

  .menu {
    width: 40px;
    height: 30px;
    margin-right: 20px;
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    z-index: 99;
    cursor: pointer;
    padding: 0px;
    font-size: 30px;
    color: var(--purple);
    background: transparent;

    .one,
    .two,
    .three {
      background: var(--purple);
      height: 5px;
      width: 100%;
      margin: 6px auto;
    }
  }

  @media (max-width: 768px) {
    span,
    button {
      display: none;
    }

    .menu {
      display: block;
      margin-top: 6px;
      z-index: 99999999999999;
    }
  }
`;

export const LogoContainer = styled.div`
  width: 135px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    height: 20px;
  }
`;

export const HamburguerMenuContainer = styled.div<HamburguerProps>`
  display: none;

  .menu {
    display: none;
    background: transparent;
  }

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    width: 0px;
    height: 100vh;
    background: var(--purple);
    z-index: 103;

    ${props =>
      props.active &&
      css`{
        width: 250px;
        background: var(--purple);
        box-shadow: -1px 5px 8px 3px rgba(0, 0, 0, 0.6);

        .menu {
          display: block;
          position: absolute;
          right: 0;
          top: 15px;
          
          .one,
          .two,
          .three {
            background: var(--white);
          }
          .one {
            transform: rotate(45deg) translate(7px, 7px);
          }
          .two {
            opacity: 0;
          }
          .three {
            transform: rotate(-45deg) translate(8px, -9px);
          }
        }
      `}
  }
`;

export const ItensContainer = styled.ul`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    list-style: none;
  }
`;

export const NavItem = styled.li<HamburguerProps>`
  @media (max-width: 768px) {
    font: 400 1.4rem 'Source Sans Pro', sans-serif;
    line-height: 65px;
    cursor: pointer;
    color: var(--white);
    transition: color 0.3s ease;
    display: ${props => (props.menuActive ? 'block' : 'none')};

    &:hover {
      color: var(--orange);
    }

    ${props =>
      props.active &&
      css`
        font-size: 1.4rem;
        color: var(--orange);
        font-weight: 700;
      `}
  }
`;

export const DialogContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 650px;
  padding: 0;

  .container {
    text-align: center;
    width: 90%;
    height: 470px;
    margin-left: 25px;
    @media (max-width: 420px) {
      margin-left: 0px;
      width: 90%;
    }

    h3 {
      margin-bottom: 20px;
    }

    .information {
      margin-bottom: 30px;
      text-align: justify;
      margin-left: 30px;
      width: 90%;
    }
    .acessar {
      width: 90%;
      background-color: var(--purple);
      margin-bottom: 30px;
      @media (max-width: 420px) {
        margin-left: 30px;
        width: 90%;
      }
    }
  }
`;
