import styled, { css } from 'styled-components';

export const Containers = styled.div`
  height: 550px;
  padding: 0;

  .img-slider {
    width: 450px;
    height: 450px;
    margin-top: 20px;
    margin-right: 60px;
  }

  @media only screen and (max-width: 1260px) {
    .img-slider {
      width: 450px;
      height: 450px;
      margin-top: 40px;
    }
  }

  @media only screen and (max-width: 1090px) {
    .img-slider {
      width: 350px;
      height: 350px;
      margin-top: 40px;
    }
  }

  @media only screen and (max-width: 999px) {
    .img-slider {
      width: 300px;
      height: 300px;
      margin-top: 70px;
    }
  }

  @media only screen and (max-width: 839px) {
    .img-slider {
      width: 280px;
      height: 280px;
      margin-top: 90px;
    }
  }

  @media only screen and (max-width: 768px) {
    .img-slider {
      width: 230px;
      height: 230px;
      margin-top: 40px;
      margin-right: 0rem;
    }
  }

  @media only screen and (max-width: 376px) {
    .img-slider {
      width: 230px;
      height: 230px;
      margin-top: 20px;
      margin-right: 0rem;
    }
  }

  .title {
    color: white;
    font-size: 2.5rem;
    font-family: 'Roboto Slab', sans-serif;
    margin-right: 0rem;
    @media only screen and (max-width: 768px) {
      text-shadow: 0.1em 0.1em 0.2em black;
      font-size: 2rem;
    }
  }
  .description {
    color: white;
    font-size: 1.2rem;
    font-family: 'Source Sans Pro', sans-serif;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 0rem;
    @media only screen and (max-width: 768px) {
      text-shadow: 0.1em 0.1em 0.2em black;
      font-size: 1.3rem;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
  .button {
    margin-top: 0.5rem;
    width: 250px;
    font-size: 1.1rem;
  }

  .flex {
    padding: 0 1.2rem;
    margin-right: 1rem;
    margin-top: 1rem;
    display: flex;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 0rem;
      margin-top: 5rem;
      padding: 0;
    }
  }

  .text {
    margin-right: 20px;
    padding-right: 20px;
    margin-top: 80px;
    @media only screen and (max-width: 768px) {
      margin-top: 8px;
      text-align: center;
      margin-right: 0rem;
      padding-right: 0px;
      width: 100%;
    }
  }
`;

export const Sliders = styled.div`
  width: 100%;
  @media only screen and (max-width: 2600px) {
    margin-top: -85px;
  }
  @media only screen and (max-width: 2560px) {
    margin-top: -80px;
  }
  @media only screen and (max-width: 2540px) {
    margin-top: -78px;
  }
  @media only screen and (max-width: 2500px) {
    margin-top: -75px;
  }
  @media only screen and (max-width: 2400px) {
    margin-top: -65px;
  }
  @media only screen and (max-width: 2300px) {
    margin-top: -60px;
  }
  @media only screen and (max-width: 2200px) {
    margin-top: -55px;
  }
  @media only screen and (max-width: 2100px) {
    margin-top: -50px;
  }
  @media only screen and (max-width: 2000px) {
    margin-top: -45px;
  }
  @media only screen and (max-width: 1900px) {
    margin-top: -40px;
  }
  @media only screen and (max-width: 1800px) {
    margin-top: -35px;
  }
  @media only screen and (max-width: 1700px) {
    margin-top: -30px;
  }
  @media only screen and (max-width: 1650px) {
    margin-top: -25px;
  }
  @media only screen and (max-width: 1600px) {
    margin-top: -19px;
  }
  @media only screen and (max-width: 1500px) {
    margin-top: -20px;
  }
  @media only screen and (max-width: 1400px) {
    margin-top: -18px;
  }
  @media only screen and (max-width: 768px) {
    margin-top: -65px;
  }

  .none {
    display: none !important;
  }

  .slick-dots {
    position: absolute;
    top: 95%;
    @media only screen and (min-width: 768px) {
      background-color: transparent;
      position: absolute;
      z-index: 1;
      top: 65%;
      left: 84%;
      transform: translateY(-50%);
      width: 40px;
    }
    @media only screen and (min-width: 769px) and (max-width: 1300px) {
      left: 92%;
    }
    li {
      button {
        &:before {
          color: #dc582a;
          font-size: 13px;
        }
      }
    }
  }
`;
