/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { Column, Content, Row } from '../../../styles/components';
import { LastContent, GridContainer, FlexContainer, Main } from './styles';

import CoreService, { New } from '../../../services/core';
import FaqSection from '../../FaqSection';

const NewsSection: React.FC = () => {
  const services = { coreService: new CoreService() };

  const [news, setNews] = React.useState<New[]>([]);

  useEffect(() => {
    async function getNews() {
      try {
        const response = await services.coreService.getNews(2);
        setNews(response.data.results);
      } catch (err) {
        console.error(err);
      }
    }
    getNews();
  }, []);

  return (
    <>
      <GridContainer>
        <Content>
          <FaqSection />
        </Content>

        <LastContent backgroundImage>
          <p>Últimas Notícias</p>
          <Main>
            {news.length > 0 && (
              <Row>
                {news.map((result: New) => (
                  <Column
                    small="6"
                    medium="6"
                    large="6"
                    key={result.id}
                    role="button"
                  >
                    <a href={`/noticia/${result.id}`}>
                      <div className="card">
                        <img src={result.image} alt={result.title} />
                        <FlexContainer>
                          {result.categories &&
                            result.categories.length > 0 && (
                              <span className="card-span">
                                {result.categories[0].name}
                              </span>
                            )}
                          <h3>{result.title}</h3>
                          <p>{result.subtitle}</p>
                          <h5>
                            <a href={`/noticia/${result.id}`}>Leia mais</a>
                          </h5>
                        </FlexContainer>
                      </div>
                    </a>
                  </Column>
                ))}
              </Row>
            )}
          </Main>
        </LastContent>
        <span>
          <h5>
            <a href="/noticias">VER TODAS &gt;&gt;</a>
          </h5>
        </span>
      </GridContainer>
    </>
  );
};

export default NewsSection;
