import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 315px;
  text-align: center;

  img {
    width: 252px;
    height: 55px;
  }
  form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    div {
      height: 50px;
      background: var(--white);
      border-radius: 10px;
      margin-bottom: 8px;

      display: flex;
      flex-direction: row-reverse;
    }

    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      margin-bottom: 26px;

      color: #4d4a48;
    }

    .enviar {
      height: 50px;
      margin-top: 10px;

      background: var(--orange);
      border-radius: 10px;
      transition: all 0.2s;

      &:hover {
        background: #dc5129;
      }
    }
    a {
      color: #4d4a48;
      margin-top: 15px;
      font-size: 16px;
      opacity: 0.8;
      transition: all 0.2s;

      &:hover {
        opacity: 1;
      }
    }
    p {
      text-align: left;
      margin-left: 2px;
      color: #4d4a48;
      margin-top: 15px;
    }
`;
