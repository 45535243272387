import React from 'react';
import Lottie from 'react-lottie';
import LoaderAnimation from '../../assets/loader.json';
import { Container } from './styles';

const DashboardLoader: React.FC = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoaderAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Container>
      <Lottie
        height={150}
        width={150}
        style={{
          zIndex: 10,
          transform: 'translateY(200%)',
        }}
        options={defaultOptions}
        isStopped={false}
        isPaused={false}
      />
    </Container>
  );
};

export default DashboardLoader;
