/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable no-return-await */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { formatCurrency } from '@brazilian-utils/brazilian-utils';
import * as React from 'react';
import api from '../../../../services/api';
import Button from '../../../Button';
import ModalTicket from '../../../ModalTicket';
import Select from '../Select';
import { useToast } from '../../../../hooks/toast';
import { useLoader } from '../../../../hooks/loader';

const Content: React.FC<any> = ({ handleClose }) => {
  // const [title, setTitle] = React.useState('');
  const [line, setLine] = React.useState<any>(undefined);
  const [ticket, setTicket ] = React.useState<any>(undefined);

  const { addToast } = useToast();
  const { changeLoader } = useLoader();

  const getList = React.useCallback(async () => {
    try {
      const { data } = await api.get(`/core/qr-code/lines/`);
      console.log(data)
    } catch (e) {
      console.log(e);
    }
  }, []);
  const estacoes = [
    {
      linha: '1000',
      nome: 'ESTAÇÃO DIAMANTE',
    },
    {
      linha: '1001',
      nome: 'ESTAÇÃO DIAMANTE',
    },
    {
      linha: '1002',
      nome: 'ESTAÇÃO DIAMANTE ACESSO LOJAS',
    },
    {
      linha: '2000',
      nome: 'ESTACAO VENDA NOVA',
    },
    {
      linha: '2001',
      nome: 'ESTAÇÃO VENDA NOVA',
    },
    {
      linha: '3000',
      nome: 'ACESSO ESTAÇÃO SAO GABRIEL',
    },
    {
      linha: '3001',
      nome: 'ESTAÇÃO SÃO GABRIEL',
    },
    {
      linha: '3002',
      nome: 'ESTAÇÃO MOVE SÃO GABRIEL (TARIFA A)',
    },
    {
      linha: '4001',
      nome: 'ESTAÇÃO BARREIRO',
    },
    {
      linha: '4002',
      nome: 'ESTAÇAO BARREIRO',
    },
    {
      linha: '4003',
      nome: 'ESTAÇÃO BARREIRO SAÍDA / ACESSO SHOPPING',
    },
    {
      linha: '4004',
      nome: 'ESTAÇÃO BARREIRO ACESSO LOJAS',
    },
    {
      linha: '6000',
      nome: 'ACESSO ESTAÇÃO PAMPULHA',
    },
    {
      linha: '6001',
      nome: 'ESTAÇÃO PAMPULHA',
    },
    {
      linha: '7000',
      nome: 'LINHAS ACESSO ESTAÇÃO VILARINHO',
    },
    {
      linha: '7001',
      nome: 'ESTAÇÃO VILARINHO',
    },
    {
      linha: 'AC01',
      nome: 'ESTAÇÃO TRANSF. CANDELÁRIA',
    },
    {
      linha: 'AC02',
      nome: 'ESTAÇÃO TRANSF. MINAS CAIXA',
    },
    {
      linha: 'AC03',
      nome: 'ESTAÇÃO TRANSF. QUADRAS DO VILARINHO',
    },
    {
      linha: 'AC04',
      nome: 'ESTAÇÃO TRANSF. UPA VENDA NOVA',
    },
    {
      linha: 'AC05',
      nome: 'ESTAÇÃO TRANSF. CRISTIANO GUIMARÃES',
    },
    {
      linha: 'AC06',
      nome: 'ESTAÇÃO TRANSF. PLANALTO',
    },
    {
      linha: 'AC07',
      nome: 'ESTAÇÃO TRANSF. SÃO JOÃO BATISTA',
    },
    {
      linha: 'AC08',
      nome: 'ESTAÇÃO TRANSF. LAGOA DO NADO',
    },
    {
      linha: 'AC09',
      nome: 'ESTAÇÃO TRANSF. MONTESE',
    },
    {
      linha: 'AC10',
      nome: 'ESTAÇÃO TRANSF. MONTE CASTELO',
    },
    {
      linha: 'AC12',
      nome: 'ESTAÇÃO TRANSF. SANTA ROSA',
    },
    {
      linha: 'AC13',
      nome: 'ESTAÇÃO TRANSF. MINEIRÃO',
    },
    {
      linha: 'AC14',
      nome: 'ESTAÇÃO TRANSF. UFMG',
    },
    {
      linha: 'AC15',
      nome: 'ESTAÇÃO TRANSF. LIBERDADE',
    },
    {
      linha: 'AC16',
      nome: 'ESTAÇÃO TRANSF. COLÉGIO MILITAR',
    },
    {
      linha: 'AC17',
      nome: 'ESTAÇÃO TRANSF. SÃO FRANCISCO',
    },
    {
      linha: 'AC18',
      nome: 'ESTAÇÃO TRANSF. CACHOEIRINHA',
    },
    {
      linha: 'AC19',
      nome: 'ESTAÇÃO TRANSF. AMÉRICO VESPÚCIO',
    },
    {
      linha: 'AC20',
      nome: 'ESTAÇÃO TRANSF. APARECIDA',
    },
    {
      linha: 'AC21',
      nome: 'ESTAÇÃO TRANSF. OPERÁRIOS',
    },
    {
      linha: 'AC22',
      nome: 'ESTAÇÃO TRANSF. HOSPITAL BELO HORIZONTE',
    },
    {
      linha: 'AC23',
      nome: 'ESTAÇÃO TRANSF. IAPI',
    },
    {
      linha: 'AC24',
      nome: 'ESTAÇÃO TRANSF. HOSPITAL ODILON BEHRENS',
    },
    {
      linha: 'AC25',
      nome: 'ESTAÇÃO TRANSF. SENAI',
    },
    {
      linha: 'AC26',
      nome: 'ESTAÇÃO TRANSF. LAGOINHA',
    },
    {
      linha: 'CM02',
      nome: 'ESTAÇÃO DE TRANSF. MINAS SHOPPING',
    },
    {
      linha: 'CM03',
      nome: 'ESTAÇÃO DE TRANSF. OURO MINAS',
    },
    {
      linha: 'CM04',
      nome: 'ESTAÇÃO DE TRANSF. UNIÃO',
    },
    {
      linha: 'CM05',
      nome: 'ESTAÇÃO DE TRANSF. IPIRANGA',
    },
    {
      linha: 'CM06',
      nome: 'ESTAÇÃO DE TRANSF. CIDADE NOVA',
    },
    {
      linha: 'CM07',
      nome: 'ESTAÇÃO DE TRANSF. FEIRA DOS PRODUTORES',
    },
    {
      linha: 'CM08',
      nome: 'ESTAÇÃO DE TRANSF. SÃO JUDAS TADEU',
    },
    {
      linha: 'CM09',
      nome: 'ESTAÇÃO DE TRANSF. SAGRADA FAMÍLIA',
    },
    {
      linha: 'CM10',
      nome: 'ESTAÇÃO DE TRANSF. SILVIANO BRANDÃO',
    },
    {
      linha: 'PR01',
      nome: 'ESTAÇÃO DE TRANSF. TAMOIOS',
    },
    {
      linha: 'PR02',
      nome: 'ESTAÇÃO DE TRANSF. CARIJÓS',
    },
    {
      linha: 'SD01',
      nome: 'ESTAÇÃO DE TRANSF. SÃO PAULO',
    },
    {
      linha: 'SD02',
      nome: 'ESTAÇÃO DE TRANSF. RIO DE JANEIRO',
    },
  ];

  React.useEffect(() => {
    getList();
  }, [line]);

  return (
    <>
      <div
        style={{
          height: 500,
          width: '100%',
        }}
      >
        {/* <Input
          placeholder="Linhas"
          label="Linhas"
          value={title}
          onChange={(e: any) => setTitle(e.target.value)}
        /> */}
        <Select
          placeholder="Linhas"
          label="Linhas"
          getOptionLabel={label => {
            const codigoLinha = label.id.linha.linCodLinhaExterno;
    
            const linhaEstacoes = estacoes.find((dados: any) => dados?.linha === codigoLinha);
            return linhaEstacoes?.linha === label.id.linha.linCodLinhaExterno ?
            `${linhaEstacoes?.nome}` : `${label.id.linha.linCodLinhaExterno  } - ${label.id.linha.linNomeLinha}`
          }}
          getOptionValue={value => {
            return value.id.linha.linCodLinhaExterno || value.id.linha.linNomeLinha;
          }}
          onChange={(e: any) => setLine(e)}
        />
        {line && (
          <div style={{ marginTop: 20 }}>
            <div><b>Valor da tarifa:</b> R${formatCurrency(line?.tarifa?.valorTarifa)}</div>
          </div>
        )}
        {line && (
          <Button
            style={{
              marginTop: '30px',
            }}
            color="orange"
            padding=""
            rounded
            className="button-generate-ticket-qrcode"
            type="button"
            onClick={async () => {
              try {
                const response = await api.post('/ticketing/qr-code/create-ticket-by-value/', {
                  line_main_code: line?.id?.linha?.id,
                  value: line?.tarifa?.valorTarifa
                })
                setTicket({
                  code: response?.data?.codigoQRCode,
                  line: response?.data?.linha,
                  value: response?.data?.valor,
                  image: response?.data?.imagemBase64,
                  lineName: line?.id?.linha?.linNomeLinha,
                  dataValidade: response?.data?.dataHoraFimValidadeCarga,
                  tempoExpiracao: response?.data?.tempo_expiracao
                })
                
              }catch (err: any) {
                changeLoader(false);
                const { ...error } = err;
                console.log(error);
                if (error?.response?.data?.detail === "Cartão transfácil está bloqueado.") {
                  addToast({
                    type: 'error',
                    title: `${error?.response?.data?.detail}`,
                    description:
                      error?.response?.data?.detail &&
                      `${error?.response?.data?.detail}`,
                  });
                } else {
                  addToast({
                    type: 'error',
                    title: `${error?.response?.data?.message}`,
                    description:
                      error?.response?.data?.sub_message &&
                      `${error?.response?.data?.sub_message}`,
                  });
                }
                
              }
              
              // handleClose()
            }}
          >
            GERAR
          </Button>
        )}
      </div>
      {ticket && (
        <ModalTicket
          code={ticket?.code}
          line={ticket?.line}
          value={ticket?.value}
          closeModal={() => setTicket(undefined)}
          image={ticket?.image}
          lineName={ticket?.lineName}
          dataValidade={ticket?.dataValidade}
          tempoExpiracao={ticket?.tempoExpiracao}
          gerando={false}
        />
      )}
    </>
  );
};

export { Content };
