import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color: string;
  padding: string;
  rounded?: boolean;
  textBlack?: boolean;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  color,
  padding,
  rounded,
  textBlack,
  children,
  disabled,
  ...rest
}) => (
  <Container
    color={color}
    padding={padding}
    rounded={rounded}
    disabled={disabled}
    type="button"
    textBlack={textBlack}
    {...rest}
  >
    {children}
  </Container>
);

export default Button;
