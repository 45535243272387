import styled from 'styled-components';
import MapImage from '../../assets/Shared/map.png';

export const TitleSection = styled.section`
  height: 220px;
  width: 98%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--white);
  @media only screen and (max-width: 768px) {
    height: 150px;
  }

  h1 {
    font-size: 2.9rem;
    line-height: 4rem;
    @media (max-width: 768px) {
      text-align: center;
      margin-top: 8rem;
      line-height: 3.3rem;
    }
    @media only screen and (max-width: 450px) {
      text-align: center;
    }
  }

  span {
    font-size: 1rem;
    line-height: 2.5rem;
    font-weight: normal;
    @media (max-width: 768px) {
      text-align: center;
    }
    @media only screen and (max-width: 450px) {
      text-align: center;
    }
  }
`;

export const GridSection = styled.section`
  display: grid;
  grid-template-columns: 2fr 286px;
  grid-template-rows: 1fr 50px;
  grid-template-areas: 'main aside';
  grid-gap: 3rem;
  min-height: 100vh;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Main = styled.main`
  grid-area: main;
  padding: 1.5rem 0;

  p {
    margin-bottom: 1rem;
    margin-left: 4.8rem;
  }

  form {
    > div {
      margin-bottom: 40px;
    }
    .btn-buscar {
      color: white;
      margin: 0;
        @media (max-width: 450px) {
        display: none;
      }
    }
    .btn-search {
      height: 30px;
      width: auto;
      display: none;
        @media (max-width: 450px) {
        padding-bottom: 5px;
        display: block;
      }
    }    
`;

export const Aside = styled.aside`
  grid-area: aside;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const AsideCard = styled.div`
  background: var(--white);
  width: 286px;
  position: sticky;
  top: 20px;
  margin: 1.5rem 0;
  height: 397px;
  border-radius: 10px 10px 0px 0px;
  box-shadow: var(--box-shadow);
  /* transform: translateY(-150px); */

  @media (max-width: 768px) {
    transform: translateY(0);

    .main {
      form {
        button {
          width: 100%;
        }
      }
    }
  }

  .header {
    background: var(--purple2);
    color: var(--orange);
    height: 73px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 3px solid var(--orange);
    font: 600 1.3rem 'Roboto Slab', sans-serif;
  }

  .main {
    margin-top: 14px;
    padding: 0 25px;

    form {
      .highlight-label {
        margin-bottom: 10px;
        font-style: normal;
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.1rem;
        color: var(--purple);
      }

      div {
        margin-bottom: 22px;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 30px;
      }
    }
  }

  .footer {
    height: 107px;
    background: url(${MapImage}) no-repeat;
    background-size: cover;

    display: flex;
    align-items: center;
    justify-content: center;

    /* position: fixed; */
    bottom: 0;
    width: inherit;

    button {
      color: var(--orange) !important;
      border: 2px solid var(--orange);
    }

    @media (max-width: 1081px) {
      height: 110px;
    }
    @media (max-width: 720px) {
      height: 117px;
    }
  }

  @media (max-width: 335px) {
    flex-wrap: wrap;
    width: 99%;
  }
`;
