/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';

import { ProfileData } from '../../../pages/MeuPerfil';

// import ModalPrincipalInformation from '../ModalPrincipalInformation';
import AccountsService from '../../../services/accounts';

import { Container, Info } from './styles';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';

interface PrincipalInformationProps {
  profile: ProfileData;
  updateStateProfile: Dispatch<SetStateAction<ProfileData>>;
}

const PrincipalInformation: React.FC<PrincipalInformationProps> = ({
  profile,
  updateStateProfile,
}) => {
  const [modaCPFlIsOpen, setModalCPFIsOpen] = useState(false);
  const servicesProfile = { AccountsService: new AccountsService() };
  const [informations, setInformations] = React.useState([]);
  const { addToast } = useToast();
  const { signOut } = useAuth();

  const closeModalCPF = () => {
    setModalCPFIsOpen(false);
  };

  useEffect(() => {
    async function getProfile() {
      try {
        const response = await servicesProfile.AccountsService.getProfile();
        setInformations(response.data);
        if (
          response.data.birth_date == null ||
          response.data.cpf == null ||
          response.data.full_name == null
        ) {
          setModalCPFIsOpen(true);
        }
      } catch (err) {
        console.error(err);
        const { ...error }: any = err;

        addToast({
          type: 'error',
          title: `${
            error?.response?.data?.message
              ? error?.response?.data?.message
              : 'Erro no Servidor'
          }`,
          description: `${
            error?.response?.data?.sub_message
              ? error?.response?.data?.sub_message
              : 'Tente deslogar e logar novamente, caso o erro persista entre em contato com o Administrador'
          }`,
        });
        if (err) {
          signOut();
        }
      }
    }
    getProfile();
  }, []);

  return (
    <>
      <Info>
        <span className="info">&#9432; </span>
        <span>
          Para alteração dos dados não editáveis envie e-mail para
          lgpd@transfacil.com.br com seu nome, data de nascimento e CPF.
        </span>
      </Info>

      {/* {modaCPFlIsOpen && (
        <ModalPrincipalInformation
          profile={profile}
          closeModal={() => closeModalCPF()}
          updateStateProfile={updateStateProfile}
        />
      )} */}
    </>
  );
};

export default PrincipalInformation;
