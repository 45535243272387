/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import ReactSelect from 'react-select';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Modal from '../Modal';
import ModalText from './Modal';
import Loader from '../../Loader';
import Button from '../../Button';
import Card from '../Card';
import AddPaymentCard from '../AddPaymentCard';
import { formatPrice } from '../../../utils/currencyFormatter';

import {
  Container,
  Content,
  CardSection,
  Circle,
  ModalContentBanner,
  CartaoDados,
  PagamentoDados,
  DivGeralCartao,
} from './styles';
import { Row, Column } from '../../../styles/components';

import { useToast } from '../../../hooks/toast';
import { useLoader } from '../../../hooks/loader';

import TicketingService, {
  Card as CardInterface,
  Payment as Pay,
} from '../../../services/ticketing';
import InputOutlined from '../../InputOutlined';
import ModalPix from './ModalPix';
import api from '../../../services/api';
import AccountsService from '../../../services/accounts';
import { useAuth } from '../../../hooks/auth';

interface RechargerCard {
  cards: CardInterface[];
}

const RechargerCard: React.FC<RechargerCard> = ({ cards, ...rest }) => {
  const { push } = useHistory();
  const { addToast } = useToast();
  const { signOut } = useAuth();
  const { changeLoader } = useLoader();
  const [loading, setLoading] = useState(true);
  const services = { ticketingService: new TicketingService() };
  const inputRef = useRef<any>(null);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [hasCreditCard, setHasCreditCard] = useState<boolean>(false);
  const [showAddPayment, setShowAddPayment] = useState<boolean>(false);
  const [modalPhone, setModalPhone] = useState<boolean>(false);
  const [modalCpf, setModalCpf] = useState<boolean>(false);
  const [successType, setSuccessType] = useState<string>();
  const [boletoLink, setBoletoLink] = useState<string>();
  const [payments, setPayments] = useState<Pay[]>([]);
  const [paymentSelected, setPaymentSelected] = useState<number>();
  const [cardSelected, setCardSelected] = useState<number>();
  const [cashSelected, setCashSelected] = useState<number>();
  const [payType, setPayType] = useState<string>('');
  const [payEnable, setPayEnable] = useState<any>([]);
  const [payEnableBoleto, setPayEnableBoleto] = useState<any>([]);
  const [payWarningBoleto, setPayWarningBoleto] = useState<any>();
  const [payEnableCreditCard, setPayEnableCreditCard] = useState<any>([]);
  const [payWarningCreditCard, setPayWarningCreditCard] = useState<any>();
  const [payEnablePIX, setPayEnablePIX] = useState<any>([]);
  const [payWarningPIX, setPayWarningPIX] = useState<any>();
  const [pixCode, setPixCode] = useState<any>();
  const [pixImage, setPixImage] = useState<any>();
  const [pixExpiration, setPixExpiration] = useState<any>();
  const formRef = useRef<FormHandles>(null);
  const [LIMIT_RECHARGE_CARD_MIN, setLimitRechargeCardMin] = useState<number>();
  const [LIMIT_RECHARGE_CARD_VAL, setLimitRechargeCardVal] = useState<number>();
  const servicesProfile = { AccountsService: new AccountsService() };
  const [informations, setInformations] = React.useState([]);
  const [phones, setPhones] = React.useState([]);
  const [cpfs, setCpfs] = React.useState([]);
  const [
    LIMIT_RECHARGE_BILLET_MIN,
    setLimitRechargeBilletMin,
  ] = useState<number>();
  const [
    LIMIT_RECHARGE_BILLET_VAL,
    setLimitRechargeBilletVal,
  ] = useState<number>();
  const [LIMIT_RECHARGE_PIX_MIN, setLimitRechargePixMin] = useState<number>();
  const [LIMIT_RECHARGE_PIX_VAL, setLimitRechargePixVal] = useState<number>();
  const [disable, setDisable] = useState(false);
  const [cardtax, setCardtax] = useState<number>();
  const [boletotax, setBoletotax] = useState<number>();
  const [pixtax, setPixtax] = useState<number>();
  const [finaltax, setFinaltax] = useState<number>();
  const [cardId, setCardId] = useState(null);
  const [tamanhotela, setTamanhotela] = useState(window.innerWidth);
  const [saldocartao, setSaldoCartao] = React.useState();
  const [eyechange, setEyechange] = React.useState<boolean>(false);

  const LimitValue = useCallback(
    ({ floatValue }) => {
      const result =
        payType === 'credit_card'
          ? LIMIT_RECHARGE_CARD_VAL
          : payType !== 'credit_card'
          ? LIMIT_RECHARGE_BILLET_VAL
          : LIMIT_RECHARGE_PIX_VAL;
      return floatValue <= result;
    },

    [
      payType,
      LIMIT_RECHARGE_BILLET_VAL,
      LIMIT_RECHARGE_CARD_VAL,
      LIMIT_RECHARGE_PIX_VAL,
    ],
  );

  const getCardsAsync = async () => {
    setLoading(true);
    const idCard = window.location.pathname.split('/');
    const response = await services.ticketingService.getCard(
      idCard[idCard.length - 1],
    );
    setCardSelected(response?.data?.id);
    setSaldoCartao(response.data?.balance);
    // changeLoader(false);
    setLoading(false);
  };

  useEffect(() => {
    getCardsAsync();
  }, [window.location.pathname]);

  useEffect(() => {
    function handleResize() {
      setTamanhotela(window.innerWidth); // Atualiza a página quando o tamanho da tela muda
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    async function getPay() {
      try {
        const response = await services.ticketingService.getFormsPay();
        setPayEnable(response.data.results);
        const boletoEnable = payEnable
          .filter((pay: any) => pay.payment_type === 'boleto')
          .map((pay: any) => pay.enable);

        const creditCardEnable = payEnable
          .filter((pay: any) => pay.payment_type === 'credit_card')
          .map((pay: any) => pay.enable);

        const pixEnable = payEnable
          .filter((pay: any) => pay.payment_type === 'pix')
          .map((pay: any) => pay.enable);

        const boletoWarning = payEnable
          .filter((pay: any) => pay.payment_type === 'boleto')
          .map((pay: any) => pay.warning);

        const creditCardWarning = payEnable
          .filter((pay: any) => pay.payment_type === 'credit_card')
          .map((pay: any) => pay.warning);

        const pixWarning = payEnable
          .filter((pay: any) => pay.payment_type === 'pix')
          .map((pay: any) => pay.warning);

        setPayEnableBoleto(boletoEnable[0]);
        setPayEnableCreditCard(creditCardEnable[0]);
        setPayEnablePIX(pixEnable[0]);
        setPayWarningBoleto(boletoWarning[0]);
        setPayWarningCreditCard(creditCardWarning[0]);
        setPayWarningPIX(pixWarning[0]);
      } catch (err) {
        console.error(err);
      }
    }
    getPay();
  }, [
    payEnableBoleto,
    payEnableCreditCard,
    payEnablePIX,
    payWarningBoleto,
    payWarningCreditCard,
    payWarningPIX,
  ]);

  const typeOptions = [
    {
      name: 'pix',
      label: 'PIX',
      isdisabled: !payEnablePIX,
    },
    {
      name: 'boleto',
      label: 'Boleto',
      isdisabled: !payEnableBoleto,
    },
    {
      name: 'credit_card',
      label: 'Cartão de Crédito',
      isdisabled: !payEnableCreditCard,
    },
  ];

  let titleWarning = '';
  if (Boolean(payEnableBoleto) === false) {
    titleWarning = payWarningBoleto;
  }
  if (Boolean(payEnableCreditCard) === false) {
    titleWarning = payWarningCreditCard;
  }
  if (Boolean(payEnablePIX) === false) {
    titleWarning = payWarningPIX;
  }
  if (
    Boolean(payEnableBoleto) === false &&
    Boolean(payEnableCreditCard) === false &&
    Boolean(payEnablePIX) === false
  ) {
    titleWarning =
      'As formas de pagamento Boleto, Cartão de Crédito e PIX para pessoa física, estão temporariamente desativadas. Estamos com uma instabilidade na plataforma.';
  }

  async function asyncGetBoletoLimit() {
    try {
      setLoading(true);
      const response = await services.ticketingService.getBoletoLimit();
      const boletoLimit = response.data.results.filter(
        (billet: any) => billet.payment_type === 'boleto',
      );

      const cardLimit = response.data.results.filter(
        (billet: any) => billet.payment_type === 'credit_card',
      );

      const pixLimit = response.data.results.filter(
        (billet: any) => billet.payment_type === 'pix',
      );

      setCardtax(cardLimit[0]?.rate);
      setBoletotax(boletoLimit[0]?.rate);
      setPixtax(pixLimit[0]?.rate);
      setLimitRechargeBilletVal(boletoLimit[0].maximum);
      setLimitRechargeBilletMin(boletoLimit[0].minimum);
      setLimitRechargeCardVal(cardLimit[0].maximum);
      setLimitRechargeCardMin(cardLimit[0].minimum);
      setLimitRechargePixVal(pixLimit[0].maximum);
      setLimitRechargePixMin(pixLimit[0].minimum);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  useEffect(() => {
    async function getProfile() {
      try {
        const response = await servicesProfile.AccountsService.getProfile();
        setInformations(response.data);
        setPhones(response?.data?.phone);
        setCpfs(response?.data?.cpf);
      } catch (err) {
        const { ...error }: any = err;

        addToast({
          type: 'error',
          title: `${
            error?.response?.data?.message
              ? error?.response?.data?.message
              : 'Erro no Servidor'
          }`,
          description: `${
            error?.response?.data?.sub_message
              ? error?.response?.data?.sub_message
              : 'Tente deslogar e logar novamente, caso o erro persista entre em contato com o Administrador'
          }`,
        });
        if (err) {
          signOut();
        }
        console.error(err);
      }
    }
    getProfile();
  }, []);

  useEffect(() => {
    async function getFaqs() {
      try {
        const response = await services.ticketingService.getPaymentForm();
        setPayments(response.data.results);
        const filteredPayments = response.data.results.filter(
          (type: any) => type.payment_type === 'credit_card',
        );

        setHasCreditCard(!!filteredPayments.length);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    }

    getFaqs();
    asyncGetBoletoLimit();
  }, []);

  const handleRedirect = useCallback(
    pathLink => {
      push(pathLink);
    },
    [push],
  );

  const handleCard = useCallback(
    idCard => {
      setCardSelected(idCard);
    },
    [setCardSelected],
  );

  const handlePayment = useCallback(
    paymentId => {
      setPaymentSelected(paymentId);
    },
    [setPaymentSelected],
  );

  const success = useCallback((type, link, pixImg, pixCod, pixExp) => {
    setSuccessType(type);
    setBoletoLink(link);
    setShowSuccess(true);
    setPixImage(pixImg);
    setPixCode(pixCod);
    setPixExpiration(pixExp);
  }, []);

  const closeModal = useCallback(() => {
    setShowSuccess(false);
    push('/meus-cartoes');
  }, [push]);

  const handleAddCreditCard = useCallback(async () => {
    try {
      const response = await services.ticketingService.getPaymentForm();
      setPayments(response.data.results);
      const filteredPayments = response.data.results.filter(
        (type: any) => type.payment_type === 'credit_card',
      );
      setHasCreditCard(!!setHasCreditCard.length);
    } catch (err: any) {
      const { ...error } = err;
      console.error(error);
    }
  }, []);

  const handleCash = useCallback(
    async cashValue => {
      const paymentType: Pay = payments.filter(
        pay => pay.id === paymentSelected,
      )[0];

      if (
        paymentType.payment_type === 'boleto' &&
        Number(inputRef.current.state.numAsString) <
          Number(LIMIT_RECHARGE_BILLET_VAL)
      ) {
        const addedValue =
          Number(inputRef.current.state.numAsString) + cashValue;

        const setValue =
          addedValue > LIMIT_RECHARGE_BILLET_VAL
            ? LIMIT_RECHARGE_BILLET_VAL
            : addedValue;

        setCashSelected(setValue);
      }
      if (
        paymentType.payment_type === 'credit_card' &&
        Number(inputRef.current.state.numAsString) <
          Number(LIMIT_RECHARGE_CARD_VAL)
      ) {
        const addedValue =
          Number(inputRef.current.state.numAsString) + cashValue;

        const setValue =
          addedValue > LIMIT_RECHARGE_CARD_VAL
            ? LIMIT_RECHARGE_CARD_VAL
            : addedValue;

        setCashSelected(setValue);
      }
      if (
        paymentType.payment_type === 'pix' &&
        Number(inputRef.current.state.numAsString) <
          Number(LIMIT_RECHARGE_PIX_VAL)
      ) {
        const addedValue =
          Number(inputRef.current.state.numAsString) + cashValue;

        const setValue =
          addedValue > LIMIT_RECHARGE_PIX_VAL
            ? LIMIT_RECHARGE_PIX_VAL
            : addedValue;

        setCashSelected(setValue);
      }
    },
    [
      LIMIT_RECHARGE_BILLET_VAL,
      LIMIT_RECHARGE_CARD_VAL,
      LIMIT_RECHARGE_PIX_VAL,
      payments,
      paymentSelected,
      cashSelected,
    ],
  );

  const handleSubmit = useCallback(async () => {
    try {
      const data = formRef.current?.getData();

      const paymentType: Pay = payments.filter(
        pay => pay.id === paymentSelected,
      )[0];

      const idCard = window.location.pathname.split('/');

      if (!phones || !cpfs) {
        if (!phones) {
          setModalPhone(true);
        } else {
          setModalCpf(true);
        }
      } else {
        if (
          paymentType?.payment_type === 'credit_card' &&
          Number(inputRef?.current?.state?.numAsString) >
            Number(LIMIT_RECHARGE_CARD_VAL)
        ) {
          addToast({
            type: 'info',
            title: `Limite Máximo de Recarga Por Cartão de crédito é de ${LIMIT_RECHARGE_CARD_VAL}`,
          });
          return;
        }
        if (data) {
          if (
            Object.values(data.cvv).length < 3 &&
            paymentType?.payment_type === 'credit_card'
          ) {
            addToast({
              type: 'error',
              title: 'CVV não informado',
            });
            return;
          }
        }

        if (
          paymentType?.payment_type === 'boleto' &&
          Number(inputRef?.current?.state?.numAsString) >
            Number(LIMIT_RECHARGE_BILLET_VAL)
        ) {
          addToast({
            type: 'info',
            title: `Limite Máximo de Recarga Pelo Meio de Pagamento Boleto é de R$${LIMIT_RECHARGE_BILLET_VAL}`,
          });
          return;
        }

        if (
          paymentType?.payment_type === 'pix' &&
          Number(inputRef?.current?.state?.numAsString) >
            Number(LIMIT_RECHARGE_PIX_VAL)
        ) {
          addToast({
            type: 'info',
            title: `Limite Máximo de Recarga Pelo Meio de Pagamento PIX é de R$${LIMIT_RECHARGE_PIX_VAL}`,
          });
          return;
        }
        changeLoader(true);
        const response = await services.ticketingService.rechargeCard(
          cardSelected,
          paymentSelected,
          cashSelected,
          data ? data.cvv : null,
        );
        setDisable(true);

        setCardSelected(null);

        setCashSelected(null);

        setPaymentSelected(null);

        changeLoader(false);

        success(
          paymentType.payment_type,
          response.data.tacom_boleto,
          response.data.pix_image_link,
          response.data.pix_copy_paste,
          response.data.pix_expiration,
        );
        setDisable(false);
      }
    } catch (err: any) {
      console.log(err);
      changeLoader(false);
      const { ...error } = err;
      if (error?.response?.data?.message) {
        addToast({
          type: 'error',
          title: `${error?.response?.data?.message}`,
          description:
            error?.response?.data?.sub_message &&
            `${error?.response?.data?.sub_message}`,
        });
      } else {
        addToast({
          type: 'error',
          title: 'Error',
          description:
            'Estamos com instabilidades no sistema, por favor tente novamente mais tarde.',
        });
      }
    }
  }, [payments, paymentSelected, cardSelected, cashSelected, phones]);

  const handleSubmitCVV = useCallback(() => {
    console.log('submit');
  }, []);

  const handleSelectBlur = useCallback(
    e => {
      if (e.name === 'boleto') {
        const paymentType: Pay = payments.filter(
          pay => pay.payment_type === e.name,
        )[0];
        setPayType('boleto');
        setPaymentSelected(paymentType.id);
      }
      if (e.name === 'credit_card') {
        setPayType('credit_card');
      }
      if (e.name === 'pix') {
        const paymentType: Pay = payments.filter(
          pay => pay.payment_type === e.name,
        )[0];
        setPayType('pix');
        setPaymentSelected(paymentType.id);
      }
    },
    [payments],
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const eyechangeParam = params.get('eyechange');

    if (eyechangeParam === 'true') {
      // Defina o estado de eyechange como verdadeiro
      setEyechange(true);
    } else {
      // Defina o estado de eyechange como falso
      setEyechange(false);
    }
  }, [location.search]);

  return (
    <Container {...rest}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <DivGeralCartao>
            <CartaoDados>
              <Row>
                {cards &&
                  cards
                    .filter(card => card.id === cardSelected)

                    .map((card: CardInterface) => (
                      <Column key={card.id} small="12" medium="8" large="6">
                        <Card
                          nickname={card.nickname}
                          cardNumber={card.card_number}
                          selected={cardSelected === card.id}
                          saldo={saldocartao}
                          recharge={true}
                          id={card.id}
                          eye={eyechange}
                        />
                      </Column>
                    ))}
              </Row>
              <p className="textotcard">
                Deseja &nbsp;
                <a className="textlink" href="/meus-cartoes">
                  carregar outro Cartão?
                </a>
              </p>
              {tamanhotela > 500 && (
                <>
                  <p className="text">Resumo da compra</p>
                  <div className="resume">
                    <div className="value">
                      <span>Recarga</span>
                      {!!cashSelected && (
                        <span>{formatPrice(cashSelected)}</span>
                      )}
                    </div>
                    <div className="tax">
                      <span>Taxa de serviço</span>
                      {!!cashSelected &&
                        (payType === 'credit_card'
                          ? `${formatPrice(cardtax * cashSelected)}`
                          : payType === 'boleto'
                          ? `${formatPrice(boletotax * cashSelected)}`
                          : payType === 'pix'
                          ? `${formatPrice(pixtax * cashSelected)}`
                          : '')}
                    </div>
                    <div className="tax">
                      <span>Total</span>
                      {!!cashSelected &&
                        (payType === 'credit_card'
                          ? `${formatPrice(
                              cardtax * cashSelected + cashSelected,
                            )}`
                          : payType === 'boleto'
                          ? `${formatPrice(
                              boletotax * cashSelected + cashSelected,
                            )}`
                          : payType === 'pix'
                          ? `${formatPrice(
                              pixtax * cashSelected + cashSelected,
                            )}`
                          : '')}
                    </div>
                  </div>
                </>
              )}
            </CartaoDados>
            <PagamentoDados>
              {cardSelected && (
                <>
                  <p className="text" style={{ marginTop: '50px' }}>
                    1. Selecione a forma de pagamento
                  </p>

                  <div title={titleWarning}>
                    <ReactSelect
                      classNamePrefix="react-select"
                      placeholder="Forma de Pagamento"
                      onChange={handleSelectBlur}
                      options={typeOptions}
                      isOptionDisabled={option => option.isdisabled}
                    />
                  </div>

                  {payType === 'boleto' && (
                    <small className="text" style={{ marginBottom: '15px' }}>
                      <div className="limit-header">
                        * Atenção! Pagamentos realizados via boleto podem ser
                        compensados pelo banco em até 72 horas.
                      </div>
                    </small>
                  )}

                  {payType === 'credit_card' && (
                    <small className="text" style={{ marginBottom: '15px' }}>
                      <div
                        className="limit-header"
                        style={{ color: 'var(--orange)' }}
                      >
                        * Atenção! As compras na modalidade cartão de crédito
                        estão limitados a 1 compra diária por usuário.
                      </div>
                    </small>
                  )}

                  {payType === 'credit_card' && (
                    <div className="content-payment-form">
                      {payments.map(pay =>
                        pay.is_active && pay.payment_type === 'credit_card' ? (
                          <>
                            <>
                              <Content
                                key={pay.id}
                                onClick={() => handlePayment(pay.id)}
                              >
                                <Circle selected={paymentSelected === pay.id}>
                                  <div className="circle1">
                                    <div className="circle2" />
                                  </div>
                                </Circle>
                                <CardSection>
                                  <span className="cartao">
                                    {pay.verbose_name}
                                  </span>
                                </CardSection>
                              </Content>
                            </>
                          </>
                        ) : (
                          <></>
                        ),
                      )}
                      <div className="cvv-wrapper">
                        <Form ref={formRef} onSubmit={handleSubmitCVV}>
                          <InputOutlined
                            cvv
                            name="cvv"
                            id="cvv"
                            placeholder="CVV"
                          />
                        </Form>
                        <Button
                          color="orange"
                          padding="1.3"
                          type="button"
                          onClick={() => setShowAddPayment(true)}
                        >
                          + Adicionar Cartão de crédito
                        </Button>
                      </div>

                      {!hasCreditCard && (
                        <div className="emptyCreditCard">
                          <small>
                            Você não tem Cartão de crédito cadastrado
                          </small>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}

              {payType.length > 0 && (
                <>
                  <p className="text" style={{ marginTop: '50px' }}>
                    2. Digite o valor da recarga
                  </p>

                  <div className="recharge">
                    <div>
                      <NumberFormat
                        getInputRef={inputRef}
                        ref={inputRef}
                        prefix="R$ "
                        className="inputcash"
                        value={cashSelected}
                        isAllowed={LimitValue}
                        onValueChange={values =>
                          setCashSelected(values.floatValue)
                        }
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale
                        decimalScale={2}
                        placeholder="R$ 0,00"
                        allowNegative={false}
                      />
                      <br />
                      {payType === 'credit_card' && (
                        <small
                          className="text"
                          style={{ marginBottom: '15px' }}
                        >
                          <div className="limit-header">
                            * Restrições de Recarga:
                          </div>
                          <div>
                            Valor Mínimo:&nbsp;
                            {formatPrice(LIMIT_RECHARGE_CARD_MIN)}
                          </div>
                          <div>
                            Valor Máximo:&nbsp;
                            {formatPrice(LIMIT_RECHARGE_CARD_VAL)}
                          </div>
                        </small>
                      )}

                      {payType === 'boleto' && (
                        <small
                          className="text"
                          style={{ marginBottom: '15px' }}
                        >
                          <div className="limit-header">
                            * Restrições de Recarga:
                          </div>
                          <div>
                            Valor Mínimo:&nbsp;
                            {formatPrice(LIMIT_RECHARGE_BILLET_MIN)}
                          </div>
                          <div>
                            Valor Máximo:&nbsp;
                            {formatPrice(LIMIT_RECHARGE_BILLET_VAL)}
                          </div>
                        </small>
                      )}

                      {payType === 'pix' && (
                        <small
                          className="text"
                          style={{ marginBottom: '15px' }}
                        >
                          <div className="limit-header">
                            * Restrições de Recarga:
                          </div>
                          <div>
                            Valor Mínimo:&nbsp;
                            {formatPrice(LIMIT_RECHARGE_PIX_MIN)}
                          </div>
                          <div>
                            Valor Máximo:&nbsp;
                            {formatPrice(LIMIT_RECHARGE_PIX_VAL)}
                          </div>
                        </small>
                      )}
                    </div>
                    <div>
                      <Button
                        color="orange"
                        padding="1.3"
                        rounded
                        className="button"
                        name="recarregar"
                        type="button"
                        onClick={() => handleCash(10)}
                      >
                        + R$ 10
                      </Button>
                      <Button
                        color="orange"
                        padding="1.3"
                        rounded
                        className="button"
                        name="recarregar"
                        type="button"
                        onClick={() => handleCash(20)}
                      >
                        + R$ 20
                      </Button>
                      <Button
                        color="orange"
                        padding="1.3"
                        rounded
                        className="button"
                        name="recarregar"
                        type="button"
                        onClick={() => handleCash(50)}
                      >
                        + R$ 50
                      </Button>
                    </div>
                  </div>
                </>
              )}
              {tamanhotela < 500 && (
                <>
                  <p className="text">Resumo da compra</p>
                  <div className="resume">
                    <div className="value">
                      <span>Recarga</span>
                      {!!cashSelected && (
                        <span>{formatPrice(cashSelected)}</span>
                      )}
                    </div>
                    <div className="tax">
                      <span>Taxa de serviço</span>
                      {!!cashSelected &&
                        (payType === 'credit_card'
                          ? `${formatPrice(cardtax * cashSelected)}`
                          : payType === 'boleto'
                          ? `${formatPrice(boletotax * cashSelected)}`
                          : payType === 'pix'
                          ? `${formatPrice(pixtax * cashSelected)}`
                          : '')}
                    </div>
                    <div className="tax">
                      <span>Total</span>
                      {!!cashSelected &&
                        (payType === 'credit_card'
                          ? `${formatPrice(
                              cardtax * cashSelected + cashSelected,
                            )}`
                          : payType === 'boleto'
                          ? `${formatPrice(
                              boletotax * cashSelected + cashSelected,
                            )}`
                          : payType === 'pix'
                          ? `${formatPrice(
                              pixtax * cashSelected + cashSelected,
                            )}`
                          : '')}
                    </div>
                  </div>
                </>
              )}
              {!!cashSelected && (
                <div className="buttonss">
                  <Button
                    color="orange"
                    padding="3"
                    rounded
                    className="button-confirmar"
                    type="button"
                    onClick={() => handleSubmit()}
                    disabled={disable}
                  >
                    CONFIRMAR
                  </Button>
                  <Button
                    color="white"
                    padding="3.1"
                    rounded
                    className="button-cancelar"
                    type="button"
                    onClick={() => handleRedirect('/meus-cartoes')}
                  >
                    CANCELAR
                  </Button>
                </div>
              )}
            </PagamentoDados>
          </DivGeralCartao>
        </>
      )}
      {showSuccess && successType !== 'pix' && (
        <Modal
          handleClose={() => closeModal()}
          success
          show={showSuccess}
          successText=""
          typeModal="success"
        >
          {successType === 'credit_card' && (
            <>
              <h1>Pedido de recarga recebido com sucesso.</h1>
              <br /> * Atenção! As compras na modalidade cartão de crédito estão
              limitados a 1 compra diária por usuário.
              <div
                style={{
                  backgroundColor: 'var(--orange)',
                  padding: '10px',
                  borderRadius: '11px',
                }}
              >
                <p style={{ color: 'white' }}>
                  Seus créditos estarão disponíveis para validação dentro dos
                  ônibus convencionais a partir de 2 horas após solicitação de
                  recarga. <br /> Para SUPLEMENTARES ou METRÔ, a validação será
                  possível 72 horas úteis após a aprovação da compra.
                </p>
              </div>
            </>
          )}
          {successType === 'boleto' && (
            <>
              <h1>Pedido de recarga recebido com sucesso.</h1>
              <p>
                <a href={boletoLink} target="_blank" rel="noreferrer">
                  Clique aqui &nbsp;
                </a>
                e baixe o boleto. Em até 72 horas após o pagamento do boleto os
                créditos estarão disponíveis.
                <div
                  style={{
                    backgroundColor: 'var(--orange)',
                    padding: '10px',
                    borderRadius: '10px',
                  }}
                >
                  <p style={{ color: 'white' }}>
                    Seus créditos estarão disponíveis para validação dentro dos
                    ônibus convencionais a partir de 2 horas após solicitação de
                    recarga. <br /> Para SUPLEMENTARES ou METRÔ, a validação
                    será possível 72 horas úteis após a aprovação da compra.
                  </p>
                </div>
              </p>
            </>
          )}
        </Modal>
      )}
      {modalPhone && (
        <ModalText
          handleClose={() => setModalPhone(false)}
          show={modalPhone}
          title="Dados Incompletos"
        >
          <ModalContentBanner>
            <div className="text">
              <p>
                Verificamos que existem dados obrigatórios não preenchidos no
                seu cadastro.
              </p>
              <p>Complete seu cadastro antes de prosseguir.</p>
            </div>
            <Button
              color="orange"
              padding="3.1"
              rounded
              className="button-confirmar"
              type="button"
              onClick={() => handleRedirect('/meu-perfil')}
            >
              CADASTRAR
            </Button>
          </ModalContentBanner>
        </ModalText>
      )}
      {modalCpf && (
        <ModalText
          handleClose={() => setModalCpf(false)}
          show={modalCpf}
          title="Dados Incompletos"
        >
          <ModalContentBanner>
            <div className="text">
              <p>
                Verificamos que existem dados obrigatórios não preenchidos no
                seu cadastro.
              </p>
              <p>Complete seu cadastro antes de prosseguir.</p>
            </div>
            <Button
              color="orange"
              padding="3.1"
              rounded
              className="button-confirmar"
              type="button"
              onClick={() => handleRedirect('/meu-perfil')}
              style={{
                marginTop: '50px',
                minHeight: '20px',
              }}
            >
              CADASTRAR
            </Button>
          </ModalContentBanner>
        </ModalText>
      )}

      {showSuccess && successType === 'pix' && (
        <>
          <ModalPix
            closeModal={() => closeModal()}
            codeExpiration={pixExpiration}
            codePix={pixCode}
            codeImage={pixImage}
          />
        </>
      )}
      {showAddPayment && (
        <AddPaymentCard
          show={showAddPayment}
          title="Adicionar Cartão"
          handleClose={() => setShowAddPayment(false)}
          handleAdd={handleAddCreditCard}
        />
      )}
    </Container>
  );
};

export default RechargerCard;
