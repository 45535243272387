import styled, { css } from 'styled-components';

interface ActiveProps {
  active: boolean;
  titleColor?: string;
}

export const Container = styled.div`
  width: 100%;
`;

export const AccordionItem = styled.div`
  background: var(--white);
  width: 100%;

  border-radius: 20.5px;

  display: flex;
  flex-direction: column;

  .icon-container {
    background-color: transparent;
    width: 100%;
    display: flex;
    align-items: center;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: normal;
    line-height: 16px;
    color: #626162;

    svg {
      font-size: 20px;
    }
  }

  ul {
    list-style-type: square !important;
  }
`;

export const CollapseContainer = styled.div<ActiveProps>`
  transition: all 0.5s ease;
  margin-top: 10px;
  min-height: 0;
  height: 0;
  transition: all 0.2s ease-out;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;

  span {
    color: var(--title-higlight);
    font-style: normal;
    font-weight: normal;
    font-size: 1.1rem;
    line-height: 1.1rem;
  }

  ${props =>
    props.active &&
    css`
      min-height: 30px;
      height: auto;
      transition: all 0.25s ease-in;
    `}
`;

export const InitialContainer = styled.div<ActiveProps>`
  display: flex;

  p {
    color: red !important;
  }

  .ball-container {
    margin-right: 15px;
    display: flex;
    align-items: flex-start;
    height: 100%;

    div {
      width: 66px;
      height: 66px;
      border-radius: 50%;
      background: var(--soft-orange);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .text-container {
    width: 100%;
    font-family: 'Source Sans Pro' !important;

    > div {
      display: flex;
      justify-content: space-between;
    }

    h1 {
      color: ${props =>
        props.titleColor ? `var(--${props.titleColor})` : 'var(--orange)'};

      font-size: 1.75rem;
      line-height: 1.7rem;
      @media (max-width: 685px) {
        font-size: 1.50rem;
      }
      @media (max-width: 578px) {
        font-size: 1.25rem;
      }
    }
    .subtitle-container {
      
  }

  button {
    color: var(--orange);
    transition: all 0.5s ease;
    cursor: pointer;
    background: transparent;
    height: 0;

    svg {

      ${props =>
        props.active &&
        css`
          transform: rotate(90deg);
        `}
    }
  }
`;
