import styled from 'styled-components';

export const TitleSection = styled.section`
  height: 220px;
  width: 98%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--white);

  h1 {
    font-size: 2.9rem;
    line-height: 4rem;
    @media (max-width: 768px) {
      margin-top: 3rem;
      text-align: center;
    }
  }

  span {
    font-size: 1rem;
    line-height: 2.5rem;
    font-weight: normal;
    @media (max-width: 768px) {
      text-align: center;
    }
    @media (max-width: 547px) {
      line-height: 1.9rem;
    }
  }
`;

export const GridSection = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'main';
  grid-gap: 3rem;
  min-height: 100vh;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Main = styled.main`
  grid-area: main;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  padding: 1.5rem 0;

  h3 {
    color: var(--purple);
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .form {
    width: 100%;
  }
  .btn-buscar {
    color: white;
    margin: 0;
    @media (max-width: 450px) {
      display: none;
    }
  }
  .btn-search {
    height: 30px;
    width: auto;
    display: none;
    @media (max-width: 450px) {
      padding-bottom: 5px;
      display: block;
    }
  }
  .card-span {
    background-color: var(--orange);
    color: var(--white);
    border-radius: 5px;
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  .card {
    display: flex;
    padding-right: 10px;
    background: white;
    border-radius: 35px 0px 0px 35px;
    border: 0.2px solid #a1aeb7;

    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    height: 250px;
    overflow: hidden;

    @media (max-width: 531px) {
      border: none;
      flex-direction: column;
      height: 450px;
      text-align: center;
      align-items: center;
      border-radius: 0;
      padding: 0;
    }

    img {
      height: 250px;
      width: 250px;
    }

    .card-subtitle,
    h3 {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (max-width: 531px) {
        margin-right: 10px;
      }
    }
  }
  .card + .card {
    margin-left: 30px;
  }
  @media (max-width: 1190px) {
    .card + .card {
      margin-left: 0px;
      margin-top: 20px;
    }
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  .categoryContainer {
    display: flex;
    align-items: center;
    width: 20px;
    span {
      display: flex;
      align-items: center;
      padding: 2px 5px;
      margin-right: 3px;
    }
    @media (max-width: 531px) {
      flex-direction: column;
      width: 100%;
    }
  }
`;
export const FlexRowContainer = styled.div`
  display: flex;
  margin-top: 35px;

  .emptyNews {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1190px) {
    display: block;
  }
`;
