import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';

const PoliticsAndTermsSession: React.FC = () => {
  return (
    <Container className="termos">
      * Ver{' '}
      <span className="text-purple">
        <Link to="/termos-de-uso">Temos e condições de uso</Link>
      </span>{' '}
      e{' '}
      <span className="text-purple">
        <Link to="politica-privacidade">Políticas de Privacidade</Link>
      </span>{' '}
      e segurança.
    </Container>
  );
};

export default PoliticsAndTermsSession;
