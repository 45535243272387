import styled from 'styled-components';

export const TitleSection = styled.section`
  margin: 60px 0px 70px 0px;
  color: var(--purple);

  h1 {
    font-size: 3rem;
    line-height: 2.3rem;
  }
`;

export const Main = styled.main`
  width: 100%;

  .header {
    background-color: var(--aside-background);
    strong {
      color: var(--purple);
    }
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: green;
    color: yellow;
    text-align: center;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .verPix {
    background: transparent;
    color: rgb(9, 85, 165);
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 0.875rem;
  }
`;
