/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
/* eslint-disable react/button-has-type */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { GoTriangleRight } from 'react-icons/go';
import { Mail } from '@material-ui/icons';

import Button from '../../components/Button';
import Select from '../../components/Select';
import Input from '../../components/Input';
import ModalMotiva from './Components/ModalMotiva';

import Accordion from '../../components/Accordion';
import { Container, Content, Blur } from '../../styles/components';
import { isValidCpf } from '../../utils/isValidCpf';
import { isValidCnpj } from '../../utils/isValidCnpj';
import {
  TitleSection,
  GridSection,
  Main,
  Aside,
  AsideCard,
  Circle,
  CircleWraper,
  CircleContainer,
  BodyForm,
  DialogContent,
  Contracts,
  ContractSession,
  ContractSessionContainer,
  Modal,
  FileDiv,
} from './styles';

import check from '../../assets/ParaEmpresas/check.svg';
import circle01 from '../../assets/ParaEmpresas/paraEmpresas_01.svg';
import circle02 from '../../assets/ParaEmpresas/paraEmpresas_02.svg';
import circle04 from '../../assets/ParaEmpresas/paraEmpresas_04.svg';
import Balao from '../../assets/ParaEmpresas/balao.svg';
import Header from '../../components/Home/Header';
import NavBar from '../../components/Home/NavBar';
import Footer from '../../components/Home/Footer';

import FaqSection from '../../components/FaqSection';
import CoreService from '../../services/core';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';
import FileInputSpecial from './Components/FileInputSpecial';
import api from '../../services/api';
// import LinkedInTracking from '../../components/LinkedInTracking';

declare global {
  interface Window {
    lintrk: (action: string, options: Record<string, any>) => void;
  }
}

const ParaEmpresas: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const services = { coreService: new CoreService() };
  const { addToast } = useToast();
  const [modalMotiva, setModalMotiva] = useState(true);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [categoriaSet, setCategoriaSet] = useState('');
  const [colaboradorSet, setColaboradorSet] = useState(0);
  const [iscnpj, setIscnpj] = useState<boolean>(true);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type === 'application/pdf') {
      setSelectedFile(file);
    } else {
      alert('Por favor, selecione um arquivo pdf!');
    }
  };

  // const [contract, setContract] = React.useState<string>('');

  const modalidadeOptions = [
    { value: 'carga_a_bordo', label: 'Carga a bordo' },
    // { value: 'carga_nos_postos', label: 'Carga nos postos' },
  ];

  const categoriaOptions = [
    { value: 'cpf', label: 'CPF' },
    { value: 'cnpj', label: 'CNPJ' },
    // { value: 'carga_nos_postos', label: 'Carga nos postos' },
  ];

  function showContactModal() {
    const opencontact = document.getElementById('contact');
    opencontact.style.display = 'block';

    setTimeout(() => {
      opencontact.style.display = 'none';
    }, 5000);
  }

  const schema = Yup.object().shape({
    modality: Yup.string().required('Modalidade é obrigatória'),
    company_name: Yup.string().required('Empresa é obrigatório'),
    aplicant_name: Yup.string().required('Nome é obrigatório'),
    email: Yup.string()
      .required('E-mail é obrigatório')
      .email('Digite um email válido'),
    phone: Yup.string()
      .required('Telefone é obrigatório')
      .min(14, 'Telefone é obrigatório'),
    employee_number: Yup.string().required(
      'Número de Colaboradores é obrigatório',
    ),
    company_document: Yup.string()
      .test(
        'cpf-cnpj-validation',
        value => (value.value.length <= 14 ? 'CPF Inválido' : 'CNPJ Inválido'),
        value => {
          if (value) {
            const isCpf = value.length <= 14;
            return isCpf ? isValidCpf(value) : isValidCnpj(value);
          }
          return true;
        },
      )
      .required('CPF ou CNPJ é obrigatório'),
    inscricaofile: Yup.string(),
    contratofile: Yup.string(),
    esocialfile: Yup.string(),
    cpffile: Yup.string(),
    categoria: Yup.string(),
    address_proof: Yup.string(),
  });

  const handleChangeColaborador = (event: any) => {
    const newValue = parseInt(event?.target?.value || '', 10);
    console.log(newValue);
    setColaboradorSet(newValue);
  };

  const handleChange = (event: any) => {
    const newValue = event?.value;
    console.log(newValue);
    setCategoriaSet(newValue);
  };

  const handleSubmit = useCallback(
    async data => {
      const formData = new FormData();

      formData.append('aplicant_name', data?.aplicant_name);
      formData.append('company_name', data?.company_name);
      formData.append('company_document', data?.company_document);
      formData.append('email', data?.email);
      formData.append('modality', data?.modality);
      formData.append('phone', data?.phone);
      formData.append('employee_number', data?.employee_number);
      if (data?.cpffile) {
        formData.append('document_file', data?.cpffile);
      }
      if (data?.inscricaofile) {
        formData.append('municipal_registration_file', data?.inscricaofile);
      }
      if (data?.inscricao) {
        formData.append('municipal_registration', data?.inscricao);
      }
      if (data?.contratofile) {
        formData.append('social_contract_file', data?.contratofile);
      }
      if (data?.esocialfile) {
        formData.append('cards_file', data?.esocialfile);
      }

      if (data?.categoria === 'cnpj') {
        formData.append('is_cnpj', 'true');
      } else {
        formData.append('is_cnpj', 'false');
      }
      if (data?.address_proof) {
        formData.append('address_proof', data?.address_proof);
      }
      try {
        console.log(data);
        formRef.current?.setErrors({});

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/companies/empresas/contrate-aqui/', formData);

        // await services.coreService.createLead(
        //   data.modality,
        //   data.company_name,
        //   data.company_document,
        //   data.aplicant_name,
        //   data.email,
        //   telefones,
        //   data.employee_number,
        //   data?.cpffile,
        //   data?.inscricaofile,
        //   data?.inscricao,
        // );

        addToast({
          type: 'success',
          title: `Informações Recebidas`,
          description: `Aguarde o contato dos nossos consultores.`,
        });

        // window.lintrk('track', { conversion_id: 16079305 });

        showContactModal();

        formRef.current.reset();
      } catch (err: any) {
        const { ...error } = err;
        console.log(error);
        console.log(error?.response);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        if (error?.response) {
          if (!error?.response?.data?.message) {
            if (error?.response?.data?.document_file) {
              addToast({
                type: 'error',
                title: `Erro no Documento`,
                description: `Verifique o documento de CNPJ/CPF`,
              });
            } else if (error?.response?.data?.cards_file) {
              addToast({
                type: 'error',
                title: `Erro no Documento`,
                description: `Verifique o documento ESOCIAL`,
              });
            } else if (error?.response?.data?.municipal_registration) {
              addToast({
                type: 'error',
                title: `Erro no Documento`,
                description: `Verifique o documento de Inscrição municipal`,
              });
            } else if (error?.response?.data?.social_contract_file) {
              addToast({
                type: 'error',
                title: `Erro no Documento`,
                description: `Verifique o documento de Contrato Social`,
              });
            } else if (error?.response.data?.message) {
              addToast({
                type: 'error',
                title: `${
                  error?.response?.data?.message
                    ? error?.response?.data?.message
                    : error?.response?.data?.detail
                }`,
                description: `${
                  error?.response?.data?.message
                    ? error?.response?.data?.message
                    : error?.response?.data?.detail
                }`,
              });
            } else {
              addToast({
                type: 'error',
                title: `Erro no Documento`,
                description: `Verifique se todos os dados estão preenchidos e anexados.`,
              });
            }
          }
        } else {
          addToast({
            type: 'error',
            title: `Erro no Documento`,
            description: `Verifique se todos os dados estão preenchidos e anexados.`,
          });
        }
      }
    },
    [schema, services.coreService, addToast],
  );

  return (
    <>
      <Container>
        <Content>
          <Header />
        </Content>
      </Container>
      <Container backgroundImage className="companies-container">
        <Blur>
          <Content className="content">
            <NavBar />
            <TitleSection>
              <h1 className="h1">
                Vale Transporte: Facilidade para sua empresa e praticidade para
                seus colaboradores
              </h1>
              <span>
                Confira o passo a passo para a contratação do serviço:
              </span>
            </TitleSection>
          </Content>
        </Blur>
      </Container>
      <Container>
        <Content
          style={{
            maxWidth: '100%',
          }}
        >
          <GridSection>
            <Main>
              <CircleContainer>
                <CircleWraper>
                  <Circle>
                    <img src={circle01} alt="Cursor clicando em um quadrado" />
                  </Circle>
                  <span>
                    Conheça a modalidade que mais combina com o seu negócio
                  </span>
                </CircleWraper>

                <CircleWraper>
                  <Circle>
                    <img src={circle02} alt="Formulario sendo preenchido" />
                  </Circle>
                  <span>
                    Preencha o formulário com os dados básicos da empresa
                  </span>
                </CircleWraper>

                <CircleWraper>
                  <Circle>
                    <img
                      src={Balao}
                      alt="Um balão de fala com reticências dentro"
                      className="balao"
                    />

                    <Mail
                      style={{ fill: 'var(--purple)', fontSize: 35 }}
                      className="envelope"
                    />
                  </Circle>
                  <span>
                    Você vai receber o contato de um consultor para dar início
                    ao processo de contratação digital
                  </span>
                </CircleWraper>

                <CircleWraper>
                  <Circle>
                    <img src={circle04} alt="Quadrado com um sinal de check" />
                  </Circle>
                  <span>
                    Tudo pronto! Agora você já pode utilizar de todas as
                    ferramentas do vale transporte.
                  </span>
                </CircleWraper>
              </CircleContainer>

              <h2 className="h2">Conheça as modalidades disponíveis</h2>
              <div className="accordion">
                <Accordion
                  CollapseIcon={GoTriangleRight}
                  title="Carga a bordo"
                  titleColor="purple3"
                >
                  Modalidade que permite a compra por meio eletrônico de
                  créditos de vale-transporte de acordo com a necessidade de
                  utilização de cada colaborador.
                  <p>
                    A recarga dos cartões acontece dentro de qualquer ônibus do
                    sistema municipal, através do validador.
                  </p>
                  <span className="destaque">PONTOS IMPORTANTES</span>
                  <p>
                    O Transfácil empresta os cartões sem custo. Basta
                    devolvê-los em bom estado. Nesta modalidade a sua empresa
                    tem como benefício a total gestão via web: funcionalidades
                    como emissão de relatórios, compra de créditos on line,
                    saldo, bloqueio de cartões e muito mais. Por isso é cobrado
                    o valor de 1% sobre o total de cada pedido de créditos de
                    Vales-transporte.
                  </p>
                  <p>
                    O contrato é por prazo indeterminado. Você poderá encerrá-lo
                    quando quiser. A validade dos créditos é de 180 dias podendo
                    ser revalidados uma única vez.
                  </p>
                </Accordion>
              </div>
              <div className="accordion">
                <Accordion
                  CollapseIcon={GoTriangleRight}
                  title="Carga nos postos"
                  titleColor="purple3"
                >
                  Modalidade que permite às empresas adquirirem para seus
                  colaboradores créditos eletrônicos de vale-transporte, com
                  recargas dos cartões diretamente no posto de venda do
                  TRANSFÁCIL.
                </Accordion>
              </div>
              <Contracts>
                <h2>Contratação de Vale Transporte para Pessoa Jurídica:</h2>
                <ContractSession>
                  <ContractSessionContainer>
                    <p>
                      Caso opte por assinar o contrato fisicamente, ele deve ser
                      entregue juntamente com toda a documentaçao necessária no
                      posto de atendimento Floresta - Rua Aquiles Lobo, 504, de
                      08:00 as 17:00 horas.
                    </p>
                    <p>
                      <strong>
                        Atenção: Para contratação presencial é necessario trazer{' '}
                        <strong style={{ color: 'var(--orange)' }}>
                          duas vias
                        </strong>{' '}
                        do contrato preenchidas e assinadas.
                      </strong>
                    </p>
                    <p className="buttons">
                      <a
                        href="/files/contrato_carga-a-bordo_pj.pdf"
                        target="_blank"
                      >
                        <Button color="" padding="2" className="button">
                          Contrato Carga a Bordo
                        </Button>
                      </a>
                      <a
                        href="/files/contrato_carga-nos-postos_pj.pdf"
                        target="_blank"
                      >
                        <Button color="" padding="1.4" className="button">
                          Contrato Carga nos Postos
                        </Button>
                      </a>
                    </p>
                  </ContractSessionContainer>
                </ContractSession>
                <h2 className="pessoaFisica">
                  Contratação de Vale Transporte para Pessoa Física:
                </h2>
                <ContractSession>
                  <ContractSessionContainer>
                    <p>
                      Caso opte por assinar o contrato fisicamente, ele deve ser
                      entregue juntamente com toda a documentaçao necessária no
                      posto de atendimento Floresta - Rua Aquiles Lobo, 504, de
                      08:00 as 17:00 horas.
                    </p>
                    <p>
                      <strong>
                        Atenção: Para contratação presencial é necessario trazer{' '}
                        <strong style={{ color: 'var(--orange)' }}>
                          duas vias
                        </strong>{' '}
                        do contrato preenchidas e assinadas.
                      </strong>
                    </p>
                    <p className="buttons">
                      <a
                        href="/files/contrato_carga-a-bordo_pf.pdf"
                        target="_blank"
                      >
                        <Button color="" padding="2" className="button">
                          Contrato Carga a Bordo
                        </Button>
                      </a>
                      <a
                        href="/files/contrato_carga-nos-postos_pf.pdf"
                        target="_blank"
                      >
                        <Button color="" padding="1.4" className="button">
                          Contrato Carga nos Postos
                        </Button>
                      </a>
                    </p>
                  </ContractSessionContainer>
                </ContractSession>
              </Contracts>
            </Main>
            <Aside>
              <AsideCard>
                <div className="header">
                  <h2>Contrate aqui</h2>
                  <span>
                    Envie os dados da empresa para que nossos consultores entrem
                    em contato com você.
                  </span>
                </div>
                <div className="main">
                  <Form ref={formRef} onSubmit={handleSubmit}>
                    <BodyForm>
                      <div className="input-container">
                        <p className="highlight-label">
                          <span className="numeros">
                            Qual a sua categoria? CPF ou CNPJ
                          </span>
                        </p>
                        <Select
                          name="categoria"
                          placeholder="Categoria"
                          options={categoriaOptions}
                          className="select"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="input-container">
                        <p className="highlight-label">
                          <span className="numeros">1. </span>Escolha a
                          modalidade <span className="asterisk">*</span>
                        </p>
                        <Select
                          name="modality"
                          placeholder="Modalidade"
                          options={modalidadeOptions}
                          className="select"
                        />
                      </div>
                      <div className="input-container">
                        <p className="highlight-label">
                          <span className="numeros">2. </span>Nome da empresa
                          <span className="asterisk"> *</span>
                        </p>
                        <Input
                          name="company_name"
                          placeholder="Nome da Empresa"
                        />
                      </div>
                      {categoriaSet === 'cnpj' || categoriaSet === 'cpf' ? (
                        categoriaSet === 'cnpj' ? (
                          <div className="input-container">
                            <p className="highlight-label">
                              <span className="numeros">3. </span> CNPJ
                              <span className="asterisk"> *</span>
                            </p>
                            <Input
                              cpforcnpj
                              name="company_document"
                              placeholder="CNPJ"
                            />

                            <>
                              <p className="highlight-label">
                                Arquivo contendo o cartão CNPJ.
                              </p>
                              <FileDiv>
                                <FileInputSpecial
                                  name="cpffile"
                                  title="Selecione um Arquivo"
                                />
                              </FileDiv>
                            </>
                          </div>
                        ) : (
                          <div className="input-container">
                            <p className="highlight-label">
                              <span className="numeros">3. </span> CPF
                              <span className="asterisk"> *</span>
                            </p>
                            <Input
                              cpforcnpj
                              name="company_document"
                              placeholder="CPF"
                            />

                            <>
                              <p className="highlight-label">
                                Arquivo contendo a identidade.
                              </p>
                              <FileDiv>
                                <FileInputSpecial
                                  name="cpffile"
                                  title="Selecione um Arquivo"
                                />
                              </FileDiv>
                            </>
                          </div>
                        )
                      ) : (
                        <div className="input-container">
                          <p className="highlight-label">
                            <span className="numeros">3. </span> CPF ou CNPJ
                            <span className="asterisk"> *</span>
                          </p>
                          <Input
                            cpforcnpj
                            name="company_document"
                            placeholder="CPF ou CNPJ"
                          />
                        </div>
                      )}
                      <div className="input-container">
                        <p className="highlight-label">
                          <span className="numeros">4. </span> Nome do Contato
                          <span className="asterisk"> *</span>
                        </p>
                        <Input
                          name="aplicant_name"
                          placeholder="Nome do Contato"
                        />
                      </div>
                      <div className="input-container">
                        <p className="highlight-label">
                          <span className="numeros">5. </span> E-mail
                          <span className="asterisk"> *</span>
                        </p>
                        <Input name="email" placeholder="E-mail" />
                      </div>
                      <div className="input-container">
                        <p className="highlight-label">
                          <span className="numeros">6. </span> Telefone
                          <span className="asterisk"> *</span>
                        </p>
                        <Input
                          phone
                          name="phone"
                          placeholder="(xx) xxxxx-xxxx"
                        />
                        <Input
                          phone
                          name="phone2"
                          placeholder="(xx) xxxxx-xxxx"
                        />
                      </div>
                      <div className="input-container">
                        <p className="highlight-label">
                          <span className="numeros">7. </span> Número de
                          Colaboradores<span className="asterisk"> *</span>
                        </p>
                        <Input
                          type="number"
                          name="employee_number"
                          placeholder="Nº de Colaboradores"
                          onChange={handleChangeColaborador}
                        />
                      </div>
                      {colaboradorSet >= 21 && categoriaSet === 'cnpj' && (
                        <div className="input-container">
                          <p className="highlight-label">
                            {categoriaSet === 'cnpj' ? (
                              <span className="numeros">8. </span>
                            ) : (
                              <span className="numeros">8. </span>
                            )}
                            ESOCIAL / CAGED/ GEFIP
                            <span className="asterisk"> *</span>
                          </p>
                          <FileDiv>
                            <FileInputSpecial
                              name="esocialfile"
                              title="Selecione um Arquivo"
                            />
                          </FileDiv>
                        </div>
                      )}
                      {categoriaSet === 'cpf' && (
                        <>
                          <div className="input-container">
                            <p className="highlight-label">
                              <span className="numeros">8. </span>
                              ESOCIAL de um funcionário
                              <span className="asterisk"> *</span>
                            </p>
                            <FileDiv>
                              <FileInputSpecial
                                name="esocialfile"
                                title="Selecione um Arquivo"
                              />
                            </FileDiv>
                          </div>
                          <div className="input-container">
                            <p className="highlight-label">
                              <span className="numeros">9. </span>
                              Comprovante de Endereço
                              <span className="asterisk"> *</span>
                            </p>
                            <FileDiv>
                              <FileInputSpecial
                                name="address_proof"
                                title="Selecione um Arquivo"
                              />
                            </FileDiv>
                          </div>
                        </>
                      )}
                      {categoriaSet === 'cnpj' && (
                        <>
                          <div className="input-container">
                            <p className="highlight-label">
                              {colaboradorSet >= 21 ? (
                                <span className="numeros">9. </span>
                              ) : (
                                <span className="numeros">8. </span>
                              )}
                              Inscrição Municipal
                              <span className="asterisk"> *</span>
                            </p>
                            <Input
                              numberinsc
                              type="number"
                              name="inscricao"
                              placeholder="Inscrição
                          Municipal"
                            />
                            <FileDiv>
                              <FileInputSpecial
                                name="inscricaofile"
                                title="Selecione um Arquivo"
                              />
                            </FileDiv>
                          </div>
                          <div className="input-container">
                            <p className="highlight-label">
                              {colaboradorSet >= 21 ? (
                                <span className="numeros">10. </span>
                              ) : (
                                <span className="numeros">9. </span>
                              )}
                              Contrato Social / Estatuto / Ata de Nomeação /
                              Ultima Alteração Contratual
                              <span className="asterisk"> *</span>
                            </p>
                            <FileDiv>
                              <FileInputSpecial
                                name="contratofile"
                                title="Selecione um Arquivo"
                              />
                            </FileDiv>
                          </div>
                        </>
                      )}
                    </BodyForm>
                    <div className="footer">
                      <Button
                        type="submit"
                        color="orange"
                        padding="0"
                        className="orange-button"
                        rounded
                      >
                        Enviar Dados
                      </Button>
                      <Modal id="contact">
                        <DialogContent>
                          <div className="container">
                            <div className="text">
                              <img
                                src={check}
                                alt="imagem confirmando as informações recebidas"
                                className="img"
                              />
                              <h1>Informações recebidas!</h1>
                              <p>Aguarde o contato dos nossos consultores.</p>
                            </div>
                          </div>
                        </DialogContent>
                      </Modal>
                    </div>
                  </Form>
                </div>
              </AsideCard>
            </Aside>
          </GridSection>
        </Content>
        <Content>
          <FaqSection />
        </Content>
        {modalMotiva && (
          <ModalMotiva
            show={modalMotiva}
            text="Digite o ID numérico do titular e você receberá no email cadastrado instruções."
            handleClose={() => setModalMotiva(false)}
          />
        )}
        {/* <LinkedInTracking /> */}
      </Container>
      <Container>
        <Footer />
      </Container>
    </>
  );
};

export default ParaEmpresas;
