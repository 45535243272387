/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { HTMLAttributes, useCallback } from 'react';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { MdQrCode } from 'react-icons/md';
import { RxEyeClosed, RxEyeOpen, RxMinus, RxReload } from 'react-icons/rx';
import {
  useHistory,
  useRouteMatch,
  matchPath,
  useLocation,
} from 'react-router-dom';
import api from '../../../services/api';

import {
  Container,
  Nickname,
  CardNumber,
  TransfacilCard,
  InfoDiv,
} from './styles';

import { formatPrice } from '../../../services/currency';

import LogoCartao from '../../../assets/MyCards/BHBUS.png';
import Loader from '../../Loader';

interface CardProps extends HTMLAttributes<HTMLElement> {
  nickname?: string;
  cardNumber?: string;
  selected?: boolean;
  saldo?: number;
  id?: any;
  active?: boolean;
  qrcode?: boolean;
  register?: boolean;
  mainmenu?: boolean;
  recharge?: boolean;
  eye?: boolean;
  info?: boolean;
}

const Card: React.FC<CardProps> = ({
  nickname,
  cardNumber,
  selected,
  saldo,
  id,
  active,
  qrcode,
  register,
  mainmenu,
  recharge,
  eye,
  info,
  ...rest
}) => {
  const { push } = useHistory();
  const { path, url } = useRouteMatch();

  const { pathname } = useLocation();
  const [cartãoIdent, setCartãoIdent] = React.useState<boolean>();
  const [eyechange, setEyechange] = React.useState<boolean>(false);
  const [saldocartao, setSaldoCartao] = React.useState();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [dadoscart, setDadoscart] = React.useState([]);
  const [novosaldo, setNovoSaldo] = React.useState<any>();
  // const [checked, setChecked] = useState<boolean>(true);
  // const toggleChecked = () => {
  //   if (checked) {
  //     setChecked(false);
  //   } else {
  //     setChecked(true);
  //   }
  // };

  // const theme = createMuiTheme({
  //   palette: {
  //     primary: orange,
  //   },
  // });

  const handleRedirect = useCallback(
    (pathLink: any, eyechangeValue, saldocartaovalue) => {
      if (eyechangeValue) {
        push(
          `${pathLink}?eyechange=${eyechangeValue}&saldocarta=${saldocartaovalue}`,
        );
      } else {
        push(`${pathLink}`);
      }
    },
    [push],
  );

  const handleUrlMatch = (activeUrl: string): boolean => {
    const match = matchPath(pathname, {
      path: activeUrl,
      exact: true,
    });

    return match ? match.isExact : false;
  };

  const handleSaldo = React.useCallback(async () => {
    try {
      if (eyechange) {
        setEyechange(false);
      } else if (!eyechange) {
        setLoading(true);
        setEyechange(true);

        const response = await api.get(`/ticketing/my-card/${id}/balance`);

        setSaldoCartao(response?.data?.card?.Saldo);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [eyechange, dadoscart, id]);

  function verificarNumeroIdentificado() {
    // Convertendo o número para string para facilitar a verificação dos primeiros dígitos
    const numeroString = cardNumber.toString();

    // Verificando se os primeiros 4 dígitos correspondem a "0362"
    const primeirosDigitos = numeroString.slice(0, 4);

    const corresponde = primeirosDigitos === '0362';
    setCartãoIdent(corresponde);
  }
  React.useEffect(() => {
    verificarNumeroIdentificado();
  }, []);

  const handleRedirectRecarga = () => {
    if (eyechange) {
      window.location.href = `/meus-cartoes/recarregar/${id}?eyechange=${eyechange}&saldocarta=${
        saldocartao || novosaldo
      }`;
    } else {
      window.location.href = `/meus-cartoes/recarregar/${id}`;
    }
  };

  const handleRedirectDetalhes = () => {
    window.location.href = `/meus-cartoes/recarregar/${id}`; // Redireciona para a página do QR Code
  };
  const style = { color: 'white', fontSize: '1.5em' };

  React.useEffect(() => {
    if (eye) {
      setEyechange(true);
    }
  }, [eye]);

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const eyechangeParam = params.get('eyechange');
    const saldoParam = params.get('saldocarta');

    if (eyechangeParam === 'true') {
      setEyechange(true);
      const saldoP = JSON.parse(saldoParam);
      setNovoSaldo(JSON.parse(saldoParam));
    } else {
      // Defina o estado de eyechange como falso
      setEyechange(false);
    }
  }, [location.search]);

  return (
    <>
      {qrcode && !register && !mainmenu && !recharge && !info && (
        <Container
          {...rest}
          selected={selected}
          active={active}
          style={{
            maxWidth: '250px',
          }}
        >
          <div role="button" className="PrincipalDiv" key={id} tabIndex={-1}>
            <div className="middlecardComplete">
              <div className="middlecard">
                <div
                  className="logocartao-container"
                  style={{
                    marginLeft: '10%',
                    marginTop: '15%',
                  }}
                >
                  <div className="icon">
                    <MdQrCode color="white" fill="white" />
                  </div>
                </div>
                <div
                  className="middlecard2"
                  style={{
                    justifyContent: 'flex-end',
                  }}
                >
                  <p className="saldotext">SALDO QR CODE</p>
                  <h3 className="saldo">{formatPrice(saldo)}</h3>
                </div>
              </div>
            </div>
          </div>
          <CardNumber>{cardNumber}</CardNumber>
        </Container>
      )}
      {!qrcode && !register && mainmenu && !recharge && !info && (
        <Container {...rest} selected={selected} active={active}>
          <div
            role="button"
            className="PrincipalDiv"
            onClick={() => {
              handleRedirect(`${path}/cartao/${id}`, eyechange, saldocartao);
            }}
            onKeyDown={() => {
              handleRedirect(`${path}/cartao/${id}`, eyechange, saldocartao);
            }}
            key={id}
            tabIndex={-1}
          >
            <div className="middlecardComplete">
              <div className="middlecard">
                <div className="logocartao-container">
                  <img src={LogoCartao} className="logocartao" />
                  <Nickname>{nickname}</Nickname>
                </div>
                {eyechange ? (
                  <div className="areasaldo">
                    {loading ? (
                      <div className="loading-icon">
                        <RxReload size={18} />
                      </div>
                    ) : (
                      <div
                        className="middlecard2"
                        style={{
                          justifyContent: 'flex-end',
                        }}
                      >
                        <p className="saldotext">SALDO</p>
                        <h3 className="saldo">{formatPrice(saldocartao)}</h3>
                      </div>
                    )}
                    <RxEyeOpen
                      onClick={(e: any) => {
                        e.stopPropagation();
                        handleSaldo();
                      }}
                      style={{
                        marginRight: '12px',
                      }}
                    />
                  </div>
                ) : (
                  <div className="areasaldo">
                    <div
                      className="middlecard2"
                      style={{
                        justifyContent: 'flex-end',
                      }}
                    >
                      <RxMinus />
                    </div>
                    <RxEyeClosed
                      onClick={(e: any) => {
                        e.stopPropagation();
                        handleSaldo();
                      }}
                      style={{
                        marginRight: '5px',
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <CardNumber>{cardNumber}</CardNumber>
          {active && (
            <TransfacilCard
              identficado={cartãoIdent}
              onClick={handleRedirectRecarga}
            >
              RECARREGAR
            </TransfacilCard>
          )}
          {/* <ThemeProvider theme={theme}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={checked}
                    onChange={toggleChecked}
                    color="primary"
                  />
                }
                label="Cartão Ativo"
                color="secondary"
                className="FormControl"
              />
            </FormGroup>
          </ThemeProvider> */}
        </Container>
      )}

      {!qrcode && !register && !mainmenu && !recharge && !info && (
        <Container {...rest} selected={selected} active={active}>
          <div role="button" className="PrincipalDiv" key={id} tabIndex={-1}>
            <div className="middlecardComplete">
              <div className="middlecard">
                <div className="logocartao-container">
                  <img src={LogoCartao} className="logocartao" />
                  <Nickname>{nickname}</Nickname>
                </div>
                {eyechange ? (
                  <div className="areasaldo">
                    {loading ? (
                      <div className="loading-icon">
                        <RxReload size={18} />
                      </div>
                    ) : (
                      <div
                        className="middlecard2"
                        style={{
                          justifyContent: 'flex-end',
                        }}
                      >
                        <p className="saldotext">SALDO</p>
                        <h3 className="saldo">{formatPrice(saldocartao)}</h3>
                      </div>
                    )}
                    <RxEyeOpen
                      onClick={(e: any) => {
                        e.stopPropagation();
                        handleSaldo();
                      }}
                      style={{
                        marginRight: '12px',
                      }}
                    />
                  </div>
                ) : (
                  <div className="areasaldo">
                    <div
                      className="middlecard2"
                      style={{
                        justifyContent: 'flex-end',
                      }}
                    >
                      <RxMinus />
                    </div>
                    <RxEyeClosed
                      onClick={(e: any) => {
                        e.stopPropagation();
                        handleSaldo();
                      }}
                      style={{
                        marginRight: '5px',
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <CardNumber>{cardNumber}</CardNumber>
          {active && (
            <InfoDiv identficado={cartãoIdent}>
              {/* <TransfacilCard
                identficado={cartãoIdent}
                onClick={() => {
                  handleRedirect(`${path}/cartao/${id}`);
                }}
              >
                DETALHES
              </TransfacilCard> */}
              <TransfacilCard
                identficado={cartãoIdent}
                onClick={handleRedirectRecarga}
              >
                RECARREGAR
              </TransfacilCard>
            </InfoDiv>
          )}
          {/* <ThemeProvider theme={theme}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={checked}
                    onChange={toggleChecked}
                    color="primary"
                  />
                }
                label="Cartão Ativo"
                color="secondary"
                className="FormControl"
              />
            </FormGroup>
          </ThemeProvider> */}
        </Container>
      )}
      {!qrcode && !register && !mainmenu && recharge && !info && (
        <Container {...rest} selected={selected} active={active}>
          <div role="button" className="PrincipalDiv" key={id} tabIndex={-1}>
            <div className="middlecardComplete">
              <div className="middlecard">
                <div className="logocartao-container">
                  <img src={LogoCartao} className="logocartao" />
                  <Nickname>{nickname}</Nickname>
                </div>
                {eyechange ? (
                  <div className="areasaldo">
                    {loading ? (
                      <div className="loading-icon">
                        <RxReload size={18} />
                      </div>
                    ) : (
                      <div
                        className="middlecard2"
                        style={{
                          justifyContent: 'flex-end',
                        }}
                      >
                        <p className="saldotext">SALDO</p>
                        <h3 className="saldo">
                          {eye
                            ? formatPrice(novosaldo)
                            : formatPrice(saldocartao)}
                        </h3>
                      </div>
                    )}
                    <RxEyeOpen
                      onClick={(e: any) => {
                        e.stopPropagation();
                        handleSaldo();
                      }}
                      style={{
                        marginRight: '12px',
                      }}
                    />
                  </div>
                ) : (
                  <div className="areasaldo">
                    <div
                      className="middlecard2"
                      style={{
                        justifyContent: 'flex-end',
                      }}
                    >
                      <RxMinus />
                    </div>
                    <RxEyeClosed
                      onClick={(e: any) => {
                        e.stopPropagation();
                        handleSaldo();
                      }}
                      style={{
                        marginRight: '5px',
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <CardNumber>{cardNumber}</CardNumber>
          {/* <ThemeProvider theme={theme}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={checked}
                    onChange={toggleChecked}
                    color="primary"
                  />
                }
                label="Cartão Ativo"
                color="secondary"
                className="FormControl"
              />
            </FormGroup>
          </ThemeProvider> */}
        </Container>
      )}

      {!qrcode && !register && !mainmenu && !recharge && info && (
        <Container {...rest} selected={selected} active={active}>
          <div role="button" className="PrincipalDiv" key={id} tabIndex={-1}>
            <div className="middlecardComplete">
              <div className="middlecard">
                <div className="logocartao-container">
                  <img src={LogoCartao} className="logocartao" />
                  <Nickname>{nickname}</Nickname>
                </div>
                <div>
                  <div>
                    {eyechange ? (
                      <div className="areasaldo">
                        {loading ? (
                          <div className="loading-icon">
                            <RxReload size={18} />
                          </div>
                        ) : (
                          <div
                            className="middlecard2"
                            style={{
                              justifyContent: 'flex-end',
                            }}
                          >
                            <p className="saldotext">SALDO</p>
                            <h3 className="saldo">
                              {eye
                                ? formatPrice(novosaldo)
                                : formatPrice(saldocartao)}
                            </h3>
                          </div>
                        )}
                        <RxEyeOpen
                          onClick={(e: any) => {
                            e.stopPropagation();
                            handleSaldo();
                          }}
                          style={{
                            marginRight: '12px',
                          }}
                        />
                      </div>
                    ) : (
                      <div className="areasaldo">
                        <div
                          className="middlecard2"
                          style={{
                            justifyContent: 'flex-end',
                          }}
                        >
                          <RxMinus />
                        </div>
                        <RxEyeClosed
                          onClick={(e: any) => {
                            e.stopPropagation();
                            handleSaldo();
                          }}
                          style={{
                            marginRight: '5px',
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CardNumber>{cardNumber}</CardNumber>
          {active && (
            <InfoDiv identficado={cartãoIdent}>
              {/* <TransfacilCard
                identficado={cartãoIdent}
                onClick={() => {
                  handleRedirect(`${path}/cartao/${id}`);
                }}
              >
                DETALHES
              </TransfacilCard> */}
              <TransfacilCard
                identficado={cartãoIdent}
                onClick={handleRedirectRecarga}
              >
                RECARREGAR
              </TransfacilCard>
            </InfoDiv>
          )}
          {/* <ThemeProvider theme={theme}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={checked}
                    onChange={toggleChecked}
                    color="primary"
                  />
                }
                label="Cartão Ativo"
                color="secondary"
                className="FormControl"
              />
            </FormGroup>
          </ThemeProvider> */}
        </Container>
      )}

      {register && !qrcode && !mainmenu && !recharge && !info && (
        <Container
          {...rest}
          selected={selected}
          active={active}
          style={{
            maxWidth: '250px',
          }}
        >
          <div role="button" className="PrincipalDiv" key={id} tabIndex={-1}>
            <div className="middlecardComplete">
              <div className="middlecard">
                <div className="logocartao-container">
                  <img src={LogoCartao} className="logocartao" />
                  <Nickname>{nickname}</Nickname>
                </div>
                <div
                  className="middlecard2"
                  style={{
                    justifyContent: 'flex-end',
                  }}
                />
              </div>
            </div>
          </div>
          <CardNumber>{cardNumber}</CardNumber>
        </Container>
      )}
    </>
  );
};

export default Card;
