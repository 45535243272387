import styled from 'styled-components';
import { Form } from '@unform/web';

export const ContainerFaq = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 100%;
  margin-top: 0%;
  max-width: 1140px;

  .h1 {
    color: #472b74;
    justify-self: start;
    align-self: start;
    margin-left: 0;
    margin-bottom: 20px;
    @media (max-width: 560px) {
      display: flex;
      justify-self: center;
      align-self: center;
      text-align: center;
      justify-content: center;
      align-items: center;
      margin-left: 0;
      margin-top: 2%;
    }
  }
  @media (max-width: 1524px) {
    flex-wrap: wrap;
    display: flex;
  }

  .ver-mais {
    display: none;
    cursor: pointer;
    @media (max-width: 731px) {
      display: flex;
      justify-content: center;
      h2 {
        color: var(--orange);
      }
    }
  }
`;

export const Container = styled.div`
  margin-bottom: 100px;
  overflow: scroll;
  border: 2px solid #c2d1d9;
  padding: 35px;
  width: 1100px;
  max-width: 1140px;
  height: 35em;
  border-radius: 10px;
  @media (max-width: 1155px) {
    width: 100%;
  }

  /* .h1 {
    justify-self: start;
    align-self: start;
    margin-left: 10%;
    margin-bottom: 20px;
  } */

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--purple);
    border-radius: 10px;
  }
  .float-right {
    /* float: right; */
    margin-top: -30px;
  }
  .faq {
    margin-left: 78px;
  }

  @media (max-width: 560px) {
    border: 0;
    padding: 0px;
    width: 93%;
    height: 50em;
    margin-top: 50px;

    /* .h1 {
    justify-self: start;
    align-self: start;
    margin-left: 10%;
    margin-bottom: 20px;
  } */

    ::-webkit-scrollbar {
      background-color: transparent;
      width: 0px;
      height: 0px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: var(--purple);
      border-radius: 10px;
    }
    .float-right {
      /* float: right; */
      margin-top: 0px;
    }
    .faq {
      margin-left: 0px;
    }
  }
`;

export const FormSearchFaq = styled(Form)`
  margin-bottom: 1em;
  @media (max-width: 560px) {
    position: absolute;
    margin-top: -60px;
    width: 87%;
  }
  @media (max-width: 430px) {
    position: absolute;
    margin-top: -60px;
    width: 85%;
  }
  .btn-buscar {
    color: white;
    margin: 0;
    @media (max-width: 450px) {
      display: none;
    }
  }
  .btn-search {
    padding-top: 3px;
    height: 35px;
    width: auto;
    display: none;
    @media (max-width: 450px) {
      padding-bottom: 5px;
      display: block;
    }
  }
`;
