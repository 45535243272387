/* eslint-disable react/button-has-type */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from 'react';
import { FiMail, FiPhoneCall, FiKey, FiUser, FiLogOut } from 'react-icons/fi';
import { GoLocation } from 'react-icons/go';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import * as Yup from 'yup';

import { ProfileData } from '../../../pages/MeuPerfil';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';

import Button from '../../Button';
import Input from '../../Input';

import PoliticsAndTermsSession from '../PoliticsAndTermsSession';
import AccountsService from '../../../services/accounts';
import ModalChangePassword from '../ModalChangePassword';

import { Container } from './styles';
import { ButtonDeleteUser } from '../../ButtonDeleteUser';
import { useAuth } from '../../../hooks/auth';

interface ContactInformationProps {
  profile: ProfileData;
  updateStateProfile: Dispatch<SetStateAction<ProfileData>>;
}

const ContactInformation: React.FC<ContactInformationProps> = ({
  profile,
  updateStateProfile,
}) => {
  const formRef = useRef<FormHandles>();
  const [modaChangePasswordIsOpen, setModaChangePasswordIsOpen] = useState(
    false,
  );
  const services = { AccountsService: new AccountsService() };
  const { addToast } = useToast();
  const { signOut } = useAuth();

  const openModaChangePasswordIsOpen = () => {
    setModaChangePasswordIsOpen(true);
  };

  const closeModaChangePasswordIsOpen = () => {
    setModaChangePasswordIsOpen(false);
  };

  const schema = Yup.object().shape({
    email: Yup.string().email().required('Email é obrigatório'),
    address: Yup.string(),
    phone: Yup.string().required('O campo de telefone é obrigatório.'),
    full_name: Yup.string(),
    cpf: Yup.string(),
    // cpf: Yup.string().required('O campo de cpf é obrigatório.'),
  });

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});
        // changeLoader(true);

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await services.AccountsService.patchProfile(data);

        updateStateProfile({ ...response.data });

        // changeLoader(false);

        addToast({
          type: 'success',
          title: 'Os dados do seu perfil foram atualizados.',
          description: 'Dados do perfil do usuário atualizados com sucesso.',
        });
      } catch (err: any) {
        // changeLoader(false);

        const { ...error } = err;

        console.log({ error });

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [addToast, schema, services.AccountsService, updateStateProfile],
  );

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit} initialData={profile}>
        {profile?.cpf ? (
          <div className="flex">
            <div className="item">
              <Input
                id="full_name"
                name="full_name"
                label="Como você quer ser chamado(a)?"
                icon={FiUser}
                placeholder="Como você quer ser chamado(a)?"
              />
              <p className="info">
                * Você pode escolher como deseja ser chamado, pode usar apelido,
                nome social ou o nome real também.
              </p>
            </div>
            <div className="item ml2">
              <h4>CPF (Não editavel)</h4>
              <b>{profile ? profile.cpf : ''}</b>
            </div>
          </div>
        ) : (
          <div className="flex">
            <div className="item">
              <Input
                id="full_name"
                name="full_name"
                label="Como você quer ser chamado(a)?"
                icon={FiUser}
                placeholder="Como você quer ser chamado(a)?"
              />
            </div>
            <div className="item ml">
              <Input
                id="cpf"
                name="cpf"
                label="CPF"
                placeholder="Digite seu CPF"
                icon={FiUser}
                className="cpf"
              />
            </div>
          </div>
        )}
        <div className="flex">
          <div className="item">
            <Input
              id="email"
              name="email"
              label="Email"
              placeholder="perfil@perfil.com.br"
              icon={FiMail}
              className="email"
            />
          </div>
          <div className="item ml">
            <Input
              id="phone"
              name="phone"
              label="Telefone"
              placeholder="(31) 99999-9999"
              icon={FiPhoneCall}
              phone
            />
          </div>
          {/* <div className="item ml">
            <Input
              id="endereco"
              name="address"
              placeholder="Rua Aquiles Lobo, nº 504"
              label="Endereço"
              icon={GoLocation}
            />
          </div> */}
        </div>

        <div className="flex">
          <div className="item">
            <Input
              type="text"
              placeholder="********"
              label="Senha:"
              name="input-password"
              id="input-password"
              disabled
              icon={FiKey}
            />
          </div>
          <span
            tabIndex={-4}
            role="button"
            className="editar"
            onClick={openModaChangePasswordIsOpen}
            onKeyDown={openModaChangePasswordIsOpen}
          >
            Trocar senha
          </span>
        </div>

        <PoliticsAndTermsSession />

        <div className="btn-wrapper">
          <Button
            type="submit"
            color="orange"
            padding="6"
            className="button"
            rounded
          >
            SALVAR
          </Button>
          <ButtonDeleteUser />
          <button className="logout" onClick={signOut}>
            <FiLogOut className="iconlog" size={18} />
            SAIR
          </button>
        </div>
      </Form>
      {modaChangePasswordIsOpen && (
        <ModalChangePassword
          closeModal={closeModaChangePasswordIsOpen}
          // profile={profile}
          // closeModal={closeModalCPF}
          // updateStateProfile={updateStateProfile}
        />
      )}
    </Container>
  );
};

export default ContactInformation;
