import React, { useState, useCallback } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';

import {
  Container,
  AccordionItem,
  InitialContainer,
  CollapseContainer,
} from './styles';

export interface AccordionProps {
  title: string;
}

const Accordion: React.FC<AccordionProps> = ({ title, children }) => {
  const [active, setActive] = useState(false);

  const handleToggle = useCallback(() => {
    setActive(!active);
  }, [active]);
  return (
    <Container>
      <AccordionItem>
        <InitialContainer active={active}>
          <button
            type="button"
            className="icon-container"
            onClick={handleToggle}
          >
            <MdKeyboardArrowDown />
            <small>{title}</small>
          </button>
        </InitialContainer>
        <CollapseContainer active={active}>{children}</CollapseContainer>
      </AccordionItem>
    </Container>
  );
};

export default Accordion;
