import styled from 'styled-components';

export const GridContainer = styled.div`
  background: var(--white);
  font-family: 'Source Sans Pro';

  display: flex;

  @media (max-width: 1100px) {
    flex-wrap: wrap;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Container = styled.div`
  position: relative;

  .locationContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--orange);
    transition: color 0.3s ease;
    margin-bottom: 20px;
    background: transparent;
    &:hover {
      cursor: pointer;
      color: black;
    }
  }

  @media only screen and (max-width: 900px) {
    img {
      display: none;
    }
  }

  img {
    position: absolute;
    left: 0;
    top: 0px;
  }

  .button {
    background-color: #dc582a;
    border-radius: 35px;
    color: white;
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 12px;
    padding-bottom: 12px;

    @media (max-width: 1100px) {
      width: 90%;
      margin-top: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  @media only screen and (max-width: 1100px) {
    img {
      display: none;
    }
  }

  position: left;
  img {
    position: absolute;
    left: 0;
    top: 0px;
  }

  h1 {
    margin-top: 130px;
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: bold;
    line-height: 55px;
    color: var(--purple);
    @media (max-width: 1100px) {
      margin-top: 50px;
    }
  }

  .lojas {
    margin-top: 10px;
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: bold;
    line-height: 40px;
    margin-bottom: 13px;
    text-align: center;

    color: var(--purple);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span {
    margin-top: 7px;
  }

  p {
    font-size: 1.3rem;
  }

  margin-top: 20px;
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;

  .localizacao {
    font-size: 0.9rem;
    margin-top: 10px;
    margin-left: 30px;
    color: #7e8b92;
  }
`;
export const FirstContent = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: left;

  .googleSearchContainer {
    margin-bottom: 15px;
    div {
      z-index: 1 !important;
      width: 100%;
    }
  }

  .searchp {
    margin-bottom: 5px;
  }

  .searchRouteContainer {
    @media (max-width: 1024px) {
      width: 100%;
    }

    button {
      background: var(--orange);
      color: white;
      padding: 15px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      color: var(--purple);
    }
  }

  .text {
    color: var(--purple);
  }
  .grey-text {
    color: grey;
    margin-top: 10px;
  }
  button {
    margin-top: 10px;
    width: 200px;
    @media (max-width: 1100px) {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .btn-buscar {
    color: white;
    margin: 0;
    @media (max-width: 450px) {
      display: none;
    }
  }
  .btn-search {
    height: 30px;
    width: auto;
    display: none;
    @media (max-width: 450px) {
      padding-bottom: 5px;
      display: block;
    }
  }
`;

export const SecondContent = styled.div`
  margin-top: 80px;
  width: 450px;
  .rota {
    width: 100%;
    height: auto;
  }
  @media (max-width: 1100px) {
    flex-wrap: wrap;
    width: 50%;
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const LastContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #452359;
  color: white;
  position: relative;

  button {
    background-color: #dc582a;
    border-radius: 35px;
    color: white;
    margin: 5px 0px 45px 0px;
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

export const BusContent = styled.div`
  display: flex;
  align-items: flex-start;

  .bus {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
    margin-right: 20px;
  }

  h1 {
    position: center;
    margin-top: 40px;
    color: white;
    font-size: 1.8rem;

    @media (max-width: 560px) {
      flex-wrap: wrap;
      margin-top: 25px;
      margin-bottom: 30px;
      width: 100%;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 560px) {
    flex-wrap: wrap;
    width: 90%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
`;

export const Aside = styled.aside`
  display: flex;
  justify-content: center;
  align-items: center;

  .maskorange {
    position: absolute;
    right: 0;
    top: 2679px;
  }

  @media (max-width: 1100px) {
    img {
      display: none;
    }
  }

  @media (max-width: 1215px) {
    .maskorange {
      position: absolute;
      right: 0;
      top: 379%;
    }
  }

  @media (max-width: 1100px) {
    flex-wrap: wrap;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ContainerFaq = styled.div`
  overflow: scroll;
  border: 2px solid #c2d1d9;
  height: 400px;
  padding: 35px;
  width: 100%;
  border-radius: 10px;

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--purple);
    border-radius: 10px;
  }

  .float-right {
    float: right;
    margin-top: -30px;
  }
`;

export const Main = styled.main`
  margin: 0 0 0 20%;
  position: relative;
  width: 70%;
  grid-area: main;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 3rem 0;

  .h2 {
    color: var(--purple3);
    margin-bottom: 20px;
  }
  .h1 {
    color: var(--purple);
    margin-top: 60px;
    margin-bottom: 20px;
  }

  .accordion {
    background-color: white;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    border: 2px solid black;
    border-radius: 10px;
    border-color: #c2d1d9;
    margin-bottom: 10px;
  }
  .active,
  .accordion:hover {
    background-color: #ccc;
  }

  .panel {
    padding: 0 18px;
    background-color: white;
    display: none;
    overflow: hidden;
  }

  .titulo {
    color: var(--purple);
    font-size: 1.7rem;
    font-weight: bold;
  }

  .subtitulo {
    color: #505d68;
  }

  .caret.caret-reversed {
    border-bottom: 15px solid #dc582a;
    border-left: 8px solid rgba(0, 0, 0, 0);
    border-right: 8px solid rgba(0, 0, 0, 0);
    content: '';
    display: inline-block;
    height: 0;
    vertical-align: top;
    width: 0;
    transform: rotate(180deg);
    float: right;
    margin-top: -30px;
  }
`;
