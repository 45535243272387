import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 110px;
  @media (max-width: 767px) {
    margin-top: 100px;
  }
  .slick-list {
    margin: 0 10%;
  }

  .slick-dots {
    li {
      button {
        &:before {
          color: #dc582a;
          font-size: 13px;
        }
      }
    }
  }

  h1 {
    font-family: Roboto Slab;
    font-style: normal;

    /* identical to box height, or 142% */

    text-align: center;

    color: #482a73;
  }
`;

export const SliderContainer = styled.div`
  margin: 50px 0;
  @media only screen and (max-width: 768px) {
    margin-bottom: 200px;
  }
`;

export const SliderItem = styled.div`
  height: 262px;
  border: 1px solid #c2d1d9;
  display: flex !important;
  margin-right: 35px;
  margin-bottom: 10px;
  @media (max-width: 550px) {
    flex-direction: column;
  }

  .imgContainer {
    cursor: pointer;
    width: 500px;
    height: 258px;
    border-right: 1px solid #c2d1d9;

    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    img {
      padding: 5px 5px 0 0px;
      width: 180px;
      height: auto;
      @media (max-width: 840px) {
        width: 80%;
        height: auto;
      }
      @media (max-width: 768px) {
        margin-top: 5px;
        width: 80%;
        height: auto;
      }
      @media (max-width: 550px) {
        width: 40%;
      }
    }
  }

  .textContainer {
    padding: 30px 15px;
    @media (max-width: 768px) {
      padding: 30px 5px;
      text-align: center;
    }
    @media (max-width: 330px) {
      padding: 10px 5px;
    }

    h1 {
      cursor: pointer;
      font-family: Roboto Slab;
      font-style: normal;
      font-weight: bold;
      font-size: 1rem;
      text-align: left;
      line-height: 2rem;
      color: #482a73;
      margin-bottom: 40px;
      @media (max-width: 1195px) {
        font-size: 1rem;
        line-height: 1.3rem;
        margin-bottom: 20px;
      }
      @media (max-width: 768px) {
        text-align: center;
        margin-top: 50px;
      }
      @media (max-width: 550px) {
        margin-top: 0px;
      }
    }
  }

  .button {
    @media (max-width: 360px) {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 1000px) {
    margin: 26px 5px;
  }
`;

export const Contact = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 9rem;
  .contact-section {
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  .fale-conosco {
    display: none;
    height: 85px;
    background: var(--purple);
    border-radius: 50%;
    box-shadow: 0px 5px 8px 2px rgba(0, 0, 0, 0.6);
    @media only screen and (max-width: 768px) {
      display: block;
      position: fixed;
      z-index: 102;
      bottom: 28px;
      width: 75px;
      height: 75px;
      right: 20px;
    }
    .message {
      position: absolute;
      height: 40px;
      right: 18px;
      bottom: 18px;
    }
  }
`;
