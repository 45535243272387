import styled from 'styled-components';

export const TitleSection = styled.section`
  margin: 60px 0px 70px 0px;
  color: var(--purple);

  h1 {
    font-size: 3rem;
    line-height: 2.3rem;
  }
`;

export const Main = styled.main`
  width: 100%;

  .nodata {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    .icon {
      color: var(--purple);
      margin-bottom: 10px;
      margin-top: 20px;
    }

    .button {
      margin-left: 10px;
    }
  }

  .header {
    background-color: var(--aside-background);
    strong {
      color: var(--purple);
    }
  }
  .paper {
    box-shadow: none;
    @media only screen and (max-width: 800px) {
      box-shadow: none;
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .infonotdata {
    color: var(--purple);
    margin-top: 20px;
    margin-left: 10px;
  }
  .infonotdatasub {
    color: var(--purple);
    margin-left: 10px;
    font-weight: 100;
  }

  .rdt_TableCol {
    padding: 5px;
  }
  .pedido {
    max-width: 5px;
  }
  .MuiTableCell-root {
    @media (max-width: 600px) {
      padding: 10px;
    }
    @media (max-width: 500px) {
      padding: 4px;
    }
    @media (max-width: 450px) {
      padding: 2px;
    }
  }

  .rdt_TableCell {
    padding: 5px;
  }
  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: green;
    color: yellow;
    text-align: center;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .verPix {
    background: transparent;
    color: rgb(9, 85, 165);
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 0.875rem;
  }
`;
