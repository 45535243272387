/* eslint-disable consistent-return */
import styled from 'styled-components';
import backgroundImage from '../assets/Shared/testeira.png';

interface GridProps {
  small: string;
  medium: string;
  large: string;
}

interface containerProps {
  backgroundColor?: string;
  backgroundImage?: boolean;
  backgroundBanner?: boolean;
  overflow?: boolean;
}

export const Container = styled.div<containerProps>`
  background: ${props =>
    // eslint-disable-next-line prettier/prettier
    `${props.backgroundColor ? `var(--${props.backgroundColor})` : 'transparent'
    }`};

  background: ${props =>
    props.backgroundImage && `url(${backgroundImage}) no-repeat`};
  background-size: cover;

  background: ${props => props.backgroundBanner && `#442461 no-repeat`};
  background-size: cover;
  overflow: ${props => props.overflow && 'hidden'};

  &.banner-container {
    height: 10vh;
    @media only screen and (max-width: 1260px) {
      background: #442461;
      background-size: cover;
      height: 10vh;
    }
    @media only screen and (max-width: 1002px) {
      height: 10vh;
    }
    @media only screen and (max-width: 768px) {
      display: none;
      height: 0;
    }
  }
  &.companies-container {
    height: 330px;
    @media only screen and (max-width: 768px) {
      height: 270px;
    }
  }
`;

export const Content = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 1.2rem;
`;

export const Blur = styled.div`
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 10%,
    rgba(255, 255, 255, 0) 50%
  );
`;

export const Row = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  &:before,
  &after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
`;

function getWidthGrid(value: string) {
  if (!value) return;
  const width = (Number(value) / 12) * 100;
  return `width: ${width}%`;
}

export const Column = styled.div<GridProps>`
  width: 100%;
  padding: 0.25rem;
  min-height: 1px;
  box-sizing: border-box;
  margin-bottom: 25px;

  @media only screen and (min-width: 768px) {
    ${props => props.small && getWidthGrid(props.small)};
  }

  @media only screen and (min-width: 1024px) {
    ${props => props.medium && getWidthGrid(props.medium)};
  }

  @media only screen and (min-width: 1480px) {
    ${props => props.large && getWidthGrid(props.large)};
  }
`;
