import styled, { css } from 'styled-components';

interface navItemProps {
  active?: boolean;
}

export const Container = styled.nav`
  height: 61px;
  background: var(--transparent);

  align-items: center;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ItensContainer = styled.ul`
  display: inline-flex;
  list-style: none;
  margin-top: 10px;
`;

export const NavItem = styled.li<navItemProps>`
  margin-right: 2.6rem;
  font: 400 0.89rem 'Source Sans Pro', sans-serif;
  line-height: 1rem;
  cursor: pointer;
  color: var(--white);
  transition: color 0.3s ease;

  &:hover {
    color: var(--orange);
  }

  ${props =>
    props.active &&
    css`
      color: var(--orange);
      font-weight: 700;
      border-bottom: 2px solid var(--orange);
    `}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: center;
  align-items: baseline;

  a {
    color: var(--white);
    font-size: 0.99rem;
    line-height: 1rem;
    margin-right: 15px;
    transition: color 0.3s ease;

    &:hover {
      color: var(--orange);
    }
  }

  button {
    height: 50px;
    margin-top: 8px;
    margin-bottom: 8px;
    background: var(-orange);
    border-radius: 10px;
    transition: all 0.2s;

    &:hover {
      background: #dc5129;
    }
  }

  .cadastrar {
    margin: 0px;

    &:hover {
      color: white;
    }
  }
  .cadastrar-button {
    @media only screen and (max-width: 807px) {
      padding: 10px;
    }
  }
`;
