/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
import React from 'react';
import { FiX } from 'react-icons/fi';
import { Container } from './styles';

interface ModalInterface {
  handleClose(): void;
  show: boolean;
  title?: string;
  icon?: any;
  success?: boolean;
  children: React.ReactNode;
}

const ModalText: React.FC<ModalInterface> = ({
  handleClose,
  show,
  title,
  icon,
  children,
}) => (
  <Container show={show}>
    <section className="modal-main">
      <>
        <button type="button" className="close-container" onClick={handleClose}>
          <FiX size={25} onClick={handleClose} />
        </button>
        {icon && <img src={icon} alt="icon" />}

        {title && (
          <div className="title">
            <h1>{title}</h1>
          </div>
        )}
        <div className="modal-body">{children}</div>
      </>
    </section>
  </Container>
);

export default ModalText;
