/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { FaClock } from 'react-icons/fa';
import { useLocation, useHistory, Link } from 'react-router-dom';
import Slider from 'react-slick';
import { useLoadScript } from '@react-google-maps/api';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import SearchMap from '../../components/SearchMap';
import Map, { MarkerProps } from '../../components/Map';
import { useMap, LatLng } from '../../hooks/map';
import Icon from '../../assets/Shared/ic-recharge-stop.svg';
import Logo from '../../assets/Shared/logo-v2.png';
import { useAuth } from '../../hooks/auth';
import Button from '../../components/Button';
import CoreService from '../../services/core';
import { useToast } from '../../hooks/toast';
import { LatLngDistance } from '../../utils/calculateLatLngDistance';
import {
  Container,
  Header,
  Aside,
  Main,
  InputsWrapper,
  RoutesWrapper,
  RouteItem,
  LogoContainer,
  ItensContainer,
  NavItem,
  ButtonsContainer,
  SliderContainer,
  SliderItem,
} from './styles';
import Input from '../../components/Input';

declare type Libraries = (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
)[];

const settings = {
  responsive: [
    {
      breakpoint: 768,
      settings: {
        fade: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
      },
    },
    {
      breakpoint: 425,
      settings: {
        fade: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
      },
    },
  ],
};

const libraries: Libraries = ['places'];

const RecargaMap: React.FC = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
    language: 'pt-BR',
  });

  const services = { coreService: new CoreService() };
  const [markersData, setMarkersData] = useState<MarkerProps[]>([]);
  const [active, setActive] = useState<boolean>(false);
  const [googleAddress, setGoogleAddress] = useState('');
  const [latLng, setLatLng] = useState<LatLng>({} as LatLng);
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const {
    changeRechargeAddress,
    changeMapCoordinates,
    changeRechargeAddresCoordinates,
    rechargeAddresCoordinates,
  } = useMap();

  const { pathname } = useLocation();
  const { push } = useHistory();
  const { user } = useAuth();

  const navItens = [
    { name: 'HOME', path: '/' },
    { name: 'CARTÕES E RECARGA', path: '/cartoes-recarga' },
    { name: 'PARA EMPRESAS', path: '/para-empresas' },
    { name: 'LOJAS', path: '/lojas-locais-recarga' },
    { name: 'ROTAS E LINHAS', path: '/rotas-e-linhas' },
    { name: 'QUEM SOMOS', path: '/quem-somos' },
    { name: 'FALE CONOSCO', path: '/fale-conosco' },
  ];

  const handleRedirectMap = useCallback(() => {
    if (googleAddress) {
      const encodedQueryParams = encodeURI(
        `?lat=${latLng.lat}&lng=${latLng.lng}&address=${googleAddress}`,
      );
      window.location.href = `/pontos-de-recarga/${encodedQueryParams}`;
    } else {
      addToast({
        type: 'info',
        title: 'Selecione primeiro o Endereço',
        description: 'Não foi possível redirecionar para o mapa',
      });
    }
  }, [googleAddress, latLng, addToast]);

  const setMapCoordinates = useCallback(
    async address => {
      const geoCode = await getGeocode({ address });
      const { lat, lng } = await getLatLng(geoCode[0]);
      changeRechargeAddress(address);
      setLatLng({ lat, lng });
      setGoogleAddress(address);
    },
    [changeRechargeAddress],
  );

  const getRechargePoints = useCallback(async () => {
    try {
      const response = await services.coreService.getRechargePoint();
      const changedRechargePoints = response.data.results.map(
        (point: MarkerProps) => {
          const rechargePoint = {
            ...point,
            lat: Number(point.lat),
            lng: Number(point.lng),
          };
          return rechargePoint;
        },
      );
      setMarkersData(changedRechargePoints);
    } catch (err) {
      console.error(err);
    }
  }, [services.coreService]);

  useEffect(() => {
    getRechargePoints();
  }, []);

  const handleRedirect = useCallback(
    path => {
      push(path);
    },
    [push],
  );

  const handleRecharge = useCallback(
    address => {
      changeRechargeAddress(address);
    },
    [changeRechargeAddress],
  );
  const handleSubmit = useCallback(() => {
    return {};
  }, []);

  const handleChangeMapCoordinates = useCallback(
    latiLng => {
      changeMapCoordinates(latiLng);
    },
    [changeMapCoordinates],
  );

  useEffect(() => {
    const stringParams = decodeURIComponent(window.location.href.split('/')[4]);
    if (stringParams) {
      setTimeout(() => {
        const params = new URLSearchParams(stringParams);
        const lat = params.get('lat');
        const lng = params.get('lng');
        const address = params.get('address');
        changeRechargeAddress(address);
        changeRechargeAddresCoordinates({ lat: Number(lat), lng: Number(lng) });
        changeMapCoordinates({ lat: Number(lat), lng: Number(lng) });
        setActive(true);
      }, 1000);
    }
  }, [
    changeMapCoordinates,
    changeRechargeAddresCoordinates,
    changeRechargeAddress,
  ]);

  const changedMarkers = useMemo(() => {
    const returnMarkers = markersData.map((point: MarkerProps) => {
      const rechargePoint = {
        ...point,
        lat: Number(point.lat),
        lng: Number(point.lng),
        distance: LatLngDistance(
          rechargeAddresCoordinates.lat,
          rechargeAddresCoordinates.lng,
          point.lat,
          point.lng,
          'k',
        ),
      };
      return rechargePoint;
    });

    const sortedMarkers = returnMarkers.sort((a: MarkerProps, b: MarkerProps) =>
      a.distance < b.distance ? -1 : 1,
    );
    return sortedMarkers;
  }, [rechargeAddresCoordinates, markersData]);

  return (
    <Container active={active}>
      <Header>
        <LogoContainer>
          <a href="https://transfacil.com.br">
            <img src={Logo} alt="Logo transfacil" />
          </a>
        </LogoContainer>

        <ItensContainer>
          {navItens.map(item => (
            <NavItem
              onClick={() => handleRedirect(item.path)}
              active={!!(pathname === item.path)}
              key={item.name}
            >
              {item.name}
            </NavItem>
          ))}
        </ItensContainer>
        <ButtonsContainer>
          <Link to={user ? '/dashboard' : '/login'}>Entrar</Link>
          <Button
            color="orange"
            padding="2"
            onClick={() => handleRedirect('/cadastrar')}
          >
            CADASTRAR
          </Button>
        </ButtonsContainer>
      </Header>
      <Aside>
        {isLoaded && (
          <>
            <InputsWrapper>Locais de Recarga </InputsWrapper>
            <RoutesWrapper>
              {active && (
                <>
                  <div style={{ padding: '15px' }}>
                    {/* <SearchMap
                      onSelect={address => handleRecharge(address)}
                      type="recharge"
                      placeholder="Pesquisar por locais de recarga"
                      routes={false}
                      recharge
                    /> */}
                    <Form ref={formRef} onSubmit={handleRedirectMap}>
                      <p className="highlight-label">
                        Digite a região ou endereço que deseja buscar
                      </p>
                      {/* <p>Usar localização</p> */}
                      <Input
                        name="endereçoOrigem"
                        onChange={e => setMapCoordinates(e.target.value)}
                        defaultValue={googleAddress}
                        placeholder="endereço"
                        type="recharge"
                      />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Button
                          style={{
                            marginBottom: '4px',
                            marginTop: '4px',
                            marginLeft: '-4px',
                          }}
                          type="submit"
                          color="purple"
                          padding="4"
                          rounded
                        >
                          Buscar
                        </Button>
                      </div>
                    </Form>
                  </div>
                </>
              )}
              {changedMarkers.length > 0 &&
                changedMarkers.map(marker => (
                  <div key={marker.id}>
                    <RouteItem
                      onClick={() =>
                        handleChangeMapCoordinates({
                          lat: marker.lat,
                          lng: marker.lng,
                        })
                      }
                    >
                      <h1>{marker.name}</h1>
                      <h2>{marker.address}</h2>
                      <small>
                        <FaClock />
                        {marker.opening}
                      </small>
                    </RouteItem>
                  </div>
                ))}

              {changedMarkers.length === 0 && (
                <div>Nenhum Ponto de recarga encontrado</div>
              )}
            </RoutesWrapper>
          </>
        )}
      </Aside>
      <Main>
        <>
          <Map
            markersData={changedMarkers}
            markerIcon={Icon}
            setActive={setActive}
            asideOpen={active}
          />
          {changedMarkers.length > 0 && (
            <SliderContainer>
              <Slider {...settings}>
                {changedMarkers.map(marker => (
                  <div key={marker.id}>
                    <SliderItem
                      onClick={() =>
                        handleChangeMapCoordinates({
                          lat: marker.lat,
                          lng: marker.lng,
                        })
                      }
                    >
                      <h1>{marker.name}</h1>
                      <h2>{marker.address}</h2>
                      <small>
                        <FaClock />
                        {marker.opening}
                      </small>
                    </SliderItem>
                  </div>
                ))}
              </Slider>
            </SliderContainer>
          )}
        </>
      </Main>
    </Container>
  );
};

export default RecargaMap;
