/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable prettier/prettier */
import styled, { css, keyframes } from 'styled-components';

interface ContainerProps {
  success?: boolean;
  typeModal?: 'success' | 'error';
  show: boolean;
}

const EaseTopDown = keyframes`
  from{ opacity:0;top:0; }
  to{ opacity:1;top:20%;}
`;

const EasyIn = keyframes`
  from{ opacity:0; }
  to{ opacity:1; }
`;

export const Container = styled.div<ContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.78);
  display: none;
  z-index: 100000000000000;
  animation: ${EasyIn} 0.3s;

  section {
    display: flex;
    position: fixed;
    background: #ffffff;
    animation: ${EaseTopDown} 0.4s;
    width: 100%;
    height: 100%;
    max-height: 80vh;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);;
    align-items: center;
    justify-content: center;
    background: transparent;

    animation: ${EasyIn} 0.5s;

    .close-container {
      position: absolute;
      top: 0px;
      right: 40px;
      background: var(--white);
      border-radius: 50px;
      display: flex;
      align-items: right;

      svg {
        margin-top: 0px;
        color: var(--black);
        cursor: pointer;
      }
    }
  }

  ${(props) =>
    props.show &&
    css`
      display: block;
    `}
`;

export const Content = styled.div`
  width: 50vw;
  margin-top: 70px;
  text-align: center;
  @media screen and (max-width: 1000px) {
    width: 80vw;
  }

  img {
    height: auto;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
  }

  h2 {
    margin-top: 10px;
    color: white;
    font-weight: bold;
    font-size: 2.1rem;
    /* @media screen and (max-width: 424px) {
      word-break: break-all;
    } */
  }

  p {
    margin-top: 10px;
    color: white;
    font-size: 1.2rem;
  }

  .clique {
    margin-top: 25px;
    width: auto;
    color: white;
    font-weight: 600;
    height: 40px;
    background: var(--purple);
    border-radius: 30px;
    transition: all 0.2s;
    &:hover {
      background: #5aab5f;
    }
  }
`;

export const Img = styled.img`
  width: 200px;
  height: 200px;
  max-width: 100%;
`;