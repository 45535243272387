/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-finally */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/button-has-type */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { formatCurrency } from '@brazilian-utils/brazilian-utils';
import React, { useEffect } from 'react';
import { useReactToPrint } from "react-to-print";
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, ModalPixPagamento, ButtonCompartilhar, TextSpan, ButtonsDownload, QrCodeLine, QrCodeInfo } from './styles';
import api from '../../services/api';


interface ModalPixProps {
  code: string
  line: string
  value: number
  closeModal: () => void
  image: string
  lineName?: string;
  dataValidade?:any;
  tempoExpiracao?: any;
  gerando?: boolean;
}

const ModalTicket: React.FC<ModalPixProps> = ({
  code,
  line,
  value,
  closeModal,
  image,
  lineName,
  dataValidade,
  tempoExpiracao,
  gerando,
}) => {

  const [lineNames, setLineNames] = React.useState<any>();
  const [tempo, setTempo] = React.useState(0);
  const [minutos, setMinutos] = React.useState(0);

  const componentRef = React.useRef(null);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef?.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "TransfacilQRCode",
    removeAfterPrint: false
  });

  const getList = React.useCallback(async () => {
    try {
      const { data } = await api.get(`/core/qr-code/lines/`);
      const lineNameIs = data?.filter((dados: any) => dados?.id?.linha?.linCodLinhaExterno === line)
      setLineNames (lineNameIs)
    } catch (e) {
      console.log(e);
    }
  }, [line]);

  const UpdateTime = React.useCallback(async () => {
    try {
      const response = await api.get(`/ticketing/qr-code/reissue-ticket-by-value/${code}/ `);
      const tempoExpiracaoEmSegundos = response?.data?.tempo_expiracao;

    if (tempoExpiracaoEmSegundos !== undefined) {
      const minutosCalculados = Math.floor(tempoExpiracaoEmSegundos / 60);
      setMinutos(minutosCalculados);
    }
    } catch (err: any) {
      const { ...error } = err;
      console.log(error);
      if(error?.response?.data?.message === "Ticket não encontrado.") {
        window.location.reload();
      }
      setMinutos(undefined)
    }
  }, [dataValidade]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  React.useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    let interval: any;
    try {
      if (dataValidade) {
        interval = setInterval(() => {
          UpdateTime();
        }, 15000);
      }
    } catch (e) {
      console.log(e)
    } finally {
      return () => clearInterval(interval);
    }
    
  }, [dataValidade, minutos]);

  useEffect(() => {
    const tempoExpiracaoEmSegundos = tempoExpiracao;

    if (tempoExpiracaoEmSegundos !== undefined) {
      const minutosCalculados = Math.floor(tempoExpiracaoEmSegundos / 60);
      setTempo(minutosCalculados);
    }

  }, [tempoExpiracao, minutos]);

  return (
    <ModalPixPagamento
      style={gerando ? { height: "100vh" } : {}}
    >
      <div className="wrapper">
        <button
          className="close"
          type="button"
          aria-label="close"
          onClick={closeModal}
        />
        <div ref={componentRef}>
          <div className="row" style={{ backgroundColor: 'white', borderRadius: "20PX", marginTop: "0" }}>
            <div>
              <h1 style={{ textAlign: "center" }}>QR Code</h1>
              <QrCodeInfo>
                <div className='iconI'>
                  <ErrorOutlineIcon  sx={{ marginRight: "1%", width: "50px", height: "50px" }}/>
                </div>
                <p>Toque no QR Code na tela do validador e aponte o código abaixo para liberar sua passagem.</p>
              </QrCodeInfo>
              <QrCodeLine>
                <div className='icon'>
                  <DirectionsBusIcon sx={{width: "50px", height: "50px" }}/>
                  <p>{`${line}`}</p>
                </div>
                
                <div>
                  {lineName ? (
                    <p style={{ marginTop: "0" }}>{`${lineName}`} </p>
                  ) : (
                    <p style={{ marginTop: "0" }}>{`${lineNames?.[0]?.id?.linha?.linNomeLinha}`} </p>
                  )}
                  <p style={{ marginTop: "0" }}> <strong> Valor da tarifa: </strong> R$ {formatCurrency(value)} </p>
                </div>
              </QrCodeLine>

              <div className='qrcode'>
                <img
                  src={image}
                  alt="ticket image"
                  style={{
                    height: '300px',
                    width: '300px',
                  }}
                />
              </div>
            </div>
            <p style={{ marginTop: 0, marginBottom: 5 }}>O QrCode expira às: <strong style={{ color: "var(--purple)" }}>{dataValidade}</strong></p>
            <div style={{ display: "flex", flexDirection: "row", marginTop: 5, marginBottom: 5, alignItems: "center" }}>
              <RefreshIcon sx={{ animation: "spin 2s linear infinite" }}/>
              <p style={{ marginTop: 0 }}>Tempo restante: <strong style={{ color: "var(--purple)" }}>{minutos !== 0 ? minutos === 30 ? 29 : minutos : tempo} { minutos === 1 ? 'minuto' :'minutos'}</strong></p>
            </div>
            
          </div>
        </div>
        <div
          style={{
            marginTop: '20px',
            marginBottom: '20px',
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <TextSpan>
            *Está no celular? Pressione o dedo sobre o QrCode para compartilha-lo para os seus contatos.

          </TextSpan>
        </div>
          <Button onClick={handlePrint}>
            DOWNLOAD
          </Button>
      </div>
    </ModalPixPagamento>
  );
};

export default ModalTicket;
