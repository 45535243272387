import styled, { keyframes } from 'styled-components';

const EaseIn = keyframes`
  from{ opacity:0; }
  to{ opacity:1; }
`;

export const Container = styled.div`
  .content-payment-form {
    margin-top: 25px;
  }
  .limit-header {
    margin-top: 5px;
    color: var(--orange);
    font-weight: bold;
  }

  .cvv-wrapper {
    margin-bottom: 30px;
    width: 50%;
    display: flex;

    div {
      margin-right: 15px;
    }

    input {
      width: 100px;
    }
  }

  .resume {
    max-width: 50%;
    min-width: 100px;
    font-weight: bold;
    border-bottom: 1px solid #5e5e5e;
    .value,
    .tax {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    @media (max-width: 365px) {
      max-width: 100%;
    }
  }
  animation: ${EaseIn} 0.3s;

  .react-select__control {
    border: 1px solid var(--purple);
    border-radius: 10px;
    height: 42px;
    max-width: 429px;
  }

  .react-select__menu {
    max-width: 429px;
  }

  .emptyCreditCard {
    margin-bottom: 15px;
    small {
      color: var(--purple);
      font-size: 18px;
    }
  }

  .buttonss {
    @media (max-width: 365px) {
      button {
        width: 100%;
      }
    }
  }
`;

export const DivGeralCartao = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 500px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const CartaoDados = styled.div`
  flex: 1;
  width: 50%;
  margin-right: 10px;
  @media (max-width: 500px) {
    flex: none;
    width: 100%;
  }
  .textotcard {
    color: var(--purple);
  }
  .textlink {
    color: var(--orange);
    font-weight: bold;
  }
`;

export const PagamentoDados = styled.div`
  flex: 1;
  width: 50%;
  margin-left: 10px;
  @media (max-width: 500px) {
    flex: none;
    width: 100%;
  }
`;

export const ModalContentBanner = styled.div`
  height: 300px;
  width: 500px;
  padding: 0px 10px 20px 10px;
  text-align: center;
  display: contents;
  h2 {
    font-weight: 14px;
  }
  p {
    font-weight: 12px;
    padding-bottom: 10px;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  margin-right: -100px;
  margin-bottom: 30px;
  color: var(--purple);

  .button {
    margin-top: 40px;
    font-size: 15px;
  }

  .button-text {
    display: grid;
    align-items: left;
    justify-content: left;
    padding: 0;
    width: 0px;
    color: red;
  }

  .button-cancelar {
    color: var(--orange);
    border: 2px solid var(--orange);
    margin-left: 1em;

    h4 {
      font-weight: normal;
    }
  }
  @media (max-width: 365px) {
    .button-cancelar {
      width: 100% !important;
    }
  }
`;

export const CardSection = styled.div`
  display: grid;
  width: 100%;
  margin-right: -100px;
  margin-bottom: 1em;
  color: var(--purple);

  .cartao {
    margin-left: 20px;
    font-size: 20px;
    font-weight: bolder;
  }

  .dados {
    margin-left: 20px;
    margin-top: 5px;
    font-size: 15px;
  }

  .button-confirmar {
    align-items: left;
    justify-content: left;
    width: 180px;
    margin-top: 10%;

    @media (max-width: 365px) {
      width: 100% !important;
    }
  }

  .inputnome {
    margin-top: 0px;
    margin-left: 20px;
    border-radius: 10px;
    padding-left: 30px;
    width: 480px;
    height: 40px;
    border: 1px solid var(--purple);
    font-size: 18px;

    ::-webkit-input-placeholder {
      color: #979797;
    }
  }

  .nome {
    position: absolute;
    margin-left: 40px;
    background-color: var(--white);
    margin-top: -12px;
    padding: 0 12px;
    color: var(--purple);
  }

  .numero {
    position: absolute;
    margin-left: 40px;
    background-color: var(--white);
    margin-top: 14px;
    padding: 0 12px;
    color: var(--purple);
  }
  .inputnumero {
    margin-top: 25px;
    margin-left: 20px;
    border-radius: 10px;
    padding-left: 30px;
    height: 40px;
    width: 260px;
    border: 1px solid var(--purple);
    font-size: 18px;

    ::-webkit-input-placeholder {
      color: #979797;
    }
  }

  .data {
    position: absolute;
    margin-left: 347px;
    background-color: var(--white);
    margin-top: -50px;
    padding: 0 12px;
    color: var(--purple);
  }
  .inputdata {
    margin-top: 25px;
    margin-left: 40px;
    border-radius: 10px;
    padding-left: 75px;
    width: 220px;
    height: 40px;
    border: 1px solid var(--purple);
    font-size: 18px;

    ::-webkit-input-placeholder {
      color: #979797;
    }
  }

  .cvv {
    position: absolute;
    margin-left: 597px;
    background-color: var(--white);
    margin-top: -50px;
    padding: 0 12px;
    color: var(--purple);
  }
  .inputcvv {
    margin-top: 25px;
    margin-left: 40px;
    border-radius: 10px;
    padding-left: 28px;
    width: 90px;
    height: 40px;
    border: 1px solid var(--purple);
    font-size: 18px;

    ::-webkit-input-placeholder {
      color: #979797;
    }
  }

  .inputcep {
    margin-top: 60px;
    border-radius: 10px;
    padding-left: 20px;
    width: 300px;
    height: 40px;
    border: 1px solid var(--purple);
    font-size: 22px;

    ::-webkit-input-placeholder {
      color: #979797;
    }
  }
  .cep {
    position: absolute;
    margin-left: 10px;
    background-color: var(--white);
    margin-top: 50px;
    padding: 0 12px;
    color: var(--purple);
  }

  .cep-remember {
    position: absolute;
    margin-left: 318px;
    color: var(--orange);
    margin-top: -17px;
  }

  .endereco {
    position: absolute;
    margin-left: 10px;
    background-color: var(--white);
    margin-top: 14px;
    padding: 0 12px;
    color: var(--purple);
  }
  .inputendereco {
    margin-top: 25px;
    border-radius: 10px;
    padding-left: 20px;
    width: 800px;
    height: 40px;
    border: 1px solid var(--purple);
    font-size: 22px;

    ::-webkit-input-placeholder {
      color: #979797;
    }
  }

  .numero {
    position: absolute;
    margin-left: 10px;
    background-color: var(--white);
    margin-top: 14px;
    padding: 0 12px;
    color: var(--purple);
  }
  .inputnumero {
    margin-top: 25px;
    border-radius: 10px;
    padding-left: 20px;
    height: 40px;
    width: 110px;
    border: 1px solid var(--purple);
    font-size: 22px;

    ::-webkit-input-placeholder {
      color: #979797;
    }
  }

  .complemento {
    position: absolute;
    margin-left: 140px;
    background-color: var(--white);
    margin-top: -50px;
    padding: 0 12px;
    color: var(--purple);
  }
  .inputcomplemento {
    margin-top: 25px;
    margin-left: 20px;
    border-radius: 10px;
    padding-left: 20px;
    width: 239px;
    height: 40px;
    border: 1px solid var(--purple);
    font-size: 22px;

    ::-webkit-input-placeholder {
      color: #979797;
    }
  }

  .bairro {
    position: absolute;
    margin-left: 400px;
    background-color: var(--white);
    margin-top: -50px;
    padding: 0 12px;
    color: var(--purple);
  }
  .inputbairro {
    margin-top: 25px;
    margin-left: 20px;
    border-radius: 10px;
    padding-left: 20px;
    width: 410px;
    height: 40px;
    border: 1px solid var(--purple);
    font-size: 22px;

    ::-webkit-input-placeholder {
      color: #979797;
    }
  }
`;

export const Section = styled.section`
  grid-area: main;
  width: 124%;
  height: 16px;
  background-color: #ebf1f4;
  margin-left: -110px;
  margin-bottom: 30px;
`;

interface CircleProps {
  selected?: boolean;
}

export const Circle = styled.div<CircleProps>`
  display: flex;

  .circle1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    border: 1px solid var(--purple);
  }

  ${props =>
    props.selected &&
    `
    .circle2 {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: var(--purple);
    }
  `};
`;
