import React, { useCallback } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { FiPlusCircle } from 'react-icons/fi';

import TicketingService, { Payment } from '../../../services/ticketing';

import ItemFormOfPayment from '../itemFormOfPayment';

import { CardButton, Content, Section } from './styles';
import { useToast } from '../../../hooks/toast';

interface ListPaymentProps {
  payments: Payment[];
}

const ListPayment: React.FC<ListPaymentProps> = ({ payments }) => {
  const { path } = useRouteMatch();
  const { push } = useHistory();

  const handleRedirect = useCallback(
    pathLink => {
      push(pathLink);
    },
    [push],
  );

  return (
    <>
      {payments &&
        payments.map((payment: Payment) => (
          <ItemFormOfPayment
            key={payment.id}
            payment={payment}
            circleSelect={false}
            editOption={payment.card_number_display !== null}
          />
        ))}
      <Section />
      <Content>
        <CardButton onClick={() => handleRedirect(`${path}/adicionar`)}>
          <FiPlusCircle className="icon" size={30} />
          Adicionar novo Cartão
        </CardButton>
      </Content>
    </>
  );
};

export default ListPayment;
