/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import * as React from 'react';

interface IInformCVV {
  cvv: any;
  setCvv: any;
}

const InformCVV: React.FC<IInformCVV> = ({ cvv, setCvv }) => {
  return (
    <div>
      <h1> Informe o CVV </h1>
      <div
        style={{
          marginTop: 20,
        }}
      >
        <input
          placeholder="Digite aqui"
          style={{
            padding: 10,
            border: '1px solid purple',
            borderRadius: 4,
          }}
          value={cvv}
          type="number"
          onChange={(e): any => {
            setCvv(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export { InformCVV };
