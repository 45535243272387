import styled from 'styled-components';

interface containerProps {
  backgroundImage?: boolean;
}

export const LastContent = styled.div<containerProps>`
  background: linear-gradient(267.38deg, #f79765 -0.96%, #dc582a 100%);

  width: 100%;
  height: 255px;
  color: white;
  margin-top: 50px;
  margin-bottom: 180px;
  @media only screen and (max-width: 1100px) {
    img {
      display: none;
    }
  }
  position: relative;

  @media only screen and (max-width: 1100px) {
    img {
      display: none;
    }
  }
  p {
    position: center;
    font-family: 'Roboto Slab', serif;
    margin-bottom: 120px;
    color: white;
    font-size: 35px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span {
    color: #dc582a;
    font-family: 'Roboto Slab', serif;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 12px;
  }
`;

export const GridContainer = styled.div`
  font-family: 'Source Sans Pro';
  color: #dc582a;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h5 {
    color: #dc582a;
    font-family: 'Roboto Slab', serif;
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 12px;
    @media (max-width: 765px) {
      margin-top: 260px;
    }
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  background: white;
  p {
    position: center;
    font-family: 'Source Sans Pro', serif;
    margin: 0 0 0 0;
    color: #a1aeb7;
    font-size: 14px;
  }
`;
export const FlexRowContainer = styled.div`
  display: flex;
  margin-top: 35px;
  img {
    border-radius: 25px;
  }

  @media (max-width: 1190px) {
    display: block;
  }
`;

export const Main = styled.main`
  position: absolute;
  top: 89px;
  width: 93%;
  grid-area: main;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  padding: 1.5rem 0;

  .h2 {
    color: var(--purple3);
    margin-bottom: 20px;
  }
  .h1 {
    color: var(--purple);
    margin-top: 100px;
    margin-bottom: 20px;
  }
  h3 {
    color: var(--purple);
    font-family: 'Roboto Slab', serif;
    margin-top: 3px;
    margin-bottom: 8px;
    font-size: 18px;
  }
  h4 {
    margin-top: 30px;
  }
  h5 {
    color: #dc582a;
    font-family: 'Roboto Slab', serif;
    margin-top: 35px;
    margin-bottom: 20px;
    font-size: 12px;
  }

  img {
    height: 250px;
  }

  .card-span {
    background-color: #ffe5cb;
    width: 50px;
    color: #424b5a;
    margin-top: 13px;
    font-family: 'Source Sans Pro', serif;
    padding: 1px 80px 1px 4px;
  }
  .card {
    display: flex;
    padding-right: 10px;
    background: white;
    border-radius: 35px 0px 0px 35px;
    border: 0.2px solid #a1aeb7;

    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    height: 250px;
    overflow: hidden;

    img {
      border-radius: 10px;
    }

    p,
    h3 {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .card + .card {
    margin-left: 30px;
  }
  @media (max-width: 1190px) {
    .card + .card {
      margin-left: 0px;
      margin-top: 20px;
    }
  }
`;
