/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import getValidationErrors from '../../../utils/getValidationErrors';
import AccountsService from '../../../services/accounts';
import { useToast } from '../../../hooks/toast';
import { useLoader } from '../../../hooks/loader';

import Button from '../../Button';
import Input from '../../Input';

import { ModalSenha } from './styles';
import DashBoardLoader from '../../DashBoardLoader';

const ModalChangePassword: React.FC<{ closeModal(): void }> = ({
  closeModal,
}) => {
  const formRef = useRef<FormHandles>();
  const services = { AccountsService: new AccountsService() };
  const { addToast } = useToast();
  const { isActive, changeLoader } = useLoader();

  const schema = Yup.object().shape({
    old_password: Yup.string().required('Senha atual é obrigatório'),
    new_password: Yup.string().required('Senha é obrigatório'),
    confirm_password: Yup.string().required(
      'Confirmação de senha é obrigatória',
    ),
  });

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});
        changeLoader(true);

        await schema.validate(data, {
          abortEarly: false,
        });

        const { old_password, new_password, confirm_password } = data;
        await services.AccountsService.changePassword(
          old_password,
          new_password,
          confirm_password,
        );

        changeLoader(false);

        addToast({
          type: 'success',
          title: 'Alteração de senha',
          description: 'Sua senha foi atualizada com sucesso.',
        });

        closeModal();
      } catch (err: any) {
        console.log(err, 'aqui');
        changeLoader(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao alterar senha',
          description: `${err.response.data.message}`,
        });
      }
    },
    [changeLoader, schema, services.AccountsService, addToast, closeModal],
  );

  return (
    <ModalSenha>
      {isActive && <DashBoardLoader />}
      <div className="wrapper">
        <button
          className="close"
          type="button"
          aria-label="close"
          onClick={closeModal}
        />
        <h1>Alteração de senha</h1>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <div className="mt-20">
            <Input
              name="old_password"
              placeholder="Digita a senha atual"
              label="Senha atual:"
              type="password"
            />
          </div>

          <div className="mt-20">
            <Input
              name="new_password"
              placeholder="Digita a nova senha"
              label="Nova senha:"
              type="password"
            />
          </div>

          <div className="mt-20">
            <Input
              name="confirm_password"
              placeholder="Confirme a nova senha"
              label="Confirmar senha:"
              type="password"
            />
          </div>

          <div className="btn-wrapper">
            <Button
              type="button"
              color="orange"
              padding="4"
              className="button button-transparent"
              rounded
              onClick={closeModal}
            >
              Sair
            </Button>
            <Button
              type="submit"
              color="orange"
              padding="4"
              className="button btn-right"
              rounded
            >
              Salvar
            </Button>
          </div>
        </Form>
      </div>
    </ModalSenha>
  );
};

export default ModalChangePassword;
