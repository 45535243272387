/* eslint-disable no-console */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Button from '../../components/Button';
import Lupasearch from '../../assets/Shared/lupasearch.svg';
import api from '../../services/api';
import { Row, Column, Container, Content, Blur } from '../../styles/components';
import {
  TitleSection,
  GridSection,
  Main,
  FlexContainer,
  FlexRowContainer,
} from './styles';
import Header from '../../components/Home/Header';
import NavBar from '../../components/Home/NavBar';
import Input from '../../components/Input';
import Footer from '../../components/Home/Footer';

interface Category {
  id: number;
  name: string;
}

const Noticias: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [news, setNews] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const getNews = async () => {
      try {
        const response = await api.get('/core/noticias/');
        setNews(response.data.results);
      } catch (err) {
        console.error(err);
      }
    };
    getNews();
  }, []);

  useEffect(() => {
    const results = news.filter(noticia => {
      return Object.keys(noticia).some(key =>
        JSON.stringify(noticia[key])
          .toLowerCase()
          .includes(searchTerm.toLowerCase()),
      );
    });
    setSearchResults(results);
  }, [searchTerm, news]);

  const handleChange = useCallback(e => {
    setSearchTerm(e.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    return {};
  }, []);

  return (
    <>
      <Container>
        <Content>
          <Header />
        </Content>
      </Container>
      <Container backgroundImage className="companies-container">
        <Blur>
          <Content className="content">
            <NavBar />
            <TitleSection>
              <h1 className="h1">Notícias</h1>
              <span>
                Conheça a história da nossa empresa e nossas motivações para
                entregar serviço de qualidade
              </span>
            </TitleSection>
          </Content>
        </Blur>
      </Container>
      <Container backgroundColor="background-gray">
        <Content>
          <GridSection>
            <Main>
              <Form ref={formRef} onSubmit={handleSubmit} className="form">
                <Input
                  name="tema"
                  placeholder="Buscar por tema ou assunto"
                  onChange={e => handleChange(e)}
                  rounded
                >
                  <Button type="submit" color="orange" padding="3">
                    <span className="btn-buscar">BUSCAR</span>
                    <img src={Lupasearch} alt="search" className="btn-search" />
                  </Button>
                </Input>
              </Form>

              <FlexRowContainer>
                <Row>
                  {searchResults.map(result => (
                    <Column key={result.id} small="12" medium="6" large="6">
                      <a
                        href={`/noticia/${result.url.split('/').pop()}`}
                        key={result.id}
                        className="card"
                      >
                        <img src={result.image} alt={result.title} />
                        <FlexContainer>
                          <div className="categoryContainer">
                            {result.categories.map((category: Category) => (
                              <span className="card-span" key={category.id}>
                                {category.name}
                              </span>
                            ))}
                          </div>
                          <h3>{result.title}</h3>
                          <div className="card-subtitle">{result.subtitle}</div>
                        </FlexContainer>
                      </a>
                    </Column>
                  ))}
                  {!searchResults.length && (
                    <div className="emptyNews">Nenhuma Notícia Encontrada</div>
                  )}
                </Row>
              </FlexRowContainer>
            </Main>
          </GridSection>
        </Content>
      </Container>
      <Container>
        <Footer />
      </Container>
    </>
  );
};

export default Noticias;
