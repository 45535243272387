/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';

import { Container, Content, Blur } from '../../styles/components';
import { TitleSection, GridSection, FlexContainer, SliderItem } from './styles';
import CoreService, { Partner } from '../../services/core';
import Header from '../../components/Home/Header';
import NavBar from '../../components/Home/NavBar';
import Button from '../../components/Button';

import Footer from '../../components/Home/Footer';

const Parceiros: React.FC = () => {
  const services = { coreService: new CoreService() };
  const [partners, setPartner] = useState<Partner[]>([]);

  useEffect(() => {
    async function getNews() {
      try {
        const response = await services.coreService.getPartners();
        setPartner(response.data.results);
      } catch (err) {
        console.error(err);
      }
    }
    getNews();
  }, []);

  const handleLink = (link: string) => {
    window.open(link, '_blank');
  };

  return (
    <>
      <Container>
        <Content>
          <Header />
        </Content>
      </Container>
      <Container backgroundImage className="companies-container">
        <Blur>
          <Content className="content">
            <NavBar />
            <TitleSection>
              <h1 className="h1">Parceiros</h1>
              <span>Nossos parceiros, mais vantagens para você!</span>
            </TitleSection>
          </Content>
        </Blur>
      </Container>
      <Container>
        <Content>
          <GridSection>
            <FlexContainer>
              <div className="partner">
                {partners.map(partner => (
                  <span className="card-span" key={partner.name}>
                    <SliderItem onClick={() => handleLink(partner.link)}>
                      <div className="imgContainer">
                        <img src={partner.logo} alt="" />
                      </div>
                      <div className="textContainer">
                        <h1>{partner.slogan}</h1>
                        <a href={partner.link}>
                          <Button
                            className="button"
                            color="orange"
                            padding="2"
                            rounded
                          >
                            ACESSE
                          </Button>
                        </a>
                      </div>
                    </SliderItem>
                  </span>
                ))}
              </div>
            </FlexContainer>
          </GridSection>
        </Content>
      </Container>
      <Container>
        <Footer />
      </Container>
    </>
  );
};

export default Parceiros;
