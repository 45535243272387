import styled from 'styled-components';

export const Container = styled.div`
  form {
    width: 100%;

    label {
      font-size: 13px;
    }

    .formTitle {
      font-size: 20px;
      margin-left: 4px;
      color: var(--purple);
    }

    .buttonsContainer {
      display: flex;
      justify-content: flex-end;
    }
  }

  .cep {
    margin: 0;
    font-size: 17px;
    color: var(--purple);
    padding: 3px;
    position: inherit;
}
  }
`;
