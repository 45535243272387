/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';
import { FiCalendar, FiXCircle } from 'react-icons/fi';
import DashboardLayout from '../DashboardLayout';
import Loader from '../Loader';
import { formatPrice } from '../../services/currency';

// import ModalUpdateInformations from '../../components/ModalUpdateInformations';

import { TitleSection, Main } from './styles';

import TicketingService, { Recarga } from '../../services/ticketing';
import AccountsService from '../../services/accounts';
import ModalPix from './ModalPix';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import Button from '../Button';

interface ExtratoUsoProps {
  idcard?: any;
}

const MeusPedidosCartao: React.FC<ExtratoUsoProps> = ({ idcard }) => {
  const servicesProfile = { AccountsService: new AccountsService() };
  const services = { ticketingService: new TicketingService() };
  const [recargas, setRecargas] = React.useState<any>();
  const [pixCode, setPixCode] = useState<any>();
  const [pixImage, setPixImage] = useState<any>();
  const [pixExpiration, setPixExpiration] = useState<any>();
  const [profile, setProfile] = React.useState([]);
  const [modalPixOpen, setModalPixOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [hasData, setHasData] = useState<boolean>(true);
  // const [
  //   modalUpdateInformationsIsOpen,
  //   setModalUpdateInformationsIsOpen,
  // ] = useState(false);

  const { addToast } = useToast();
  const { signOut } = useAuth();

  const handleRedirectRecarga = () => {
    window.location.href = `/meus-cartoes/recarregar/${idcard}`; // Redireciona para a página do QR Code
  };

  useEffect(() => {
    async function getRecargas() {
      try {
        const response = await services.ticketingService.getRecarga();
        const cartaorecarga = response.data.results.filter(
          (cartao: any) => cartao.card_data?.id === idcard,
        );
        setRecargas(cartaorecarga);
        setLoading(false);
        if (cartaorecarga.length > 0) {
          setHasData(true);
        } else {
          setHasData(false);
        }
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    }
    getRecargas();
  }, []);

  useEffect(() => {
    async function getProfile() {
      try {
        const response = await servicesProfile.AccountsService.getProfile();
        setProfile(response.data);
        if (
          response.data.address == null ||
          response.data.birth_date == null ||
          response.data.cpf == null ||
          response.data.email == null ||
          response.data.full_name == null
        )
          setLoading(false);
      } catch (err) {
        const { ...error }: any = err;

        addToast({
          type: 'error',
          title: `${
            error?.response?.data?.message
              ? error?.response?.data?.message
              : 'Erro no Servidor'
          }`,
          description: `${
            error?.response?.data?.sub_message
              ? error?.response?.data?.sub_message
              : 'Tente deslogar e logar novamente, caso o erro persista entre em contato com o Administrador'
          }`,
        });
        if (err) {
          signOut();
        }
        setLoading(false);
      }
    }
    getProfile();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <TitleSection>
            <h1>Histórico de Recargas</h1>
          </TitleSection>
          <Main>
            <Paper className="paper">
              {hasData ? (
                <Table>
                  <TableHead>
                    <TableRow className="header">
                      <TableCell>
                        <strong>Número do Cartão</strong>
                      </TableCell>
                      <TableCell className="pedido">
                        <strong>Data do Pedido</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Valor</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Status</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Pagamento</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {recargas &&
                      recargas.map((recarga: Recarga) => (
                        <TableRow key={Math.random()}>
                          <TableCell>{recarga.card_data.card_number}</TableCell>
                          <TableCell>{recarga.created_at}</TableCell>
                          <TableCell>{formatPrice(recarga.amount)}</TableCell>
                          <TableCell
                            style={{
                              cursor: 'default',
                            }}
                          >
                            {recarga.status_display ===
                            'Aguardando processamento' ? (
                              <div title="Pedido aguardando sequência para a próxima etapa.">
                                &#9432; {recarga.status_display}
                              </div>
                            ) : recarga.status_display ===
                              'Pedido em análise' ? (
                              <div title="Em caso de Dúvidas, entre em contato com nosso time de Sucesso do Cliente por telefone ou WhatsApp: 3248-7300.">
                                &#9432; {recarga.status_display}
                              </div>
                            ) : recarga.status_display ===
                              'Aguardando a confirmação do pagamento' ? (
                              <div title="O método de pagamento escolhido depende de compensação de pagamento pelo banco, podendo esse procedimento levar até 72 horas.">
                                &#9432; {recarga.status_display}
                              </div>
                            ) : recarga.status_display ===
                              'Pagamento confirmado. Pedido em processamento' ? (
                              <div title="Seu pedido foi confirmado! Os créditos podem ser disponibilizados em até 3 dias em seu cartão. Em caso de Dúvidas, entre em contato com nosso time de Sucesso do Cliente por telefone ou WhatsApp: 3248-7300.">
                                &#9432; {recarga.status_display}
                              </div>
                            ) : recarga.status_display ===
                              'Pedido processado com sucesso' ? (
                              <div title="Sua carga já está disponível, para utilizá-la basta realizar a validação nos leitores dos ônibus.">
                                &#9432; {recarga.status_display}
                              </div>
                            ) : recarga.status_display ===
                              'Aguardando confirmação de pagamento' ? (
                              <div title="Recebemos seu pedido. Estamos aguardando a confirmação do seu pagamento.">
                                &#9432; {recarga.status_display}
                              </div>
                            ) : recarga.status_display ===
                              'Erro no processamento' ? (
                              <div title="Houve uma falha técnica no processamento de seu pedido, gentileza entrar em contato com nosso time de Sucesso do Cliente por telefone ou WhatsApp: 3248-7300.">
                                &#9432; {recarga.status_display}
                              </div>
                            ) : recarga.status_display === 'Pedido negado' ? (
                              <div title="Para mais informações, entrar em contato com nosso time de Sucesso do Cliente telefone ou WhatsApp: 3248-7300.">
                                &#9432; {recarga.status_display}
                              </div>
                            ) : recarga.status_display ===
                              'Pagamento recusado' ? (
                              <div title="A compra em seu cartão foi recusada; para obter mais informações entre contato com a central de atendimento da operadora do seu cartão de crédito.">
                                &#9432; {recarga.status_display}
                              </div>
                            ) : recarga.status_display ===
                              'Em processamento' ? (
                              <div title="Pedido em processamento.">
                                &#9432; {recarga.status_display}
                              </div>
                            ) : recarga.status_display ===
                              'Aguardando pagamento de boleto' ? (
                              <div title="Recebemos seu pedido. Estamos aguardando o pagamento do boleto.">
                                &#9432; {recarga.status_display}
                              </div>
                            ) : (
                              <div title={recarga.status_display}>
                                &#9432; {recarga.status_display}.
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            {recarga.tacom_boleto && (
                              <a
                                href={recarga.tacom_boleto}
                                target="_blank"
                                rel="noreferrer"
                                style={{ color: '#0955a5' }}
                              >
                                Ver Boleto
                              </a>
                            )}
                            {recarga.pix_image_link && (
                              <>
                                <button
                                  type="button"
                                  className="verPix"
                                  onClick={() => {
                                    setModalPixOpen(true);
                                    setPixCode(recarga.pix_copy_paste);
                                    setPixImage(recarga.pix_image_link);
                                    setPixExpiration(recarga.pix_expiration);
                                  }}
                                >
                                  Ver PIX
                                </button>
                              </>
                            )}
                            {!recarga.tacom_boleto &&
                              !recarga.pix_image_link &&
                              '-'}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              ) : (
                <div className="nodata">
                  <FiXCircle size={80} className="icon" />
                  <h3 className="infonotdata">
                    Sem Histórico de recarga disponíveis.
                  </h3>
                  <h4 className="infonotdatasub">
                    Faça recargas para elas aparecerem aqui
                  </h4>
                  <Button
                    onClick={handleRedirectRecarga}
                    color="orange"
                    padding="6"
                    className="button"
                    rounded
                  >
                    FAZER UMA RECARREGA AGORA
                  </Button>
                </div>
              )}
            </Paper>
            {/* {modalUpdateInformationsIsOpen && <ModalUpdateInformations />} */}
            {modalPixOpen && (
              <>
                <ModalPix
                  closeModal={() => setModalPixOpen(false)}
                  codeExpiration={pixExpiration}
                  codePix={pixCode}
                  codeImage={pixImage}
                />
              </>
            )}
          </Main>
        </>
      )}
    </>
  );
};

export default MeusPedidosCartao;
