import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

export const CheckboxContainer = styled.span`
  width: 100%;
  height: auto;
  padding-top: 10px;
  padding-left: 10px;
  margin: 4px;
  border-radius: 20px;

  display: flex;
  align-items: center;
  flex: 1;
  .data-tip {
    color: var(--orange);
  }
  @media (max-width: 800px) {
    padding-left: 5px;
  }
  .tooltip {
    width: 120px;
    background-color: green;
    color: yellow;
    text-align: center;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
  }
`;

export const Text = styled.label`
  text-align: left;
  padding-left: 15px;
  font: 14px 'Source Sans Pro', sans-serif;
  font-weight: normal;
  @media (max-width: 800px) {
    font-size: 12px;
    padding: 3px;
  }
  @media (max-width: 650px) {
    font-size: 12px;
    padding: 3px;
    padding-left: 8px;
  }
  :hover {
    color: var(--orange);
  }
  .tooltip:hover {
    color: var(--purple);
  }
`;

export const HiddenCheckbox = styled.input`
  position: relative;
  cursor: pointer;
  :before {
    content: '';
    display: block;
    position: absolute;
    width: 14px;
    height: 14px;
    top: 0;
    left: 0;
    border: 2px solid var(--gray-line);
    border-radius: 3px;
    background-color: white;
  }
  :checked:after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background-color: var(--orange);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    position: absolute;
    top: 3px;
    left: 3px;
  }
`;
export const Error = styled.label`
  font-family: 'Inter', sans-serif;
  height: 20px;
  font-size: 15px;
  margin-bottom: 3px;
  color: var(--red);
  display: flex;
`;
export const ReactTooltipStyled = styled(ReactTooltip)`
  &.type-dark.place-top {
    background-color: white;
    padding: 0.3rem 1rem;
    width: 250px;
    height: auto;
    border: 3px solid var(--orange);
    color: var(--grey);

    &:after {
      border-top-color: var(--purple);
    }
  }
`;
