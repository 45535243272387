import React from 'react';
import { FiX } from 'react-icons/fi';
import { Container, Content } from './styles';
import Button from '../../../../components/Button';
import BannerMotiva from '../../../../assets/Banner/BannerMotiva3.png';

interface ModalInterface {
  handleClose(): void;
  show: boolean;
  text?: string;
  success?: boolean;
}

const ModalMotiva: React.FC<ModalInterface> = ({ handleClose, show }) => (
  <Container show={show}>
    <section>
      <>
        <button type="button" className="close-container" onClick={handleClose}>
          <FiX size={25} onClick={handleClose} />
        </button>
        <div className="modal-body">
          <Content>
            <a
              href="http://motivabeneficios.com.br/transfacil-diferenciais/?utm_source=site-transfacil&utm_medium=popup-pagempesas&utm_id=lead-transfacil-difer"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={BannerMotiva} alt="Banner Motiva" />
            </a>
          </Content>
        </div>
      </>
    </section>
  </Container>
);

export default ModalMotiva;
