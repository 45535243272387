/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { GoTriangleRight } from 'react-icons/go';
import { useLoadScript } from '@react-google-maps/api';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Input from '../../components/Input';
import AccountsService from '../../services/accounts';
import Loader from '../../components/Loader';
import LoaderSubLine from './LoaderSubLine';
import api from '../../services/api';
import { ReactComponent as Bus } from '../../assets/Shared/Bus.svg';
import { ReactComponent as ClockIcon } from '../../assets/RoutesAndLines/clock.svg';
import Accordion from '../../components/Accordion';
import Button from '../../components/Button';
import Header from '../../components/Home/Header';
import NavBar from '../../components/Home/NavBar';
import Lupasearch from '../../assets/Shared/lupasearch.svg';
import { useToast } from '../../hooks/toast';
import { Container, Content, Blur } from '../../styles/components';
import Lupaorange from '../../assets/RoutesAndLines/Lupaorange.png';
import { useAuth } from '../../hooks/auth';
import {
  TitleSection,
  GridSection,
  Main,
  Aside,
  AsideCard,
  CollapseContainer,
  TimeContainer,
  Dialogo,
  Modal,
  Dialogoline,
  Modalline,
} from './styles';
import Footer from '../../components/Home/Footer';

declare type Libraries = (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
)[];

interface Favoritas {
  id: number;
  user: number;
  code_line: string;
  name_line: string;
  sgl_line: string;
  follow: boolean;
}

interface LineItem {
  code: string;
  sgl: string;
  itinerario: any;
  name: string;
  tabelahorario: any;
  follow: boolean;
}

interface Line {
  cod: string;
  sgl: string;
  nom: string;
  display: string;
}

interface Itineraries {
  code: string;
  sgl: string;
  name: string;
  itinerario: any;
  tabelahorario: any;
}

const libraries: Libraries = ['places'];

const RoutesAndLines: React.FC = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
    language: 'pt-BR',
  });
  const { user } = useAuth();
  const servicesAccounts = { AccountsService: new AccountsService() };
  const formRef = useRef<FormHandles>(null);
  const { push } = useHistory();
  const [buscaLinha, setBuscaLinha] = useState<any>();
  console.log({ buscaLinha });
  const { addToast } = useToast();
  const [linhas, setLinhas] = useState<Itineraries[]>([]);
  const [rotas, setRotas] = useState<Line[]>([]);
  const [favoritas, setFavoritas] = useState<Favoritas[]>([]);
  const [line, setLine] = useState<LineItem | null>();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = useState<any>();
  const [origin, setOrigin] = React.useState<string>('');
  const [destination, setDestination] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(true);
  const [loadingSubLine, setLoadingSubLine] = React.useState<boolean>(false);
  const [subLinhas, setSubLinhas] = React.useState<any>([]);
  const [subLines, setSubLines] = React.useState<any>([]);

  useEffect(() => {
    async function getLinhas() {
      try {
        setLoading(true);
        const response = await api.get('/core/lines-itineraries/');
        setLinhas(response.data.lines_with_itinerary);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    }
    getLinhas();
  }, []);

  useEffect(() => {
    async function getRotas() {
      try {
        setLoading(true);
        const response = await api.get('/core/lines/');
        setRotas(response.data.lines);
        // setLoading(false);
      } catch (err) {
        const modallines = document.getElementById('lines');
        modallines.style.display = 'none';
        console.error(err);
        setLoading(false);
      }
    }
    getRotas();
  }, []);

  const handleSubmit = useCallback(() => {
    const getLines = async () => {
      setLine(null);
      try {
        setLoading(true);
        /* const response = await api.get(`/core/line-info/${value.cod}/`);
        setLine(response.data); */
        if (value.sgl) {
          const response = await api.get(`/core/v2/line-info/${value.cod}/`);
          setLine(response.data);
        } else {
          const responses = await api.get(`/core/v2/line-info/${value}/`);
          setLine(responses.data);
        }
        setLoading(false);
        const modallines = document.getElementById('lines');
        modallines.style.display = 'none';
      } catch (err) {
        setLoading(false);
        const modal = document.getElementById('errorline');
        modal.style.display = 'block';
        const modallines = document.getElementById('lines');
        modallines.style.display = 'none';
      }
    };
    getLines();
  }, [value]);

  const onChangeAutocomplete = useCallback(
    (event: any, newValue: Line) => {
      console.log(newValue);
      setValue(newValue);
    },
    [setValue],
  );

  const handleSubmitLinhas = useCallback(data => {
    const getLines = async () => {
      setLine(null);
      try {
        setLoading(true);
        const response = await api.get('/core/lines/');
        const filteredLines = response.data.lines.filter(
          (f: any) => f.sgl === data.search,
        );
        const responseLinha = await api.get(
          `/core/line-info/${filteredLines[0].sgl}/`,
        );
        setRotas(responseLinha.data.lines);
        setLine(responseLinha.data);
        setLoading(false);
        const modallines = document.getElementById('lines');
        modallines.style.display = 'none';
        const modal = document.getElementById('errorline');
        modal.style.display = 'none';
      } catch (err) {
        setLoading(false);
        const modal = document.getElementById('errorline');
        modal.style.display = 'block';
        const modallines = document.getElementById('lines');
        modallines.style.display = 'none';
      }
    };
    getLines();
  }, []);

  useEffect(() => {
    setInputValue(inputValue);
  }, [inputValue]);

  const handleOrigin = useCallback(address => {
    setOrigin(address);
  }, []);

  const handleDestination = useCallback(address => {
    setDestination(address);
  }, []);

  useEffect(() => {
    async function getFavoritas() {
      try {
        if (user) {
          setLoading(true);
          const response = await servicesAccounts.AccountsService.getLineFavorite();
          setFavoritas(response.data.results);
          // setLoading(false);
        }
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    }
    getFavoritas();
  }, [user]);

  const handleCreateFavorite = useCallback(
    async data => {
      try {
        await servicesAccounts.AccountsService.createLineFavorite({
          ...data,
        });
        const response = await api.get('/core/lines/');
        const filteredLines = response.data.lines.filter(
          (f: any) => f.sgl === data.search,
        );
        const responseLinha = await api.get(
          `/core/line-info/${filteredLines[0].cod}/`,
        );
        console.log({ responseLinha });
        const responsefavorites = await servicesAccounts.AccountsService.getLineFavorite();
        setFavoritas(responsefavorites.data.results);
        setLine(null);
        setLine(response.data);
      } catch (err: any) {
        const { ...error } = err;
        console.error(error);
      }
    },
    [servicesAccounts.AccountsService],
  );

  const handleRemoveFavorite = useCallback(
    async data => {
      try {
        await servicesAccounts.AccountsService.deleteLineFavorite(
          data.favorite,
        );
        const response = await api.get('/core/lines/');
        const filteredLines = response.data.lines.filter(
          (f: any) => f.sgl === data.search,
        );
        const responseLinha = await api.get(
          `/core/line-info/${filteredLines[0].cod}/`,
        );
        console.log({ responseLinha });
        const responsefavorites = await servicesAccounts.AccountsService.getLineFavorite();
        const responselinhas = await api.get('/core/lines-itineraries/');
        setFavoritas(responsefavorites.data.results);
        setLinhas([]);
        setLinhas(responselinhas.data.lines_with_itinerary);
        setLine(null);
        setLine(response.data);
      } catch (err: any) {
        const { ...error } = err;
        console.error(error);
      }
    },
    [servicesAccounts.AccountsService],
  );

  const handleFavorite = useCallback(
    async data => {
      const filteredFavorite = favoritas.filter((favorita: any) => {
        return favorita.sgl_line === data.sgl_line;
      });
      try {
        if (data.follow) {
          handleRemoveFavorite({
            favorite: filteredFavorite[0].id,
            slg: data.sgl_line,
          });
        } else {
          handleCreateFavorite(data);
        }
      } catch (err: any) {
        const { ...error } = err;
        console.error(error);
      }
    },
    [favoritas, handleCreateFavorite, handleRemoveFavorite],
  );

  const handleSearchMap = useCallback(() => {
    if (destination && origin) {
      const encodedQueryParams = encodeURI(
        `?origin=${origin}&destination=${destination}`,
      );
      window.location.href = `/trajetos/${encodedQueryParams}`;
    } else {
      addToast({
        type: 'info',
        title: 'Selecione primeiro a origem e destino',
        description: 'Não foi possível redirecionar para o mapa',
      });
    }
  }, [destination, origin, addToast]);

  const handleSublines = useCallback(async (cod: any) => {
    setLoadingSubLine(true);
    const response = await api.get(`/core/sub-lines/${cod}/`);
    const responseSubLinhas = response.data.sub_lines.map(async (sub: any) => {
      const responsibles = await api.get(`/core/v2/line-info/${sub.cod}/`);
      return responsibles.data;
    });
    const promisesResponse = await Promise.all(responseSubLinhas);

    const responseSubLines = await api.get(`/core/sub-lines/${cod}/`);
    const sublinhas = responseSubLines.data.sub_lines.map((sub: any) => sub);
    const changedResponse = promisesResponse.map((item: any) => {
      const filteredSubline = response.data.sub_lines.filter(
        (subline: any) => subline.cod === Number(item.code),
      )[0];
      return { ...item, sublineName: filteredSubline.nom };
    });
    setSubLinhas(sublinhas);
    setSubLines(changedResponse);
    setLoadingSubLine(false);
    return {};
  }, []);

  React.useEffect(() => {
    console.log(inputValue);
    console.log(value);
  }, [inputValue]);

  return (
    <>
      <Container>
        <Content>
          <Header />
        </Content>
      </Container>
      <Container backgroundImage className="companies-container">
        <Blur>
          <Content className="content">
            <NavBar />
            <TitleSection>
              <h1>Rotas e Linhas</h1>
              <span>Confira o itinerário e rotas</span>
            </TitleSection>
          </Content>
        </Blur>
      </Container>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Container backgroundColor="background-gray">
            <Content>
              <GridSection>
                <Main>
                  <h2>Digite o número ou nome da linha desejada</h2>

                  <div className="inputcontainer">
                    <Autocomplete
                      freeSolo
                      id="free-solo-2-demo"
                      options={rotas}
                      getOptionLabel={rota => rota.display}
                      value={value}
                      autoHighlight
                      onChange={onChangeAutocomplete}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      onFocus={() => setValue(null)}
                      onSelect={value && handleSubmit}
                      renderInput={params => (
                        <div
                          className="inputWrapper"
                          ref={params.InputProps.ref}
                        >
                          <input
                            type="text"
                            placeholder="Pesquise linhas"
                            {...params.inputProps}
                            className="input"
                            onKeyPress={event =>
                              event.key === 'Enter' && handleSubmit()
                            }
                          />

                          {/* <Button
                            type="submit"
                            color="orange"
                            padding="3"
                            className="button"
                          >
                            <span className="btn-buscar">BUSCAR</span>
                            <img
                              src={Lupasearch}
                              alt="search"
                              className="btn-search"
                            />
                          </Button> */}
                        </div>
                      )}
                    />
                    <Form ref={formRef} onSubmit={handleSubmit}>
                      <Input
                        name="search"
                        className="buscalinha"
                        placeholder="Pesquise Linhas ou paradas"
                        defaultValue={inputValue}
                        onChange={e => setBuscaLinha(e.target.value)}
                      >
                        {value !== null ? (
                          <Button
                            type="submit"
                            color="orange"
                            padding="3"
                            className="button"
                          >
                            <span className="btn-buscar">BUSCAR</span>
                            <img
                              src={Lupasearch}
                              alt="search"
                              className="btn-search"
                            />
                          </Button>
                        ) : (
                          <Button
                            type="submit"
                            color="gray-line"
                            padding="3"
                            className="button"
                            disabled
                          >
                            <span className="btn-buscar">BUSCAR</span>
                            <img
                              src={Lupasearch}
                              alt="search"
                              className="btn-search"
                            />
                          </Button>
                        )}
                      </Input>
                    </Form>
                  </div>

                  {line && (
                    <Accordion
                      key={line.code}
                      cod={line.code}
                      CollapseIcon={GoTriangleRight}
                      title={line.sgl}
                      subTitle={line.name}
                      fav={line.follow}
                      handleFavorite={handleFavorite}
                      name={user && line.name}
                      Icon={Bus}
                      handleSublines={handleSublines}
                      hasSublines
                    >
                      {loadingSubLine ? (
                        <LoaderSubLine />
                      ) : (
                        <>
                          {subLinhas[1] &&
                            subLines.map((sub: any) => (
                              <>
                                {Object.keys(sub.tabelahorario).length > 0 && (
                                  <Accordion
                                    key={sub.code}
                                    CollapseIcon={GoTriangleRight}
                                    title={line.sgl}
                                    subTitle={sub.sublineName}
                                  >
                                    <CollapseContainer>
                                      {Object.keys(sub.tabelahorario).map(
                                        (rota: any) => {
                                          return (
                                            <>
                                              <h3>{rota}</h3>
                                              {Object.keys(
                                                sub.tabelahorario[rota],
                                              ).map((dia: any) => {
                                                return (
                                                  <>
                                                    {!dia.includes('Covid') && (
                                                      <>
                                                        <TimeContainer
                                                          key={rota}
                                                        >
                                                          <div className="sub-ball-container">
                                                            <div>
                                                              <ClockIcon />
                                                            </div>
                                                          </div>
                                                          <div className="text-container">
                                                            <h1>{dia}</h1>
                                                            <div className="flex">
                                                              {sub.tabelahorario[
                                                                rota
                                                              ][dia].map(
                                                                (item: any) => {
                                                                  return (
                                                                    <div
                                                                      className="horario"
                                                                      key={item}
                                                                    >
                                                                      {item}
                                                                    </div>
                                                                  );
                                                                },
                                                              )}
                                                            </div>
                                                          </div>
                                                        </TimeContainer>
                                                      </>
                                                    )}
                                                  </>
                                                );
                                              })}
                                            </>
                                          );
                                        },
                                      )}
                                      <h1>Itinerário</h1>
                                      {Object.keys(sub.itinerario).map(
                                        (ponto: any) => {
                                          return (
                                            <>
                                              <div className="text-container">
                                                <h3
                                                  className="localizacao-pontos"
                                                  key={ponto}
                                                >
                                                  {ponto}
                                                </h3>
                                              </div>
                                              {Object.keys(
                                                sub.itinerario[ponto],
                                              ).map((localizacao: any) => {
                                                return (
                                                  <div key={localizacao}>
                                                    {
                                                      sub.itinerario[ponto][
                                                        localizacao
                                                      ]
                                                    }
                                                  </div>
                                                );
                                              })}
                                            </>
                                          );
                                        },
                                      )}
                                    </CollapseContainer>
                                  </Accordion>
                                )}
                              </>
                            ))}
                          {!subLinhas[1] && (
                            <CollapseContainer>
                              {Object.keys(line.tabelahorario).map(
                                (rota: any) => {
                                  return (
                                    <>
                                      <h3>{rota}</h3>
                                      {Object.keys(
                                        line.tabelahorario[rota],
                                      ).map((dia: any) => {
                                        return (
                                          <>
                                            {!dia.includes('Covid') && (
                                              <>
                                                <TimeContainer key={rota}>
                                                  <div className="sub-ball-container">
                                                    <div>
                                                      <ClockIcon />
                                                    </div>
                                                  </div>
                                                  <div className="text-container">
                                                    <h1>{dia}</h1>
                                                    <div className="flex">
                                                      {line.tabelahorario[rota][
                                                        dia
                                                      ].map((item: any) => {
                                                        return (
                                                          <div
                                                            className="horario"
                                                            key={item}
                                                          >
                                                            {item}
                                                          </div>
                                                        );
                                                      })}
                                                    </div>
                                                  </div>
                                                </TimeContainer>
                                              </>
                                            )}
                                          </>
                                        );
                                      })}
                                    </>
                                  );
                                },
                              )}
                              <h1>Itinerário</h1>
                              {Object.keys(line.itinerario).map(
                                (ponto: any) => {
                                  return (
                                    <>
                                      <div className="text-container">
                                        <h3
                                          className="localizacao-pontos"
                                          key={ponto}
                                        >
                                          {ponto}
                                        </h3>
                                      </div>
                                      {Object.keys(line.itinerario[ponto]).map(
                                        (localizacao: any) => {
                                          return (
                                            <div key={localizacao}>
                                              {
                                                line.itinerario[ponto][
                                                  localizacao
                                                ]
                                              }
                                            </div>
                                          );
                                        },
                                      )}
                                    </>
                                  );
                                },
                              )}
                            </CollapseContainer>
                          )}
                        </>
                      )}
                    </Accordion>
                  )}
                  <Modalline id="lines">
                    <Dialogoline>
                      {linhas &&
                        linhas.map((linha: any) => (
                          <Accordion
                            key={linha.code}
                            cod={linha.code}
                            CollapseIcon={GoTriangleRight}
                            title={linha.sgl}
                            handleFavorite={handleFavorite}
                            name={user && linha.name}
                            fav={linha.follow}
                            subTitle={linha.name}
                            Icon={Bus}
                            handleSublines={handleSublines}
                            hasSublines
                          >
                            {loadingSubLine ? (
                              <LoaderSubLine />
                            ) : (
                              <>
                                {subLinhas[1] &&
                                  subLines.map((sub: any) => (
                                    <>
                                      {Object.keys(sub.tabelahorario).length >
                                        0 && (
                                        <Accordion
                                          key={sub.code}
                                          CollapseIcon={GoTriangleRight}
                                          title={linha.sgl}
                                          subTitle={sub.sublineName}
                                        >
                                          <CollapseContainer>
                                            {Object.keys(sub.tabelahorario).map(
                                              (rota: any) => {
                                                return (
                                                  <>
                                                    <h3>{rota}</h3>
                                                    {Object.keys(
                                                      sub.tabelahorario[rota],
                                                    ).map((dia: any) => {
                                                      return (
                                                        <>
                                                          {!dia.includes(
                                                            'Covid',
                                                          ) && (
                                                            <>
                                                              <TimeContainer
                                                                key={rota}
                                                              >
                                                                <div className="sub-ball-container">
                                                                  <div>
                                                                    <ClockIcon />
                                                                  </div>
                                                                </div>
                                                                <div className="text-container">
                                                                  <h1>{dia}</h1>
                                                                  <div className="flex">
                                                                    {sub.tabelahorario[
                                                                      rota
                                                                    ][dia].map(
                                                                      (
                                                                        item: any,
                                                                      ) => {
                                                                        return (
                                                                          <div
                                                                            className="horario"
                                                                            key={
                                                                              item
                                                                            }
                                                                          >
                                                                            {
                                                                              item
                                                                            }
                                                                          </div>
                                                                        );
                                                                      },
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              </TimeContainer>
                                                            </>
                                                          )}
                                                        </>
                                                      );
                                                    })}
                                                  </>
                                                );
                                              },
                                            )}
                                            <h1>Itinerário</h1>
                                            {Object.keys(sub.itinerario).map(
                                              (ponto: any) => {
                                                return (
                                                  <>
                                                    <div className="text-container">
                                                      <h3
                                                        className="localizacao-pontos"
                                                        key={ponto}
                                                      >
                                                        {ponto}
                                                      </h3>
                                                    </div>
                                                    {Object.keys(
                                                      sub.itinerario[ponto],
                                                    ).map(
                                                      (localizacao: any) => {
                                                        return (
                                                          <div
                                                            key={localizacao}
                                                          >
                                                            {
                                                              sub.itinerario[
                                                                ponto
                                                              ][localizacao]
                                                            }
                                                          </div>
                                                        );
                                                      },
                                                    )}
                                                  </>
                                                );
                                              },
                                            )}
                                          </CollapseContainer>
                                        </Accordion>
                                      )}
                                    </>
                                  ))}
                                {!subLinhas[1] && (
                                  <CollapseContainer>
                                    {Object.keys(linha.tabelahorario).map(
                                      (rota: any) => {
                                        return (
                                          <>
                                            <h3>{rota}</h3>
                                            {Object.keys(
                                              linha.tabelahorario[rota],
                                            ).map((dia: any) => {
                                              return (
                                                <>
                                                  {!dia.includes('Covid') && (
                                                    <>
                                                      <TimeContainer key={rota}>
                                                        <div className="sub-ball-container">
                                                          <div>
                                                            <ClockIcon />
                                                          </div>
                                                        </div>
                                                        <div className="text-container">
                                                          <h1>{dia}</h1>
                                                          <div className="flex">
                                                            {linha.tabelahorario[
                                                              rota
                                                            ][dia].map(
                                                              (item: any) => {
                                                                return (
                                                                  <div
                                                                    className="horario"
                                                                    key={item}
                                                                  >
                                                                    {item}
                                                                  </div>
                                                                );
                                                              },
                                                            )}
                                                          </div>
                                                        </div>
                                                      </TimeContainer>
                                                    </>
                                                  )}
                                                </>
                                              );
                                            })}
                                          </>
                                        );
                                      },
                                    )}
                                    <h1>Itinerário</h1>
                                    {Object.keys(linha.itinerario).map(
                                      (ponto: any) => {
                                        return (
                                          <>
                                            <div className="text-container">
                                              <h3
                                                className="localizacao-pontos"
                                                key={ponto}
                                              >
                                                {ponto}
                                              </h3>
                                            </div>
                                            {Object.keys(
                                              linha.itinerario[ponto],
                                            ).map((localizacao: any) => {
                                              return (
                                                <div key={localizacao}>
                                                  {
                                                    linha.itinerario[ponto][
                                                      localizacao
                                                    ]
                                                  }
                                                </div>
                                              );
                                            })}
                                          </>
                                        );
                                      },
                                    )}
                                  </CollapseContainer>
                                )}
                              </>
                            )}
                          </Accordion>
                        ))}
                    </Dialogoline>
                  </Modalline>
                  <Modal id="errorline">
                    <Dialogo>
                      <div className="container">
                        <div className="text" id="text">
                          <img src={Lupaorange} alt="lupa" />
                          <h1>Nenhuma linha encontrada!</h1>
                          <p>Revise os dados digitados e pesquise novamente.</p>
                        </div>
                      </div>
                    </Dialogo>
                  </Modal>
                </Main>

                <Aside>
                  {isLoaded && (
                    <AsideCard>
                      <div className="header">Faça sua Rota</div>
                      <div className="main">
                        <Form ref={formRef} onSubmit={handleSubmit}>
                          <p className="highlight-label">
                            Seu Local de partida
                          </p>
                          <p>Digite seu lugar de origem</p>

                          <Input
                            onChange={e => handleOrigin(e.target.value)}
                            name="OrigemInput"
                            placeholder="Origem"
                          />

                          <p>Digite para onde você vai</p>
                          <Input
                            onChange={e => handleDestination(e.target.value)}
                            name="DestinoInput"
                            placeholder="Destino"
                          />

                          <Button
                            style={{ marginBottom: '12px' }}
                            type="button"
                            color="orange"
                            padding="5"
                            onClick={() => handleSearchMap()}
                            rounded
                          >
                            Buscar
                          </Button>
                        </Form>
                      </div>
                      <div className="footer">
                        <Button
                          onClick={() => push('trajetos/')}
                          type="submit"
                          color="white"
                          padding="4"
                          rounded
                        >
                          Ver Mapa
                        </Button>
                      </div>
                    </AsideCard>
                  )}
                </Aside>
              </GridSection>
            </Content>
          </Container>
          <Container>
            <Footer />
          </Container>
        </>
      )}
    </>
  );
};

export default RoutesAndLines;
