/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { useEffect } from 'react';

import { Container, AddCard } from './styles';
import Card from './Card';

import { Column } from '../../../styles/components';
import Add from '../../../assets/MyCards/addcard.svg';

import TicketingService from '../../../services/ticketing';
import AddPaymentCard from '../../../components/MyCards/AddPaymentCard';

interface CardListProps {
  setSelectCard: any;
  selectCard: any
}

const ListCard: React.FC<CardListProps> = ({ setSelectCard, selectCard, ...rest }) => {
  const services = { ticketingService: new TicketingService() };
  const [show, setShow] = React.useState(false);
  const [cards, setCards] = React.useState<any[]>([]);

  const getCards = React.useCallback(async () => {
    try {
      // setLoading(true);
      const response = await services.ticketingService.getPaymentForm();

      setCards(response?.data?.results);
    } catch (err) {
      console.log({ err });
    } finally {
      // setLoading(false);
    }
  }, []);
  useEffect(() => {
    getCards();
  }, []);

  return (
    <Container {...rest}>
      <AddPaymentCard
        show={show}
        title="Adicionar Cartão"
        handleClose={() => setShow(false)}
        handleAdd={() => getCards()}
      />
      {cards && (
        <>
          <div className="cards-container">
            <Column small="12" medium="4" large="3">
              <AddCard onClick={() => setShow(!show)}>
                <img src={Add} alt="cadastrar cartao" />
                <span>CADASTRAR NOVO CARTÃO</span>
              </AddCard>
            </Column>
            {cards.filter((card: any) => {
              if (card.is_active && card.payment_type === 'credit_card') {
                return true;
              }
              return false
            }).map((card: any) => (
              <Column key={card.id} small="12" medium="4" large="3">
                <div
                  role="button"
                  onClick={() => setSelectCard(card)}
                  onKeyDown={() => setSelectCard(card)}
                  key={card.id}
                  tabIndex={-1}
                >
                  <Card
                    selected={selectCard?.id === card?.id}
                    nickname={card?.titular_name}
                    cardNumber={card?.card_number_display}
                  />
                </div>
              </Column>
            ))}
          </div>
        </>
      )}
    </Container>
  );
};

export default ListCard;
