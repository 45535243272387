import styled from 'styled-components';

export const Main = styled.main`
  align-self: center;
  width: 100%;
  grid-area: main;
  padding: 1.5rem 0;

  h1 {
    color: var(--purple);
    margin-top: 40px;
    @media (max-width: 768px) {
      margin-top: 0px;
    }
  }

  span {
    display: inline-block;
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    text-align: center;
    padding: 0.5rem 0;
  }
`;

export const Sliders = styled.div`
  width: 100%;
  margin-top: 25px 0 20px 0;

  .slick-list {
    margin: 0;
  }

  .slick-dots {
    li {
      button {
        &:before {
          color: #dc582a;
          font-size: 13px;
        }
      }
    }
  }
`;

export const SliderItem = styled.div`
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .imgMobile {
    cursor: pointer;
    width: 100%;
    margin: 0;
  }
`;

export const SliderDesktop = styled.div`
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const SliderMobile = styled.div`
  display: none;
  width: 100%;
  @media (max-width: 768px) {
    display: block;
  }
`;
