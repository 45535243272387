import styled from 'styled-components';

export const Container = styled.div`
  .title {
    color: var(--purple);
    margin-bottom: 50px;
    font-size: 3rem;
  }
  .accordion {
    background: black;
  }
`;

export const CollapseContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* max-width: 85%; */
  .flex {
    display: flex;
    flex-wrap: wrap;
  }
  .localizacao-pontos {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .horario {
    margin-right: 10px;
  }

  .sub-ball-container {
    margin: 0 15px;
    display: flex;
    align-items: flex-start;
    height: 100%;
    div {
      width: 42px;
      height: 42px;

      border-radius: 50%;
      background: var(--soft-orange);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .text-container {
    width: 100%;
    h1 {
      font-style: normal;

      font-size: 18px;
      line-height: 24px;
      text-transform: uppercase;
      color: var(--title-higlight);
      margin-bottom: 8px;
    }

    span,
    small {
      font-style: normal;

      font-size: 18px;
      line-height: 32px;
      text-transform: uppercase;
      color: var(--title-higlight);
    }

    small {
      font-weight: 500;
      text-transform: inherit;
    }
  }
`;

export const TimeContainer = styled.div`
  display: flex;
  margin-bottom: 25px;
  margin-top: 25px;
`;
