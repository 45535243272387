import React, { useCallback, useState } from 'react';
import { Form } from '@unform/web';

import { AiOutlineAim } from 'react-icons/ai';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import Rota from '../../../assets/Shared/rota.svg';
import Mask from '../../../assets/Home/mask2.svg';

import Input from '../../Input';
import Button from '../../Button';
import { useToast } from '../../../hooks/toast';
import { LatLng } from '../../../hooks/map';
import {
  Container as GlobalContainer,
  Content,
} from '../../../styles/components';

import {
  GridContainer,
  Container,
  FirstContent,
  SecondContent,
  LastContent,
  Main,
  BusContent,
  Aside,
} from './styles';

const LocationAndRouteSection: React.FC = () => {
  const { addToast } = useToast();
  const [origin, setOrigin] = React.useState<string>('');
  const [destination, setDestination] = React.useState<string>('');
  const [googleAddress, setGoogleAddress] = useState('');
  const [latLng, setLatLng] = useState<LatLng>({} as LatLng);

  const handleOrigin = useCallback(address => {
    setOrigin(address);
  }, []);

  const handleDestination = useCallback(address => {
    setDestination(address);
  }, []);

  const setMapCoordinates = useCallback(async address => {
    const geoCode = await getGeocode({ address });
    const { lat, lng } = await getLatLng(geoCode[0]);
    setLatLng({ lat, lng });
    setGoogleAddress(address);
  }, []);

  const handleSearchMap = useCallback(() => {
    if (destination && origin) {
      const encodedQueryParams = encodeURI(
        `?origin=${origin}&destination=${destination}`,
      );
      window.location.href = `/trajetos/${encodedQueryParams}`;
    } else {
      addToast({
        type: 'info',
        title: 'Selecione primeiro a origem e destino',
        description: 'Não foi possível redirecionar para o mapa',
      });
    }
  }, [destination, origin, addToast]);

  const handleRedirectMap = useCallback(() => {
    if (googleAddress) {
      const encodedQueryParams = encodeURI(
        `?lat=${latLng.lat}&lng=${latLng.lng}&address=${googleAddress}`,
      );
      window.location.href = `/pontos-de-recarga/${encodedQueryParams}`;
    } else {
      addToast({
        type: 'info',
        title: 'Selecione primeiro o Endereço',
        description: 'Não foi possível redirecionar para o mapa',
      });
    }
  }, [googleAddress, latLng, addToast]);

  const handleMyLocation = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      position => {
        const encodedQueryParams = encodeURI(
          `?lat=${position.coords.latitude}&lng=${position.coords.longitude}`,
        );
        window.location.href = `/pontos-de-recarga/${encodedQueryParams}`;
      },
      () => null,
    );
  }, []);

  return (
    <>
      <GlobalContainer>
        <Content>
          <Container>
            <h1 className="lojas">
              Confira as lojas ou os locais de recarga perto de você.
            </h1>
            <button
              type="button"
              className="locationContainer"
              onClick={handleMyLocation}
            >
              <AiOutlineAim /> Usar minha localização
            </button>
            <Form className="ButtonSend" onSubmit={handleRedirectMap}>
              <Input
                name="EndereçoInput"
                onChange={e => setMapCoordinates(e.target.value)}
                placeholder="Endereço"
                type="recharge"
              >
                <Button type="submit" color="orange" padding="3">
                  <span className="btn-buscar">BUSCAR</span>
                </Button>
              </Input>
            </Form>
          </Container>
          <GridContainer>
            <FirstContent>
              <Container>
                <h1>Consulte sua rota:</h1>
              </Container>
              <Form onSubmit={handleSearchMap}>
                <div className="searchRouteContainer">
                  <p className="searchp">Digite seu lugar de origem</p>
                  <div className="googleSearchContainer">
                    <Input
                      name="OrigemInput"
                      onChange={e => handleOrigin(e.target.value)}
                      placeholder="Origem"
                    />
                  </div>

                  <p className="searchp">Digite para onde você vai</p>
                  <div className="googleSearchContainer">
                    <Input
                      name="DestinoInput"
                      onChange={e => handleDestination(e.target.value)}
                      placeholder="Destino"
                    />
                  </div>
                  <Button type="submit" className="button" color="" padding="">
                    BUSCAR
                  </Button>
                </div>
              </Form>
            </FirstContent>
            <SecondContent>
              <img src={Rota} alt="Imagem origem e destino" className="rota" />
            </SecondContent>
          </GridContainer>
        </Content>
      </GlobalContainer>
      <Aside>
        <img src={Mask} alt="" className="maskorange" />
        <LastContent>
          <BusContent>
            <div>
              <h1>Confira nossas linhas e itinerários</h1>
            </div>
          </BusContent>
          <p>
            <a href="/rotas-e-linhas">
              <button type="button">VER LINHAS</button>
            </a>
          </p>
        </LastContent>
      </Aside>
      <Main />
    </>
  );
};

export default LocationAndRouteSection;
