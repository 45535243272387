/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useRef, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useLoadScript } from '@react-google-maps/api';
import { GoTriangleRight } from 'react-icons/go';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { LatLng } from '../../hooks/map';
import { useToast } from '../../hooks/toast';
import Loader from '../../components/Loader';

import { ReactComponent as PinIcon } from '../../assets/LojasLocaisRecarga/pin.svg';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Accordion from '../../components/Accordion';
import { Container, Content, Blur } from '../../styles/components';
import { TitleSection, GridSection, Main, Aside, AsideCard } from './styles';
import Lupasearch from '../../assets/Shared/lupasearch.svg';

import Header from '../../components/Home/Header';
import NavBar from '../../components/Home/NavBar';
import Footer from '../../components/Home/Footer';

import CoreService, { RechargePoint } from '../../services/core';

declare type Libraries = (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
)[];

const libraries: Libraries = ['places'];

const LojasLocaisRecarga: React.FC = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
    language: 'pt-BR',
  });
  const { addToast } = useToast();
  const { push } = useHistory();
  const services = { coreService: new CoreService() };
  const [loading, setLoading] = React.useState<boolean>(true);
  const formRef = useRef<FormHandles>(null);
  const [points, setPoints] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [googleAddress, setGoogleAddress] = useState('');
  const [latLng, setLatLng] = useState<LatLng>({} as LatLng);

  useEffect(() => {
    async function getFaqs() {
      try {
        const response = await services.coreService.getRechargePoint();
        setPoints(response.data.results);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    }
    getFaqs();
  }, []);

  useEffect(() => {
    const results = points.filter(point => {
      return Object.keys(point).some(key =>
        JSON.stringify(point[key])
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/-(?!>)/g, '')
          .replace(/\s/g, '')
          .toLowerCase()
          .toUpperCase()
          .includes(
            searchTerm
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .replace(/-(?!>)/g, '')
              .replace(/\s/g, '')
              .toLowerCase()
              .toUpperCase(),
          ),
      );
    });
    setSearchResults(results);
  }, [searchTerm, points]);

  const handleChange = useCallback(e => {
    setSearchTerm(e.target.value);
  }, []);

  const setMapCoordinates = useCallback(async address => {
    const geoCode = await getGeocode({ address });
    const { lat, lng } = await getLatLng(geoCode[0]);
    setLatLng({ lat, lng });
    setGoogleAddress(address);
  }, []);

  const handleRedirectMap = useCallback(() => {
    if (googleAddress) {
      const encodedQueryParams = encodeURI(
        `?lat=${latLng.lat}&lng=${latLng.lng}&address=${googleAddress}`,
      );
      window.location.href = `/pontos-de-recarga/${encodedQueryParams}`;
    } else {
      addToast({
        type: 'info',
        title: 'Selecione primeiro o Endereço',
        description: 'Não foi possível redirecionar para o mapa',
      });
    }
  }, [googleAddress, latLng, addToast]);

  const handleSubmit = useCallback(() => {
    return {};
  }, []);

  return (
    <>
      <Container>
        <Content>
          <Header />
        </Content>
      </Container>
      <Container backgroundImage className="companies-container">
        <Blur>
          <Content className="content">
            <NavBar />
            <TitleSection>
              <h1 className="h1">Lojas e Locais de Recarga</h1>
              <span>Confira os pontos mais próximos de você.</span>
            </TitleSection>
          </Content>
        </Blur>
      </Container>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Container backgroundColor="background-gray">
            <Content>
              <GridSection>
                <Main>
                  <Form ref={formRef} onSubmit={handleSubmit}>
                    <Input
                      name="search"
                      placeholder="Pesquisar local de recarga ou região"
                      onChange={e => handleChange(e)}
                    >
                      <Button type="submit" color="orange" padding="3">
                        <span className="btn-buscar">BUSCAR</span>
                        <img
                          src={Lupasearch}
                          alt="search"
                          className="btn-search"
                        />
                      </Button>
                    </Input>
                  </Form>
                  {searchResults.map((point: RechargePoint) => (
                    <Accordion
                      key={point.id}
                      Icon={PinIcon}
                      CollapseIcon={GoTriangleRight}
                      title={point.name}
                      region1={point.regions ? point.regions.split(',')[0] : ''}
                      region2={point.regions ? point.regions.split(',')[1] : ''}
                      region3={point.regions ? point.regions.split(',')[2] : ''}
                      region4={point.regions ? point.regions.split(',')[3] : ''}
                    >
                      <p>
                        {point.address}
                        <br />
                        {point.opening}
                      </p>
                    </Accordion>
                  ))}
                </Main>

                <Aside>
                  {isLoaded && (
                    <AsideCard>
                      <div className="header">Busque perto de você</div>
                      <div className="main">
                        <Form ref={formRef} onSubmit={handleSubmit}>
                          <p className="highlight-label">
                            Digite a região ou endereço que deseja buscar
                          </p>
                          {/* <p>Usar localização</p> */}
                          <Input
                            name="endereçoOrigem"
                            onChange={e => setMapCoordinates(e.target.value)}
                            placeholder="endereço"
                            type="recharge"
                          />
                          {/* <Input name="pesquisar" placeholder="Pesquisar" /> */}
                          <Button
                            onClick={handleRedirectMap}
                            type="submit"
                            color="orange"
                            padding="5"
                            rounded
                          >
                            Buscar
                          </Button>
                        </Form>
                      </div>
                      <div className="footer">
                        <Button
                          onClick={() => push('pontos-de-recarga/')}
                          type="submit"
                          color="white"
                          padding="4"
                          rounded
                        >
                          Ver Mapa
                        </Button>
                      </div>
                    </AsideCard>
                  )}
                </Aside>
              </GridSection>
            </Content>
          </Container>
          <Container>
            <Footer />
          </Container>
        </>
      )}
    </>
  );
};

export default LojasLocaisRecarga;
