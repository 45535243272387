/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { useRef, useCallback, useState } from 'react';
import * as Yup from 'yup';
import luhn from 'luhn';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import getValidationErrors from '../../../utils/getValidationErrors';
import Button from '../../Button';
import Modal from '../../Modal';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import InputOutlined from '../../InputOutlined';
import { Row, Column } from '../../../styles/components';
import Loader from '../Loader';
import TicketingService from '../../../services/ticketing';
import { Container } from './styles';

interface AddPaymentCardProps {
  show: boolean;
  title: string;
  handleClose(): void;
  handleAdd(): void;
}

const AddPaymentCard: React.FC<AddPaymentCardProps> = ({
  handleClose,
  handleAdd,
  show,
  title,
}) => {
  const services = { ticketingService: new TicketingService() };
  const formRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);

  const schema = Yup.object().shape({
    titular_name: Yup.string().required('Nome é obrigatório'),
    card_number: Yup.string()
      .test('credit-card-validation', 'Cartão de crédito invalido', value => {
        const card = luhn.validate(value);
        return card;
      })
      .required('Cartão de crédito é obrigatório'),
    due_date: Yup.string().required('Data de validade é obrigatório'),
    cvv: Yup.number(),
    charge_address: Yup.string().required('Endereço é obrigatório'),
    charge_address_complement: Yup.string(),
    charge_address_number: Yup.string().required('Número é obrigatório'),
    charge_address_neighborhood: Yup.string().required('Bairro é obrigatório'),
    charge_address_city: Yup.string().required('Cidade é obrigatório'),
    charge_address_state: Yup.string().required('Estado é obrigatório'),
    charge_address_cep: Yup.string().required('CEP é obrigatório'),
  });

  const handleSubmit = useCallback(
    async data => {
      try {
        const response = await schema.validate(data, {
          abortEarly: false,
        });
        setLoading(true);
        await services.ticketingService.createPaymentForm(
          user.id,
          data.titular_name,
          data.card_number,
          data.due_date,
          data.charge_address,
          data.charge_address_complement,
          data.charge_address_number,
          data.charge_address_neighborhood,
          data.charge_address_city,
          data.charge_address_state,
          data.charge_address_cep,
        );

        addToast({
          type: 'success',
          title: 'Sucesso ao cadastrar',
          description: 'Forma de pagamento cadastrada com sucesso!!',
        });

        handleAdd();
        setLoading(false);
        handleClose();
      } catch (err: any) {
        setLoading(false);
        const { ...error } = err;
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        if (error.response.data.due_date) {
          addToast({
            type: 'error',
            title: 'Data inválida',
            description: `${error.response.data.due_date.due_date}`,
          });
        } else {
          addToast({
            type: 'error',
            title: 'Erro ao cadastrar',
            description: `Por favor revise seus dados ou contate o administrador do site`,
          });
        }
      }
    },
    [
      addToast,
      handleAdd,
      handleClose,
      schema,
      services.ticketingService,
      user.id,
    ],
  );

  return (
    <Container>
      <Modal show={show} title={title} handleClose={handleClose}>
        {loading && <Loader />}
        <Form ref={formRef} onSubmit={handleSubmit}>
          <span className="formTitle">Cartão de Crédito</span>
          <Row>
            <Column small="12" medium="7" large="7">
              <InputOutlined
                card_number
                type="text"
                name="card_number"
                schema={schema}
                placeholder="Nº do Cartão"
              />
            </Column>
            <Column small="12" medium="5" large="5">
              <InputOutlined
                dateMMYY
                name="due_date"
                placeholder="MM/YY"
                schema={schema}
              />
            </Column>
          </Row>
          <Row>
            <Column small="12" medium="12" large="12">
              <InputOutlined
                creditCardName
                type="text"
                name="titular_name"
                placeholder="Nome do Titular"
                schema={schema}
              />
            </Column>
          </Row>
          <span className="formTitle">Endereço de Cobrança</span>
          <Row>
            <Column small="12" medium="4" large="4">
              <div className="cepContainer">
                <InputOutlined
                  cep
                  type="text"
                  placeholder="CEP"
                  schema={schema}
                  name="charge_address_cep"
                />
                <a
                  className="cep"
                  href="https://buscacepinter.correios.com.br/app/endereco/index.php?t"
                  target="_blank"
                  rel="noreferrer"
                >
                  Não lembro meu CEP
                </a>
              </div>
            </Column>
          </Row>
          <Row>
            <Column small="12" medium="12" large="12">
              <InputOutlined
                type="text"
                schema={schema}
                name="charge_address"
                placeholder="Endereço"
              />
            </Column>
          </Row>
          <Row>
            <Column small="12" medium="2" large="2">
              <InputOutlined
                type="text"
                schema={schema}
                name="charge_address_number"
                placeholder="Nº"
              />
            </Column>
            <Column small="12" medium="5" large="5">
              <InputOutlined
                type="text"
                schema={schema}
                name="charge_address_complement"
                placeholder="Complemento"
              />
            </Column>
            <Column small="12" medium="5" large="5">
              <InputOutlined
                type="text"
                schema={schema}
                name="charge_address_neighborhood"
                placeholder="Bairro"
              />
            </Column>
            <Column small="12" medium="7" large="7">
              <InputOutlined
                schema={schema}
                type="text"
                name="charge_address_state"
                placeholder="Cidade"
              />
            </Column>
            <Column small="12" medium="5" large="5">
              <InputOutlined
                schema={schema}
                type="text"
                name="charge_address_city"
                placeholder="Estado"
              />
            </Column>
          </Row>

          <div className="buttonsContainer">
            <Button color="green" padding="1" type="submit">
              Adicionar
            </Button>
          </div>
        </Form>
      </Modal>
    </Container>
  );
};

export default AddPaymentCard;
