/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';

import {
  Main,
  Sliders,
  SliderItem,
  SliderDesktop,
  SliderMobile,
} from './styles';
import CoreService from '../../../services/core';
import { Container, Content } from '../../../styles/components';
import SlideStayInformed from '../SlideStayInformed';

interface Medias {
  media_id: number;
  media_url: string;
  permalink: string;
}

const StayInInformedSection: React.FC = () => {
  const [medias, setMedias] = useState<Medias[]>([]);
  const [mobileMedias, setMobileMedias] = useState<Medias[]>([]);
  const services = { coreService: new CoreService() };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
  };

  useEffect(() => {
    async function getMedias() {
      try {
        const response = await services.coreService.getMedias();
        setMedias(response.data.results.slice(0, 24));
        setMobileMedias(response.data.results.slice(0, 5));
      } catch (err) {
        console.error(err);
      }
    }
    getMedias();
  }, []);

  const handleLink = (link: string) => {
    window.open(link, '_blank');
  };

  return (
    <Container>
      <Content>
        <Main>
          <h1>Fique por dentro!</h1>
          <span>
            Acompanhe nossas Redes Sociais e fique por dentro de todas as
            novidades e informações do transporte coletivo de BH!
          </span>
        </Main>
        <SliderDesktop>
          <SlideStayInformed items={medias} />
        </SliderDesktop>
        <SliderMobile>
          <Sliders>
            <Slider {...settings}>
              {mobileMedias.map(media => (
                <>
                  <div key={media.media_id}>
                    <SliderItem onClick={() => handleLink(media.permalink)}>
                      <div className="teste">
                        <img
                          src={media.media_url}
                          alt="instagram"
                          className="imgMobile"
                        />
                      </div>
                    </SliderItem>
                  </div>
                </>
              ))}
            </Slider>
          </Sliders>
        </SliderMobile>
      </Content>
    </Container>
  );
};

export default StayInInformedSection;
