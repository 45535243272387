import styled from 'styled-components';

interface CardProps {
  active: boolean;
}

export const Container = styled.div`
  .cards-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    &:before,
    &after {
      content: '';
      display: table;
    }
    &:after {
      clear: both;
    }
    margin-top: 60px;
    @media (max-width: 1024px) {
      flex-wrap: wrap;
      width: 50%;
    }
    @media (max-width: 650px) {
      flex-wrap: wrap;
      display: grid;
      width: 100%;
      max-width: 300px;
      justify-content: center;
    }
  }
`;

export const AddCard = styled.div`
  background: #f2f5f7;
  height: 11rem;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  margin-right: 1em;
  margin-bottom: 1em;
  width: 29%;
  cursor: pointer;
  transition: all 0.3s ease;
  max-width: 300px;

  &:hover {
    background: #e6e6e6;
  }
  @media (max-width: 650px) {
    width: 250px;
    max-width: 300px;
  }

  span {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    text-align: center;
    letter-spacing: 0.18px;
    text-transform: uppercase;

    color: #a1aeb7;
  }

  img {
    margin-bottom: 15px;
  }
`;

export const TransfacilCard = styled.button<CardProps>`
  background: var(--purple);
  border-radius: 20px;
  min-width: 200px;
  padding: 5px 10px 5px 10px;
  margin-right: 25px;
  margin-top: -18px;
  margin-left: 15px;
  color: var(--text);
  align-items: center;
  @media (max-width: 940px) {
    margin-bottom: 1rem;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
  @media (max-width: 315px) {
    font-size: 0.9rem;
  }

  p {
    font-size: 15px;
  }

  display: flex;
  flex-direction: column;

  background: ${props => (props.active ? `#F2F5F7` : `var(--orange)`)};
  color: ${props => (props.active ? `var(--purple)` : `#F2F5F7`)};
  font-weight: ${props => (props.active ? `bolder` : ``)};

  svg {
    margin-bottom: 10px;

    path {
      fill: ${props => (props.active ? `var(--purple)` : `var(--white)`)};
    }
  }
`;
