/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-shadow */
import React, { useCallback, useRef, useState, useEffect } from 'react';
import Switch from 'react-switch';
import { useHistory, useParams } from 'react-router-dom';

import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import luhn from 'luhn';
import TicketingService, { Payment as Pay } from '../../../services/ticketing';
import Loader from '../../Loader';

import getValidationErrors from '../../../utils/getValidationErrors';

import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';

import Button from '../../Button';
import InputOutlined from '../../InputOutlined';

import {
  CardContainer,
  Content,
  CEPContainer,
  AddressContainer,
  LocationContainer,
  SearchCEPLink,
  Row,
} from './styles';

interface FormPaymentProps {
  refreshList?: any;
}

const schema = Yup.object().shape({
  titular_name: Yup.string().required('Nome é obrigatório'),
  card_number: Yup.string()
    .test('credit-card-validation', 'Cartão de crédito invalido', value => {
      const card = luhn.validate(value);
      return card;
    })
    .required('Cartão de crédito é obrigatório'),
  due_date: Yup.string().required('Data de validade é obrigatório'),
  charge_address: Yup.string().required('Endereço é obrigatório'),
  charge_address_complement: Yup.string(),
  charge_address_number: Yup.string().required('Número é obrigatório'),
  charge_address_neighborhood: Yup.string().required('Bairro é obrigatório'),
  charge_address_city: Yup.string().required('Cidade é obrigatório'),
  charge_address_state: Yup.string().required('Estado é obrigatório'),
  charge_address_cep: Yup.string().required('CEP é obrigatório'),
});

const FormPayment: React.FC<FormPaymentProps> = ({ refreshList }) => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const { push } = useHistory();
  const history = useHistory();
  const { paymentId } = useParams<any>();
  const [payments, setPayments] = useState<Pay[]>([]);
  const services = { ticketingService: new TicketingService() };
  const formRef = useRef<FormHandles>(null);
  const [checked, setChecked] = useState<boolean>();
  const [FormaPagamento] = useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [disableButton, setDisableButton] = useState<boolean>(false);

  async function removePayment() {
    try {
      setLoading(true);
      const response = await services.ticketingService.deletePaymentForm(
        paymentId,
      );
      await refreshList();

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: `Forma de pagamento removida com sucesso!!`,
      });

      history.goBack();

      setLoading(false);
    } catch (err) {
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Erro interno do servidor',
        description: `Tente novamente mais tarde`,
      });
    }
  }
  async function getCards() {
    try {
      const response = await services.ticketingService.getPaymentForm();
      setPayments(response.data.results);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }
  useEffect(() => {
    getCards();
  }, []);

  const handleChange = (isChecked: any) => {
    setChecked(isChecked);
  };

  const handleRedirect = useCallback(
    pathLink => {
      push(pathLink);
    },
    [push],
  );

  const handleSubmit = useCallback(
    async data => {
      try {
        setDisableButton(true);
        formRef.current?.setErrors({});
        if (paymentId) {
          try {
            setLoading(true);
            const response = await services.ticketingService.changeCardStatus(
              paymentId,
              checked,
            );
            addToast({
              type: 'success',
              title: 'Sucesso ao editar',
              description: 'Forma de pagamento editada com sucesso!!',
            });
            history.goBack();
            setLoading(false);
          } catch (err: any) {
            addToast({
              type: 'error',
              title: `Ocorreu um erro na Recarga`,
              description: `${err.message}`,
            });
            setLoading(false);
          }
        } else {
          await schema.validate(data, {
            abortEarly: false,
          });
          // const splitedDeadLine = data.due_date.split('-');
          await services.ticketingService.createPaymentForm(
            user.id,
            data.titular_name,
            data.card_number,
            data.due_date,
            data.charge_address,
            data.charge_address_complement,
            data.charge_address_number,
            data.charge_address_neighborhood,
            data.charge_address_city,
            data.charge_address_state,
            data.charge_address_cep,
          );
          await refreshList();
          history.goBack();

          addToast({
            type: 'success',
            title: 'Sucesso ao cadastrar',
            description: 'Forma de pagamento cadastrada com sucesso!!',
          });
          formRef.current.reset();

          setTimeout(() => {
            return handleRedirect('/cartoes-credito/');
          }, 600);
        }
      } catch (err: any) {
        const { ...error } = err;
        setDisableButton(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          addToast({
            type: 'error',
            title: 'Erro ao cadastrar',
            description: `Por favor revise seus dados ou contate o administrador do site`,
          });
          return;
        }

        if (error.response.data.due_date) {
          addToast({
            type: 'error',
            title: 'Data inválida',
            description: `${error.response.data.due_date.due_date}`,
          });
        }
      } finally {
        setDisableButton(false);
      }
    },
    [addToast, checked],
  );

  return (
    <>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <Content>
            <Row>
              <div className="start">
                <span>Cartão de Crédito</span>
              </div>
              <div>
                {paymentId && (
                  <Button
                    color="red"
                    padding="2"
                    type="button"
                    rounded
                    className="removeButton"
                    onClick={removePayment}
                  >
                    Remover
                  </Button>
                )}
              </div>
            </Row>
            <Form ref={formRef} onSubmit={handleSubmit}>
              {paymentId ? (
                <>
                  <CardContainer>
                    <InputOutlined
                      type="text"
                      name="card_number"
                      placeholder="Nº do Cartão"
                      readOnly
                      value={
                        FormaPagamento[0] ? FormaPagamento[0].verbose_name : ''
                      }
                    />
                    <InputOutlined
                      dateMMYY
                      placeholder="MM/YY"
                      name="due_date"
                      readOnly
                      value={
                        FormaPagamento[0] ? FormaPagamento[0].due_date : ''
                      }
                    />
                    <InputOutlined
                      type="text"
                      name="cvv"
                      placeholder="CVV"
                      readOnly
                      value="..."
                    />
                  </CardContainer>
                </>
              ) : (
                <>
                  <CardContainer className="add">
                    <InputOutlined
                      card_number
                      schema={schema}
                      type="text"
                      name="card_number"
                      placeholder="Nº do Cartão"
                    />
                    <InputOutlined
                      dateMMYY
                      schema={schema}
                      name="due_date"
                      placeholder="MM/YY"
                    />
                  </CardContainer>
                </>
              )}

              {paymentId ? (
                <>
                  <InputOutlined
                    creditCardName
                    type="text"
                    name="titular_name"
                    placeholder="Nome do Titular"
                    readOnly
                    value={
                      FormaPagamento[0] ? FormaPagamento[0].titular_name : ''
                    }
                  />
                </>
              ) : (
                <>
                  <InputOutlined
                    creditCardName
                    schema={schema}
                    type="text"
                    name="titular_name"
                    placeholder="Nome do Titular"
                  />
                </>
              )}
              <br />
              <span>Endereço de Cobrança</span>
              <CEPContainer>
                {paymentId ? (
                  <>
                    <InputOutlined
                      type="text"
                      placeholder="CEP"
                      name="charge_address_cep"
                      readOnly
                      value={
                        FormaPagamento[0]
                          ? FormaPagamento[0].charge_address_cep
                          : ''
                      }
                    />
                  </>
                ) : (
                  <>
                    <InputOutlined
                      schema={schema}
                      cep
                      type="text"
                      placeholder="CEP"
                      name="charge_address_cep"
                    />
                  </>
                )}

                <SearchCEPLink
                  href="https://buscacepinter.correios.com.br/app/endereco/index.php?t"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h4 className="cep-remember">Não lembro meu CEP</h4>
                </SearchCEPLink>
              </CEPContainer>
              {paymentId ? (
                <>
                  <InputOutlined
                    type="text"
                    name="charge_address"
                    placeholder="Endereço"
                    readOnly
                    value={
                      FormaPagamento[0] ? FormaPagamento[0].charge_address : ''
                    }
                  />
                </>
              ) : (
                <>
                  <InputOutlined
                    schema={schema}
                    type="text"
                    name="charge_address"
                    placeholder="Endereço"
                  />
                </>
              )}

              <AddressContainer>
                {paymentId ? (
                  <>
                    <InputOutlined
                      type="text"
                      name="charge_address_number"
                      placeholder="Nº"
                      readOnly
                      value={
                        FormaPagamento[0]
                          ? FormaPagamento[0].charge_address_number
                          : ''
                      }
                    />
                  </>
                ) : (
                  <>
                    <InputOutlined
                      schema={schema}
                      type="text"
                      name="charge_address_number"
                      placeholder="Nº"
                    />
                  </>
                )}

                {paymentId ? (
                  <>
                    <InputOutlined
                      type="text"
                      name="charge_address_complement"
                      placeholder="Complemento"
                      readOnly
                      value={
                        FormaPagamento[0]
                          ? FormaPagamento[0].charge_address_complement
                          : ''
                      }
                    />
                  </>
                ) : (
                  <>
                    <InputOutlined
                      schema={schema}
                      type="text"
                      name="charge_address_complement"
                      placeholder="Complemento"
                    />
                  </>
                )}

                {paymentId ? (
                  <>
                    <InputOutlined
                      type="text"
                      name="charge_address_neighborhood"
                      placeholder="Bairro"
                      readOnly
                      value={
                        FormaPagamento[0]
                          ? FormaPagamento[0].charge_address_neighborhood
                          : ''
                      }
                    />
                  </>
                ) : (
                  <>
                    <InputOutlined
                      schema={schema}
                      type="text"
                      name="charge_address_neighborhood"
                      placeholder="Bairro"
                    />
                  </>
                )}
              </AddressContainer>
              <LocationContainer>
                {paymentId ? (
                  <>
                    <InputOutlined
                      type="text"
                      name="charge_address_state"
                      placeholder="Estado"
                      readOnly
                      value={
                        FormaPagamento[0]
                          ? FormaPagamento[0].charge_address_state
                          : ''
                      }
                    />
                  </>
                ) : (
                  <>
                    <InputOutlined
                      schema={schema}
                      type="text"
                      name="charge_address_state"
                      placeholder="Estado"
                    />
                  </>
                )}

                {paymentId ? (
                  <>
                    <InputOutlined
                      type="text"
                      name="charge_address_city"
                      placeholder="Cidade"
                      readOnly
                      value={
                        FormaPagamento[0]
                          ? FormaPagamento[0].charge_address_city
                          : ''
                      }
                    />
                  </>
                ) : (
                  <>
                    <InputOutlined
                      schema={schema}
                      type="text"
                      name="charge_address_city"
                      placeholder="Cidade"
                    />
                  </>
                )}
              </LocationContainer>

              <div className="statusCard">
                <Switch onChange={handleChange} checked={checked} />
                <span className="status-cartao">Cartão ativo</span>
              </div>

              <Button
                color="orange"
                padding="3.5"
                rounded
                className="button-confirmar"
                type="submit"
                disabled={disableButton}
              >
                CONFIRMAR
              </Button>
              <Button
                onClick={() => handleRedirect('/cartoes-credito')}
                type="button"
                color=""
                padding="3.6"
                rounded
                className="button-cancelar"
              >
                CANCELAR
              </Button>
            </Form>
          </Content>
        </>
      )}
    </>
  );
};

export default FormPayment;
