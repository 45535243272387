import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 15px;
  border-radius: 20px 20px;
  max-width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling if needed */
  display: flex;
  justify-content: center;

  .rdt_Table {
    width: 100%;
    max-width: 100%;
    max-width: 900px;
  }

  .rdt_TableHead,
  .rdt_TableRow {
    display: flex;
    width: 100%;
  }

  .rdt_TableCol {
    flex: 1;
    padding: 0 5px;
    background-color: var(--aside-background);
    font-size: 0.875rem;
    text-align: left;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.43;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    color: var(--purple);
    font-weight: bold;
  }

  .rdt_TableRow {
    font-size: 16px;
    @media only screen and (max-width: 800px) {
      font-size: 14px;
    }
    @media only screen and (max-width: 600px) {
      font-size: 12px;
    }
  }

  .rdt_TableCell > div {
    white-space: inherit;
  }
  .rdt_TableCell #cell-3-undefined {
    white-space: nowrap; /* Prevent wrapping */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Show ellipsis (...) for overflowed text */
  }

  .emptyText {
    padding: 16px 0;
  }
`;
