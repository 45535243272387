import styled from 'styled-components';

export const TitleSection = styled.section`
  height: 220px;
  width: 98%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--white);

  h1 {
    font-size: 2.9rem;
    line-height: 4rem;
    @media (max-width: 768px) {
      margin-top: 2rem;
      text-align: center;
    }
  }

  span {
    font-size: 1rem;
    line-height: 2.5rem;
    font-weight: normal;
    @media (max-width: 768px) {
      text-align: center;
    }
  }
`;

export const GridSection = styled.section`
  display: grid;
  grid-template-columns: 2fr;
  grid-template-rows: 2fr;
  padding-top: 3rem;
  padding-bottom: 5rem;
  max-width: 1140px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  max-width: 1140px;

  .imgContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    height: 258px;
    border: 1px solid #c2d1d9;
    @media (max-width: 516px) {
      border: none;
      height: auto;
      width: 100%;
    }

    img {
      cursor: pointer;
      display: flex;
      padding: 5px 5px 0 5px;
      width: 180px;
      height: auto;
      @media (max-width: 516px) {
        width: 180px;
        text-align: center;
      }
    }
    @media (max-width: 516px) {
      width: 100%;
    }
    @media (max-width: 405px) {
      width: 100%;
    }
  }
  .textContainer {
    padding: 36px 15px;
    border: 1px solid #c2d1d9;
    width: 100%;
    height: 258px;
    @media (max-width: 516px) {
      border: none;
      text-align: center;
    }

    h1 {
      cursor: pointer;
      font-family: Roboto Slab;
      font-style: normal;
      font-weight: bold;
      font-size: 1.3rem;
      text-align: left;
      line-height: 2rem;
      color: #482a73;
      margin-top: 1rem;
      margin-bottom: 40px;
      @media (max-width: 516px) {
        margin-top: 0rem;
        line-height: 1.8rem;
        margin-bottom: 20px;
        text-align: center;
      }
    }
    @media (max-width: 516px) {
      width: 100%;
      margin-top: 0rem;
    }
    @media (max-width: 405px) {
      width: 100%;
      margin-top: 0rem;
    }
  }

  .button {
    @media (max-width: 360px) {
      font-size: 0.9rem;
    }
  }
  .partner {
    display: grid;
    align-items: left;
    width: 100%;
    span {
      display: flex;
      align-items: center;
      padding: 2px 5px;
      margin-right: 3px;
    }
  }
`;

export const SliderItem = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 516px) {
    display: grid;
    text-align: center;
  }
`;
