/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/require-default-props */
import { useEffect, useState, useRef, InputHTMLAttributes } from 'react';

import { useField } from '@unform/core';

import {
  CheckboxContainer,
  HiddenCheckbox,
  Text,
  Error,
  ReactTooltipStyled,
} from './styles';

/**
 * This is a Radio component that supports rendering multiple options.
 *
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/radio
 */
interface Props {
  name: string;
  label?: string;
  onChange?: (e: any) => void;
  limit?: number;
  options: {
    id: string;
    value: string;
    label: string;
    tooltip?: string;
    onmouseover?: void;
    onmouseout?: void;
  }[];
}

type InputProps = InputHTMLAttributes<HTMLInputElement> & Props;

export function CheckBoxSpecial({
  name,
  options,
  onChange,
  limit = 0,
  ...rest
}: InputProps) {
  const inputRef = useRef([]);
  const { fieldName, registerField, defaultValue = '', error } = useField(name);
  const [values] = useState(new Map());

  useEffect(() => {
    registerField({
      name: fieldName,
      // path: "value",
      ref: inputRef.current,
      getValue: refs => {
        const data = refs.filter((ref: any) => {
          if (ref?.checked) {
            return true;
          }
          return false;
        });
        return data.flatMap((ref: any) => ref?.value).toString();
      },
      setValue: (refs, value: any) => {
        const data = value.toString().split(',') || [];
        console.log(value);
        data.flatMap((element: any) => {
          const item = refs.find((ref: any) => ref?.value === element);
          if (item) {
            item.checked = true;
          } else {
            item.checked = false;
          }
        });
      },
      clearValue: refs => {
        refs.flatMap((ref: any) => {
          ref.checked = false;
        });
      },
    });
  }, [fieldName, registerField]);

  return (
    <div
      style={{
        display: 'flex',
        height: '40px',
        minWidth: '50px',
      }}
    >
      {options.map((option, index) => (
        <>
          <CheckboxContainer key={option.id} data-tip={option.tooltip}>
            <HiddenCheckbox
              type="checkbox"
              ref={ref => {
                inputRef.current[index] = ref;
              }}
              id={option.id}
              name={name}
              defaultChecked={defaultValue === option.value}
              value={option.value}
              {...rest}
              onChange={(e: any) => {
                console.log({ e });
                const item = values.get(option.label);
                if (!item) {
                  const isBlocked = limit >= 0 ? values.size > limit : false;
                  if (!isBlocked) {
                    inputRef.current[index].checked = true;
                    values.set(option.label, option.value);
                  } else {
                    inputRef.current[index].checked = false;
                    values.delete(option.label);
                  }
                } else {
                  inputRef.current[index].checked = false;
                  values.delete(option.label);
                }
                onChange && onChange(option);
              }}
            />
            <Text style={{ fontWeight: 'bold' }}>{option.label} </Text>
          </CheckboxContainer>
          <ReactTooltipStyled />
        </>
      ))}

      {!!error && <Error>{error}</Error>}
    </div>
  );
}
