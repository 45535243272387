import styled, { css } from 'styled-components';

interface buttonProps {
  color: string;
  padding: string;
  rounded?: boolean;
  textBlack?: boolean;
  disabled?: boolean;
}

const buttonRoundVariations = {
  rounded: css`
    border-radius: 18px;
  `,
  block: css`
    border-radius: 6px;
  `,
};

export const Container = styled.button<buttonProps>`
  ${props =>
    props.textBlack ? `color: var(--black);` : `color: var(--white);`}
  font-family: 'Source Sans Pro' sans-serif;
  font-size: 1rem;
  font-weight: 400;
  height: 40px;
  padding: 0 3rem;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  padding: ${props => `0 ${props.padding}rem`};
  background: ${props => `var(--${props.color})`};
  @media (max-width: 800px) {
    margin-top: 10px;
  }

  ${props =>
    props.rounded ? buttonRoundVariations.rounded : buttonRoundVariations.block}

  border: 0;
  transition: background 0.2s;
`;
