import styled from 'styled-components';
import PlayButton from '../../assets/QuemSomos/playbuttonorange.svg';

interface SliderItemProps {
  img: string;
}

export const TitleSection = styled.section`
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--white);

  @media only screen and (max-width: 768px) {
    height: 250px;
  }

  h1 {
    font-size: 3rem;
    line-height: 2.3rem;
    @media (max-width: 768px) {
      flex-wrap: wrap;
      text-align: center;
      line-height: 3rem;
      margin-left: 0rem;
    }
  }

  span {
    font-size: 1rem;
    line-height: 3.5rem;
    font-weight: normal;
    @media (max-width: 768px) {
      flex-wrap: wrap;
      text-align: center;
      line-height: 1.5rem;
      margin-left: 0rem;
      margin-top: 10px;
    }
  }
`;

export const VideoSection = styled.section`
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  padding-top: 25px;
  max-width: 1140px;
  height: 430px;

  .video-container {
    margin-top: 20px;
    width: 50%;
    border-radius: 10px;
    display: flex;
    align-items: center;

    & > div {
      width: 100% !important;
      height: 310px !important;
      border-radius: 10px;
      @media (max-width: 768px) {
        width: 100% !important;
      }
    }

    .html5-video-player {
      border-radius: 10px !important;
    }
    @media (max-width: 768px) {
      width: 95%;
      flex-wrap: wrap;
      margin-left: 0rem;
      display: block;
      position: absolute;
      max-width: 1140px;
      top: 640px;
    }
    @media (max-width: 640px) {
      width: 94%;
    }
    @media (max-width: 437px) {
      width: 93%;
    }
    @media (max-width: 600px) {
      top: 720px;
    }
    @media (max-width: 399px) {
      top: 800px;
    }
    @media (max-width: 320px) {
      top: 810px;
      width: 91%;
    }
  }

  .empresa-container {
    width: 50%;

    display: grid;
    flex-direction: column;
    text-align: justify;
    padding: 0 0 0 5%;

    .icon {
      text-align: center;
    }

    h1 {
      font-family: Roboto Slab;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 37px;
      text-align: center;
      margin-bottom: 5px;
      margin-top: -10px;
      color: #531f76;
    }

    p {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 15px;

      color: #a1aeb7;
    }
    @media (max-width: 768px) {
      width: 100%;
      flex-wrap: wrap;
      margin-left: 0rem;
      display: block;
      padding: 0;
      margin-top: 10px;
      margin-bottom: 25rem;
    }
    @media (max-width: 600px) {
      width: 100%;
      flex-wrap: wrap;
      margin-left: 0rem;
      display: block;
      padding: 0;
      margin-top: 10px;
      margin-bottom: 29rem;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    max-width: 1140px;
    height: 80%;
    display: block;
    margin-left: 0rem;
  }
`;

export const MissionSection = styled.section`
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  padding-top: 100px;
  height: 790px;

  .text-container {
    width: 35%;
    padding-right: 90px;
    @media (max-width: 768px) {
      width: 100%;
      padding-right: 0px;
    }
    h3 {
      font-family: Roboto Slab;
      font-style: normal;
      font-weight: bold;
      font-size: 1.7rem;
      line-height: 2rem;
      color: #505d68;
      margin-bottom: 50px;
      margin-top: 3px;
      @media (max-width: 768px) {
        margin-top: 3rem;
        line-height: 1rem;
        margin-bottom: 1.5rem;
      }
    }

    h1 {
      font-family: Roboto Slab;
      font-style: normal;
      font-weight: bold;
      font-size: 2.8rem;
      line-height: 3.6rem;

      color: #531f76;
      margin-bottom: 40px;
      @media (max-width: 768px) {
        margin-top: 2rem;
        font-size: 2.3rem;
        margin-bottom: 1rem;
      }
    }

    small {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.4rem;

      color: #a1aeb7;
    }
    @media (max-width: 768px) {
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 4rem;
      margin-top: -7rem;
      text-align: center;
    }
  }

  .mission-container {
    h3 {
      font-family: Roboto Slab;
      font-style: normal;
      font-weight: bold;
      font-size: 1.7rem;
      line-height: 2rem;
      color: #505d68;
      margin-bottom: 50px;
      @media (max-width: 768px) {
        text-align: center;
        line-height: 0rem;
        margin-bottom: 30px;
      }
    }

    width: 65%;
    @media (max-width: 768px) {
      width: 100%;
    }

    .mission-card {
      width: 100%;
      height: 260px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 1rem;

      @media (max-width: 768px) {
        width: 100%;
        margin-top: 0rem;
        margin-bottom: 0rem;
      }

      @media (max-width: 400px) {
        margin-top: 0rem;
        margin-bottom: 0rem;
      }

      img {
        width: 70px;
      }

      .imgvalor {
        width: 75px;
      }

      h1 {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 1.1rem;
        margin-top: 1.4rem;
        margin-bottom: 0.8rem;
        line-height: 1.3rem;
        /* identical to box height, or 306% */

        text-align: center;
        letter-spacing: 0.8px;
        text-transform: uppercase;

        color: #472b74;
        @media (max-width: 1140px) {
          flex-wrap: wrap;
          line-height: 1.3rem;
        }
      }

      span {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        margin-bottom: 1.2rem;

        text-align: center;
        color: var(--purple);
      }

      small {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 20px;
        text-align: center;

        color: #a1aeb7;
      }
    }
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
    width: 100%;
    margin-left: 0rem;
    margin-bottom: 0rem;
  }
`;

export const TransfacilTextSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;

  h1 {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 37px;
    /* identical to box height */

    text-align: center;

    color: #472b74;
    @media (max-width: 400px) {
      margin-top: 15rem;
    }
    @media (max-width: 307px) {
      margin-top: 30rem;
    }
  }
  span {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    color: #a1aeb7;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
    width: 100%;
    margin-top: 40rem;
  }
  @media (max-width: 600px) {
    flex-wrap: wrap;
    width: 100%;
    margin-top: 47rem;
  }
  @media (max-width: 401px) {
    flex-wrap: wrap;
    width: 100%;
    margin-top: 37rem;
  }
  @media (max-width: 307px) {
    flex-wrap: wrap;
    width: 100%;
    margin-top: 23rem;
  }
`;

export const NewsSection = styled.section`
  height: 504px;
  display: flex;

  .image-container {
    width: 55%;

    img {
      width: 100%;
    }
  }
  .text-container {
    width: 45%;
    margin-left: 5%;

    h3 {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;

      color: #505d68;
      margin-bottom: 33px;
    }

    h1 {
      font-family: Roboto Slab;
      font-style: normal;
      font-weight: bold;
      font-size: 45px;
      line-height: 59px;

      color: #531f76;
      margin-bottom: 33px;
    }

    h2 {
      font-family: Roboto Slab;
      margin-top: 3.5rem;
      font-style: normal;
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 3.6rem;

      color: #531f76;
      margin-bottom: 2.5rem;
    }
    p {
      margin-top: 1rem;
    }

    span {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
    }

    & > span {
      margin-bottom: 15px;
    }
  }
  @media (max-width: 1260px) {
    flex-wrap: wrap;
    width: 50%;
  }
`;

export const MetricSection = styled.section`
  display: flex;
  max-width: 1140px;
  .metrics {
    display: flex;
    justify-content: space-between;
    width: 102%;
    @media (max-width: 900px) {
      flex-wrap: wrap;
      width: 80%;
      justify-content: space-between;
      margin-left: 9rem;
    }
    @media (max-width: 768px) {
      flex-wrap: wrap;
      width: 90%;
      justify-content: space-between;
      margin-left: 4rem;
    }
    @media (max-width: 703px) {
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      margin-left: 6rem;
    }
    @media (max-width: 688px) {
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      margin-left: 0rem;
    }
    @media (max-width: 520px) {
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      margin-left: 0rem;
    }
    @media (max-width: 492px) {
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      margin-left: 0rem;
    }
    @media (max-width: 416px) {
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      margin-left: 0rem;
    }
    @media (max-width: 358px) {
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      margin-left: 0rem;
    }
    @media (max-width: 290px) {
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      margin-left: 0rem;
    }

    .metricCard {
      display: flex;
      justify-content: left;
      align-items: center;
      width: 280px;
      height: 105px;
      @media (max-width: 768px) {
        display: grid;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;
      }
      @media (max-width: 688px) {
        width: 230px;
      }
      @media (max-width: 520px) {
        width: 190px;
        margin-right: 2rem;
      }
      @media (max-width: 492px) {
        width: 150px;
        margin-right: 1rem;
      }
      @media (max-width: 358px) {
        width: 120px;
        margin-right: 0rem;
      }
      @media (max-width: 290px) {
        width: 200px;
      }

      img {
        margin-right: 1.5rem;
        display: flex;
        @media (max-width: 768px) {
          margin-right: 0rem;
        }
      }

      .image {
        @media (max-width: 768px) {
          display: grid;
          text-align: center;
          align-items: center;
          justify-content: center;
        }
      }

      .shop {
        width: 45px;
        margin-top: 5px;
      }

      .bus {
        width: 45px;
      }

      .textContainer {
        display: flex;
        flex-direction: column;

        span {
          font-family: Roboto Slab;
          font-style: normal;
          font-weight: bold;
          font-size: 2rem;
          line-height: 3rem;

          color: #505d68;
        }

        p {
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: normal;
          font-size: 1rem;
          line-height: 1.4rem;

          color: #a1aeb7;
        }
      }
    }
    .metricCardLinhas {
      display: flex;
      justify-content: left;
      align-items: center;
      width: 300px;
      height: 105px;
      margin-left: 1rem;
      @media (max-width: 900px) {
        margin-left: 0;
      }
      @media (max-width: 768px) {
        display: grid;
        width: 280px;
        text-align: center;
        justify-content: center;
        margin-bottom: 2rem;
      }
      @media (max-width: 688px) {
        width: 240px;
        display: grid;
        text-align: center;
      }
      @media (max-width: 520px) {
        width: 230px;
      }
      @media (max-width: 492px) {
        width: 150px;
      }
      @media (max-width: 358px) {
        width: 120px;
      }
      @media (max-width: 290px) {
        width: 200px;
      }

      .image {
        @media (max-width: 768px) {
          display: grid;
          text-align: center;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
      }

      .textContainer {
        display: flex;
        flex-direction: column;

        span {
          font-family: Roboto Slab;
          font-style: normal;
          font-weight: bold;
          font-size: 2rem;
          line-height: 3rem;

          color: #505d68;
        }

        p {
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: normal;
          font-size: 1rem;
          line-height: 1.4rem;

          color: #a1aeb7;
        }
      }
    }

    .metricCardVeiculos {
      display: flex;
      justify-content: left;
      align-items: center;
      width: 210px;
      height: 105px;
      @media (max-width: 900px) {
        margin-left: 13rem;
      }
      @media (max-width: 768px) {
        margin-left: 15.5rem;
        display: grid;
        text-align: center;
        justify-content: center;
        margin-top: 2rem;
      }

      @media (max-width: 688px) {
        width: 250px;
        display: grid;
        text-align: center;
        margin-left: 13rem;
      }

      @media (max-width: 620px) {
        width: 210px;
        margin-left: 13rem;
      }
      @media (max-width: 560px) {
        margin-left: 12rem;
      }
      @media (max-width: 520px) {
        margin-left: 10rem;
      }
      @media (max-width: 445px) {
        margin-left: 8rem;
      }
      @media (max-width: 416px) {
        margin-left: 6rem;
      }
      @media (max-width: 350px) {
        margin-left: 4.5rem;
      }
      @media (max-width: 320px) {
        margin-left: 4rem;
      }
      @media (max-width: 306px) {
        margin-left: 4.5rem;
      }
      @media (max-width: 290px) {
        margin-left: 0rem;
      }

      img {
        margin-right: 1.5rem;
        display: flex;
        @media (max-width: 768px) {
          margin-right: 0rem;
        }
      }

      .image {
        @media (max-width: 768px) {
          display: grid;
          text-align: center;
          align-items: center;
          justify-content: center;
        }
      }

      .textContainer {
        display: flex;
        flex-direction: column;

        span {
          font-family: Roboto Slab;
          font-style: normal;
          font-weight: bold;
          font-size: 2rem;
          line-height: 3rem;

          color: #505d68;
        }

        p {
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: normal;
          font-size: 1rem;
          line-height: 1.4rem;

          color: #a1aeb7;
        }
      }
    }
  }
`;

export const WorkwithUsSection = styled.section`
  margin-top: 50px;
  .text-container {
    h1 {
      font-family: Roboto Slab;
      font-style: normal;
      font-weight: bold;
      font-size: 45px;
      line-height: 59px;

      color: #41195c;
      margin-bottom: 20px;
    }

    h3 {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 24px;

      color: #505d68;
    }

    small {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 170px;

      color: #a1aeb7;
      p {
        margin-bottom: 1rem;
      }
    }
  }
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const JobsSection = styled.div`
  margin-top: 50px;
  .h1 {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 28px;
    color: #505d68;
    margin-bottom: 56px;
  }

  .vagas {
    display: grid;
  }

  .button {
    width: 361px;
    height: 40px;
    background: var(--orange);
    border-radius: 5px;
    color: var(--white);
    transition: background 0.5s ease;
    margin-top: 25px;
    margin-bottom: 50px;
    @media (max-width: 768px) {
      margin-bottom: 30px;
    }

    &:hover {
      background: #bb421f;
    }
  }

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const BackgroundSliderNews = styled.div`
  margin-bottom: 50px;
  .slick-prev:before {
    background: transparent;
    background-size: 50px 50px;
    display: inline-block;
    width: 50px;
    cursor: default;
    height: 50px;
    content: '';
  }
  .slick-next:before {
    background-image: url(${PlayButton});
    background-size: 50px 50px;
    display: inline-block;
    width: 50px;
    height: 50px;
    content: '';
  }

  @media (max-width: 1240px) {
    flex-wrap: wrap;
    width: 95%;
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
    width: 100%;
    text-align: center;
    margin-left: 1rem;
  }
  @media (max-width: 750px) {
    flex-wrap: wrap;
    width: 100%;
  }
  @media (max-width: 581px) {
    flex-wrap: wrap;
    margin-left: 0rem;
  }
  @media (max-width: 425px) {
    flex-wrap: wrap;
    width: 100%;
  }
`;

export const SliderItemNews = styled.div`
  height: 380px;
  display: flex;

  .image-container {
    width: 60%;
    @media (max-width: 768px) {
      width: 61.5%;
    }

    img {
      width: 100%;
    }
  }
  .text-container {
    width: 45%;
    margin-left: 5%;
    @media (max-width: 768px) {
      flex-wrap: wrap;
      width: 61.5%;
      margin-left: 0%;
    }

    h3 {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;

      color: #505d68;
      margin-bottom: 33px;
    }

    h1 {
      font-family: Roboto Slab;
      font-style: normal;
      font-weight: bold;
      font-size: 45px;
      line-height: 59px;

      color: #531f76;
      margin-bottom: 33px;
    }

    h2 {
      font-family: Roboto Slab;
      margin-top: 3.5rem;
      font-style: normal;
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 2rem;

      color: #531f76;
      margin-bottom: 2.5rem;
      @media (max-width: 1115px) {
        margin-top: 0rem;
      }
      @media (max-width: 943px) {
        margin-top: 1rem;
      }
    }
    p {
      margin-top: 1rem;
    }

    span {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
    }

    & > span {
      margin-bottom: 15px;
    }
  }
  @media (max-width: 1115px) {
    height: 50%;
    margin-left: 1rem;
  }
  @media (max-width: 943px) {
    flex-wrap: wrap;
    width: 150%;
  }
`;

export const BackgroundSlider = styled.div`
  background-color: var(--purple);
  margin: 50px 0;
  .slick-prev:before {
    background-color: transparent;
    color: var(--orange);
    display: flex;
    position: absolute;
  }
  .slick-next:before {
    background-color: transparent;
    color: var(--orange);
    display: flex;
    position: absolute;
  }
  .slick-prev {
    position: absolute;
    display: block;
    height: 200px;
    width: 60px;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background: transparent;
    color: var(--orange);
    top: 50%;
    left: 20px;
    margin-top: 0px;
    padding: 0;
    border: none;
    outline: none;
    z-index: 99;
  }
  .slick-next {
    position: absolute;
    display: block;
    height: 200px;
    width: 20px;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background: transparent;
    color: var(--orange);
    top: 50%;
    right: 20px;
    margin-top: 0px;
    padding: 0;
    border: none;
    outline: none;
  }
`;

export const SliderItem = styled.div<SliderItemProps>`
  background: url(${props => props.img && props.img}) no-repeat;
  background-size: cover;
  height: 715px;
  width: 85%;
  margin: 26px 40px;
  @media (max-width: 1900px) {
    margin: 26px auto;
    width: 95%;
    height: 515px;
  }
  @media (max-width: 1700px) {
    margin: 26px auto;
    width: 95%;
    height: 415px;
  }
  @media (max-width: 1000px) {
    margin: 26px auto;
    width: 95%;
    height: 300px;
  }
  @media (max-width: 800px) {
    margin: 26px auto;
    width: 95%;
    height: 250px;
  }
  @media (max-width: 600px) {
    margin: 23px auto;
    width: 95%;
    height: 300px;
  }
  @media (max-width: 461px) {
    margin: 23px auto;
    width: 95%;
    height: 250px;
  }
  @media (max-width: 380px) {
    margin: 23px auto;
    width: 95%;
    height: 200px;
  }
`;

export const ContentPolitica = styled.div`
  margin-top: 50px;
  width: 100%;
  background: #ebf1f4;
  text-align: center;
  margin-top: 6rem;
  margin-bottom: 10rem;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.61);

  h1 {
    color: var(--orange);
    padding-top: 3rem;
    font-size: 2.5rem;
    padding-bottom: 3rem;
    @media (max-width: 426px) {
      font-size: 1.8rem;
    }
  }

  .politica {
    display: flex;
    width: 100%;
    color: #474747;
    padding: 0px 85px 40px 100px;
    text-align: left;
    @media (max-width: 768px) {
      padding: 0px 30px 20px 73px;
    }
    @media (max-width: 500px) {
      flex-direction: column;
      padding: 0px 20px 20px 30px;
    }
  }

  .bloco1 {
    width: 25%;
    color: #474747;
    .strong {
      margin-top: 1rem;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }

  .bloco2 {
    width: 70%;
    color: #474747;
    padding-left: 90px;
    @media (max-width: 500px) {
      width: 100%;
      padding-left: 0px;
      margin-top: 3rem;
    }
    img {
      padding-right: 15px;
      margin-top: 3px;
    }
  }

  .bloco3 {
    display: flex;
    color: #474747;
    margin-bottom: 20px;
  }

  .bloco4 {
    display: flex;
    color: #474747;
    width: 100%;
    padding: 0px 85px 40px 100px;
    text-align: left;
    @media (max-width: 768px) {
      padding: 0px 60px 20px 73px;
    }
    @media (max-width: 500px) {
      padding: 0px 20px 20px 30px;
    }
  }

  .bloco5 {
    width: 100%;
    color: #474747;
    padding: 0px 85px 40px 100px;
    text-align: right;
    @media (max-width: 500px) {
      padding: 0px 20px 20px 30px;
    }
  }
`;

export const PurpleContainer = styled.div`
  width: 100%;
  height: 580px;
  background: linear-gradient(166.07deg, var(--purple) 100%, #3b1753 107.69%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
  margin-bottom: 5rem;
  margin-top: 5rem;

  @media (max-width: 960px) {
    height: 650px;
  }
  @media (max-width: 450px) {
    height: 700px;
  }
  @media (max-width: 420px) {
    height: 750px;
  }
  @media (max-width: 385px) {
    height: 800px;
  }
  @media (max-width: 350px) {
    height: 830px;
  }

  .legislacao {
    max-width: 1140px;
    width: 100%;
  }
    .oval {
    display: flex;
    align-items: center;
    background-color: #d8d8d8;
    justify-content: center;
    margin-top: 5px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid #979797;
    @media (max-width: 440px) {
      display: none;
    }
  }

  .text-white {
    color: var(--white);
    @media (max-width: 440px) {
      flex-wrap;
      width: 90%;
    }
  }

  .flex {
    display: flex;
    margin-top: 1.8rem;
    margin-bottom: 2rem;
    @media (max-width: 960px) {
      margin-top: 2rem;
    }
    @media (max-width: 721px) {
      margin-top: 0rem;
    }

    div + div {
      margin-left: 1.5rem;
    }

    h1 {
      margin-right: 3rem;
      @media (max-width: 440px) {
      flex-wrap;
    }
    }
    @media (max-width: 460px) {
      flex-wrap;
      width: 100%;
      margin-left: 0.5rem;
    }
  }

  .flex-row {
    display: flex;
    justify-content: space-between;
    p {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 1.5rem;
      color: #a1aeb7;
      @media (max-width: 440px) {
      flex-wrap;
      margin-left: 0rem;
      width: 90%;
    }
    }

    div + div {
      margin-left: 100px;
      @media (max-width: 960px) {
        flex-wrap: wrap;
        width: 100%;
        margin: 0;
      }
    }
    .pdf {
      color: #ebbe54;
      margin-left: 0.5rem;
    }
    @media (max-width: 960px) {
      flex-wrap: wrap;
      width: 100%;
    }
    @media (max-width: 460px) {
      flex-wrap: wrap;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      width: 100%;
    }
    @media (max-width: 440px) {
      flex-wrap: wrap;
      padding-right: 2rem;
    }
    @media (max-width: 440px) {
      flex-wrap;
    }
  }
  .flex-column {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }

  h1 {
    margin-right: 30px;
  }

  h4 {
    margin-top: 1rem;
    color: #a1aeb7;
    @media (max-width: 440px) {
      flex-wrap;
      width: 95%;
    }
  }

  img {
    width: 35px;
    height: 35px;
    margin-left: 20px;
    margin-top: 5px;
  }
  @media (max-width: 1273px) {
    flex-wrap: wrap;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  
`;
