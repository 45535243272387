import React, { useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { Container, Content } from './styles';

const AcceptCookies: React.FC = () => {
  const [accepted, setAccepted] = useState<boolean>(true);

  const Accept = useCallback(() => {
    localStorage.setItem('cookie-consent', 'true');
    setAccepted(true);
  }, []);

  const NoAccept = useCallback(() => {
    localStorage.removeItem('cookie-consent');
    setAccepted(true);
  }, []);

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookie-consent');
    setAccepted(!!cookieConsent);
  }, []);

  if (accepted) return null;

  return (
    <Container accepted={accepted}>
      <Content>
        <p>
          O site transfácil utiliza cookies e outras tecnologias semelhantes
          para melhorar a sua experiência, de acordo com a nossa
          <Link to="/politica-cookies">{` Política de Cookies `}</Link>
          e, ao continuar navegando, você concorda com estas condições.
        </p>
        <div className="buttons">
          <button onClick={() => NoAccept()} type="button">
            NÃO CONCORDO
          </button>
          <button onClick={() => Accept()} type="button">
            CONCORDO
          </button>
        </div>
      </Content>
    </Container>
  );
};

export default AcceptCookies;
