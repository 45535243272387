/* eslint-disable prettier/prettier */
import styled, { css, keyframes } from 'styled-components';

interface ContainerProps {
  success?: boolean;
  typeModal?: 'success' | 'error';
  show: boolean;
}

const EaseTopDown = keyframes`
  from{ opacity:0;top:0; }
  to{ opacity:1;top:20%;}
`;

const EasyIn = keyframes`
  from{ opacity:0; }
  to{ opacity:1; }
`;

const ContainerTypeVariations = {
  success: css`
    /* width: 100%; */
  `,
  error: css`
    height: 7rem;
  `,
};

export const Container = styled.div<ContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: none;
  z-index: 100000000000000;
  animation: ${EasyIn} 0.3s;

  section {
    position: fixed;
    background: #ffffff;
    animation: ${EaseTopDown} 0.4s;

    border-radius: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    display: flex;
    align-items: center;
    text-align: center;

    width: 60%;
    height: auto;
    max-height: 80vh;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);

    p {
      a {
        color: #2a41c1;
      }
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    padding: 40px;
    animation: ${EasyIn} 0.5s;

    .close-container {
      position: fixed;
      top: -40px;
      right: 0;
      color: var(--title-higlight);
      background: transparent;

      display: flex;
      align-items: center;

      svg {
        margin-top: 4px;
        color: var(--white);

        cursor: pointer;
      }
    }

    p {
      color: var(--title-higlight);
      margin-top: 17px;
    }

    ${props =>
    props.success &&
    css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #5eb245;
        padding: 40px;
        animation: ${EasyIn} 0.5s;
        .close-container {
          position: fixed;
          top: -40px;
          right: 0;
          color: var(--white);
          background: transparent;

          display: flex;
          align-items: center;

          svg {
            margin-top: 4px;
            color: var(--white);

            cursor: pointer;
          }
        }

        p {
          font-size: 1.5rem;
          color: var(--white);
          margin-top: 17px;
        }
      `}

    .modal-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--dark-blue);
      height: 65px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      font: normal normal bold 26px/34px IBM Plex Sans;
      color: var(--white);

      span {
        margin-right: 13px;
      }

      .close-container {
        position: fixed;
        top: -40px;
        right: 0;
        font: normal normal bold 26px/34px IBM Plex Sans;
        color: var(--white);
        background: transparent;

        display: flex;
        align-items: center;

        svg {
          margin-top: 4px;
          color: var(--white);

          cursor: pointer;
        }
      }
    }

    .modal-body {
      padding: 15px;
      overflow: auto;
      max-height: 80vh;
      font: 400 1rem 'Source Sans Pro', sans-serif;

      ${props => ContainerTypeVariations[props.typeModal]}

      p {
        a {
          color: blue !important;
        }
      }
    }
  }

  ${props =>
    props.show &&
    css`
      display: block;
    `}
`;
