import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 500px;
  text-align: center;
  @media only screen and (max-width: 610px) {
    max-width: 400px;
  }
  @media only screen and (max-width: 475px) {
    max-width: 330px;
  }
  @media only screen and (max-width: 400px) {
    max-width: 270px;
  }

  .alert {
    color: var(--red);
  }

  img {
    width: 252px;
    height: 55px;
  }
  form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    .terms > div {
      position: relative;
      display: flex;
      flex-direction: row-reverse;
      border: 0;
      padding: 0 0 28px 0;
      width: 50px;
    }
    span {
      position: relative;
      color: #4d4a48;
      align-self: flex-start;
      text-align: left;
    }
    div {
      height: 50px;
      background: var(--white);
      border-radius: 10px;
      margin-bottom: 8px;

      display: flex;
      flex-direction: row-reverse;
    }

    .email > div {
      margin-top: 26px;
      margin-bottom: 6px;
    }
    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      margin-bottom: 26px;

      color: #4d4a48;
    }
    p {
      text-align: left;
      margin-left: 2px;
      color: #4d4a48;
      margin-top: 15px;
    }

    button {
      height: 50px;
      margin-top: 20px;

      background: var(-orange);
      border-radius: 10px;
      transition: all 0.2s;

      &:hover {
        background: #dc5129;
      }
    }
    a {
      color: #4d4a48;
      margin-top: 15px;
      font-size: 16px;
      opacity: 0.8;
      transition: all 0.2s;

      &:hover {
        opacity: 1;
      }
    }
  }
`;
