import React, { useCallback, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Button from '../../Button';
import Logo from '../../../assets/Shared/logo-v2.png';
import { useAuth } from '../../../hooks/auth';

import {
  Container,
  LogoContainer,
  HamburguerMenuContainer,
  ItensContainer,
  NavItem,
} from './styles';

const Header: React.FC = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { user, signOut } = useAuth();
  const [menuToggle, setMenuToggle] = useState(false);

  const handleToggleMenu = useCallback(() => {
    setMenuToggle(!menuToggle);
  }, [menuToggle]);

  const navItens = [
    { name: 'INÍCIO', path: '/' },
    { name: 'CARTÕES E RECARGA', path: '/cartoes-recarga' },
    { name: 'PARA EMPRESAS', path: '/para-empresas' },
    { name: 'LOJAS', path: '/lojas-locais-recarga' },
    { name: 'ROTAS E LINHAS', path: '/rotas-e-linhas' },
    { name: 'QUEM SOMOS', path: '/quem-somos' },
    { name: 'FALE CONOSCO', path: '/fale-conosco' },
    { name: 'ENTRAR', path: user ? '/dashboard' : '/login' },
    { name: user ? '' : 'CADASTRAR', path: '/cadastrar' },
  ];

  const navUser = [{ name: user ? 'SAIR' : '', path: user ? '/' : '' }];

  const handleRedirect = useCallback(
    path => {
      push(path);
    },
    [push],
  );

  return (
    <>
      <Container>
        <LogoContainer>
          <a href="https://transfacil.com.br">
            <img src={Logo} alt="Logo transfacil" />
          </a>
        </LogoContainer>
        <span>
          Conheça as modalidades oferecidas para a sua empresa &nbsp;
          <a href="/para-empresas"> aqui!</a>
        </span>
        <a href="https://empresa.transfacil.com.br" target="blank">
          <Button color="purple" padding="1.5">
            ACESSAR ÁREA PESSOA JURÍDICA
          </Button>
        </a>
        {!menuToggle && (
          <button type="button" className="menu" onClick={handleToggleMenu}>
            <strong> &#9776;</strong>
          </button>
        )}

        <HamburguerMenuContainer active={menuToggle}>
          <button type="button" className="menu" onClick={handleToggleMenu}>
            <div className="one" />
            <div className="two" />
            <div className="three" />
          </button>
          <ItensContainer>
            {navItens.map(item => (
              <NavItem
                onClick={() => handleRedirect(item.path)}
                active={!!(pathname === item.path)}
                menuActive={menuToggle}
                key={item.name}
              >
                {item.name}
              </NavItem>
            ))}
            {navUser.map(item => (
              <NavItem
                onClick={signOut}
                active={!!(pathname === item.path)}
                menuActive={menuToggle}
                key={item.name}
              >
                {item.name}
              </NavItem>
            ))}
          </ItensContainer>
        </HamburguerMenuContainer>
      </Container>
    </>
  );
};

export default Header;
