import styled from 'styled-components';

export const WorkwithUsSection = styled.section`
  margin-top: 40px;
  background: var(--orange);
  color: var(--white);
  border-radius: 32px;
  border-bottom-right-radius: 0px;
  padding: 20px 40px;
  @media (max-width: 650px) {
    padding: 10px 15px;
  }
  .text-container {
    h1 {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 59px;

      margin-bottom: 10px;
      @media (max-width: 800px) {
        font-size: 26px;
      }
      @media (max-width: 600px) {
        font-size: 20px;
      }
    }

    h3 {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 0px;
      @media (max-width: 800px) {
        font-size: 16px;
      }
      @media (max-width: 600px) {
        font-size: 12px;
      }
    }

    small {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 170px;

      color: #a1aeb7;
      p {
        margin-bottom: 1rem;
        font: 'Source Sans Pro', sans-serif;
      }
    }
  }
`;

export const JobsSection = styled.div`
  margin-top: 0px;
  margin-bottom: 200px;
  padding-left: 20px;
  border-top: 2px solid var(--gray-line);
  .h1 {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: var(--purple);
    margin-bottom: 56px;
    margin-top: 50px;
  }

  .vagas {
    display: grid;
    border-top: 2px solid var(--gray-line);
    border-bottom: 2px solid var(--gray-line);
  }

  .button {
    width: 361px;
    height: 40px;
    background: var(--orange);
    border-radius: 5px;
    color: var(--white);
    transition: background 0.5s ease;
    margin-top: 25px;
    margin-bottom: 50px;

    &:hover {
      background: #bb421f;
    }
  }
`;

export const FormContent = styled.div`
  html body {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  max-width: 100%;
`;

export const WorkwithUsDiv = styled.div`
  margin-bottom: 0px;
`;

export const VagasDiv = styled.div`
  margin-right: 40px;
  margin-left: 40px;
  @media (max-width: 600px) {
    margin-right: 2px;
    margin-left: 2px;
  }
`;
