import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { VscAccount } from 'react-icons/vsc';
import { FiHeart } from 'react-icons/fi';
import { MdQrCode, MdCreditCard, MdHelpCenter } from 'react-icons/md';
import CartaoCliente from '../../assets/CartoesRecarga/CartaoCliente.svg';
import DashBoardLoader from '../DashBoardLoader';
import { ReactComponent as Logo } from '../../assets/Shared/logo.svg';
import { useAuth } from '../../hooks/auth';
import { useLoader } from '../../hooks/loader';

import {
  GridContainer,
  Header,
  Aside,
  Main,
  MainOverview,
  Footer,
  AsideMenu,
} from './styles';

const DashboardLayout: React.FC = ({ children }) => {
  const [menuActive, setMenuActive] = useState(false);
  const { user, signOut } = useAuth();
  const { push } = useHistory();
  const { isActive } = useLoader();

  const userName = () => {
    return user.full_name ? user.full_name.split(' ')[0] : '';
  };

  const haldleSetMenu = useCallback(() => {
    setMenuActive(!menuActive);
  }, [menuActive]);

  const handleRedirect = useCallback(
    pathLink => {
      push(pathLink);
    },
    [push],
  );

  return (
    <GridContainer active={menuActive}>
      {isActive && <DashBoardLoader />}
      <Header>
        <button type="button" className="menu-icon" onClick={haldleSetMenu}>
          <strong> &#9776;</strong>
        </button>
        <div className="header-wrap">
          <div className="profile">
            <div className="profile-data">
              <h3>
                {user.full_name ? (
                  <a href="/meu-perfil">
                    Olá, {userName()}
                    {/* <button type="button" onClick={signOut}>
                      Sair
                    </button> */}
                  </a>
                ) : (
                  <a href="/">
                    Olá, Seja Bem-vindo
                    {/* <button type="button" onClick={signOut}>
                      Sair
                    </button> */}
                  </a>
                )}
              </h3>
            </div>
          </div>
        </div>
      </Header>
      <Aside>
        <div className="topo">
          <div className="logo-container">
            <Logo onClick={() => push('/')} />
          </div>
          {menuActive && (
            <button
              type="button"
              className="aside_close-icon"
              onClick={haldleSetMenu}
            >
              <strong> &#9776;</strong>
            </button>
          )}
        </div>

        <AsideMenu>
          <div
            className="username-wrapper"
            role="button"
            onClick={() => {
              handleRedirect('/meu-perfil');
            }}
            onKeyDown={() => {
              handleRedirect('/meu-perfil');
            }}
            tabIndex={-1}
          >
            <li className="icon-link home">
              {user.full_name ? (
                <>
                  <div className="svg">
                    <VscAccount color="white" size={22} />
                  </div>
                  <div className="link">
                    <a href="/meu-perfil">{userName()}</a>
                  </div>
                </>
              ) : (
                <div className="link">
                  <a href="/dashboard">Olá, Seja Bem-vindo</a>
                </div>
              )}
            </li>
          </div>
          <div
            role="button"
            onClick={() => {
              handleRedirect('/meus-cartoes');
            }}
            onKeyDown={() => {
              handleRedirect('/meus-cartoes');
            }}
            tabIndex={-2}
          >
            <li className="icon-link home">
              <div className="link">
                <img
                  src={CartaoCliente}
                  alt="Cartão cliente BHBus"
                  className="img-cliente"
                  height={20}
                  style={{
                    marginRight: '10px',
                  }}
                />
                <a href="/meus-cartoes">Meus Cartões</a>
              </div>
            </li>
          </div>
          <div
            role="button"
            onClick={() => {
              handleRedirect('/qr-code');
            }}
            onKeyDown={() => {
              handleRedirect('/qr-code');
            }}
            tabIndex={-3}
          >
            <li className="icon-link home">
              <div className="link">
                <MdQrCode />
                <a href="/qr-code"> QR Code </a>
              </div>
            </li>
          </div>

          {/* <div
            role="button"
            onClick={() => {
              handleRedirect('/meu-perfil');
            }}
            onKeyDown={() => {
              handleRedirect('/meu-perfil');
            }}
            tabIndex={-4}
          >
            <li className="icon-link home">
              <div className="link">
                <a href="/meu-perfil">Meu Perfil</a>
              </div>
            </li>
          </div> */}

          <div
            role="button"
            onClick={() => {
              handleRedirect('/cartoes-credito');
            }}
            onKeyDown={() => {
              handleRedirect('/cartoes-credito');
            }}
            tabIndex={-6}
          >
            <li className="icon-link home">
              <div className="link">
                <MdCreditCard />
                <a href="/cartoes-credito">Cartões de crédito</a>
              </div>
            </li>
          </div>

          <div
            role="button"
            onClick={() => {
              handleRedirect('/favoritos');
            }}
            onKeyDown={() => {
              handleRedirect('/favoritos');
            }}
            tabIndex={-7}
          >
            <li className="icon-link home">
              <div className="link">
                <FiHeart />
                <a href="/favoritos">Favoritos</a>
              </div>
            </li>
          </div>

          <div
            role="button"
            onClick={() => {
              handleRedirect('/faq');
            }}
            onKeyDown={() => {
              handleRedirect('/faq');
            }}
            tabIndex={-8}
          >
            <li className="icon-link home">
              <div className="link">
                <MdHelpCenter />
                <a href="/faq">Perguntas Frequentes</a>
              </div>
            </li>
          </div>
        </AsideMenu>
      </Aside>
      <Main>
        <MainOverview>{children}</MainOverview>
      </Main>
      <Footer />
    </GridContainer>
  );
};

export default DashboardLayout;
