/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import NumberFormat from 'react-number-format';
import {
  formatCurrency,
  parseCurrency,
} from '@brazilian-utils/brazilian-utils';
import { useState, useEffect } from 'react';

interface IInformValue {
  onClick: any;
  qtyInput: number;
}

const InformValue: React.FC<IInformValue> = ({ onClick, qtyInput }) => {
  const [valueChange, setValueChange] = useState('');

  useEffect(() => {
    setValueChange(formatCurrency(qtyInput));
  }, [qtyInput]);
  return (
    <>
      {/* <NumberFormat
        prefix="R$ "
        className="inputcash"
        value={qtyInput}
        onValueChange={(e): any => {
          onClick(Number(qtyInput));
        }}
        thousandSeparator="."
        decimalSeparator=","
        fixedDecimalScale
        decimalScale={2}
        placeholder="R$ 0,00"
        allowNegative={false}
      /> */}
      <h1> Ou informe outro valor </h1>
      <div
        style={{
          marginTop: 20,
        }}
      >
        <input
          placeholder="Digite aqui"
          style={{
            padding: 10,
            border: '1px solid purple',
            borderRadius: 4,
          }}
          value={valueChange}
          onInput={(e: any) => {
            const parseNumber = parseCurrency(e.target.value);
            const formatNumber = formatCurrency(parseNumber);
            e.target.value = `${formatNumber.toString()}`;
          }}
          onChange={(e): any => {
            onClick(e.target.value);
          }}
        />
      </div>
    </>
  );
};

export { InformValue };
