import React from 'react';
import OrangeBox from '../../../assets/Home/Mask.png';
import { Container, BoxesContainer, BoxBoxesContainer } from './styles';
import Money from '../../../assets/Home/money.png';
import Lock from '../../../assets/Home/locked-lock.png';
import Card from '../../../assets/Home/card.png';

const CardSection: React.FC = () => {
  return (
    <>
      <Container>
        <img src={OrangeBox} alt="Orange Box" />
        <h1>Já adquiriu o seu cartão BHBUS?</h1>
        <span>Confira nossos serviços disponíveis para você.</span>
      </Container>

      <BoxBoxesContainer>
        <BoxesContainer>
          <a
            href="/cartoes-recarga/?active='recarga'"
            className="button"
            type="button"
          >
            <div className="box_roxa">
              <img src={Money} alt="Money sign" />

              <h4>RECARREGAR</h4>
              <p>MEU CARTÃO</p>
            </div>
          </a>

          <a href="/cartoes-recarga/?active='bloquear'">
            <button className="button" type="button">
              <div className="box_roxa">
                <img src={Lock} alt="Money sign" className="margem" />
                <h4>BLOQUEAR</h4>
                <p>MEU CARTÃO</p>
              </div>
            </button>
          </a>

          <a href="/cartoes-recarga#nossoscartoes">
            <div className="box_roxa">
              <img src={Card} alt="Money sign" className="margem" />
              <h4>NOSSOS</h4>
              <p>CARTÕES</p>
            </div>
          </a>
        </BoxesContainer>
      </BoxBoxesContainer>

      <Container>
        {/* <p className="texto-final">
          Confira as lojas ou os locais de recarga perto de você.
        </p>
        <p className="localizacao">Usar minha localização</p>
        <div>
          <input
            name="local"
            placeholder="Digite o local ou região que deseja buscar"
          />
          <button type="button">BUSCAR</button>
        </div> */}
      </Container>
    </>
  );
};

export default CardSection;
