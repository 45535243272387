/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect } from 'react';
import IconCopy from '../../../../assets/MyCards/iconCopy.svg';
import { ModalPixPagamento } from './styles';

interface ModalPixProps {
  codeExpiration: any;
  codePix: any;
  codeImage: any;
  closeModal(): void;
}

const ModalPix: React.FC<ModalPixProps> = ({
  codeExpiration,
  codePix,
  codeImage,
  closeModal,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dataExp = codeExpiration.split('T')[0];
  const dataBra = dataExp.split('-').reverse().join('/');

  function copiarTexto() {
    navigator.clipboard.writeText(codePix);
    const element: HTMLElement = document.getElementById('copy') as HTMLElement;
    element.innerHTML = 'Copiado';
    element.style.background = '#D3D3D3';
    element.style.color = 'var(--purple)';
    element.style.fontWeight = 'bold';
  }

  return (
    <ModalPixPagamento>
      <div className="wrapper">
        <button
          className="close"
          type="button"
          aria-label="close"
          onClick={closeModal}
        />
        <div className="row">
          <h2>Para finalizar sua compra é só realizar o pagamento com Pix!</h2>
          <p>
            O código é válido até&nbsp;
            <strong>{dataBra}</strong>
            &nbsp;às&nbsp;
            <strong>
              {codeExpiration.split('T')[1].split(':', 1)}:
              {codeExpiration.split('T')[1].split(':')[1]}
            </strong>
          </p>
          <img
            src={codeImage}
            alt=""
            style={{
              width: '180px',
              marginTop: '20px',
            }}
          />
          <div className="code">{codePix}</div>
          <button
            type="button"
            id="copy"
            onClick={copiarTexto}
            className="buttonCopy"
          >
            <img
              src={IconCopy}
              alt=""
              style={{
                width: '28px',
                marginRight: '10px',
              }}
            />
            Copiar código
          </button>
          <p style={{ marginTop: '10px', width: '95%' }}>
            <strong>
              Se o pagamento não for confirmado, não se preocupe. O pedido será
              cancelado automaticamente.
            </strong>
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            textAlign: 'left',
            marginTop: '30px',
          }}
        >
          <div className="number">1</div>
          <div>Copie o código de pagamento no botão acima</div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            textAlign: 'left',
            marginTop: '20px',
          }}
        >
          <div className="number">2</div>
          <div>
            Abra o app ou banco de sua preferência e cole o código. Confira
            todas as informações e autorize o pagamento.
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            textAlign: 'left',
            marginTop: '20px',
          }}
        >
          <div className="number">3</div>
          <div>
            Pronto! Você vai receber a confirmação de pagamento por aqui
          </div>
        </div>
      </div>
    </ModalPixPagamento>
  );
};

export default ModalPix;
