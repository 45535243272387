import styled from 'styled-components';
import MapImage from '../../assets/Shared/map.png';

export const TitleSection = styled.section`
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--white);

  @media only screen and (max-width: 768px) {
    height: 260px;
  }

  h1 {
    font-size: 3rem;
    line-height: 2.3rem;
    @media (max-width: 768px) {
      text-align: center;
    }
    @media (max-width: 308px) {
      line-height: 3.5rem;
    }
  }

  span {
    font-size: 1rem;
    line-height: 3.5rem;
    font-weight: normal;
    @media (max-width: 768px) {
      text-align: center;
    }
    @media (max-width: 339px) {
      line-height: 2rem;
      margin-top: 1rem;
    }
  }
`;

export const GridSection = styled.section`
  display: grid;
  grid-template-columns: 2fr 286px;
  grid-template-rows: 1fr 50px;
  grid-template-areas: 'main aside';
  grid-gap: 3rem;
  min-height: 100vh;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    height: '400px';
  }
`;

export const Main = styled.main`
  grid-area: main;
  padding: 1.5rem 0;

  h2 {
    color: var(--purple);
    margin: 10px 0 30px 0;
    font-size: 1.5rem;
  }

  form {
    > div {
      margin-bottom: 40px;
    }
  }

  .inputcontainer {
    display: flex;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 0px;

    .MuiAutocomplete-root {
      width: 100%;
    }

    .inputwrapper {
      width: 100% !important;
    }

    .buscalinha {
      display: none;
    }
    .input {
      width: 100%;
      margin-top: 10px;
      height: 40px;
      border: 1px solid var(--gray-line);
      color: var(--text);
      flex: 1;
      background: white;
      border-radius: 8px;
      padding-left: 1rem;
      padding-right: 1rem;

      &::placeholder {
        color: var(--gray-line);
      }

      @media (max-width: 450px) {
        width: 81%;
      }
    }
  }
  .input:focus {
    outline: none !important;
    border: 1px solid var(--orange);
  }
  .button {
    margin-left: -25px;
    @media (max-width: 450px) {
        position: absolute;
        margin-left: -100px;
      }

    
    .btn-buscar {
      color: white;
        @media (max-width: 450px) {
        display: none;
      }
    }
    .btn-search {
      height: 30px;
      width: auto;
      display: none;
        @media (max-width: 450px) {
          margin-top: 0px;
          display: block;
        }
        @media (max-width: 317px) {
          position: relative;
          width: 100%;
        }
  }
`;

export const Aside = styled.aside`
  grid-area: aside;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
export const ModalWrapper = styled.div`
  width: 100%;
  text-align: center;

  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 50px;
  }

  p {
    justify-content: center;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 50px;
  }
`;

export const AsideCard = styled.div`
  background: var(--white);
  width: 286px;
  position: sticky;
  top: 20px;
  margin: 1.5rem 0;
  height: 472px;
  border-radius: 10px 10px 0px 0px;
  box-shadow: var(--box-shadow);
  /* transform: translateY(-150px); */

  .header {
    background: var(--purple2);
    color: var(--orange);
    height: 73px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 3px solid var(--orange);
    font: 600 1.3rem 'Roboto Slab', sans-serif;
  }

  .main {
    margin-top: 14px;
    padding: 0 25px;

    form {
      .highlight-label {
        margin-bottom: 10px;
        font-style: normal;
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.1rem;
        color: var(--purple);
      }

      div {
        margin-bottom: 22px;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 30px;
      }
    }
  }

  .footer {
    height: 110px;
    background: url(${MapImage}) no-repeat;
    background-size: cover;

    display: flex;
    align-items: center;
    justify-content: center;

    button {
      color: var(--orange) !important;
      border: 2px solid var(--orange);
    }
    @media (max-width: 1081px) {
      height: 115px;
    }
    @media (max-width: 720px) {
      height: 120px;
    }
  }

  @media (max-width: 335px) {
    flex-wrap: wrap;
    width: 99%;
  }
`;

export const CollapseContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* max-width: 85%; */
  .flex {
    display: flex;
    flex-wrap: wrap;
  }
  .localizacao-pontos {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .horario {
    margin-right: 10px;
  }

  .sub-ball-container {
    margin: 0 15px;
    display: flex;
    align-items: flex-start;
    height: 100%;
    div {
      width: 42px;
      height: 42px;

      border-radius: 50%;
      background: var(--soft-orange);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .text-container {
    width: 100%;
    h1 {
      font-style: normal;

      font-size: 18px;
      line-height: 24px;
      text-transform: uppercase;
      color: var(--title-higlight);
      margin-bottom: 8px;
    }

    span,
    small {
      font-style: normal;

      font-size: 18px;
      line-height: 32px;
      text-transform: uppercase;
      color: var(--title-higlight);
    }

    small {
      font-weight: 500;
      text-transform: inherit;
    }
  }
`;

export const TimeContainer = styled.div`
  display: flex;
  margin-bottom: 25px;
  margin-top: 25px;
`;

export const Modal = styled.div`
  position: relative;
  margin-bottom: 10px;
  margin-top: -20px;
  display: none;
`;

export const Dialogo = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 25px;

  .container {
    text-align: center;
    width: 90%;
    height: 260px;
    @media (max-width: 420px) {
      margin-left: 0px;
      width: 100%;
    }
    img {
      width: 60px;
    }
    h1 {
      text-align: center;
      font-size: 1.7rem;
      color: var(--orange);
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .text {
      padding: 40px;
      width: 100%;
      @media (max-width: 350px) {
        padding: 40px 0 0 0;
      }
    }
    p {
      font-size: 1.2rem;
      color: var(--title-higlight);
      margin-bottom: 2.5rem;
      text-align: center;
    }
  }
`;

export const Modalline = styled.div`
  position: relative;
  margin-bottom: 10px;
  display: block;
`;

export const Dialogoline = styled.div`
  position: relative;
  justify-content: center;
  width: 100%;
  }
`;
