import styled from 'styled-components';

export const ModalPixPagamento = styled.div`
  width: 100%;
  min-width: 300px;
  min-height: 600px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    height: 155vh;
  }
  @media (max-width: 677px) {
    height: 150vh;
  }
  @media (max-width: 500px) {
    height: 165vh;
  }

  h2 {
    margin-bottom: 15px;
    color: var(--purple);
    width: 90%;
    text-align: center;
  }

  .wrapper {
    background-color: #fff;
    width: 850px;
    border-radius: 20px;
    padding: 5px 30px 15px 30px;
    display: flex;
    flex-direction: column;
    flex-wrap: 1;
    border: 1px solid #cbcbcb;
    text-align: center;
    color: var(--purple);
    background-color: white;
    overflow: scroll;
    overflow: auto;
    @media (max-width: 850px) {
      height: 100%;
    }
    ::-webkit-scrollbar {
      background-color: transparent;
      width: 10px;
      height: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--purple);
      border-radius: 10px;
    }

    .qrcode {
      justify-content: center;
      display: flex;
      align-items: center;
    }

    .close {
      background-color: transparent;
      outline: none;
      width: 100%;
      height: 32px;
      right: -15px;
      top: 5px;
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      position: relative;

      &:before,
      &:after {
        content: ' ';
        position: absolute;
        width: 2.5px;
        height: 24px;
        background-color: #000;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }

  .row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
    margin-top: 10px;
    background-color: #f5f5f5;
  }

  .code {
    margin-top: 20px;
    background-color: #edeaf0;
    border-radius: 10px;
    height: auto;
    padding: 5px 5px 0 5px;
    width: 95%;
    resize: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    word-break: break-all;
  }

  .buttonCopy {
    margin-top: 15px;
    width: 95%;
    background-color: var(--purple);
    color: white;
    height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .number {
    color: var(--orange);
    border: solid 2px var(--orange);
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: bold;
    margin-right: 20px;
  }
`;

export const Button = styled.button`
  background: var(--purple);
  border-radius: 20px;
  min-width: 200px;
  padding: 14px 23px 14px 23px;
  color: var(--text);
  align-items: center;
  @media (max-width: 940px) {
    margin-bottom: 1rem;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
  @media (max-width: 315px) {
    font-size: 0.9rem;
  }

  p {
    font-size: 15px;
  }

  display: flex;
  flex-direction: column;

  background: var(--purple);
  color: #f2f5f7;
  svg {
    margin-bottom: 10px;

    path {
      fill: var(--white);
    }
  }
`;
export const ButtonsDownload = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  text-align: left;
  width: 100%;
  margin-top: 10px;
  background-color: #f5f5f5;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const ButtonCompartilhar = styled.button`
  background: var(--orange);
  border-radius: 20px;
  min-width: 200px;
  padding: 14px 23px 14px 23px;
  color: var(--white);
  align-items: center;
  @media (max-width: 940px) {
    margin-bottom: 1rem;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
  @media (max-width: 315px) {
    font-size: 0.9rem;
  }

  p {
    font-size: 15px;
  }

  display: flex;
  flex-direction: column;

  background: var(--orange);
  color: #f2f5f7;
  svg {
    margin-bottom: 10px;

    path {
      fill: var(--white);
    }
  }
`;

export const TextSpan = styled.span`
  color: var(--red);
  margin-top: -10px;
`;

export const QrCodeLine = styled.div`
  background-color: var(--aside-background);
  border-radius: 20px;
  padding: 1%;
  margin: 1%;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    flex-direction: row;
    display: flex;
    justify-content: space-evenly;
    margin-right: 2%;
    align-items: center;
    color: white;
    background-color: var(--purple);
    p {
      color: white;
      margin-top: 0;
    }
    padding: 1%;
    border-radius: 20px;
    width: 120px;
  }
`;

export const QrCodeLineInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QrCodeInfo = styled.div`
  display: flex;
  text-align: center;
  border: 4px solid var(--aside-background);
  border-radius: 20px;
  margin: 2% 1%;
  justify-content: space-evenly;

  .iconI {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1%;
  }
`;
