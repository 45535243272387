import styled from 'styled-components';

export const Container = styled.div`
  h1,
  h4 {
    color: var(--purple);
  }

  .button-transparent {
    background-color: var(--transparent) !important;
    color: var(--orange) !important;
    border: 1px solid var(--orange) !important;
  }

  .VirtualCard {
    h2 {
      margin: 2px 0px 2px 0px;
    }
  }

  @media (max-width: 492px) {
    display: flex;
    flex-direction: column;
  }

  .Table {
    @media only screen and (max-width: 1200px) {
      display: grid;
      max-width: 100%;
    }
    @media only screen and (max-width: 395px) {
      margin-left: -5%;
    }
    @media only screen and (max-width: 380px) {
      margin-left: -10%;
    }
    .specifitcell {
      width: 100%;
    }
    #cell-1-undefined {
      padding: 0px 5px;
      max-width: 200px;
    }
    #cell-2-undefined {
      padding: 0px 5px;
      @media only screen and (max-width: 500px) {
        width: 40px;
      }
    }
    div[data-column-id='1'] {
      @media only screen and (max-width: 500px) {
        max-width: 100px;
      }
    }
    div[data-column-id='2'] {
      @media only screen and (max-width: 500px) {
        min-width: 20px;
      }
    }
    div[data-column-id='3'] {
      justify-content: center;
      @media only screen and (max-width: 500px) {
        max-width: 50px;
      }
      @media only screen and (max-width: 400px) {
        max-width: 90px;
      }
    }
    #cell-3-undefined {
      padding: 0px 5px;
      max-width: 150px;
      @media only screen and (max-width: 500px) {
        max-width: 60px;
      }
    }
  }
`;

export const TitleSection = styled.section`
  margin: 60px 0 60px 0;
  color: var(--purple);
  max-width: 100%;

  .historico {
    color: var(--orange);
    cursor: pointer;
    font-weight: bold;
    margin-left: 20px;
  }

  h1 {
    font-size: 3rem;
    line-height: 2.3rem;
    margin-bottom: 30px;
    padding: 2px 0px 2px 0px;
    @media (max-width: 492px) {
      line-height: 3.5rem;
    }
  }
  h4 {
    margin-top: 30px;
  }
`;

export const ButtonBuy = styled.button`
  background: var(--purple);
  border-radius: 20px;
  min-width: 200px;
  height: 48px;
  padding: 14px 20px 14px 20px;
  margin-right: 25px;
  color: var(--text);
  align-items: center;
  @media (max-width: 940px) {
    margin-bottom: 1rem;
  }
  @media only screen and (max-width: 800px) {
    margin-top: 10px;
  }
  @media (max-width: 315px) {
    font-size: 0.9rem;
  }

  p {
    font-size: 15px;
  }

  display: flex;
  flex-direction: column;

  background: var(--purple);
  color: #f2f5f7;
  svg {
    margin-bottom: 10px;

    path {
      fill: var(--white);
    }
  }
`;

export const QrCodeButtons = styled.div`
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  @media (max-width: 700px) {
    flex-direction: column;
    margin-bottom: 50px;
  }
`;

export const LinkHistory = styled.a`
  :hover {
    color: var(--orange);
  }
`;
