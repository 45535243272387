/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-console */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable object-shorthand */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable camelcase */
import React, {
  useRef,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { FiX } from 'react-icons/fi';

import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import api from '../../../services/api';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import FileInputSpecial from '../../../components/FileInputSpecial';
import { Container, Content } from '../../../styles/components';
import { CheckBox } from '../../../components/Checkbox';
import { CheckBoxSpecial } from '../../../components/CheckboxSpecial';
import FormularioFinalizado from './FormularioFinalizado';

import EstacaoBarreiro from '../../../assets/Estacao/est-barreiro.png';
import EstacaoDiamante from '../../../assets/Estacao/est-diamante.png';
import Hipercentro from '../../../assets/Estacao/hipercentro.png';
import EstacaoPampulha from '../../../assets/Estacao/est-pampulha.png';
import EstacaoVendaNova from '../../../assets/Estacao/est-vendanova.png';
import EstacaoVilarinho from '../../../assets/Estacao/est-vilarinho.png';
import EstacaoSaoGabriel from '../../../assets/Estacao/est-saogabriel.png';

import {
  FlexContainer,
  FormWrapper,
  BoxInput,
  BoxInputDiv,
  WorkDiv,
  ButtonDiv,
  BoxSectorDiv,
  FormDataDiv,
  FileDiv,
  BoxSectorCheck,
  BoxSectorTitle,
  SpecialLink,
  DialogContent,
  EspecialButton,
  BoxSectorpdc,
  BoxDiv,
  BoxStationDiv,
  BoxStationCheck,
  BoxStationTitle,
  ImgDiv,
  SpecialPhrase,
} from './styles';

const TrabalheConoscoForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [jobs, setJobs] = useState([]);
  const { addToast } = useToast();
  const { push } = useHistory();
  const [checkvalue, SetCheckvalue] = useState(undefined);
  const [text, setText] = useState(false);
  const [station, setStation] = useState(false);
  const [valortermo] = useState(undefined);
  const [finalize] = useState(false);
  const [disable, setDisable] = useState(false);
  const [pcd, setPcd] = useState(undefined);
  const [formularioFinalizado, setFormularioFinalizado] = useState(false);
  const [setorExample, setSetorExample] = useState(false);
  const [stationExample, setStationExample] = useState(false);

  const theme = useTheme();

  const [maxWidth] = useState<DialogProps['maxWidth']>('lg');
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const schema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    phone: Yup.string().required('Telefone é obrigatório'),
    address: Yup.string().required('Area de endereço é obrigatório'),
    interest_area: Yup.string().required('É obrigatorio a área de interesse'),
    curriculum: Yup.string().required('O currículo é obrigatório'),
    estacao: Yup.string(),
    termo: Yup.string().required('É necessário aceitar o termo'),
    pdc: Yup.string().required('Este campo é obrigatorio'),
    indicacao: Yup.string(),
    email: Yup.string()
      .required('Email é obrigatório')
      .email('Digite um email valido'),
  });

  function checkterm() {
    if (text) {
      setText(false);
    } else if (!text) {
      setText(true);
    }
  }

  function checkStation() {
    if (station) {
      setStation(false);
    } else if (!station) {
      setStation(true);
    }
  }

  useEffect(() => {
    const getJobs = async () => {
      try {
        const response = await api.get('/core/vagas/');
        setJobs(response.data.results);
      } catch (err) {
        console.error(err);
      }
    };

    getJobs();
  }, [text, valortermo]);

  function openmaster() {
    const modal = document.getElementById('master');
    const disp = modal.style.display;
    modal.style.display = disp === 'none' ? 'block' : 'block';
    const modal1 = document.getElementById('button-ver-mais-master');
    const disp1 = modal.style.display;
    modal1.style.display = disp1 === 'block' ? 'none' : 'none';
  }

  function closemaster() {
    const modal = document.getElementById('master');
    const disp = modal.style.display;
    modal.style.display = disp === 'block' ? 'none' : 'none';
    const modal1 = document.getElementById('button-ver-mais-master');
    const disp1 = modal.style.display;
    modal1.style.display = disp1 === 'none' ? 'block' : 'block';
  }

  function openSetorExample() {
    setSetorExample(true);
  }

  function openStationExample() {
    setStationExample(true);
  }

  function closeSetorExample() {
    setSetorExample(false);
  }
  function closeStationExample() {
    setStationExample(false);
  }

  const jobsOptions = useMemo(() => {
    return jobs.map(job => ({ value: job.id, label: job.name }));
  }, [jobs]);

  console.log({ jobsOptions });

  const handleSubmit = useCallback(
    async data => {
      const formData = new FormData();
      console.log(data.pdc);

      if (data.pdc === 'sim') {
        setPcd(true);
      } else if (data.pdc === 'nao') {
        setPcd(false);
      }

      console.log(data);

      const dataForm = {
        name: data.name,
        phone: data.phone,
        email: data.email,
        interest_area: data.interest_area,
        address: data.endereco,
        estacao: data.estacao,
        curriculum: data.curriculum,
        pdc: data.pdc,
        indicacao: data.indicacao,
      };
      console.log(dataForm);
      for (const name in data) {
        if (Object.prototype.hasOwnProperty.call(data, name)) {
          formData.append(name, data[name]);
        }
      }
      console.log(data.pdc);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      console.log(config);
      console.log(formData);
      try {
        formRef.current?.setErrors({});

        SetCheckvalue(data.CheckBox);
        await schema.validate(data, {
          abortEarly: false,
        });
        setDisable(true);
        await api.post('/core/trabalhe-conosco/', formData);

        setFormularioFinalizado(true);
        setDisable(false);

        addToast({
          type: 'success',
          title: `Seu currículo foi recebido com sucesso!`,
          description: `Agradecemos o interesse em fazer parte do nosso time.`,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: `error`,
          description: `error`,
        });
      }
    },
    [pcd, schema, addToast],
  );

  const handleEstacao = useCallback(
    async data => {
      console.log(data);
      if (data.id === 'sim' && !station) {
        setStation(true);
      } else if (data.id === 'sim' && station) {
        setStation(false);
      } else if (data.id === 'nao') {
        setStation(false);
      }
    },
    [station],
  );

  return (
    <WorkDiv>
      <Container>
        <Content>
          <FlexContainer>
            <div className="text-wrapper">
              <h1>BANCO DE TALENTOS</h1>
            </div>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <FormWrapper>
                <FormDataDiv>
                  <BoxInputDiv>
                    <BoxInput>
                      <Input
                        className="InputWork"
                        name="name"
                        schema={schema}
                        placeholder="Digite o seu nome"
                        label="Nome Completo*"
                      />
                    </BoxInput>
                    <BoxSectorpdc
                      className="Checkbox"
                      style={{ flexDirection: 'row' }}
                    >
                      <BoxSectorTitle>
                        <p className="TitleP">
                          Possui alguma deficiência? (PCD)
                        </p>
                      </BoxSectorTitle>
                      <BoxDiv>
                        <CheckBoxSpecial
                          name="pdc"
                          limit={0}
                          options={[
                            {
                              id: 'sim',
                              label: 'SIM',
                              value: 'Sim',
                            },
                            {
                              id: 'nao',
                              label: 'NÃO',
                              value: 'Não',
                            },
                          ]}
                        />
                      </BoxDiv>
                    </BoxSectorpdc>
                    <BoxInput>
                      <Input
                        className="InputWork"
                        phone
                        name="phone"
                        schema={schema}
                        placeholder="(xx)xxxxx-xxxx"
                        label="Telefone*"
                      />
                    </BoxInput>
                    <BoxInput>
                      <Input
                        className="InputWork"
                        name="email"
                        schema={schema}
                        placeholder="Digite seu email"
                        label="Email*"
                      />
                    </BoxInput>
                    <BoxInput>
                      <Input
                        className="InputWork"
                        name="address"
                        schema={schema}
                        placeholder="Digite seu endereço"
                        label="Endereço (RUA-NUMERO-BAIRRO)*"
                      />
                    </BoxInput>
                    <BoxInput>
                      <Input
                        className="InputWork"
                        name="indicacao"
                        schema={schema}
                        placeholder="Nome/Setor"
                        label="Foi indicado? Por quem? (Nome/setor)"
                      />
                    </BoxInput>
                    <FileDiv>
                      <p style={{ fontSize: '16px' }}>Currículo*</p>
                      <FileInputSpecial
                        className="curriculum"
                        name="curriculum"
                        title="Arraste o arquivo aqui
                        ou
                        procure em seu dispositivo"
                      />
                    </FileDiv>
                  </BoxInputDiv>
                  <BoxSectorDiv className="Checkbox">
                    <BoxSectorTitle>
                      <p className="TitleP">
                        {' '}
                        Area de interesse (escolha até 3 opções):*{' '}
                        <SpecialLink
                          style={{ fontWeight: 'bold' }}
                          onClick={openSetorExample}
                        >
                          DÚVIDAS?
                        </SpecialLink>
                      </p>
                    </BoxSectorTitle>
                    <BoxSectorCheck>
                      <CheckBox
                        name="interest_area"
                        limit={2}
                        options={[
                          {
                            id: 'fin',
                            label: 'Financeiro',
                            value: 'Financeiro',
                          },
                          {
                            id: 'rhdp',
                            label: 'Recursos humanos ou Dp',
                            value: 'Recursoshumanos ou Dp',
                          },
                          {
                            id: 'ti',
                            label: 'Tecnologia da informação',
                            value: 'Tecnologia da informação',
                          },
                          {
                            id: 'relcl',
                            label: 'Relacionamento com o cliente',
                            value: 'Relacionamento com o cliente',
                          },
                          {
                            id: 'mkt',
                            label: 'Marketing ou Comunicação',
                            value: 'Marketing ou Comunicação',
                          },
                          {
                            id: 'mon',
                            label: 'Monitoramento',
                            value: 'Monitoramento',
                          },
                          {
                            id: 'fivi',
                            label: 'Fiscalização ou Vigilância',
                            value: 'Fiscalização ou Vigilância',
                          },
                          {
                            id: 'com',
                            label: 'Comercial',
                            value: 'Comercial',
                          },
                          {
                            id: 'adm',
                            label: 'Administrativo',
                            value: 'Administrativo',
                          },
                          {
                            id: 'atend',
                            label: 'Atendimento ao público',
                            value: 'Atendimento ao público',
                          },
                        ]}
                      />
                    </BoxSectorCheck>
                    <BoxStationTitle>
                      <SpecialPhrase
                        className="TitleP"
                        title="(Agente de Estação e 
                        Controlador de Acesso)"
                      >
                        Interesse em vagas de Estação? (Agente de Estação e
                        Controlador de Acesso)
                      </SpecialPhrase>
                      <BoxDiv>
                        <CheckBoxSpecial
                          name="station"
                          limit={0}
                          onChange={handleEstacao}
                          options={[
                            {
                              id: 'sim',
                              label: 'SIM',
                              value: 'Sim',
                            },
                            {
                              id: 'nao',
                              label: 'NÃO',
                              value: 'Não',
                            },
                          ]}
                        />
                      </BoxDiv>
                    </BoxStationTitle>
                  </BoxSectorDiv>
                  {station && station ? (
                    <BoxSectorDiv className="Checkbox">
                      <BoxSectorTitle>
                        <p className="TitleP">
                          {' '}
                          Estações mais próximas de você(escolha até 2 opções)*:{' '}
                          <SpecialLink
                            style={{ fontWeight: 'bold' }}
                            onClick={openStationExample}
                          >
                            DÚVIDAS?
                          </SpecialLink>
                        </p>
                      </BoxSectorTitle>
                      <BoxStationCheck>
                        <CheckBox
                          name="estacao"
                          limit={1}
                          options={[
                            {
                              id: 'ba',
                              label: 'Barreiro',
                              value: 'Barreiro',
                            },
                            {
                              id: 'hi',
                              label: 'Hipercentro',
                              value: 'Hipercentro',
                            },
                            {
                              id: 'dia',
                              label: 'Diamante',
                              value: 'Diamante',
                            },
                            {
                              id: 'pam',
                              label: 'Pampulha',
                              value: 'Pampulha',
                            },
                            {
                              id: 'vi',
                              label: 'Vilarinho',
                              value: 'Vilarinho',
                            },
                            {
                              id: 'vn',
                              label: 'Venda Nova',
                              value: 'Venda Nova',
                            },
                            {
                              id: 'sao',
                              label: 'São Gabriel',
                              value: 'São Gabriel',
                            },
                          ]}
                        />
                      </BoxStationCheck>
                    </BoxSectorDiv>
                  ) : (
                    ''
                  )}
                </FormDataDiv>
                <ButtonDiv>
                  <Button
                    className="buttonsent"
                    type="submit"
                    color="orange"
                    padding="1"
                    rounded
                    style={{
                      marginTop: '15px',
                    }}
                    disabled={disable}
                  >
                    ENVIAR
                  </Button>
                  <CheckBox
                    style={{ marginTop: '8px' }}
                    name="termo"
                    onChange={checkterm}
                    options={[
                      {
                        id: 'termo',
                        label: '',
                        value: 'true',
                      },
                    ]}
                  />
                  <p style={{ marginTop: '31px' }}>
                    Li e concordo com os termos de política e privacidade(LGPD)
                    do Transfácil.{' '}
                    <a
                      href="https://transfacil.com.br/termos-de-uso"
                      target="_blank"
                      style={{ color: 'var(--orange)', fontWeight: 'bold' }}
                      rel="noreferrer"
                    >
                      Clique aqui para acessar a politica.
                    </a>
                  </p>
                </ButtonDiv>
                {text ? (
                  <div>
                    <p style={{ fontWeight: 'normal' }}>
                      O titular de dados (Interessado em aderir ao banco de
                      talentos da Empresa) consente que o Transfácil, armazene
                      seus dados e informações por um período de 6 meses, a
                      contar da data de recebimento dos dados preenchidos, e que
                      possa se comunicar com o candidato, incluindo, porém não
                      se limitando à contatos via Whatsapp, e-mail ou telefone
                      celular (desde que sejam preenchidos), além de declarar
                      estar ciente da{' '}
                      <strong
                        style={{ color: 'var(--orange)', fontWeight: 'bold' }}
                      >
                        Política de Privacidade e Tratamento de Dados da
                        Empresa,{' '}
                      </strong>{' '}
                      que pode ser acessada através do link:{' '}
                      <a
                        href="https://transfacil.com.br/termos-de-uso"
                        target="_blank"
                        style={{
                          color: 'var(--orange)',
                          fontWeight: 'bold',
                          textDecoration: 'underline',
                        }}
                        rel="noreferrer"
                      >
                        https://transfacil.com.br/termos-de-uso.
                      </a>
                    </p>
                    <p style={{ fontWeight: 'normal' }}>
                      Deixamos claro que o Interessado em questão, se coloca
                      responsável por todas as informações preenchidas e
                      encaminhadas e conhece sobre os seus direitos de revogação
                      do consentimento junto à Empresa, podendo solicitá-lo a
                      qualquer momento, sabendo que neste caso, suas informações
                      serão retiradas do banco de talentos e não poderá
                      concorrer ou ser comunicado sobre vagas que vierem a
                      existir.
                    </p>
                  </div>
                ) : (
                  <p />
                )}
                {formularioFinalizado && (
                  <FormularioFinalizado
                    show={formularioFinalizado}
                    text="Digite o ID numérico do titular e você receberá no email cadastrado instruções."
                    handleClose={() => push('/')}
                  />
                )}
                <Dialog
                  maxWidth={maxWidth}
                  fullScreen={fullScreen}
                  open={setorExample}
                  onClose={closeSetorExample}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <EspecialButton
                    type="button"
                    className="close-container"
                    onClick={closeSetorExample}
                  >
                    <FiX size={40} onClick={closeSetorExample} />
                  </EspecialButton>
                  <DialogContent>
                    <div>
                      <div className="flex-container">
                        <div className="container2">
                          <h1 className="title-benefit">
                            DESCRIÇÃO DAS AREAS DE INTERESSE
                          </h1>
                          <h3 className="title-benefit1">FINANCEIRO</h3>
                          <p
                            style={{
                              fontWeight: 'bold',
                              paddingBottom: '5px',
                            }}
                          >
                            Ex: Auxiliar Financeiro, Assistente Financeiro
                          </p>
                          <p>
                            Realizar atividades inerentes aos custos e a receita
                            da empresa, aos processos de pagamento a
                            fornecedores e/ou Operadoras de Transporte Coletivo.
                          </p>
                          <h3 className="title-benefit1">
                            RECURSOS HUMANOS OU DP
                          </h3>
                          <p
                            style={{
                              fontWeight: 'bold',
                              paddingBottom: '5px',
                            }}
                          >
                            Ex: Assistente de Departamento Pessoal, Analista de
                            Recursos Humanos, Estágio de RH
                          </p>
                          <p>
                            Realizar processos de gestão e desenvolvimento de
                            gente, através de políticas e diretrizes de
                            Treinamento e Desenvolvimento, Analise de
                            Performance, Recrutamento e Seleção, Remuneração,
                            Gestão de Benefícios, Cultura e Clima, através de
                            ferramentas e metodologias de gestão de pessoas.
                            Realizar atividades relacionadas ao processo de
                            folha de pagamento dos empregados, alteração de
                            salários, cargos, admissão, demissão, homologações,
                            férias, afastamentos, atendimento e acompanhamento
                            de fiscalizações, de acordo com os procedimentos
                            internos, legislação vigente.
                          </p>
                          <h3 className="title-benefit1">
                            TECNOLOGIA DA INFORMAÇÃO
                          </h3>
                          <p
                            style={{
                              fontWeight: 'bold',
                              paddingBottom: '5px',
                            }}
                          >
                            Ex: Técnico de Suporte e Infraestrutura, Assistente
                            de Suporte, Analista de Rede
                          </p>
                          <p>
                            Realizar atividades inerentes ao monitoramento e
                            manutenção do banco de dados, hardwares e softwares
                            da Empresa, aos sistemas e infraestrutura da
                            Empresa.
                          </p>
                          <h3 className="title-benefit1">
                            RELACIONAMENTO COM O CLIENTE
                          </h3>
                          <p
                            style={{
                              fontWeight: 'bold',
                              paddingBottom: '5px',
                            }}
                          >
                            Ex: Analista de Sucesso do Cliente, Auxiliar de
                            Atendimento ao Cliente
                          </p>
                          <p>
                            Relacionar com os grandes clientes do
                            vale-transporte fazendo contato regular através de
                            ligações, e-mail e reuniões, realizando as demandas
                            encaminhadas, solucionando problemas e prestando
                            esclarecimentos e orientações. Acompanhar
                            indicadores de consumo e satisfação dos clientes,
                            elaborar estratégias e ações para aumentar a receita
                            e experiência positiva.
                          </p>
                          <h3 className="title-benefit1">
                            MARKETING OU COMUNICAÇÃO
                          </h3>
                          <p
                            style={{
                              fontWeight: 'bold',
                              paddingBottom: '5px',
                            }}
                          >
                            Ex: Analista de Marketing, Estágio de Marketing
                          </p>
                          <p>
                            Planejar e executar campanhas de comunicação e
                            marketing, digitais e tradicionais, definindo mídias
                            e canais de veiculação, peças e demais elementos das
                            ações, visando prospectar e fidelizar clientes,
                            gerar negócios e alavancar a imagem da Instituição.
                          </p>
                          <button
                            type="button"
                            id="button-ver-mais-master"
                            className="ver-mais"
                            onClick={openmaster}
                          >
                            <p className="MoreOptions">VER MAIS</p>
                          </button>

                          <div id="master" className="cards-benefit">
                            <h3 className="title-benefit1">MONITORAMENTO</h3>
                            <p
                              style={{
                                fontWeight: 'bold',
                                paddingBottom: '5px',
                              }}
                            >
                              Ex: Monitor de Câmeras, Controlador de Operações
                            </p>
                            <p>
                              Monitorar pelo sistema de circuito fechado de TV,
                              presença e posicionamento dos colaboradores,
                              usuários e veículos nas estações, procedimentos,
                              organização e condições das instalações e
                              equipamentos de operação.
                            </p>
                            <h3 className="title-benefit1">
                              FISCALIZAÇÃO / VIGILÂNCIA
                            </h3>
                            <p
                              style={{
                                fontWeight: 'bold',
                                paddingBottom: '5px',
                              }}
                            >
                              Ex: Controlador de Acesso
                            </p>
                            <p>
                              Acompanhar o fluxo de usuários nas Estações,
                              realizando a fiscalização nas linhas de bloqueio,
                              conferência de gratuidades, orientação e abordagem
                              aos usuários, evitando assim a evasão de receita
                              da estação.
                            </p>
                            <h3 className="title-benefit1">COMERCIAL</h3>
                            <p
                              style={{
                                fontWeight: 'bold',
                                paddingBottom: '5px',
                              }}
                            >
                              Ex: Analista Comercial, Assistente de Mídia
                            </p>
                            <p>
                              Dar suporte na execução das atividades ligadas às
                              áreas Comercial e Marketing, fazendo a conexão
                              entre os dois setores. Dar suporte ao time de
                              vendas, acompanhando os projetos e propostas
                              comerciais. Auxiliar na organização e logística de
                              eventos e reuniões. Emitir orçamentos, relatórios
                              no sistema, previsão de receitas, realizar
                              acompanhamento de contratos, processo de pós
                              vendas e de compras, bem como apoiar no
                              desenvolvimento das atividades administrativas das
                              áreas, visando o cumprimento dos objetivos
                              definidos pela Empresa.
                            </p>
                            <h3 className="title-benefit1">ADMINISTRATIVO</h3>
                            <p
                              style={{
                                fontWeight: 'bold',
                                paddingBottom: '5px',
                              }}
                            >
                              Ex: Auxiliar Administrativo, Assistente
                              Administrativo
                            </p>
                            <p>
                              Realizar atividades de rotina administrativa,
                              utilizando equipamentos de informática, telefonia
                              e acesso a sistemas informatizados. Receber,
                              conferir e administrar arquivo de documentos, de
                              acordo com os procedimentos internos. Emitir
                              relatórios, auxiliar nos processos, visando
                              atender às demandas da Empresa, acompanhando e
                              garantindo o cumprimento de prazos.
                            </p>
                            <h3 className="title-benefit1">
                              ATENDIMENTO AO PUBLICO
                            </h3>
                            <p
                              style={{
                                fontWeight: 'bold',
                                paddingBottom: '5px',
                              }}
                            >
                              Ex: Atendente de SAC, Atendente na Bilheteria
                            </p>
                            <p>
                              Realizar o devido atendimento ao público que
                              utiliza o Transporte Coletivo de Belo Horizonte e
                              compra Cartão BHBUS, dando orientações e
                              informações, oferecendo o suporte necessário,
                              visando reter os clientes e expandir os negócios
                              da empresa.
                            </p>
                            <button
                              type="button"
                              className="ver-mais"
                              onClick={closemaster}
                            >
                              <p className="MoreOptions">VER MENOS</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
                <Dialog
                  maxWidth={maxWidth}
                  fullScreen={fullScreen}
                  open={stationExample}
                  onClose={closeStationExample}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <EspecialButton
                    type="button"
                    className="close-container"
                    onClick={closeStationExample}
                  >
                    <FiX size={40} onClick={closeStationExample} />
                  </EspecialButton>
                  <DialogContent>
                    <div>
                      <div className="flex-container">
                        <div className="container2">
                          <h1 className="title-benefit">ESTAÇÕES</h1>
                          <ImgDiv>
                            <div
                              style={{
                                width: '20%',
                              }}
                            >
                              <img
                                src={EstacaoBarreiro}
                                alt="Estação Barreiro"
                                className="img-est"
                              />
                            </div>
                            <div
                              style={{
                                width: '80%',
                              }}
                            >
                              <h3 className="est">
                                <a
                                  href="https://www.google.com/maps/search/Esta%C3%A7%C3%A3o+Barreiro+-+Barreiro,+Belo+Horizonte+-+MG/@-19.9733891,-44.0209197,18z/data=!3m1!4b1?entry=ttu"
                                  target="_blank"
                                >
                                  ESTAÇÃO BARREIRO
                                </a>
                              </h3>
                              <p
                                style={{
                                  fontWeight: 'bold',
                                  paddingBottom: '5px',
                                }}
                              >
                                AV. AFONSO VAZ DE MELO, 640 -BARREIRO DE BAIXO
                              </p>
                            </div>
                          </ImgDiv>
                          <ImgDiv>
                            <div
                              style={{
                                width: '20%',
                              }}
                            >
                              <img
                                src={Hipercentro}
                                alt="Hipercentro"
                                className="img-est"
                              />
                            </div>
                            <div
                              style={{
                                width: '80%',
                              }}
                            >
                              <h3 className="est">
                                <a
                                  href="https://www.google.com/maps/place/Avenida+Santos+Dumont+414+Centro+Belo+Horizonte+-+Minas+Gerais+31600+Brasil/@-19.9162439,-43.9375306,19.73z/data=!4m6!3m5!1s0xa699fbd7539305:0xb03dbde897124e6d!8m2!3d-19.91603!4d-43.93699!16s%2Fg%2F11rjqh58g6?entry=ttu"
                                  target="_blank"
                                >
                                  HIPERCENTRO
                                </a>
                              </h3>
                              <p
                                style={{
                                  fontWeight: 'bold',
                                  paddingBottom: '5px',
                                }}
                              >
                                AV. SANTOS DUMONT
                              </p>
                              <p
                                style={{
                                  fontWeight: 'bold',
                                  paddingBottom: '5px',
                                }}
                              >
                                (ENTRE A RUA RIO DE JANEIRO E RUA ESPIRITO
                                SANTO)
                              </p>
                            </div>
                          </ImgDiv>
                          <ImgDiv>
                            <div
                              style={{
                                width: '20%',
                              }}
                            >
                              <img
                                src={EstacaoDiamante}
                                alt="Estação Diamante"
                                className="img-est"
                              />
                            </div>
                            <div
                              style={{
                                width: '80%',
                              }}
                            >
                              <h3 className="est">
                                <a
                                  href="https://www.google.com/maps/place/Esta%C3%A7%C3%A3o+Diamante/@-19.9943987,-44.0263252,17z/data=!3m1!4b1!4m6!3m5!1s0xa6be7e885b8539:0xe9c074b9e34abc18!8m2!3d-19.9944038!4d-44.0237503!16s%2Fg%2F122_y33q?entry=ttu"
                                  target="_blank"
                                >
                                  ESTAÇÃO DIAMANTE
                                </a>
                              </h3>
                              <p
                                style={{
                                  fontWeight: 'bold',
                                  paddingBottom: '5px',
                                }}
                              >
                                AV. JOÃO ROLLA FILHO, 50 - DIAMANTE
                              </p>
                            </div>
                          </ImgDiv>
                          <ImgDiv>
                            <div
                              style={{
                                width: '20%',
                              }}
                            >
                              <img
                                src={EstacaoPampulha}
                                alt="Estação Pampulha"
                                className="img-est"
                              />
                            </div>
                            <div
                              style={{
                                width: '80%',
                              }}
                            >
                              <h3 className="est">
                                <a
                                  href="https://www.google.com/maps/place/MOVE+Municipal+-+Esta%C3%A7%C3%A3o+Pampulha/@-19.8422894,-43.9697286,17z/data=!3m1!4b1!4m6!3m5!1s0xa691a1b541f3f3:0xded9b5d1cfb13a11!8m2!3d-19.8422945!4d-43.9671537!16s%2Fg%2F11fmyppq87?entry=ttu"
                                  target="_blank"
                                >
                                  ESTAÇÃO PAMPULHA
                                </a>
                              </h3>
                              <p
                                style={{
                                  fontWeight: 'bold',
                                  paddingBottom: '5px',
                                }}
                              >
                                AV. PORTUGAL, 3700 - JARDIM ATLÂNTICO
                              </p>
                            </div>
                          </ImgDiv>
                          <ImgDiv>
                            <div
                              style={{
                                width: '20%',
                              }}
                            >
                              <img
                                src={EstacaoVilarinho}
                                alt="Estação Vilarinho"
                                className="img-est"
                              />
                            </div>
                            <div
                              style={{
                                width: '80%',
                              }}
                            >
                              <h3 className="est">
                                <a
                                  href="https://www.google.com/maps/place/Esta%C3%A7%C3%A3o+Vilarinho/@-19.8216149,-43.9501449,17z/data=!3m1!4b1!4m6!3m5!1s0xa68ff7187b0be7:0x359ce156d14e701!8m2!3d-19.82162!4d-43.94757!16s%2Fg%2F121y0gr9?entry=ttu"
                                  target="_blank"
                                >
                                  ESTAÇÃO VILARINHO
                                </a>
                              </h3>
                              <p
                                style={{
                                  fontWeight: 'bold',
                                  paddingBottom: '5px',
                                }}
                              >
                                AV. VILARINHO, 36 - VENDA NOVA
                              </p>
                            </div>
                          </ImgDiv>
                          <ImgDiv>
                            <div
                              style={{
                                width: '20%',
                              }}
                            >
                              <img
                                src={EstacaoVendaNova}
                                alt="Estação Venda Nova"
                                className="img-est"
                              />
                            </div>
                            <div
                              style={{
                                width: '80%',
                              }}
                            >
                              <h3 className="est">
                                <a
                                  href="https://www.google.com/maps/place/Esta%C3%A7%C3%A3o+Venda+Nova/@-19.8093665,-43.9716849,17z/data=!3m2!4b1!5s0xa68fc6fe6163ed:0x106e660d5883a602!4m6!3m5!1s0xa68f319ae8ba8b:0x1fe38252a373cbfa!8m2!3d-19.8093716!4d-43.96911!16s%2Fg%2F11g89dgzlg?entry=ttu"
                                  target="_blank"
                                >
                                  ESTAÇÃO VENDA NOVA
                                </a>
                              </h3>
                              <p
                                style={{
                                  fontWeight: 'bold',
                                  paddingBottom: '5px',
                                }}
                              >
                                RUA PADRE PEDRO PINTO, 2277 - VENDA NOVA
                              </p>
                            </div>
                          </ImgDiv>
                          <ImgDiv>
                            <div
                              style={{
                                width: '20%',
                              }}
                            >
                              <img
                                src={EstacaoSaoGabriel}
                                alt="Estação São Gabriel"
                                className="img-est"
                              />
                            </div>
                            <div
                              style={{
                                width: '80%',
                              }}
                            >
                              <h3 className="est">
                                <a
                                  href="https://www.google.com/maps/place/Esta%C3%A7%C3%A3o+S%C3%A3o+Gabriel+-+Primeiro+de+Maio,+Belo+Horizonte+-+MG/@-19.8639221,-43.9293276,17z/data=!3m1!4b1!4m6!3m5!1s0xa69a93767fb7a3:0x489ecf77d69de859!8m2!3d-19.8639272!4d-43.9267527!16s%2Fg%2F1ptvznr0k?entry=ttu"
                                  target="_blank"
                                >
                                  ESTAÇÃO SÃO GABRIEL
                                </a>
                              </h3>
                              <p
                                style={{
                                  fontWeight: 'bold',
                                  paddingBottom: '5px',
                                }}
                              >
                                AV. CRISTIANO MACHADO, 5600 - SÃO GABRIEL
                              </p>
                            </div>
                          </ImgDiv>
                        </div>
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
              </FormWrapper>
            </Form>
          </FlexContainer>
        </Content>
      </Container>
    </WorkDiv>
  );
};

export default TrabalheConoscoForm;
