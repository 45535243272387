import styled from 'styled-components';

export const TitleSection = styled.section`
  margin: 60px 0px 70px 0px;
  color: var(--purple);

  h1 {
    font-size: 3rem;
    line-height: 2.3rem;
  }
`;

export const Main = styled.main`
  width: 100%;

  .header {
    background-color: var(--aside-background);
    strong {
      color: var(--purple);
    }
  }

  .nodata {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    .icon {
      color: var(--purple);
      margin-bottom: 10px;
      margin-top: 20px;
    }
  }

  .paper {
    box-shadow: none;
    @media only screen and (max-width: 800px) {
      display: flex;
      justify-content: center;
    }
  }
  .Tabela {
    @media only screen and (max-width: 1200px) {
      display: grid;
      max-width: 100%;
    }
    @media only screen and (max-width: 395px) {
      margin-left: -5%;
    }
    @media only screen and (max-width: 380px) {
      margin-left: -10%;
    }
    .specifitcell {
      width: 100%;
    }
    #cell-1-undefined {
      padding: 0px 5px;
      max-width: 200px;
    }
    #cell-2-undefined {
      width: 100px;
      padding: 0px 5px;
      @media only screen and (max-width: 500px) {
        width: 100px;
      }
    }
    div[data-column-id='1'] {
      max-width: 200px;
      @media only screen and (max-width: 500px) {
        max-width: 100px;
      }
    }
    div[data-column-id='2'] {
      width: 120px;
      @media only screen and (max-width: 500px) {
        min-width: 150px;
      }
    }
    div[data-column-id='3'] {
      max-width: 1620px;
      justify-content: center;
      @media only screen and (max-width: 500px) {
        max-width: 100px;
      }
      @media only screen and (max-width: 400px) {
        max-width: 90px;
      }
    }
    /* #cell-3-undefined {
      font-size: 14px;
      padding: 0px 5px;
      @media only screen and (max-width: 500px) {
        font-size: 12px;
      }
    } */
    #cell-3-undefined {
      padding: 0px 5px;
      max-width: 150px;
      @media only screen and (max-width: 400px) {
        max-width: 80px;
      }
    }
    /* #cell-4-undefined {
      padding: 0px 5px;
    } */
  }
  .button-cadastrar {
    margin-top: 10px;
  }

  .datadiv {
    display: flex;
    flex-direction: row;
    padding: 10px;
  }
  .infonotdata {
    color: var(--purple);
    margin-top: 20px;
    margin-left: 10px;
  }
  .data {
    border-radius: 20px;
    padding: 5px;
    text-align: center;
    color: var(--purple);
    font-weight: bold;
    margin: 5px;
  }
  .datatext {
    color: var(--orange);
    margin-left: 5px;
    margin-bottom: 10px;
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: green;
    color: yellow;
    text-align: center;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .verPix {
    background: transparent;
    color: rgb(9, 85, 165);
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 0.875rem;
  }
`;
