/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import DashboardLayout from '../../components/DashboardLayout';
import { TitleSection, Container } from './styles';

import ListPayment from '../../components/FormOfPayment/ListPayment';
import FormPayment from '../../components/FormOfPayment/FormPayment';
import Loader from '../../components/Loader';

import TicketingService, { Payment as Pay } from '../../services/ticketing';

const FormOfPayment: React.FC = () => {
  const services = { ticketingService: new TicketingService() };
  const { path } = useRouteMatch();
  const [payments, setPayments] = useState<Pay[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  async function getFaqs() {
    try {
      const response = await services.ticketingService.getPaymentForm();
      const creditcard = response.data.results.filter(
        (data: any) => data?.payment_type === 'credit_card',
      );
      setPayments(creditcard);
      setLoading(false);
      console.log(response?.data?.results);
    } catch (err) {
      console.error(err);
    }
  }
  useEffect(() => {
    getFaqs();
  }, []);

  return (
    <>
      <DashboardLayout>
        {loading ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <Container>
              <TitleSection>
                <h1>Cartões de crédito</h1>
              </TitleSection>
              <Switch>
                <Route path={`${path}/editar/:paymentId`}>
                  <FormPayment refreshList={getFaqs} />
                </Route>
                <Route path={`${path}/adicionar`}>
                  <FormPayment refreshList={getFaqs} />
                </Route>
                <Route exact path={path}>
                  <ListPayment payments={payments} />
                </Route>
              </Switch>
            </Container>
          </>
        )}
      </DashboardLayout>
    </>
  );
};

export default FormOfPayment;
