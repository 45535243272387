/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import Carousel from 'react-material-ui-carousel';

import {
  Container,
  CarrouselContainer,
  ListItem,
  LeftColumn,
  Item,
} from './styles';

interface SlideStayInformedProps {
  items: any[];
  rowItems?: number;
  columItems?: number;
}

interface Item {
  id: number;
  // eslint-disable-next-line camelcase
  media_url: string;
}

const SlideStayInformed: React.FC<SlideStayInformedProps> = ({
  items,
  rowItems = 2,
  columItems = 5,
  ...rest
}) => {
  const handlePageMedia = (data: any) => {
    window.open(data.permalink, '_blank');
  };

  const createRightList = (row: any, index: number) => {
    return (
      index !== 0 && (
        <Item
          key={row.id}
          src={row.media_url}
          alt=""
          onClick={() => handlePageMedia(row)}
        />
      )
    );
  };

  const createCarrouselList = (rows: any[]) => {
    return (
      rows.length && (
        <CarrouselContainer>
          {rows.length === 1 && (
            <Item
              src={rows[0].media_url}
              alt=""
              onClick={() => handlePageMedia(rows[0])}
            />
          )}
          {rows.length === 2 && (
            <div>
              <Item
                src={rows[0].media_url}
                alt=""
                onClick={() => handlePageMedia(rows[0])}
              />
              <Item
                src={rows[1].media_url}
                alt=""
                onClick={() => handlePageMedia(rows[1])}
              />
            </div>
          )}
          {rows.length > 2 && (
            <>
              <LeftColumn
                src={rows[0].media_url}
                alt=""
                onClick={() => handlePageMedia(rows[0])}
              />
              <ListItem>
                {rows.map((row: Item, index: number) =>
                  createRightList(row, index),
                )}
              </ListItem>
            </>
          )}
        </CarrouselContainer>
      )
    );
  };

  const memoItens = useMemo(() => {
    const rows = [];
    const itensLength = items.length;

    let index = 0;
    while (index <= itensLength) {
      const slicedItems = items.slice(index, columItems + index);
      rows.push(slicedItems);
      index += columItems;
    }

    return rows;
  }, [items, columItems]);

  return (
    <Container {...rest}>
      <Carousel autoPlay={false} PrevIcon={false} NextIcon={false}>
        {memoItens.length &&
          memoItens.map((rows: any[]) => createCarrouselList(rows))}
      </Carousel>
    </Container>
  );
};

export default SlideStayInformed;
