/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

interface ContainerProps {
  active: boolean;
  asideOpacity: boolean;
}

interface navItemProps {
  active?: boolean;
}

interface TurnByTurnProps {
  travelMode: string;
}

export const Container = styled.div<ContainerProps>`
  .seeMap {
    position: fixed;
    bottom: 25px;
    left: 50%;
    transform: translate(-50%, 0);
    background: var(--orange);
    color: var(--white);
    padding: 15px;
    border-radius: 32px;
    width: 250px;
    display: none;
    z-index: 9999999;

    @media (max-width: 768px) {
      display: block;
    }
  }

  .mb20 {
    margin-bottom: 20px;
  }

  aside {
    display: flex;
    flex-direction: column;
    z-index: 999;
    width: 383px;
    position: fixed;
    overflow-y: auto;
    opacity: 1;

    height: 100vh;
    transform: translateX(0);

    @media (max-width: 768px) {
      width: 100vw;
    }
  }

  ${props =>
    !props.active &&
    css`
      aside {
        transform: translateX(-383px);

        @media (max-width: 768px) {
          transform: translateX(-100vw);
        }
      }
    `};

  ${props =>
    !props.asideOpacity &&
    css`
      aside {
        display: none;
      }
    `};
`;

export const Header = styled.header`
  background: var(--white);
  padding: 0 40px;
  box-shadow: 0 -1px 0 #e8e8e8 inset;
  color: #626162;
  display: flex;
  width: 100vw;
  justify-content: space-between;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Aside = styled.aside`
  transition: all 0.3s;
  overflow-y: auto;
  height: 95vh;
  background: var(--white);
  border-right: 1px solid #dedede;
`;

export const AsideDetail = styled.aside`
  transition: all 0.3s;
  overflow-y: auto;
  height: 95vh;
  background: var(--white);
  border-right: 1px solid #dedede;
`;

export const Main = styled.main`
  position: relative;
  background: var(--white);
`;

export const InputsWrapper = styled.div`
  background: var(--orange);
  width: 100%;
  height: 180px;
  padding: 20px;
  display: flex;

  .backContainer {
    align-self: baseline;
    cursor: pointer;
  }

  .inputsContainer {
    width: 100%;
    margin-left: 17px;
  }
`;

export const DetailTitleWrapper = styled.div`
  background: linear-gradient(45deg, #dc582a -20%, #dc582a 20%, #f98041 100%);
  width: 100%;
  min-height: 144px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  font-weight: bold;
  font-size: 22px;
`;

export const RouteContainer = styled.div`
  width: 100%;
  padding: 10px 12px;
  border-bottom: 1px solid #dadada;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const RoutePathItem = styled.div`
  width: 100%;
  min-height: 50px;
  display: flex;
  margin-bottom: 12px;

  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  color: #3b393b;

  .title {
    margin-right: 15px;
  }
`;

export const RoutesWrapper = styled.div`
  background: var(--white);
  width: 100%;
`;

export const RouteItem = styled.div`
  background: var(--white);
  width: 100%;
  border-bottom: 1px solid #dadada;
  padding: 20px;
  transition: background 0.3s ease;
  display: flex;

  .price {
    display: flex;
    flex-direction: column;
    min-width: 92px;
    margin-right: 10px;

    .timeText {
      color: #423434;
      font-weight: 600;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      font-family: '"Roboto"', Helvetica, Arial, sans-serif;
      font-size: 15px;
    }

    .value {
      font-family: '"Roboto"', Helvetica, Arial, sans-serif;
      font-size: 14px;
      font-weight: 500;
      color: #7d7d7d;
      font-weight: 600;
    }
  }

  .location {
    color: #3b393b;

    .min {
      font-family: '"Roboto"', Helvetica, Arial, sans-serif;
      font-size: 14px;
      font-weight: bold;
      display: flex;
      color: #cd5a57;
      margin-bottom: 8px;
    }

    .start {
      margin-bottom: 10px;
      font-size: 14px;

      .ballon {
        display: inline-block;
        padding: 3px 8px;
        font-family: 'Roboto', Helvetica, Arial, sans-serif;
        font-size: 14px;
        border-radius: 8px;
        color: #fff;
        background: linear-gradient(
          45deg,
          #dc582a -20%,
          #dc582a 20%,
          #dc582a 100%
        );
        margin-right: 5px;
      }
    }
  }

  &:hover {
    cursor: pointer;
    background: #e8e8e8;
  }
`;

export const LogoContainer = styled.div`
  width: 135px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: auto;
    height: 33.599999999999994px;
    margin: 5.200000000000003px;
  }
`;

export const ItensContainer = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin-left: 50px;
`;

export const NavItem = styled.li<navItemProps>`
  font-weight: bold;
  line-height: 24px;
  text-transform: uppercase;
  color: #626162;
  cursor: pointer;
  margin-right: 15px;

  transition: color 0.3s ease;

  &:hover {
    color: var(--orange);
  }

  ${props =>
    props.active &&
    css`
      color: var(--orange);
      font-weight: 700;
      border-bottom: 2px solid var(--orange);
    `}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: center;
  align-items: baseline;

  a {
    color: var(--orange);
    font-size: 0.99rem;
    line-height: 1rem;
    margin-right: 15px;
    transition: color 0.3s ease;

    &:hover {
      color: #626162;
    }
  }

  button {
    height: 32px;
    background: var(-orange);
    border-radius: 10px;
    transition: all 0.2s;

    &:hover {
      background: #dc5129;
    }
  }

  .cadastrar {
    margin: 0px;

    &:hover {
      color: white;
    }
  }
`;
export const TurnByTurnContainer = styled.div``;

export const TurnByTurnItem = styled.div<TurnByTurnProps>`
  display: flex;
  flex-direction: row;

  .imageContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    min-width: 48px;
    margin: 0 16px;
    margin-top: -5px;
    text-align: right;

    svg {
      max-width: 24px;
      font-size: 24px;
      color: black;
    }
  }

  .path {
    width: 12px;
    min-width: 12px;

    .circle {
      position: relative;
      width: 12px;
      height: 12px;
      background-color: #fff;
      border-spacing: 2px;
      border-style: solid;
      border-radius: 50%;

      .circleText {
        transform: translate3d(-45px, -8px, 0);
        color: #636363;
        font-weight: 600;
      }

      .circleTextEnd {
        transform: translate3d(-50px, -11px, 0);
        color: #636363;
        font-weight: 600;
        margin-bottom: 50px;
      }

      ${props =>
        props.travelMode === 'WALKING' &&
        css`
          border-color: #ebbe54;
        `}

      ${props =>
        props.travelMode === 'TRANSIT' &&
        css`
          border-color: var(--orange);
        `}

      ${props =>
        props.travelMode === 'ENDING' &&
        css`
          border-style: double;
          margin-left: -2px;
          border-width: 8px;
          border-color: #3b393b;
        `}
    }

    .line {
      width: 0;
      height: 100%;
      margin: auto;
      border-right-width: 2px;
      border-right-style: ${props =>
        props.travelMode === 'WALKING' ? 'dotted' : 'solid'};
      border-color: ${props =>
        props.travelMode === 'WALKING' ? '#ebbe54' : 'var(--orange)'};
    }
  }

  .content {
    margin: 0 16px;
    margin-top: -6px;
    margin-bottom: 6px;

    .title {
      margin-top: 16px;
      margin-bottom: 8px;
      font-family: 'Roboto', Helvetica, Arial, sans-serif;
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      color: #3b393b;
    }

    .last {
      margin-top: 0;
      margin-bottom: 35px;
    }

    .stepType {
      margin-top: 14px;
      margin-bottom: 14px;
      font-family: 'Roboto', Helvetica, Arial, sans-serif;
      font-size: 14px;
      font-weight: normal;
      line-height: 16px;
      color: #3b393b;

      .ballon {
        display: inline-block;
        padding: 3px 8px;
        font-family: 'BrandonText', Helvetica, Arial, sans-serif;
        font-size: 14px;
        border-radius: 8px;
        color: #fff;
        background: linear-gradient(
          45deg,
          #dc582a -20%,
          #dc582a 20%,
          #f98041 100%
        );
        margin-right: 5px;
      }
    }
    .stops {
      font-family: 'Roboto', Helvetica, Arial, sans-serif;
      font-size: 13px;
      font-weight: normal;
      line-height: 16px;
      color: #626162;
    }
  }

  .instructionsItem {
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.4;
    color: #626162;
    list-style-type: initial;
    margin-bottom: 10px;
  }
`;

export const CloseContainer = styled.div`
  position: absolute;
  top: 0;
  cursor: pointer;
  margin-top: 15px;

  svg {
    transition: color 0.3s ease;
    &:hover {
      color: #dadada;
    }
  }
`;
