import styled from 'styled-components';

export const Container = styled.div`
  h3 {
    color: var(--purple);
    margin-bottom: 5px;
  }
  .value {
    color: #000000;
    font-weight: 600;
  }
`;
