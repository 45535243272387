import React, { useCallback } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';

import Button from '../../Button';
import { Container, ItensContainer, NavItem, ButtonsContainer } from './styles';

const NavBar: React.FC = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { user, signOut } = useAuth();

  const navItens = [
    { name: 'INÍCIO', path: '/' },
    { name: 'CARTÕES E RECARGA', path: '/cartoes-recarga' },
    { name: 'PARA EMPRESAS', path: '/para-empresas' },
    { name: 'LOJAS', path: '/lojas-locais-recarga' },
    { name: 'ROTAS E LINHAS', path: '/rotas-e-linhas' },
    { name: 'QUEM SOMOS', path: '/quem-somos' },
    { name: 'FALE CONOSCO', path: '/fale-conosco' },
  ];

  const handleRedirect = useCallback(
    path => {
      push(path);
    },
    [push],
  );

  return (
    <Container>
      <ItensContainer>
        {navItens.map(item => (
          <NavItem
            onClick={() => handleRedirect(item.path)}
            active={!!(pathname === item.path)}
            key={item.name}
          >
            {item.name}
          </NavItem>
        ))}
      </ItensContainer>
      <ButtonsContainer>
        <Link to={user ? '/meus-cartoes' : '/login'}>Entrar</Link>
        {user ? (
          <Button
            color="orange"
            padding="2"
            className="cadastrar-button"
            onClick={signOut}
          >
            SAIR
          </Button>
        ) : (
          <Button
            color="orange"
            padding="2"
            className="cadastrar-button"
            onClick={() => handleRedirect('/cadastrar')}
          >
            CADASTRAR
          </Button>
        )}
      </ButtonsContainer>
    </Container>
  );
};

export default NavBar;
