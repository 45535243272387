/* eslint-disable no-param-reassign */
import React, {
  ChangeEvent,
  useRef,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { useField } from '@unform/core';
import Button from '../Button';
import { Container } from './styles';

interface Props {
  name: string;
  title: string;
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

const FileInput: React.FC<InputProps> = ({ name, title, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [preview, setPreview] = useState(defaultValue);
  const handlePreview = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      setPreview(null);
    }
    setPreview(file.name);
  }, []);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref: HTMLInputElement) {
        ref.value = '';
        setPreview(null);
      },
      setValue(_: HTMLInputElement, value: string) {
        setPreview(value);
      },
    });
  }, [fieldName, registerField]);
  return (
    <Container>
      <Button type="button" color="orange" padding="1" rounded>
        {title ? `${title}` : 'Anexar Arquivo'}
      </Button>
      {!!preview && preview}
      <input type="file" ref={inputRef} onChange={handlePreview} {...rest} />
      {!!error && <span>{error}</span>}
    </Container>
  );
};

export default FileInput;
