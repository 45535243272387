import React from 'react';
import Lottie from 'react-lottie';
import LoaderAnimation from '../../assets/notFoundMarker.json';
import { Container } from './styles';

const EmptyRoute: React.FC = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoaderAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Container>
      <Lottie
        height={150}
        width={150}
        options={defaultOptions}
        isStopped={false}
        isPaused={false}
      />
      <h1>Nenhuma Rota Encontrada</h1>
    </Container>
  );
};

export default EmptyRoute;
