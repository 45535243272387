/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-const */
/* eslint-disable no-console */
import React, { createContext, useCallback, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../services/api';

export interface UserInterface {
  id: number;
  name: string;
  username: string;
  full_name: string;
  email: string;
  cpf: string;
}

interface AuthState {
  token: string;
  user: UserInterface;
  migrated_user?: boolean;
}

interface SignInCredentials {
  user: string;
  password: string;
}

interface AuthContextData {
  user: UserInterface | any;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  setData(e: any): void;
  data: any;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Transfacil:token');
    const user = localStorage.getItem('@Transfacil:user');
    if (token && user) {
      api.defaults.headers.Authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const { push } = useHistory();

  const signIn = useCallback(
    async ({ user, password }) => {
      const response = await api.post('/v2/accounts/login/', {
        user,
        password,
      });
      let result = response.data;
      api.defaults.headers.Authorization = `Bearer ${result.token}`;
      localStorage.setItem('@Transfacil:token', result.token);
      const responseUser = await api.get('/accounts/profile/');
      result.user = {
        ...result.user,
        ...responseUser?.data,
      };
      setData({ ...result });
      localStorage.setItem('@Transfacil:user', JSON.stringify(result.user));
      push('/meus-cartoes');
    },
    [push],
  );

  const signOut = useCallback(async () => {
    localStorage.removeItem('@Transfacil:token');
    localStorage.removeItem('@Transfacil:user');
    delete api.defaults.headers.Authorization;

    try {
      await api.get('/accounts/logout/');
    } catch (error) {
      console.log('error');
    } finally {
      delete api.defaults.headers.Authorization;
      setData({} as AuthState);
    }
  }, []);
  // React.useEffect(() => {
  //   const token = localStorage.getItem('@Transfacil:token');
  //   const user = localStorage.getItem('@Transfacil:user');

  //   if (!token || !user) {
  //     return () => {
  //       delete api.defaults.headers.Authorization;
  //     };
  //   }
  // }, [data.token]);

  return (
    <>
      <AuthContext.Provider
        value={{ user: data.user, data, setData, signIn, signOut }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth só pode ser usado dentro de um AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
