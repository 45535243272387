import React from 'react';
import Lottie from 'react-lottie';
import LoaderAnimation from '../../../assets/loader.json';

const LoaderSubLine: React.FC = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoaderAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Lottie
      height={50}
      width={50}
      style={{
        margin: '-90px auto',
        zIndex: 10,
        transform: 'translateY(160%)',
      }}
      options={defaultOptions}
      isStopped={false}
      isPaused={false}
    />
  );
};

export default LoaderSubLine;
