/* eslint-disable no-console */
/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';

import { BsChevronRight } from 'react-icons/bs';
import api from '../../services/api';
import { Container, Content } from '../../styles/components';
import Header from '../../components/Home/Header';
import NavBar from '../../components/Home/NavBar';
import Background from '../../assets/Shared/testeira.png';
import Accordion from '../../components/QuemSomos/Accordion';
import Footer from '../../components/Home/Footer';

import {
  WorkwithUsSection,
  JobsSection,
  FormContent,
  WorkwithUsDiv,
  VagasDiv,
} from './styles';
import TrabalheConoscoForm from './TrabalheConoscoForm';

const VagasDisponiveis: React.FC = () => {
  const [jobs, setJobs] = useState([]);

  const handleLink = (link: string) => {
    window.open(link, '_blank');
  };

  useEffect(() => {
    const getJobs = async () => {
      try {
        const response = await api.get('/core/vagas/');
        setJobs(response.data.results);
      } catch (err) {
        console.log(err);
      }
    };
    getJobs();
  }, []);

  return (
    <>
      <Container>
        <Content>
          <Header />
        </Content>
      </Container>
      <Container style={{ backgroundImage: `url(${Background})` }}>
        <Content>
          <NavBar />
        </Content>
      </Container>
      <VagasDiv>
        <Container>
          <Content>
            <WorkwithUsDiv>
              <WorkwithUsSection>
                <div className="text-container">
                  <h1>TRABALHE CONOSCO</h1>
                  <h3 className="workwithus">
                    Que tal fazer parte de uma equipe de talentos que trabalha
                    para transformar a mobilidade urbana de BH?
                  </h3>
                  <h3 className="workwithus" style={{ fontWeight: 'bold' }}>
                    Preencha o formulário, coloque as suas áreas de interesse e
                    deixe o seu currículo conosco.
                  </h3>
                </div>
              </WorkwithUsSection>
              <FormContent>
                <TrabalheConoscoForm />
              </FormContent>
            </WorkwithUsDiv>
            <JobsSection>
              <h1 className="h1">
                CONFIRA NOSSAS VAGAS E PARTICIPE DO PROCESSO SELETIVO!
              </h1>
              <div className="accordionContainer">
                {jobs.map(vaga => (
                  <Accordion
                    key={vaga.id}
                    title={vaga.name}
                    CollapseIcon={BsChevronRight}
                  >
                    <div className="vagas">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: vaga.description,
                        }}
                      />
                      <button
                        onClick={() => handleLink(vaga.link)}
                        type="button"
                        className="button"
                      >
                        Quero me candidatar
                      </button>
                    </div>
                  </Accordion>
                ))}
                {!jobs.length && (
                  <span>
                    No momento, não há vagas disponíveis. Preencha o formulário
                    acima e faça parte do nosso Banco de Talentos. <br />
                    <strong>
                      {' '}
                      Agradecemos o interesse em fazer parte do nosso time!
                    </strong>
                  </span>
                )}
              </div>
              {/* {!!jobs.length && (
                <button
                  onClick={() =>  push('/trabalhe-conosco')}
                  type="button"
                  className="button"
                >
                  Quero me candidatar
                </button>
              )} */}
            </JobsSection>
          </Content>
        </Container>
      </VagasDiv>
      <Container>
        <Footer />
      </Container>
    </>
  );
};

export default VagasDisponiveis;
