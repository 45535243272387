/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { VscAccount } from 'react-icons/vsc';
import AccountsService from '../../services/accounts';
import { useToast } from '../../hooks/toast';

import DashboardLayout from '../../components/DashboardLayout';
import { Container, TitleSection } from './styles';

import ContactInformation from '../../components/MyProfile/ContactInformation';
import PrincipalInformation from '../../components/MyProfile/PrincipalInformation';
import { useAuth } from '../../hooks/auth';

export interface ProfileData {
  address: string | null;
  birth_date: string;
  full_name: string;
  cpf: string;
  email: string;
  id: number;
  phone: string | null;
}

const MeuPerfil: React.FC = () => {
  const [profile, setProfile] = useState<ProfileData>({} as ProfileData);
  const { addToast } = useToast();
  const { signOut } = useAuth();
  const services = { AccountsService: new AccountsService() };

  useEffect(() => {
    const getProfile = async () => {
      setProfile(null);
      try {
        const response = await services.AccountsService.getProfile();
        setProfile(response.data);
      } catch (err) {
        const { ...error }: any = err;

        addToast({
          type: 'error',
          title: `${
            error?.response?.data?.message
              ? error?.response?.data?.message
              : 'Erro no Servidor'
          }`,
          description: `${
            error?.response?.data?.sub_message
              ? error?.response?.data?.sub_message
              : 'Tente deslogar e logar novamente, caso o erro persista entre em contato com o Administrador'
          }`,
        });
        if (err) {
          signOut();
        }
      }
    };
    getProfile();
  }, []);

  return (
    <DashboardLayout>
      <Container>
        <TitleSection>
          <VscAccount color="var(--purple)" className="icon" size={40} />
          <h1>Meu Perfil</h1>
        </TitleSection>

        <PrincipalInformation
          profile={profile}
          updateStateProfile={setProfile}
        />

        <ContactInformation profile={profile} updateStateProfile={setProfile} />

        {/* {modalIsOpen ? (
            <>
              <ModalSenha>
                <div className="wrapper">
                  <button
                    className="close"
                    type="button"
                    aria-label="close"
                    onClick={closeModalSenha}
                  />
                  <h1>Alteração de senha</h1>

                  <Form ref={modalForm} onSubmit={onSubmitModal}>
                    <div className="mt-20">
                      <Input
                        name="password"
                        placeholder="Digita a nova senha"
                        label="Nova senha:"
                        type="password"
                        required
                      />
                    </div>

                    <div className="mt-20">
                      <Input
                        name="confirmPassword"
                        placeholder="Confirme a nova senha"
                        label="Confirmar senha:"
                        type="password"
                        required
                      />
                    </div>

                    <div className="btn-wrapper">
                      <Button
                        type="button"
                        color="orange"
                        padding="4"
                        className="button button-transparent"
                        rounded
                        onClick={() => setModalIsOpen(false)}
                      >
                        Sair
                      </Button>
                      <Button
                        type="submit"
                        color="orange"
                        padding="4"
                        className="button btn-right"
                        rounded
                      >
                        Salvar
                      </Button>
                    </div>
                  </Form>
                </div>
              </ModalSenha>
            </>
          ) : null} */}
      </Container>
    </DashboardLayout>
  );
};

export default MeuPerfil;
