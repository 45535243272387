/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-alert */
/* eslint-disable react/display-name */
/* eslint-disable react/button-has-type */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { formatCurrency } from '@brazilian-utils/brazilian-utils';
import * as React from 'react';
import { FiXCircle } from 'react-icons/fi';
import Paper from '@material-ui/core/Paper';
import ModalTicket from '../../../components/ModalTicket';
import { Table } from '../../../components/Table';
import { useToast } from '../../../hooks/toast';
import Loader from '../../../components/Loader';
import api from '../../../services/api';
import { Buttons, DivPrincipal, Main } from './styles';
import Button from '../../../components/Button';
import { TableMap } from './tableMapHistory';

const MapHistory: React.FC<any> = () => {
  const [mapHistory, setMapHistory] = React.useState([]);
  const [ticket, setTicket] = React.useState<any>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [hasData, setHasData] = React.useState<boolean>(true);
  const [botao1, setBotao1] = React.useState<boolean>(true);
  const [botao2, setBotao2] = React.useState<boolean>(true);
  const [botao3, setBotao3] = React.useState<boolean>(true);
  const [botao4, setBotao4] = React.useState<boolean>(true);
  const { addToast } = useToast();


    const convertStringToDate = (dataHoraEvento: any) => {
      if (!dataHoraEvento) {
        return '2'; // Ou defina um valor padrão, se necessário
      }

      const [date, time] = dataHoraEvento.split(' ');
      const [day, month, year] = date.split('/').map(Number);
      const [hours, minutes, seconds] = time.split(':').map(Number);
      // Mês no JavaScript começa do zero, então subtraia 1 do mês
      return new Date(day, month - 1, year, hours, minutes, seconds);
    };


  const getMapHistory = React.useCallback(async () => {
    try {
      setBotao1(false);
      setBotao2(true);
      setBotao3(true);
      setBotao4(true);
      setLoading(true);
      const { data } = await api.get(
        `/ticketing/qr-code/map/?start_date=2021-01-01`,
      );
      const newData = data?.conteudo?.filter((dados: any) =>dados?.codigoQRCode !== null) || []
      setMapHistory(newData);
      if (newData.conteudo.length === 0) {
        setLoading(false);
        setHasData(false);
      }
      setLoading(false);
    } catch (e) {
      console.log({ e });
      setLoading(false);
    }
  }, []);
  const handleDisponiveis = React.useCallback(
    async () => {
      try {
        setBotao1(true);
        setBotao2(false);
        setBotao3(true);
        setBotao4(true);
        setMapHistory([]);
        setLoading(true);
        const { data } = await api.get(
          `/ticketing/qr-code/map/?start_date=2021-01-01`,
        );
        const newData = data?.conteudo?.filter((dados: any) => dados?.codigoQRCode !== null) || [];
        const datadisp = newData.find ((dados: any) => dados?.statusUso === 0 ? dados?.statusUso === 0 : '')
        if (datadisp?.statusUso === 0) {
          setMapHistory([datadisp]);
          setLoading(false);
        } else {
          setHasData(false);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        console.log({ e });
      }
    },
    [],
  );
  const handleExp = React.useCallback(
    async () => {
      try {
        setBotao1(true);
        setBotao2(true);
        setBotao3(false);
        setBotao4(true);
        setMapHistory([]);
        setLoading(true);
        const { data } = await api.get(
          `/ticketing/qr-code/map/?start_date=2021-01-01`,
        );
        const newData = data?.conteudo?.filter((dados: any) =>dados?.codigoQRCode !== null) || [];
        const datadisp = newData.find ((dados: any) => dados?.statusUso === 2)
        if (datadisp?.statusUso === 2) {
          setMapHistory([datadisp]);
          setLoading(false);
        } else {
          setHasData(false);
          setLoading(false);
        }

      } catch (e) {
        setLoading(false);
        console.log({ e });
        addToast({
          type: 'error',
          title: 'Erro Ticket',
          description: 'Erro Ticket!',
        });
      }
    },
    [],
  );
  const handleUtil = React.useCallback(
    async () => {
      try {
        setBotao1(true);
        setBotao2(true);
        setBotao3(true);
        setBotao4(false);
        setMapHistory([]);
        setLoading(true);
        const { data } = await api.get(
          `/ticketing/qr-code/map/?start_date=2021-01-01`,
        );

        const newData = data?.conteudo?.filter((dados: any) =>dados?.codigoQRCode !== null) || [];
        const datadisp = newData.find ((dados: any) => dados?.statusUso === 1)
        if (datadisp?.statusUso === 1) {
          setMapHistory([datadisp]);
          setLoading(false);
        } else {
          setHasData(false);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        console.log({ e });
        addToast({
          type: 'error',
          title: 'Erro Ticket',
          description: 'Erro Ticket!',
        });
      }
    },
    [],
  );


  // const HandleDate90 = () => {
  //   const Date90 = new Date(currentDate);
  //   Date90.setDate(currentDate.getDate() - 90);
  //   setDateStart(Date90);
  // };

  React.useEffect(() => {
    getMapHistory();
  }, []);
  return (
    <DivPrincipal>
        <h1 className="datatext">
          Histórico de QrCodes
        </h1>
       <div className="botoes">
        <Button
          color={botao1 ? 'var(--orange)' : 'var(--purple)'}
          padding="3.5"
          rounded
          className="button-cadastrar"
          type="submit"
          onClick={getMapHistory}
          style={{
            backgroundColor: botao1 ? 'var(--orange)' : 'var(--purple)',
          }}
        >
          TODOS
        </Button>
        <Button
          color={botao2 ? 'var(--orange)' : 'var(--purple)'}
          padding="3.5"
          rounded
          className="button-cadastrar"
          type="submit"
          style={{
            backgroundColor: botao2 ? 'var(--orange)' : 'var(--purple)',
          }}
          onClick={handleDisponiveis}
        >
          DISPONÍVEIS
        </Button>
        <Button
          color={botao3 ? 'var(--orange)' : 'var(--purple)'}
          padding="3.5"
          rounded
          className="button-cadastrar"
          type="submit"
          style={{
            backgroundColor: botao3 ? 'var(--orange)' : 'var(--purple)',
          }}
          onClick={handleExp}
        >
          EXPIRADOS
        </Button>
        <Button
          color={botao4 ? 'var(--orange)' : 'var(--purple)'}
          padding="3.5"
          rounded
          className="button-cadastrar"
          type="submit"
          style={{
            backgroundColor: botao4 ? 'var(--orange)' : 'var(--purple)',
          }}
          onClick={handleUtil}
        >
          UTILIZADOS
        </Button>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <Main>
        {hasData ? (
          <Paper className="paper">
            <div className="Tabela">
              <TableMap
                title=""
                columns={[
                  {
                    name: 'Código QRCode',
                    selector: 'codigoqrcode',
                    cell: ({ codigoQRCode }: any) => (
                      <div className="specifitcell">{codigoQRCode}</div>
                      
                    ),
                  },
                  {
                    name: 'Linha',
                    selector: 'linha',
                    cell: ({ linha }: any) => (
                      <div className="specifitcell">{linha}</div>
                    ),
                  },
                  {
                    name: 'Valor',
                    selector: 'valor',
                    cell: ({ valor }: any) => (
                      <div className="specifitcell">{`R$ ${formatCurrency(
                        Number(valor),
                      )}`}</div>)
                  },
                  {
                    name: 'Data da compra',
                    selector: 'datacompra',
                    cell: ({ dataHoraEvento }: any) => {
                      const datafinal = convertStringToDate(dataHoraEvento);

                      return <div className="specifitcell">{dataHoraEvento}</div>;
                    }
                      ,
                  },
                  {
                    name: 'Ações / Status',
                    selector: (row: any) => {
                      if (!row?.codigoQRCode) return null

                      switch (row?.statusUso) {
                        case 0:
                          return (
                            <Buttons
                              onClick={async () => {
                                try {
                                  const response = await api.get(`/ticketing/qr-code/reissue-ticket-by-value/${row?.codigoQRCode}/ `);
                                  setTicket({
                                    code: row?.codigoQRCode,
                                    line: row?.linha,
                                    value: Number(row?.valor),
                                    image: response?.data?.imagemBase64,
                                    dataHora: response?.data?.dataHoraFimValidadeCarga,
                                    tempoExpiracao: response?.data?.tempo_expiracao
                                  })
                                  console.log(response?.data);
                                  setLoading(true)
                                  console.log({ row });
                                } catch (e) {
                                  console.log({ e });
                                  setTicket(undefined)
                                  setLoading(false)
                                } finally {
                                  setLoading(false)
                                }
                              }}
                            >
                              Visualizar ticket
                            </Buttons>
                          )
                        case 1:
                          return <p> Confirmada Utilização </p>
                        case 2:
                          return <p> Expirado </p>
                        case 3:
                          return <p> Ressarcido </p>
                        case 4:
                          return <p> Estorno Ressarcimento </p>
                        default:
                          return null
                      }
                    }
                  },
                ]}
                data={mapHistory}
              />
            </div>
          </Paper>
        ) : (
        <div className="nodata">
          <FiXCircle size={80} className="icon" />
          <h3 className="infonotdata">
            Sem dados disponíves para o filtro selecionado.
          </h3>
        </div>
        )}
        </Main>
      )}
      {ticket && (
        <ModalTicket
          code={ticket?.code}
          line={ticket?.line}
          value={ticket?.value}
          closeModal={() => setTicket(undefined)}
          image={ticket?.image}
          dataValidade={ticket?.dataHora}
          tempoExpiracao={ticket?.tempoExpiracao}
          gerando
        />
      )}
    </DivPrincipal>
  );
};

export { MapHistory };
